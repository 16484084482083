<template>
  <h1><span>Запуск в производство каждый день</span></h1>

  <div v-if="!loaderFilter">
    <Filter
      class="wb-filter"
      :options = "filter"
      :stick = "stick"
      @change-field="showGraph" />
  </div>

  <LoaderTreeSpin v-if="loader" />
  <div v-show="!loader">
    <WBChart
      v-show="graphData.length"
      :dataGraph = "graphData"
      :options = "graphOptions"
      :unit = "stick"
      @chg-options="toggleGraph" />
    <div v-if="!graphData.length" class="text-warn-1">
      Нет данных по выбранным параметрам фильтра
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import WBChart from '@/components/parts/WBChart.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'InWayEveryDay',
  components: {
    WBChart, Filter, LoaderTreeSpin,
  },
  data() {
    return {
      urlAction: '/scm/api/report/in-way-every-day',

      filter: {},
      stick: 'Шт.',

      graphData: [],
      graphOptions: {},

      loaderFilter: true,
      loader: true,
      errors: [],
    };
  },
  mounted() {
    if (localStorage.getItem('stock')) {
      this.urlAction += `?stockid=${localStorage.getItem('stock')}`;
    }
    this.init();
  },
  methods: {
    init() {
      axios.get(this.urlAction)
        .then((res) => {
          this.filter = res.data.filter;
          this.graphData = res.data.graphData;
          this.graphOptions = res.data.graphOptions;
          this.stick = res.data.stick;

          this.loaderFilter = false;
          this.loader = false;
        })
        .catch(() => {
          this.loaderFilter = false;
          this.loader = false;
          this.$router.push('/404');
        });
    },
    showGraph() {
      this.loader = true;
      this.loaderFilter = true;
      setTimeout(() => {
        if (this.filter.left[0].value.val) {
          localStorage.setItem('stock', this.filter.left[0].value.val);
        } else {
          localStorage.setItem('stock', this.filter.left[0].value);
        }

        axios.post(this.urlAction, {
          filter: this.filter,
          onlystock: this.modeStock,
        })
          .then((res) => {
            this.graphData = res.data.graphData;
            this.graphOptions = res.data.graphOptions;
            this.stick = res.data.stick;
            this.filter = res.data.filter;

            this.loaderFilter = false;
            this.loader = false;
          })
          .catch(() => {
            this.loaderFilter = false;
            this.loader = false;
            this.$router.push('/404');
          });
      }, 50);
    },
    toggleGraph(data) {
      this.graphOptions.legend[data.ind].show = data.val;
    },
  },
};
</script>

<style scoped>
.report-area-btn{
  float: right;
  margin-right: 12px;
}
.btn-stick-def{
  background: #ddd;
  padding: 4px 10px;
  display: inline-block;
  color: #000;
}
.btn-stick-def.active{
  background: #5981ce;
  color: #fff;
}
.left-round{border-radius: 4px 0 0 4px;}
.right-round{border-radius: 0 4px 4px 0;}
</style>
