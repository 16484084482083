<template>
  <h1>
    <span>Целевые уровни</span>
    <span style="float:right">{{pagination.cnt}}</span>
  </h1>
  <div v-if="pagination.cnt && pagination.els.length > 1" class="pagination">
    <div v-for="(el, ind) in pagination.els" :key="ind"
      class="pag-el" :class="{active: pagination.page == el.lbl}"
      @click="pagLoad(el)"
      @keydown.f7="pagLoad(el)">{{el.lbl}}</div>
  </div>
  <transition name="fade">
    <div v-if="!loaderFilter">
      <Filter
        :options = "filter"
        stick = ""
        @change-field="showList" />
    </div>
  </transition>
  <button @click="openModal" class="btn-load-cu"
    >Загрузить ЦУ XLSX файлом</button>
  <transition name="fade">
    <div v-if="loader" class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-else>
      <div class="wrp-table">
        <div v-if="infoData.length" class="wrp-line-1">
          <div class="line-1">{{infoTitle}} </div>
          <div v-for="(el, ind) in infoData" :key="ind" class="line-1 badge">
            {{el.lbl}}: <strong>{{el.val}}</strong> {{el.unit}}
          </div>
        </div>
        <table class="table-iface">
          <thead>
            <tr class="tr-head">
              <th v-for="(el, ind) in head" :key="ind"
              :title="el.lbltitle"
              :class="{hover: el.sort.show, active: el.sort.active > 0,
                'btn-filter': ind === 0, 'btn-active': showFilter && ind === 0}"
              @click="sort(ind)">
                <div v-if="ind == numberFieldCalc && infoData.length"
                  class="stick-head-dop-info">{{summWeight}} кг.</div>
                <i v-if="el.sort.active == 1"
                  class="fa right"
                  :class="{
                    'fa-sort-amount-asc': el.sort.val == 0,
                    'fa-sort-amount-desc': el.sort.val == 1
                  }"
                  aria-hidden="true"></i>
                <template v-if="ind === 0">
                  <i title="Показать/скрыть фильтр" class="fa fa-filter" aria-hidden="true"></i>
                </template>
                <template v-else>
                  <span v-if="el.type === 0">{{el.lbl}}</span>
                  <span v-if="el.type === 1" v-html="el.lbl" class="th-icon"
                    :style="{'color': el.color}" ></span>
                </template>
              </th>
            </tr>
            <tr class="table-filter"
              :class="{'filter-hide': !showFilter, 'filter-show': showFilter}">
              <td :title="el.title" v-for="(el, ind) in tablefilter" :key="ind"
              style="padding: 0px;">
                <div v-if="ind === 0" class="wrp-icon-filter">
                  <i class="fa fa-filter big-icon" aria-hidden="true"></i>
                </div>
                <InputTextShort v-if="el.type == 'I'"
                  v-model="el.value"
                  @change="chgSubFilter"
                  class="short-input-filter" />
              </td>
            </tr>
          </thead>
          <transition name="fade">
            <tbody v-if="loaderBody" class="bg-loader">
              <tr><td colspan="12">
                <div class="spinner">
                  <i style="color:#fff"
                     class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                  <i style="color:#2567f3"
                     class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                  <i style="color:#f32525"
                     class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                </div>
              </td></tr>
            </tbody>
            <tbody v-else-if="list.length">
              <tr v-for="(tr, ind) in list" :key="ind">
                <td v-for="(el, ind2) in tr.fields" :key="ind2">
                  <template v-if="el.type === 'H'">
                    <button v-if="el.btn && el.btn.icon" class="btn-group"
                      @click="showGroup(ind, el.value)">
                      <i class="fa" :class="el.btn.icon" aria-hidden="true"></i>
                    </button>
                  </template>
                  <template v-if="el.type === 'S'">
                    <router-link v-if="el.link" class="btn-field"
                      :to="el.link.url" :title="el.link.title" >
                      <i class="fa" :class="el.link.icon" aria-hidden="true"></i>
                    </router-link>
                    <span>{{el.value}}</span>
                  </template>
                  <template v-if="el.type === 'L'">
                    <router-link v-if="el.link" class="btn-field"
                      :to="el.link.url" :title="el.link.title" >
                      <i class="fa" :class="el.link.icon" aria-hidden="true"></i>
                    </router-link>
                    <a href="javascript:void(0)" @click.prevent="showArticleBtn(el)"
                      class="btn-a">{{el.value}}</a>
                  </template>
                  <div v-if="el.type === 'G'" style="position:relative">
                    <div title="Присвоить рек.ЦУ" class="btn-squad"
                      @click="assignRecCu(ind, ind2)"
                      @keydown.f7="assignRecCu(ind, ind2)" >
                      <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                      <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                      <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                    </div>
                    <input-text v-model="el.value"
                      :def="el.placeholder"
                      class="l-margin"
                      @update:modelValue="toggleInstrumental" />
                    <div v-if="(el.value * tr.fields[9].value) > 0"
                      class="stick-top">
                      {{Math.round(((el.value - tr.fields[5].value) * tr.fields[9].value) *
                      100000) / 100000}} кг.
                    </div>
                  </div>
                  <template v-if="el.type === 'B'">
                    <div class='progress progress-striped'>
                      <div class='progress-bar' :class="el.opt.color"
                        role='progressbar' :aria-valuenow='el.lbl'
                        aria-valuemin='0' aria-valuemax='100'
                        :style='{width: el.opt.w_block}'>
                        <span class='sr-only'>{{el.lbl}}</span>
                      </div>
                      <div :class="el.opt.color_text"
                      style='position:absolute;' :style="{left: el.opt.left}">{{el.lbl}} %</div>
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else class="text-warn-1">
              <tr><td colspan="11">Список пуст</td></tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>
  </transition>

  <div v-if="showForm" class="context-menu modal-form"
    @click="closeModal"
    @keydown.f7="closeModal">
    <div class="context-menu--body"  @click.stop >
      <div class="" style="width:600px;">
        <div v-if="answerText" class="answer-text">
          {{answerText}}
        </div>
        <template v-else>
          <div class="form-head">
            <div class="form-title">{{form.title}}</div>
          </div>
          <div v-if="errText" class="err-text">{{errText}}</div>
          <div class="form-body">
            <div class="form-field">
               <SelectCustom v-model="form.fields[0].value"
                :def="form.fields[0].placeholder"
                :lbl="form.fields[0].lbl"
                :options="form.fields[0].options"
                :err="form.fields[0].error" />
            </div>
            <div class="form-field">
              <TextareaCustom v-model="form.fields[1].value"
                :def="form.fields[1].placeholder"
                :lbl="form.fields[1].lbl"
                :err="form.fields[1].error" />
            </div>
          </div>
          <a class="wb-btn-sh wb-btn-info"
            style="margin-right:15px;"
            @click.prevent="chgCu"
            @keydown.f7.prevent="chgCu">Изменить ЦУ</a>
          <a class="wb-btn-sh wb-btn-def"
            @click.prevent="closeModal"
            @keydown.f7.prevent="closeModal">Отменить</a>
        </template>
      </div>
    </div>
  </div>

  <transition name="fade">
  <div v-if="showArticle" class="context-menu modal-form"
    @click="closeModalArticle"
    @keydown.f7="closeModalArticle">
    <div class="context-menu--body" style="padding:0px !important;"  @click.stop >
      <div class="head-graph" v-if="article.info.list">
        <div class="line-1">
          <strong class="stock-head">{{article.info.list[0][0]}}: </strong>
          <span class="stock-name">{{article.info.list[0][1]}}</span>&nbsp;
          <strong class="category-head">{{article.info.list[1][0]}}: </strong>
          <span class="category-name">{{article.info.list[1][1]}}</span>
        </div>
        <div class="btn-close-big"
          @click.prevent="closeModalArticle"
          @keydown.f7.prevent="closeModalArticle">
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        <div class="line-1">
          <span>[{{article.info.list[2][1]}}] </span>
          <span class="prod-name">{{article.info.list[3][1]}}</span>
          <router-link class="btn-a"
            style="padding-left: 20px; padding-left: 20px; font-size: 14px; color: #367ce0;"
            :to="{path: article.linkUrl, query: article.linkParam}"
            @click.stop="closeModalArticle"
            title="Перейти в отчет по товару" >
            <span>Перейти в отчет <i class="fa fa-external-link" aria-hidden="true"></i></span>
          </router-link>
          <router-link class="btn-a"
            style="padding-left: 20px; padding-left: 20px; font-size: 14px; color: #367ce0;"
            :to="{path: article.configLink}"
            @click.stop="closeModalArticle"
            title="Перейти к настройкам" >
            <span>Перейти к настройкам <i class="fa fa-cog" aria-hidden="true"></i></span>
          </router-link>
        </div>
      </div>
      <div class="right-top-icons">
        <div v-for="(el, ind) in article.info3" :key="ind"
          :title="el.title"
          class="flag-line"
          :class="el.color">
          <div v-if="el.type == 0" class="icon-head">
            <i :class="el.icon" aria-hidden="true"></i>
          </div>
          <div v-else class="icon-head">{{el.icon}}</div>
          <div class="icon-body">{{el.val}}</div>
        </div>
      </div>
      <div class="wrp-graph"
        v-show="article.graphData.length">
        <WBChart
          :dataGraph = "article.graphData"
          :options = "article.graphOptions"
          @chg-options = "toggleGraph"/>
      </div>

      <div class="inline-block" v-if="article.info.title">
        <div class="title">{{article.info.title}}</div>
        <table>
          <tr v-for="(elem, ind) in article.info.list" :key="ind">
            <template v-if="ind > 3">
              <td v-for="(el, ind2) in elem" :key="ind2">{{el}}</td>
            </template>
          </tr>
        </table>
      </div>
      <div class="wrp-info-line" v-if="article.info4.list && article.info4.list.length">
        <div class="title" v-if="article.info4.title">{{article.info4.title}}</div>
        <table>
          <tr v-for="(el, ind) in article.info4.list" :key="ind">
            <td>[{{el.articul}}] {{el.name}}</td>
          </tr>
        </table>
      </div>
      <div class="wrp-info-line" v-if="article.info2.head">
        <div class="title" v-if="article.info2.title">{{article.info2.title}}</div>
        <table>
          <tr class="table-title">
            <th v-for="(elem, ind) in article.info2.head" :key="ind">{{elem}}</th>
          </tr>
          <tr v-for="(elem, ind) in article.info2.list" :key="ind">
            <td v-for="(ele, ind2) in elem" :key="ind2">{{ele}}</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
  </transition>

  <transition name="slide-form">
    <ModalRight v-show="showModalRight"
      @close="showModalRight = false;"
      :title="modalTitle">
      <LoadFileForChange v-if="showModalRight" :config="filter" />
    </ModalRight>
  </transition>

  <modalGroup v-show="showModalGroup"
    :viewGroup="viewGroup"
    :loadModal="loadModal"
    @close-modal-group="closeModalGroup" />

  <div class="instrumental" :class="{show: instrumental.length}">
    <a v-for="(instrument, ind) in instrumental"
      :key="ind" class="btn btn-add instrumental-item"
      style="float: right"
      @click.prevent="showForm = true"
      @keydown.f7.prevent="showForm = true" >
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import SelectCustom from '@/components/atoms/Select.vue';
import InputText from '@/components/atoms/InputText.vue';
import TextareaCustom from '@/components/atoms/Textarea.vue';
import InputTextShort from '@/components/atoms/InputTextShort.vue';
import WBChart from '@/components/parts/WBChart.vue';
import ModalGroup from '@/components/modal/ModalGroup.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import LoadFileForChange from '@/components/v2/cross/form/LoadFileForChange.vue';

export default {
  name: 'ScmCu',
  components: {
    Filter,
    InputText,
    TextareaCustom,
    SelectCustom,
    InputTextShort,
    WBChart,
    ModalGroup,
    ModalRight,
    LoadFileForChange,
  },
  data() {
    return {
      numberFieldCalc: 7,
      showModalRight: false,
      modalTitle: '',

      l_curr_stock: localStorage.getItem('stock'),

      filter: {},
      list: [],
      head: [],
      page: 1,
      tablefilter: [],
      instrumental: [],
      infoTitle: 'Общий объём запасов:',
      infoData: [],

      showForm: false,
      modalProducts: [],
      modalGenProduct: {},
      modalBuffer: {},
      modalStock: '',

      showFilter: false,

      currParam: {},
      currProduct: {},

      yesBtnChange: false,

      form: {
        title: 'Причина изменения',
        fields: [
          {
            value: '',
            placeholder: '',
            lbl: 'Укажите причину',
            error: '',
            required: false,
          }, {
            value: '',
            placeholder: '',
            lbl: 'Комментарий',
            error: '',
          },
        ],
      },

      pagination: {
        cnt: 0,
        els: [],
        page: 1,
      },
      pagUrlActive: '/scm/cu/sett-iface',

      answerText: '',
      errText: '',
      errors: [],

      loader: true,
      loaderFilter: true,
      loaderBody: false,

      showArticle: false,
      article: {
        graphData: [],
        graphOptions: [],
        info: {
          title: '',
        },
        info2: {
          title: '',
        },
        info3: [],
        info4: {},
        linkUrl: '',
        linkParam: '',
        configLink: '',
        answer: '',
      },

      viewGroup: {},
      loadModal: false,
      showModalGroup: false,
    };
  },
  mounted() {
    this.settIface();
  },
  computed: {
    currSuppliers() {
      let suppliers = [];
      if (this.currProduct.suppliers) {
        suppliers = this.currProduct.suppliers;
      }
      return suppliers;
    },
    summWeight() {
      let summ = 0;
      let i = this.list.length;

      const nField = this.numberFieldCalc;

      while (i) {
        i -= 1;
        if (this.list[i].fields) {
          const el = this.list[i].fields;
          if (el[nField].value !== '') {
            const diff = (parseInt(el[nField].value, 10) - el[nField - 2].value);
            summ += diff * el[nField + 2].value;
          }
        }
      }

      summ = Math.round(summ * 100000) / 100000;

      if (summ > 0) {
        summ = `+${summ}`;
      }

      return summ;
    },
  },
  methods: {
    settIface() {
      this.loader = true;
      let url = '/scm/cu/sett-iface';

      if (this.$route.query.recchangecu) {
        url += `?recchangecu=${this.$route.query.recchangecu}`;
      }
      if (this.$route.query.stockid) {
        url += `&stockid=${this.$route.query.stockid}`;
      } else if (this.l_curr_stock) {
        url += `?stockid=${this.l_curr_stock}`;
      }

      axios.get(url)
        .then((res) => {
          if (res.data.success === 1) {
            this.filter = res.data.filter;
            this.head = res.data.list.heads;
            this.tablefilter = res.data.list.filter;

            res.data.list.items.forEach((item) => {
              this.list.push(item);
            });

            this.infoTitle = res.data.info.title;
            this.infoData = res.data.info.els;
            this.form = res.data.form;

            this.pagination = res.data.pagination;

            this.instrumental = res.data.instrumental;
          }
          this.loader = false;
          this.loaderFilter = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loader = false;
        });
    },
    pagLoad(el) {
      if (this.pagination.page === el.lbl) { return; }

      this.pagUrlActive = el.val;

      this.loaderBody = true;

      setTimeout(() => {
        this.loadList(1);
      }, 300);
    },
    assignRecCu(i, j) {
      const elRec = this.list[i].fields[j - 1];
      this.list[i].fields[j].value = elRec.value;

      if (!this.yesBtnChange) {
        this.addBtn();
      }
    },
    toggleInstrumental(val) {
      if (val !== '') {
        if (!this.yesBtnChange) {
          this.addBtn();
        }
      } else {
        let flagHide = true;
        let i = this.list.length;
        while (i) {
          i -= 1;
          if (this.list[i].fields) {
            let j = this.list[i].fields.length;
            while (j) {
              j -= 1;
              if (this.list[i].fields[j].type === 'G') {
                if (this.list[i].fields[j].value !== '') {
                  flagHide = false;
                  break;
                }
              }
            }
          }
        }

        if (flagHide) {
          this.instrumental = [];
          this.yesBtnChange = false;
        }
      }
    },
    addBtn() {
      const btn = {
        type: 1,
        icon: 'fa-sliders',
        lbl: 'Указать причину, после изменить ЦУ',
      };
      this.instrumental.push(btn);
      this.yesBtnChange = true;
    },
    showList() {
      this.loader = true;
      this.loaderFilter = true;
      this.pagUrlActive = '/scm/cu/sett-iface';
      setTimeout(() => {
        this.loadList(0);
      }, 300);
    },
    closeModal() {
      this.showForm = false;
      this.answerText = '';
    },
    chgCu() {
      const pack = [];
      let i = this.list.length;
      while (i) {
        i -= 1;
        let j = this.list[i].fields.length;
        let id = 0;
        let val = 0;
        while (j) {
          j -= 1;
          const el = this.list[i].fields[j];
          if (el.name === 'id') {
            id = el.value;
          }
          if (el.name === 'chg_cu' && el.value !== '') {
            val = el.value;
          }
        }

        if (id > 0 && val > 0) {
          pack.push({
            val: id,
            lbl: val,
          });
        }
      }

      this.form.fields.push(pack);

      axios.post('/scm/cu/chg', this.form.fields)
        .then((res) => {
          if (res.data.success === 1) {
            this.answerText = res.data.answer;
            this.showList(this.filter);
            this.form.fields.pop();
            this.instrumental = [];
            this.yesBtnChange = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
    },
    loadList(mode) {
      if (this.filter.left[0].value.val) {
        localStorage.setItem('stock', this.filter.left[0].value.val);
      } else {
        localStorage.setItem('stock', this.filter.left[0].value);
      }

      axios.post(this.pagUrlActive, {
        filter: this.filter,
        dopfilter: { arr: this.tablefilter },
        sort: { arr: this.head },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.filter = res.data.filter;
            this.list = res.data.list.items;
            if (mode === 0) {
              this.tablefilter = res.data.list.filter;
              this.head = res.data.list.heads;
            }

            this.pagination = res.data.pagination;

            this.infoTitle = res.data.info.title;
            this.infoData = res.data.info.els;
          }
          this.loader = false;
          if (mode !== 1) {
            this.loaderFilter = false;
          }
          this.loaderBody = false;
        })
        .catch((e) => {
          this.loader = false;
          if (mode !== 1) {
            this.loaderFilter = false;
          }
          if (mode !== 2) {
            this.loaderBody = false;
          }
          this.errors.push(e);
        });
    },
    chgSubFilter() {
      this.loaderBody = true;
      this.pagUrlActive = '/scm/cu/sett-iface';
      this.loadList(2);
    },
    sort(ind) {
      if (!this.head[ind].sort.show) {
        if (ind === 0) {
          this.showFilter = !this.showFilter;
        }
        return;
      }
      if (this.head[ind].sort.show) {
        this.loaderBody = true;
      }
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
        this.pagUrlActive = '/scm/cu/sett-iface';
        this.loadList(1);
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
        this.pagUrlActive = '/scm/cu/sett-iface';
        this.loadList(1);
      }
    },
    showArticleBtn(el) {
      this.showArticle = true;
      document.body.style.overflowY = 'hidden';

      this.article.linkUrl = el.url;
      this.article.linkParam = el.param;

      const filterArticul = {
        left: [
          {
            value: {
              val: el.param.stock,
            },
          },
          {
            value: el.param.prod,
          },
        ],
      };

      axios.post('/scm/api/report/articul', { filter: filterArticul })
        .then((res) => {
          if (res.data.success === 0) {
            this.$router.push('/login');
          } else {
            this.article.graphData = res.data.graphData;
            this.article.graphOptions = res.data.graphOptions;
            this.article.answer = res.data.answer;

            this.article.info = res.data.info;
            this.article.info2 = res.data.info2;
            this.article.info3 = res.data.info3;
            this.article.info4 = res.data.info4;

            this.article.configLink = res.data.configLink;
          }
        })
        .catch(() => {
        });
    },
    toggleGraph(data) {
      this.article.graphOptions.legend[data.ind].show = data.val;
    },
    closeModalArticle() {
      document.body.style.overflow = 'visible';
      this.showArticle = false;
    },
    showGroup(ind, val) {
      this.viewGroup = this.list[ind];
      this.showModalGroup = true;

      if (!this.list[ind].extinfo) {
        axios.get('/scm/product-group/ext-info', {
          params: {
            val,
            analog: 1,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.list[ind].extinfo = res.data.info;
              this.list[ind].childs = res.data.childs;
            }
            this.loadModal = true;
          })
          .catch((e) => {
            this.errors.push(e);
            this.loadModal = true;
          });
      }
    },
    closeModalGroup() {
      this.showModalGroup = false;
    },
    openModal() {
      document.body.style.overflowY = 'hidden';
      this.showModalRight = true;
      this.modalTitle = this.filter.left[0].value.lbl;
    },
  },
};
</script>

<style scoped>
.wrp-graph{
  padding-left: 10px; padding-right: 10px;
  background: white;
}
.head-graph{
  padding-bottom: 20px;
  background: white;
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}

.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}
.btn-active{
  color: #5981ce;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 50px;
  background: white;
  z-index: 10;
  transition: width 1s;
  /* height: 40px; */
  white-space: nowrap;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.short-input{
  width:100px;
}
.wb-input-text.l-margin{
  margin-left:40px;
}
.btn-squad{
  float: left;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background: #749be5;
}
.btn-squad:hover {
  cursor: pointer;
  background: #4c7fe1;
}

.form-title{
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
}

.err-text{
  margin-top: -18px;
  margin-bottom: 10px;
  color: #b34040;
  background: #ffdfdf;
  padding: 4px;
}

.block .column{
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 40px;
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: bottom;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.form-field{
  margin-bottom: 20px;
}

.instrumental{
  height: 0px;
  /* overflow: hidden; */
  transition: all 0.5s;
}
.instrumental.show{
  height: 31px;
}
.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.btn-a{color: #556b7d}
.btn-a:hover {color: #2f4a60; text-decoration: underline;}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.btn-close{
  float: right;
  font-size: 20px;
  color: #780c0c;
  cursor: pointer;
}
.btn-close:hover {
  color: #bf1111;
}

.btn-close-big{
  position: absolute;
  right: 20px;
  top: 4px;
  font-size: 30px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
}
.btn-close-big:hover {
  color: #bf1111;
}

.context-menu--item{
  display:block;
  line-height: 20px;
  cursor:pointer;
  padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
    background-color: #5baade;
}

.progress-bar-black {
    background-color: #757575;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-bar-danger {
    background-color: #d9534f;
}

.progress-bar-success {
    background-color: #5cb85c;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}
.context-menu{
  text-align: center;
}

.table-iface th.hover:hover {
  background: #dfdddd;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}

.wrp-line-1{
  float: right;
  margin-top: -19px;
}
.line-1{
  display: inline-block;
}
.line-1.badge{
  border: 1px solid #cccc;
  padding: 0px 4px;
  margin-left: 4px;
  background: #e6e6e6;
  border-bottom: none;
}
.head-graph .line-1{display: block;}
.wrp-line-1 .line-1:first-child{
  padding-top:0px;
  background: none;
  padding: 0px;
}
.wrp-line-1 .line-1:last-child{
  line-height: 18px;
  font-size: 16px;
  color: #000;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
.stick-top{
  position: absolute;
  top: -6px;
  right: 6px;
  background: #b8e7ac;
  border: 1px solid #86a97d;
  font-size: 12px;
  line-height: 12px;
  padding: 0px 4px;
}
.stick-head-dop-info{
  position: absolute;
  top: 0px;
  right: 0px;
  background: #b8e7ac;
  font-weight: normal;
  padding: 0px 6px;
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #86a97d;
}
.btn-group{
  cursor: pointer;
  border: none;
  padding: 0px;
  font-size: 24px;
  color: #4a975b;
}

.right-top-icons{
  margin-top: -20px;
  z-index: 1;
  position: relative;
  background: #bbb;
  float: right;
}
.flag-line{
  display: inline-block;
  vertical-align: top;
}
.right-top-icons .flag-line{
  margin: 0px 0px 0px 2px;
  background: #efefef;
  padding: 4px 8px 2px;
  text-align: center;
  min-width: 30px;
  cursor: default;
}
.right-top-icons .flag-line > div {
  display: inline-block;
}
.icon-head{margin-right: 12px;}

.flag-line.blue,
.flag-line.green,
.flag-line.yellow,
.flag-line.red,
.flag-line.black,
.flag-line.gray{
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,
                    transparent 0,
                    transparent 50%,
                    hsla(0,0%,100%,.15) 0,
                    hsla(0,0%,100%,.15) 75%,
                    transparent 0,
                    transparent);
  color: #fff;
}
.flag-line.blue{ background-color: #5baade;}
.flag-line.green { background-color: #5cb85c;}
.flag-line.yellow { background-color: #f0ad4e; color: #000;}
.flag-line.red { background-color: #d9534f;}
.flag-line.black { background-color: #757575;}

.flag-line.new{ background: #e7e992; color: #136e23; }
.btn-load-cu{
  margin-left: 12px;
  border-radius: 0px;
  padding: 7px;
  border: 1px solid #ccc;
  border-bottom: none;
}
</style>
