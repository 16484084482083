<template>
  <canvas @mousemove="placeCoordinates" :id="name" height='320' width='320'></canvas>
</template>

<script>
export default {
  name: 'LifeColumn',
  props: {
    name: String,
    val: Number,
    vals: Array,
    offset: Number,
    inverse: Boolean,
  },
  data() {
    return {
      canvas: null,
      xMouse: 0,
      yMouse: 0,
      wCanvas: 0,
      hCanvas: 0,
      rs: [],

      lOffset: 120,
      rOffset: 120,
      tOffset: 20,
      bOffset: 20,

      tempAxel: 0,

      errors: [],
    };
  },
  computed: {
    wColumn() {
      return this.wCanvas - this.lOffset - this.rOffset;
    },
    hColumn() {
      return this.hCanvas - this.tOffset - this.bOffset;
    },
  },
  mounted() {
    const c = document.getElementById(this.name);
    this.canvas = c.getContext('2d');

    this.wCanvas = c.width;
    this.hCanvas = c.height;

    this.init();
  },
  methods: {
    init() {
      const ctx = this.canvas;

      ctx.beginPath();
      // ctx.strokeStyle = '';
      ctx.fillStyle = '#ddd';
      ctx.fillRect(this.lOffset, this.tOffset, this.wColumn, this.hColumn);

      let i = this.vals.length;
      i -= 1;
      const interval = this.hColumn / i;
      while (i) {
        i -= 1;

        const y = this.tOffset + interval * i;

        ctx.beginPath();
        ctx.fillStyle = this.vals[i].color;
        ctx.shadowColor = '#fff';
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.fillRect(this.lOffset, y, this.wColumn, interval);

        if (!this.inverse) {
          if (this.val >= this.vals[i].val && this.val <= this.vals[i + 1].val) {
            this.selectedDraw(y, y + interval, this.vals[i].val, this.vals[i + 1].val);
          }
        }
        if (this.inverse) {
          if (this.val <= this.vals[i].val && this.val >= this.vals[i + 1].val) {
            this.selectedDraw(y, y + interval, this.vals[i].val, this.vals[i + 1].val);
          }
        }

        ctx.shadowColor = '#fff';
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.fillStyle = '#000';
        ctx.font = 'italic 20px Arial';
        ctx.textBaseline = 'middle';
        ctx.fillText(this.vals[i].lbl, this.lOffset + this.wColumn + 5, y);
      }

      ctx.fillStyle = '#000';
      ctx.font = 'italic 20px Arial';
      ctx.textBaseline = 'middle';
      ctx.fillText(this.vals[3].lbl, this.lOffset + this.wColumn + 5, this.tOffset + this.hColumn);
    },
    selectedDraw(h, d, y1, y2) {
      const ctx = this.canvas;
      let predelPixel = d - h;
      let predelPercent = y2 - y1;

      let needVal = this.val - y1;
      const needPercent = ((needVal * 100) / predelPercent);
      let offsetPixel = (predelPixel * needPercent) / 100;

      if (this.inverse) {
        predelPercent = y1 - y2;
        predelPixel = d - h;
        needVal = this.val - y2;
        const needPercent = ((needVal * 100) / predelPercent);
        offsetPixel = predelPixel - ((predelPixel * needPercent) / 100);
      }

      const startLine = h + offsetPixel;

      ctx.shadowColor = '#fff';
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;

      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000';
      ctx.moveTo(this.lOffset - 60, startLine - 1);
      ctx.lineTo(this.lOffset + this.wColumn + 2, startLine - 1);
      ctx.stroke();

      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000';
      ctx.moveTo(this.lOffset - 60, startLine + 1);
      ctx.lineTo(this.lOffset + this.wColumn + 2, startLine + 1);
      ctx.stroke();

      ctx.fillStyle = '#000';
      ctx.font = 'italic 14px Arial';
      ctx.textBaseline = 'middle';
      ctx.fillText(`${Math.round(this.val * 100) / 100}%`, this.lOffset - 58, startLine - 1 - 10);

      ctx.font = 'bold 14px Arial';
      ctx.textBaseline = 'top';
      let off = '';
      if (this.inverse) {
        ctx.fillStyle = '#c51717';
        off = `↓ ${Math.round(this.offset * 100) / 100}`;
        if (this.offset > 0) {
          ctx.fillStyle = '#2db128';
          off = `↑ +${Math.round(this.offset * 100) / 100}`;
        }
      } else {
        ctx.fillStyle = '#c51717';
        off = `↓ +${Math.round(this.offset * 100) / 100}`;
        if (this.offset < 0) {
          ctx.fillStyle = '#2db128';
          off = `↑ ${Math.round(this.offset * 100) / 100}`;
        }
      }

      ctx.fillText(off, this.lOffset - 58, startLine - 1 + 5);
    },
    placeCoordinates() {
    },
  },
};
</script>

<style scoped>
.panel-bottom {
  display:flex;
  position: fixed;
  bottom:0px;
  left:0px;
  width: 100%;
  background: #cacaca;
  padding-right:12px;
  text-align: right
}
.panel-bottom .block-info {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 5px;
}
.sum-rub {
  text-align: right;
  background: #82b5ef;
  display: inline-block;
  padding: 2px 12px;
  color: rgb(255, 255, 255);
  font-size: 23px;
  float: right;
  line-height:30px;
}
</style>
