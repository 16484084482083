<template>
  <h1><span>{{mess.title}}</span></h1>
  <transition name="fade">
    <div v-if="loader" class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-else-if="form.flag_send_1c.lbl" style="padding: 0px 15px;">
      <div class="line" style="overflow:hidden">
        <Checkbox v-model="form.chk.value"
          :text="form.chk.lbl"
          :err="form.chk.error" />
      </div>
      <div class="line" style="overflow:hidden">
        <Checkbox v-model="form.flag_use_custom_stock.value"
          :text="form.flag_use_custom_stock.lbl"
          :err="form.flag_use_custom_stock.error" />
      </div>
      <div class="line">
        <input-text v-model="form.name.value"
          :def="form.name.placeholder"
          :lbl="form.name.lbl"
          :err="form.name.error"
          :req="form.name.required" />
      </div>
      <div class="line">
        <Radio v-model="form.flag_send_1c.value"
          :lbl="form.flag_send_1c.lbl"
          :options="form.flag_send_1c.options"
        />
      </div>

      <div class="line">
        <div class="wb-select" v-click-outside="closeSelect">
          <div class="label">{{form.mode_type.lbl}}</div>
          <div class="wb-select-label"
            @click.stop="showList = !showList"
            @keydown.f7.stop="showList = !showList">
            <div class="icon-right">
              <template v-if="showList">
                <i class="fa fa-chevron-up"></i>
              </template>
              <template v-else >
                <i class="fa fa-chevron-down"></i>
              </template>
            </div>
            <div class="val-text">
              {{form.mode_type.value.lbl}}
            </div>
          </div>

          <div class="wb-select-list" v-if="showList">
            <div v-for="(option, index) in form.mode_type.options" :key="index"
              class="list--item"
              @click="selectTypeMode(option)"
              @keydown.f7="selectTypeMode(option)">{{option.lbl}}</div>
          </div>
        </div>

        <transition-group name="fade">
          <div class="tab" v-if="form.mode_type.value.val == 1">
            <div class="line-flex">
              <div class="point">
                <div class="">Доступные склады</div>
                <transition-group name="fade">
                  <div v-for="(el2, ind2) in form.mode_type.value.options.allStock" :key="ind2"
                    class="move-stock"
                    @click="addStockPack(ind2)"
                    @keydown.f7="addStockPack(ind2)">
                    {{el2.lbl}}
                  </div>
                </transition-group>
              </div>
              <div class="point">
                <div class="">Выбранные склады</div>
                <transition-group name="fade">
                  <div v-for="(el2, ind2) in form.mode_type.value.options.currStock" :key="ind2"
                    class="move-stock-active"
                    @click="delStockPack(ind2)"
                    @keydown.f7="delStockPack(ind2)">
                    {{el2.lbl}}
                  </div>
                </transition-group>
              </div>
            </div>
          </div>
          <div class="tab" v-if="form.mode_type.value.val == 2">
            <div class="line">
              <SelectCustom class="w100" v-model="form.stock.value"
                :def="form.stock.placeholder"
                :lbl="form.stock.lbl"
                :options="form.stock.options"
                :err="form.stock.error"
                @change="selectStock" />
            </div>

            <div class="line-flex">
              <div class="point">
                <div class="custom-wb-select">
                  <div class="custom-label">Категории ({{custCatChk}} / {{custCatAll}})</div>
                  <div class="list" style="padding: 6px 12px 10px;">
                    <ListIerarchDef
                      :action="form.category.action"
                      :stock="form.stock.value.val"
                      mode="checkbox"
                      :autorule="form.id.value"
                      @sel="setCategory"
                      :key="form.stock.value.val"
                    />
                  </div>
                </div>
              </div>
              <div class="point">
                <MultiSelect v-model="form.supplier.value"
                  :lbl="form.supplier.lbl"
                  :def="form.supplier.placeholder"
                  :options="form.supplier.options" />
              </div>
              <div class="point">
                <MultiSelect v-model="form.product.value"
                  :lbl="form.product.lbl"
                  :def="form.product.placeholder"
                  :options="form.product.options" />
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <div v-show="form.mode_type.value.val !== 0">
        <div class="line">
          <ModeTime v-if="form.time_type.value" v-model="form.time_type.value"
            :data="form.time_type" />
        </div>
      </div>
      <div v-if="form.time_type.value && form.time_type.value.val > 0
                || form.mode_all_all.value === 1" class="line">
        <div style="margin-bottom:6px"><strong>{{form.time_hours.lbl}}</strong></div>
        <div class="time">
          <InputText v-model="form.time_hours.value" />
        </div>
        <div class="time min">:</div>
        <div class="time">
          <InputText v-model="form.time_minutes.value" />
        </div>
      </div>

      <div class="line">
        <div style="margin-bottom:6px"><strong>{{form.time_clean_hours.lbl}}</strong></div>
        <div class="time">
          <InputText v-model="form.time_clean_hours.value" />
        </div>
        <div class="time min">:</div>
        <div class="time">
          <InputText v-model="form.time_clean_minutes.value" />
        </div>
      </div>

      <div class="wb-btn-sh wb-btn-success"
        @click="saveRule"
        @keydown.f7="saveRule">{{mess.btnText}}</div>
      <br />
      <br />
      <br />
      <br />
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import SelectCustom from '@/components/atoms/Select.vue';
import InputText from '@/components/atoms/InputText.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import Radio from '@/components/atoms/Radio.vue';
import MultiSelect from '@/components/atoms/MultiSelect.vue';
import ModeTime from '@/components/parts/ModeTime.vue';
import ListIerarchDef from '@/components/atoms/ListIerarchDef.vue';

export default {
  name: 'ScmAutoorder',
  components: {
    SelectCustom, InputText, Checkbox, Radio, MultiSelect, ModeTime, ListIerarchDef,
  },
  data() {
    return {
      form: {
        id: {},
        chk: {},
        flag_use_custom_stock: {},
        name: {},
        flag_send_1c: {},
        stock: {},
        category: {},
        supplier: {},
        product: {},
        time_type: {},
        time_hours: {},
        time_minutes: {},
        time_clean_hours: {},
        time_clean_minutes: {},
      },

      mess: {},

      showList: false,
      currOption: {},

      loader: true,
      errors: [],

      custCatAll: 0,
      custCatChk: 0,
    };
  },
  mounted() {
    this.settIface();
  },
  methods: {
    settIface() {
      let url = '';
      if (this.$route.query.elem) {
        url = `/scm/order/autorule-add?elem=${this.$route.query.elem}`;
      } else {
        url = '/scm/order/autorule-add';
      }
      axios.get(url)
        .then((res) => {
          if (res.data.success === 1) {
            this.form = res.data.form;
            this.mess = res.data.mess;
          }
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loader = false;
        });
    },
    saveRule(event) {
      axios.post('/scm/order/auto-add-save', this.form)
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.clientX, event.clientY, res.data.answer, 'ok');

            this.$router.push({ name: 'ScmAutoorder' });
          } else {
            this.answer(event.clientX, event.clientY, res.data.answer, 'err');
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    selectStock() {
      console.log('изменен склад');
    },
    answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'err') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
    setCategory(list) {
      this.form.category.options = list;

      this.custCatAll = list.length;
      let custCatChk = 0;
      list.forEach((entry) => {
        if (entry.chk) {
          custCatChk += 1;
        }
      });
      this.custCatChk = custCatChk;
    },
    selectTypeMode(option) {
      this.showList = false;
      this.currOption = option;

      console.log(this.currOption);

      this.form.mode_type.value = option;
    },
    closeSelect() {
      this.showList = false;
    },
    addStockPack(ind) {
      this.form.mode_type.value.options.currStock.push(
        this.form.mode_type.value.options.allStock[ind],
      );
      this.form.mode_type.value.options.allStock.splice(ind, 1);
    },
    delStockPack(ind) {
      this.form.mode_type.value.options.allStock.push(
        this.form.mode_type.value.options.currStock[ind],
      );
      this.form.mode_type.value.options.currStock.splice(ind, 1);
    },
  },
};
</script>

<style scoped>
.time{
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  text-align:center;
}
.time.min{width: 20px}
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 20px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-tab:hover{background:#eee;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
.line{
  margin-bottom:20px;
}
.line-flex{
  /* margin: 0px -5px 20px -5px; */
  display: flex;
}
.line-flex .point{
  margin:0 5px;
  flex: 1;
  width: 33%;
  /*
  display: inline-block;
  vertical-align: top;
  */
  height:300px;
  overflow: hidden;
  overflow-y: auto;
}
.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
.line-danger{
  background: #f5c5c5;
  padding: 4px 6px;
}

.custom-wb-select{
  position:relative; border-radius: 0 4px 4px 0;
  border: 1px solid #aaa;
  background: #fff;
  border-left: 4px solid #aaa;
  min-width: 200px;
  padding-top:20px;
  height: 92%;
  overflow-y: auto;
}
.custom-label{
  position: absolute;
  background: #5981ce;
  left: 0px;
  top: 0px;
  font-size: 14px;
  line-height: 16px;
  padding: 1px 12px;
  border-radius: 0px;
  color: #fff;
}

/* for select */
.tab{
  padding:12px;
  border: 1px solid #aaa;
  border-top: none;
  border-left-width: 4px;
}
.tab .el{overflow: hidden;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 6px;
  padding: 2px 6px 2px 6px;
}
.tab .el .galka{display:none; color: #000;}
.tab .el:hover{
  cursor: pointer;
  background: #aaa;
}
.tab .el.active{
  background: #63b15e;
  color: #fff;
}
.tab .el.active .galka{display: inline;}
.tab .el .squad{margin-right: 6px;}
.tab .el span{line-height: 20px}
.wb-select{
  position:relative;
  border-radius: 0 4px 4px 0;
  border: 1px solid #aaa;
  cursor: pointer;
  background: #fff;
  border-left: 4px solid #aaa;
  min-width: 200px;
}
.wb-select:hover{background:#ccc}
.wb-select-label{
  padding: 10px 12px;
  white-space: nowrap;
}
.val-text{
  overflow: hidden;
}
.wb-select-label:hover{background:#efefef;}
.wb-select-list{
  position: absolute;
  left: -4px;
  top: 26px;
  margin-top: 10px;
  width: 100%;
  max-height: 250px;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0px 0px 4px 4px;
  overflow-y: auto;
  border-left: 4px solid #aaa;
  z-index:2
}
.list--item{padding:8px 12px }
.list--item:hover{background:#ddd}

.label{
  position: absolute;
  background: #5981ce;
  left: 8px;
  top: -10px;
  font-size: 14px;
  line-height: 16px;
  padding: 1px 12px;
  border-radius: 0px;
  color: #fff;
}
.icon-right{
  float: right;
  margin-left: 5px;
}
/* /for select */
.move-stock{
  margin-top: 4px;
  background-color: #ebebeb;
  padding: 4px 4px;
  cursor: pointer;
  transition: background-color 0.7s;
}
.move-stock:hover{
  background-color: #d5d5d5;
}
.move-stock-active{
  margin-top: 4px;
  background: #63b15e;
  padding: 4px 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.7s;
}
.move-stock-active:hover{
  background-color: #449f3e;
}

</style>
