<template>
  <div class="comp-multi-fields">
    <div class="label">
      {{lbl}}
    </div>
    <div class="c-4 th" v-for="(el, ind2) in heads" :key="ind2">
      {{el}}
    </div>
    <div class="line r" style="white-space: nowrap">
      <div v-for="(row, ind2) in items" :key="ind2">
        <div class="c-4" v-for="(el, ind3) in row" :key="ind3">
          <SingleFile v-if="el.type == 'F'" v-model="el.value"
            :ind="ind3"
            :err="el.error"
            @outError="outError"
            />
          <input-text v-else-if="el.type == 'I'" v-model="el.value"
            :def="el.placeholder"
            :err="el.error"
            :ind="ind3"
            @outError="outError" />
          <SelectCustom v-else-if="el.type == 'L'" v-model="el.value"
            class="w100"
            :def="el.placeholder"
            :options="el.options"
            :err="el.error"
            :ind="ind3"
            @outError="outError" />
        </div>
      </div>
    </div>
    <div style="text-align:right">
      <div v-if="btnMinusShow" class="btn-minus"
        @click="removeRow()"
        @keydown.f7="removeRow()">
        <i class="fa fa-minus" aria-hidden="true"></i>
      </div>
      <div v-if="btnPlusShow" class="btn-plus"
        @click="addRow()"
        @keydown.f7="addRow()">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</template>
<script>
import SelectCustom from '@/components/atoms/Select.vue';
import InputText from '@/components/atoms/InputText.vue';
import SingleFile from '@/components/atoms/SingleFile.vue';

// ['def', 'lbl', 'sample', 'err', 'ind', 'modelValue'],
export default {
  name: 'MultiFields',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    ind: {
      type: Number,
      default: 0,
    },
    err: {
      type: String,
      default: '',
    },
    fields: Array,
    tpl: Array,
    heads: Array,
  },
  components: {
    SelectCustom,
    InputText,
    SingleFile,
  },
  data() {
    return {
      btnPlusShow: true,
      btnMinusShow: false,
    };
  },
  computed: {
    items() {
      return this.fields;
    },
  },
  methods: {
    handleFileUpload() {
      this.$emit('update:modelValue', this.fields);
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
    addRow() {
      this.items.push(this.tpl);
      this.btnMinusShow = true;
    },
    removeRow() {
      if (this.items.length > 1) {
        const index = this.items.length - 1;
        this.items.splice(index, 1);
        if (this.items.length === 1) {
          this.btnMinusShow = false;
        }
      } else {
        this.btnMinusShow = false;
      }
    },
  },
};
</script>
<style scoped>
 .comp-multi-fields .label{
  background: #5981ce;
  font-size: 14px;
  line-height: 16px;
  padding: 1px 12px;
  border-radius: 0px;
  color: #fff;
}
.comp-multi-fields .th{
  background: #c7c7c7;
}
.comp-multi-fields .btn-plus, .comp-multi-fields .btn-minus{
  display: inline-block; vertical-align:top;
  text-align: center;
  width: 50px;
  background-color: #ddd;
  line-height: 23px;
  cursor: pointer;
  transition: background-color 0.5s;
  margin-left: 4px;
}
.comp-multi-fields .btn-plus:hover, .comp-multi-fields .btn-minus:hover{
  background-color: #aaa;
}
.r{
  margin-left: -5px;
  margin-right: -5px;
}
.c-4{
  width: calc(100% / 3);
  padding-right: 5px;
  padding-left: 5px;
  min-width: 320px;
  display: inline-block;
  max-height: 320px;
  vertical-align: top;
  box-sizing: border-box;
}
</style>
