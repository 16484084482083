<template>
  <div class="modal-info" @click="closeModal"
    @keydown.f7="closeModal">
    <div @click.stop class="modal-info-body">
      <div class="modal-info-text">{{txt}}</div>
      <div v-if="btns" class="modal-info-btns">
        <div v-for="(el, ind) in btns" :key="ind" class="wrp-wb-btn">
          <template v-if="el.tp == 'action'">
            <div v-if="run == false"
              @click="action(el.action, el.param)"
              @keydown.f7="action(el.action, el.param)"
              class="wb-btn wb-btn-success wb-mini wb-mini-offer">{{el.txt}}</div>
            <LoaderTreeSpin v-else-if="run == true" class="small" />
          </template>
          <template v-else-if="el.tp == 'close'">
            <div class="wb-btn wb-btn-def wb-mini wb-mini-offer"
              @click="closeModal"
              @keydown.f7="closeModal">{{el.txt}}</div>
          </template>
          <template v-else></template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'ModalConfirmation',
  props: ['inputtxt', 'btns'],
  components: {
    LoaderTreeSpin,
  },
  data() {
    return {
      run: false,
      txt: '',
    };
  },
  mounted() {
    this.txt = this.inputtxt;
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    action(url, param) {
      this.run = true;

      axios.post(url, param)
        .then((res) => {
          if (res.data.success === 1) {
            this.txt = res.data.answer;

            this.$emit('ok', res.data);
            setTimeout(() => this.$emit('close'), 3000);
          } else if (res.data.success === 0) {
            this.txt = res.data.answer;
          } else {
            this.txt = 'Ошибка отправки запроса';
          }
          this.run = false;
        });
    },
  },
};
</script>

<style scoped>
.modal-info{
  position: fixed;
  left: 0px;
  top: 0px;
  background: #ccccccaa;
  height: calc(100vh);
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-info-body{
  width: 50%;
  height: auto;
  background: #f0f0f0;
  padding: 16px 20px;
  cursor: default;
}
.modal-info-text{
  font-size: 18px;
  line-height: 22px;
  border-bottom: 6px solid #8392ae;
  padding-bottom: 14px;
}
.modal-info-btns{
  margin-top: 12px;
  text-align: right;
}
.modal-info-btns .wb-btn{

}
.wrp-wb-btn{
  display: inline-block;
  vertical-align: top;
  margin-left: 12px;
}
.wb-btn{width: auto;}
</style>
