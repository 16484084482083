<template>
  <h1>
    <span>Моделирование</span>
    <span style="float:right">{{pagination.cnt}}</span>
  </h1>
  <div v-if="pagination.cnt && pagination.els.length > 1" class="pagination">
    <div v-for="(el, ind) in pagination.els" :key="ind"
      class="pag-el" :class="{active: pagination.page == el.lbl}"
      @click="pagLoad(el)"
      @keydown.f7="pagLoad(el)">{{el.lbl}}</div>
  </div>
  <transition name="fade">
    <div v-if="loader" class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-else>
      <div class="wrp-table">
        <table class="table-iface">
          <thead>
            <tr class="tr-head">
              <th v-for="(el, ind) in head" :key="ind"
              :title="el.lbltitle"
              :class="{hover: el.sort.show, active: el.sort.active > 0,
                'btn-filter': ind === 0, 'btn-active': showFilter && ind === 0}"
              @click="sort(ind)">
                <i v-if="el.sort.active == 1"
                  class="fa"
                  :class="{
                    'fa-sort-amount-asc': el.sort.val == 0,
                    'fa-sort-amount-desc': el.sort.val == 1
                  }"
                  aria-hidden="true"></i>
                <template v-if="ind === 0">
                  <i title="Показать/скрыть фильтр" class="fa fa-filter"
                    aria-hidden="true"></i>
                </template>
                <template v-else>
                  <span v-if="el.type === 0">{{el.lbl}}</span>
                  <span v-if="el.type === 1" v-html="el.lbl" class="th-icon"
                    :style="{'color': el.color}" ></span>
                </template>
              </th>
            </tr>
            <tr class="table-filter"
              :class="{'filter-hide': !showFilter, 'filter-show': showFilter}">
              <td :title="el.title" v-for="(el, ind) in tablefilter" :key="ind"
              style="padding: 0px;">
                <div v-if="ind === 0" class="wrp-icon-filter">
                  <i class="fa fa-filter big-icon" aria-hidden="true"></i>
                </div>
                <InputTextShort v-if="el.type == 'I'"
                  v-model="el.value"
                  @change="chgSubFilter"
                  class="short-input-filter" />
              </td>
            </tr>
          </thead>
          <transition name="fade">
            <tbody v-if="loaderBody" class="bg-loader">
              <tr><td colspan="12">
                <div class="spinner">
                  <i style="color:#fff"
                     class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                  <i style="color:#2567f3"
                     class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                  <i style="color:#f32525"
                     class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                </div>
              </td></tr>
            </tbody>
            <tbody v-else-if="list.length">
              <tr v-for="(tr, ind) in list" :key="ind" :class="{disabled: tr.disabled == 1}">
                <td v-for="(elem, ind2) in tr.fields" :key="ind2">
                  <template v-if="elem.type === 'S'">
                    <router-link v-if="elem.link" class="btn-field"
                      :to="elem.link.url" :title="elem.link.title" >
                      <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                    </router-link>
                    <i v-if="elem.icon && parseInt(elem.value) > 0" class="icon-last-status fa"
                      :class="elem.icon.fa"
                      :title="elem.icon.title" ></i>
                    <span>{{elem.value}}</span>
                  </template>
                  <template v-if="elem.type === 'L'">
                    <a href="javascript:void(0)" @click.prevent="showLink(elem, ind)"
                      class="btn-a">{{elem.value}}</a>
                  </template>
                  <template v-if="elem.type === 'B'">
                    <div class='progress progress-striped'>
                      <div class='progress-bar' :class="elem.opt.color"
                        role='progressbar' :aria-valuenow='elem.lbl'
                        aria-valuemin='0' aria-valuemax='100'
                        :style='{width: elem.opt.w_block}'>
                        <span class='sr-only'>{{elem.lbl}}</span>
                      </div>
                      <div v-if="elem.opt.color_text"
                        class="buffer-lbl"
                        :class="elem.opt.color_text" >{{elem.value}} %</div>
                    </div>
                  </template>
                </td>
                <td v-if="tr.btns"  class="cell-act">
                  <div class="acts__item" v-for="(btn, ind4) in tr.btns" :key="ind4">
                    <div :title="btn.title"
                      @click.prevent.stop="actTable(btn, ind, $event)"
                      @keydown.enter.stop="actTable(btn, ind, $event)" >
                      <i class="fa" :class="btn.icon" aria-hidden="true"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else class="text-warn-1">
              <tr><td colspan="12">Список пуст</td></tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>
  </transition>

  <div v-if="showSett" class="context-menu modal-form"
    @click="closeModalSett"
    @keydown.enter="closeModalSett">
    <div class="btn-close-big">
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <div class="context-menu--body" @click.stop >
      <template v-if="modalStatus == 0 && listForm">
        <div class="context-menu--title">
          Наименование модели: <strong>{{formTitle}}</strong>
        </div>
        <div class="blockquote-citata">
          Добавьте параметры поставщиков
        </div>
        <table class="table-multi-sett">
          <thead>
            <tr>
              <th v-for="(el, ind) in listForm.heads" :key="ind">
                {{el}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(els, ind) in listForm.items" :key="ind">
              <td v-for="(el, ind2) in els" :key="ind2">
                <input-text v-if="el.type == 'I'" v-model="el.value"
                  :def="el.placeholder"
                  :lbl="el.lbl"
                  :err="el.error"
                  :req="el.required"
                  :ind="ind2"
                  @outError="outError" />
                <Checkbox v-else-if="el.type == 'C'" v-model="el.value"
                  class="big"
                  :text="el.placeholder"
                  :err="el.error" />
                <div v-else-if="el.type == 'S'" class="form-lbl">
                  {{el.value}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="collapse-cust">
          <div class="collapse-title" @click="toggleCollapse" @keydown.f7="toggleCollapse">
            <i style="float:right" class="fa fa-angle-down" aria-hidden="true"></i>
            {{titleCollapse}}
          </div>
          <transition name="fade">
            <div class="collapse-body" v-if="showCollapse">
              <div class="collapse-field" v-for="(el, ind) in settPolitik" :key="ind">
                <input-text v-if="el.type == 'I'" v-model="el.value"
                  :def="el.lbl"
                  :lbl="el.lbl"
                  :err="el.error"
                  :req="el.required"
                  :ind="ind"
                  @outError="outError" />
                <Checkbox v-else-if="el.type == 'C'" v-model="el.value"
                  class="big"
                  :text="el.lbl"
                  :err="el.error" />
              </div>
            </div>
          </transition>
        </div>
        <div class="blockquote-danger" v-if="textValidate.length">{{textValidate}}</div>
        <div class="wb-btn wb-btn-success" @click="saveSettSuppliers"
          @keydown.f7="saveSettSuppliers">
          Применить настройки
        </div>
      </template>
      <template v-else>
        {{txtMessageModal}}
      </template>
    </div>
  </div>

  <div v-if="instrumental.length" class="instrumental">
    <a v-for="(instrument, ind) in instrumental" :key="ind"
      class="btn btn-add instrumental-item"
      @click.prevent="act(instrument)"
      @keydown.f7.prevent="act(instrument)">
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import InputText from '@/components/atoms/InputText.vue';
import InputTextShort from '@/components/atoms/InputTextShort.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';

export default {
  name: 'ScmModeling',
  components: {
    InputText, InputTextShort, Checkbox,
  },
  data() {
    return {
      l_curr_stock: localStorage.getItem('stock'),

      list: [],
      head: [],
      tablefilter: [],
      instrumental: [],

      showFormOrder: false,
      modalProducts: [],
      modalGenProduct: {},
      modalBuffer: {},
      modalStock: '',

      showFilter: false,

      currParam: {},
      currProduct: {},
      currIndex: [],

      pagination: {
        cnt: 0,
        els: [],
        page: 1,
      },
      pagUrlActive: '/scm/modeling',

      answerText: '',
      errText: '',
      errors: [],

      loader: true,
      loaderFilter: true,
      loaderBody: false,

      cntAll: 0,

      showSett: false,
      formTitle: '',
      textValidate: '',
      listForm: {},
      settPolitik: [],
      showCollapse: false,
      currEmul: 0,
      currEmulIndex: 0,

      modalStatus: 0,
      txtMessageModal: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loader = true;
      const url = '/scm/modeling';

      axios.get(url)
        .then((res) => {
          if (res.data.success === 1) {
            this.head = res.data.list.heads;

            res.data.list.items.forEach((item) => {
              this.list.push(item);
            });

            this.pagination = res.data.pagination;

            this.instrumental = res.data.instrumental;
          }
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
      /*
      else if (elem.type === 1) {
        this.actionType = 'chg';
        this.modeModal = 0;

        axios.get(btn.action, {
          params: {
            elem: this.items.items[ind].fields[0].value,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.showContextForm = true;
              this.form = res.data.form;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
      */
    },
    showLink(el, ind) {
      document.body.style.overflowY = 'hidden';

      console.log(el.param.status);

      switch (el.param.status) {
        case 3:
          this.modalStatus = 0;
          this.currEmul = el.param.val;
          this.currEmulIndex = ind;
          this.formTitle = el.value;
          this.showSett = true;

          axios.post(el.url, el.param)
            .then((res) => {
              this.listForm = res.data.listForm;
              this.settPolitik = res.data.settPolitik;
              this.titleCollapse = res.data.titleCollapse;
            })
            .catch(() => {
            });
          break;
        case 4:
          this.modalStatus = 1;
          this.showSett = true;
          this.txtMessageModal = 'В очереди на расчет';
          break;
        case 5:
        case 6:
          this.modalStatus = 1;
          this.showSett = true;
          this.txtMessageModal = 'На этапе расчета';
          break;
        case 7:
          this.modalStatus = 0;
          this.showSett = false;
          this.$router.push(el.urldetail);
          break;
        default:
          this.modalStatus = 1;
          this.showSett = true;
          this.txtMessageModal = 'На этапе расчета';
      }
    },
    toggleGraph(data) {
      this.article.graphOptions.legend[data.ind].show = data.val;
    },
    closeModalSett() {
      document.body.style.overflow = 'visible';
      this.showSett = false;
    },
    actTable(btn, ind, event) {
      if (btn.type === 'modalform') {
        this.actionType = 'chg';
        this.actionElem = ind;
        this.modeModal = 0;

        axios.get(btn.action, {
          params: {
            elem: this.list[ind].fields[0].value,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.showContextForm = true;
              this.form = res.data.form;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else if (btn.type === 'modal') {
        this.modeModal = 1;
        this.showContextForm = true;
        this.modalDopInfo = btn.info;
        this.actionModal = `${btn.action}?elem=${this.list[ind].fields[0].value}`;
      } else if (btn.type === 'link') {
        this.$router.push(`${btn.action}?elem=${this.list[ind].fields[0].value}`);
      } else {
        axios.post(btn.action, {
          elem: this.list[ind].fields[0].value,
        })
          .then((res) => {
            if (res.data.success === 1) {
              // this.showAnswer(event, res.data.answer, 'ok');

              if (btn.type === 'add') {
                this.list.push(res.data.el);
              } else if (btn.type === 'del') {
                this.list.splice(ind, 1);
              } else if (btn.type === 'no') {
                console.log('no');
              } else if (!btn.nodel) {
                this.list.splice(ind, 1);
              }
              if (res.data.parent_del) {
                this.$emit('del-parent');
              }
              if (res.data.reload) {
                this.init();
              }
            } else {
              this.showAnswer(event, res.data.answer, 'error');
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    saveSettSuppliers() {
      for (let i = 0; i < this.listForm.items.length; i += 1) {
        for (let j = 0; j < this.listForm.items[i].length; j += 1) {
          if (this.listForm.items[i][j].value.length === 0) {
            this.textValidate = 'Не все поля заполнены';
            return 0;
          }
        }
      }

      this.textValidate = '';

      const url = '/scm/modeling/add-step-two';
      axios.post(url, {
        form: this.listForm.items,
        politika: this.settPolitik,
        val: this.currEmul,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.showSett = false;

            console.log(this.list[this.currEmulIndex].fields[0].value);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });

      return 1;
    },
    toggleCollapse() {
      if (this.showCollapse) {
        this.showCollapse = false;
      } else {
        this.showCollapse = true;
      }
    },
  },
};
</script>

<style scoped>
.collapse-field{
  margin-top: 16px;
}
.collapse-cust{
  margin-top: 14px;
  margin-bottom: 18px;
}
.collapse-cust .collapse-title{
  color: #333;
  background-color: #bfbfbf;
  /* border-color: #343434; */
  padding: 8px;
  border: 1px solid #adadad;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.5s;
}
.collapse-cust .collapse-title:hover{
  background-color: #b5b5b5;
}
.blockquote-danger{
  font-style: italic;
  color: #c50a04;
  font-weight: bold;
  margin-bottom: 12px;
}
.blockquote-citata{
  font-style: italic;
  color: #0269c4;
  font-weight: bold;
}
.table-multi-sett th{
  vertical-align: top;
  font-size: 12px;
  line-heigth: 14px;
  background: #d2d2d2;
}
.wrp-graph{
  padding-left: 10px; padding-right: 10px;
  background: white;
}
.head-graph{
  padding-bottom: 20px;
  background: white;
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}

.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 20px;
  background: white;
  z-index: 10;
  transition: width 1s;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.btn-active{
  color: #5981ce;
}
.tr-head span{
  margin-right: 15px;
}
.right{
  float: right;
}
.modal-form{
  text-align: center;
}
.wrp-table{
  margin-bottom: 40px;
}
.short-input{
  width:100px;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.icon-last-status{
  font-size: 22px;
  font-weight: bold;
  padding-right: 6px;
}

.btn-close{
  float: right;
  font-size: 20px;
  color: #780c0c;
  cursor: pointer;
}
.btn-close:hover {
  color: #bf1111;
}

.btn-close-big{
  position: absolute;
  right: 20px;
  top: 4px;
  font-size: 30px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
}
.btn-close-big:hover {
  color: #bf1111;
}

.err-text{
  margin-top: -18px;
  margin-bottom: 10px;
  color: #b34040;
  background: #ffdfdf;
  padding: 4px;
}

.block .column{
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  margin-right: 12px;
  margin-left: 12px;
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: botton;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.btn-a{color: #556b7d}
.btn-a:hover {color: #2f4a60; text-decoration: underline;}

.btn-cart{
  display: block;
  width: 100%;
  background: #44b752;
  border: 1px solid #2c9539;

  cursor:pointer;
  color:#fff;
  text-align: center
}
.btn-cart:hover{background: #2c9539}
.btn-cart.disabled{
  background: #a4e5ac;
  border-color: #ddd;
  position: relative;
}

.btn-cart.disabled:after{
  content: '';
  position: absolute;
  top: 5px;
  left: 40%;
  margin-left: -15px;
  width: 60px;
  height: 30px;
  border-top: 2px solid red;
  transform: rotate(-40deg);
}

tr.disabled {
  opacity: 0.5;
}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.acts__item > div{
  font-size: 22px;
  cursor: pointer;
}

.fa-times, .fa-chain-broken{color: #ce5959}
.fa-times:hover, .fa-chain-broken:hover{color: #ef3737}

.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
    background-color: #5baade;
}

.progress-bar-black {
    background-color: #757575;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-bar-danger {
    background-color: #d9534f;
}

.progress-bar-success {
    background-color: #5cb85c;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}

.buffer-lbl{
  position: absolute;
  width: 100%;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
