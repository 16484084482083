<template>
  <div class="list">
    <div v-for="(el, ind) in list" :key="ind" class="list-el">
      <template v-if="el.type && el.type == 'CA'">
        <span class="strong">{{el.lbl}}</span>
        <div class="wb-btn wb-btn-success wb-mini" @click="modalInfo(el, ind)"
          @keydown.f7="modalInfo(el, ind)">
          {{el.title}}
        </div>
      </template>
      <template v-else>
        <span class="strong">{{el.lbl}}:</span> <span>{{el.val}}</span>
      </template>
    </div>
  </div>

  <div class="div-btns">
    <div v-for="(el, ind) in btns" :key="ind"
      class="wb-btn-sh wb-btn-danger"
      title="При нажатии удалятся только выше указанные данные"
      @click="act(el)"
      @keydown.f7="act(el)">
        {{el.lbl}}
    </div>
  </div>

  <transition name="fade">
    <div v-if="show_modal_info" class="modal-info" @click="closeModalInfo"
      @keydown.f7="closeModalInfo">
      <div @click.stop class="modal-info-body">
        <div class="modal-info-text">{{alertTxt}}</div>
        <div class="modal-info-btns">
          <div v-if="alertOkShow == 1"
            @click="btnOk"
            @keydown.f7="btnOk"
            class="wb-btn wb-btn-success wb-mini wb-mini-offer">Да, все так</div>
          <div v-else-if="alertOkShow == 0" class="spinner">
            <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          </div>

          <div class="wb-btn wb-btn-def wb-mini wb-mini-offer" @click="btnCancel"
            @keydown.f7="btnCancel">Отмена</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

export default {
  name: 'InfoForm',
  props: ['list', 'btns'],
  data() {
    return {
      show_modal_info: false,
      alertTxt: '',
      alertAction: '',
      alertOption: {},
      alertIndex: 0,
      alertOkShow: 1,
      alertEl: {},

      errors: [],
      spiner: false,
    };
  },
  methods: {
    act(el) {
      axios.get(el.action)
        .then((res) => {
          this.$emit('answer', res.data);
        });
    },
    modalInfo(el, ind) {
      this.show_modal_info = true;
      this.alertTxt = el.alert;
      this.alertAction = el.action;
      this.alertIndex = ind;
      this.alertEl = el;
      this.alertOption = {
        propName: el.name,
        propVal: el.val,
      };
    },
    btnOk() {
      this.alertOkShow = 0;
      if (this.alertAction !== '') {
        axios.post(this.alertAction, this.alertOption)
          .then((res) => {
            if (res.data.success === 1) {
              this.alertTxt = res.data.answer;

              this.alertEl.val = res.data.val;
              if (res.data.val === 1) {
                this.alertEl.title = 'Отключить';
              } else {
                this.alertEl.title = 'Активировать';
              }

              this.$emit('component-update', this.alertEl, this.alertIndex);
              // this.list[this.alertIndex].val = res.data.val;

              setTimeout(() => this.closeModalInfo(), 3000);
            } else if (res.data.success === 0) {
              this.alertTxt = res.data.answer;
            } else {
              this.alertTxt = 'Ошибка отправки запроса';
            }
            this.alertOkShow = 2;
          });
      } else {
        this.alertTxt = 'Ошибка отправки запроса';
      }
    },
    btnCancel() {
      this.closeModalInfo();
    },
    closeModalInfo() {
      this.show_modal_info = false;
      this.alertTxt = '';
      this.alertAction = '';
      this.alertOkShow = 1;
    },
  },
};
</script>

<style scoped>
.list-el{
  background: #eee;
  padding: 12px;
  margin-bottom: 14px;
}
.strong{
  font-weight: bold;
  font-size: 14px;
}
.wb-mini{
  display: inline-block;
  width: auto;
  padding: 4px 14px;
  border-radius: 3px;
}
.wb-mini-offer{
  margin-left: 12px;
}

.modal-info{
  position: fixed;
  left: 0px;
  top: 0px;
  background: #ccccccaa;
  height: calc(100vh);
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal-info-body{
  width: 50%;
  height: auto;
  background: #f0f0f0;
  padding: 16px 20px;
  cursor: default;
}
.modal-info-text{
  font-size: 18px;
  line-height: 22px;
  border-bottom: 6px solid #8392ae;
  padding-bottom: 14px;
}
.modal-info-btns{
  margin-top: 12px;
  text-align: right;
}
.modal-info-btns .wb-btn{

}
.spinner {
  float: left;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.spinner i{background: #cfcfcf; padding: 3px}
</style>
