<template>
  <div class="wb-autocomplette">
    <div style="display:flex">
      <input class="text text-min" @input="sendDataList" v-model="search" :placeholder="def" />
      <div class="list-cnt">{{list.length == 300 ? `${list.length}+` : list.length}}</div>
    </div>
    <transition-group name="list" tag="div" class="list" v-if="list.length > 0">
      <div v-for="(el, ind) in list" :key="ind"
        class="list-el"
        :class="{odd: ind % 2 == 0}"
        >
        <a class="btn-link" @click.prevent="linkEl(ind, el)" @keydown.prevent="linkEl(ind, el)">
        добавить <i class="fa fa-link" aria-hidden="true"></i></a>
        <div class="el-name">{{el.fields[1].value}}</div>
      </div>
    </transition-group>
    <div v-else class="list">
      Не найдены товары
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AutocompletteAdd',
  props: ['lbl', 'text', 'def', 'modelValue', 'url', 'action'],
  data() {
    return {
      list: [],
      search: this.text,
      loader: false,

      options: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get(this.action)
        .then((res) => {
          this.list = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendDataList() {
      if (this.search.length > 2) {
        axios.get(this.action, {
          params: {
            search: this.search,
            limit: 50,
          },
        })
          .then((res) => {
            this.list = res.data.list;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.list = [];
      }
    },
    linkEl(ind, el) {
      axios.post(this.url, {
        el: el.fields[0].value,
      })
        .then((res) => {
          if (res.data.success) {
            this.list.splice(ind, 1);
            this.$emit('checkelautocomplette', el);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
  .list-cnt{
    line-height: 30px;
    padding:0px 4px;
  }
  .wb-autocomplette{
    position: relative;
    z-index: 1;
    height: 105%;
  }
  .wb-autocomplette input.text{
    line-height: 18px;
    border: 1px solid #aaa;
    padding: 10px 8px;
    border-radius: 0px 4px 4px 0px;
    border-left-width: 4px;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    height: 30px;
  }
  .wb-autocomplette input.text:hover{
    background: #efefef;
  }
  .wb-autocomplette-list{
    position: absolute;
    left: 0px;
    top: 37px;
    border:1px solid #aaa;
    border-left-width: 4px;
    min-width: 245px;
    max-width: 320px;
    max-height: 340px;
    overflow-y: auto;
    background: #fff;
  }
  .wb-autocomplette-list .list--item {
    padding:8px 12px;
    cursor: pointer;
  }
  .list--item:hover {
    background: #ddd
  }
  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .list{
    height: 76%;
    overflow-y: auto;

    margin-top: 3px;
    padding: 5px;
    background: #fff;
    border-left: 4px solid #aaa;
    border-right: 1px solid #aaa;
  }

  a.btn-link{
    background-color: #bed1e3;
    cursor: pointer;
    padding: 0 8px;
    float: right;

    padding-top: 3px;
    padding-bottom: 3px;

    transition: color 0.3s, background-color 0.3s;
  }
  a.btn-link:hover{
    background-color: #5981ce;
    color: #fff;
  }

  .list-el{
    background: #efefef;
  }
  .list-el.odd{
    background: #ddd;
  }
  .list-el:hover, .list-el.odd:hover{
    background-color: #bfbfbf;
  }
  .el-name{
    padding:  3px;
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 1s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
</style>
