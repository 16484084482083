<template>
  <div class="board">
    <button v-if="showLeftScroll" class="gran-left"
      @mouseover="left"
      @mouseleave="leave"
      @focusin="left"
      @focusout="leave">
      <span><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
    </button>
    <button v-if="showRightScroll" class="gran-right"
      @mouseover="right"
      @mouseleave="leave"
      @focusin="right"
      @focusout="leave">
      <span><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
    </button>
    <div v-for="column in columns"
        :key="column.id"
        class="column"
        @drop="onDrop($event, column)"
        @dragover.prevent
        @dragenter.prevent>
      <div class="name">{{column.name}}</div>
      <div class="els">
        <div v-for="el in els.filter(x => x.status == column.id)"
            :key="el.id"
            @dragstart="onDragStart($event, el)"
            class="task"
            :class="el.color"
            draggable="true">
          <div class="type">
            <i v-if="el.type == 1" class="fa fa-exchange" aria-hidden="true"></i>
            <i v-else class="fa fa-truck" aria-hidden="true"></i>
          </div>

          <div class="title">{{el.suname}}</div>
          <div class="numb">{{el.title_number}}</div>
          <div class="created">{{el.send_way}}</div>
          <div class="sended">{{el.delivery_date}}</div>
          <div class="pack-stock">
            <div class="stname">{{el.stname}}</div>
            <div class="link-prod"
              @click="showProds(el)"
              @keydown.f7="showProds(el)">
              <i class="fa fa-angle-up" aria-hidden="true"></i>
              <i class="fa fa-angle-right" aria-hidden="true"></i>
              <i class="fa fa-angle-left" aria-hidden="true"></i>
              <span>{{el.els.length}} ед. товара</span>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <div v-if="showContextForm" class="context-menu modal-form"
      @click="closeModal"
      @keydown.f7="closeModal">
      <div class="context-menu--body"  @click.stop >
        <template v-if="mode == 'form'">
          <div v-if="answerForm" class="form-answer">{{answerForm}}</div>
          <Form v-else :title="form.title"
            :btn="form.btn"
            class="center-center"
            :fields="form.fields"
            :action="form.action"
            :method="form.method"
            @answerForm="formAnswer" />
        </template>
        <template v-else-if="mode == 'synh'">
          <SynhModal
            :fields="pack.els"
            :action="pack.action"
            :title="pack.title"
            :btn="pack.btn"
            @answerForm="formAnswer" />
        </template>
        <template v-else>
          <TableInfo
            :title="packTitle"
            :els="pack" />
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import { reactive, toRefs } from 'vue';
import Form from '@/components/atoms/Form.vue';
import SynhModal from '@/components/special/SynhModal.vue';
import TableInfo from '@/components/static/TableInfo.vue';

export default {
  name: 'WBBoard',
  props: {
    action: String,
  },
  components: {
    Form, SynhModal, TableInfo,
  },
  setup(props) {
    const state = reactive({
      els: [],
      columns: [],
      actionStatus: '',
      showContextForm: false,
      answerForm: '',
      mode: 'form',
      form: {
        title: '',
        btn: '',
        fields: [],
        action: '',
        method: 'post',
      },
      pack: [],
    });

    const temp = {
      ind: 0,
      vals: [],
      columnid: 0,
    };

    const fetchBoard = () => {
      axios.get(props.action)
        .then((res) => {
          if (res.data.success) {
            state.els = res.data.els;
            state.columns = res.data.columns;
            state.actionStatus = res.data.actionStatus;
          } else {
            this.answer(0, 0, res.data.answer, 'error');
          }
        });
    };

    fetchBoard();

    function onDragStart(e, el) {
      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('itemId', el.id.toString());
    }
    function onDrop(e, column) {
      const itemId = parseInt(e.dataTransfer.getData('itemId'), 10);
      state.els = state.els.map((x) => {
        if (x.id === itemId) {
          const to = column.id - x.status;
          if (Math.abs(to) === 1) {
            temp.vals = x.vals;
            temp.columnid = column.id;
            temp.ind = state.els.findIndex((el) => el.id === itemId);

            if (to === -1 && column.prevAction !== '') {
              axios.get(column.prevAction, {
                params: { elID: x.vals },
              })
                .then((res) => {
                  if (res.data.success) {
                    state.mode = res.data.mode;
                    state.form = res.data.form;
                    state.showContextForm = true;
                  } else {
                    this.answer(e.pageX, e.pageY, res.data.answer, 'error');
                  }
                });
            } else if (to === 1 && column.nextAction !== '') {
              axios.get(column.nextAction, {
                params: { elID: x.vals },
              })
                .then((res) => {
                  state.mode = res.data.mode;
                  if (state.mode === 'form') {
                    state.form = res.data.form;
                  } else {
                    state.pack = res.data.pack;
                  }
                  state.showContextForm = true;
                });
            } else {
              axios.post(state.actionStatus, {
                elID: x.vals,
                status: column.id,
              })
                .then(() => {
                });

              x.status = column.id;
            }
          } else if (Math.abs(to) > 1) {
            this.answer(e.pageX, e.pageY, 'Нельзя перепрыгивать через промежуточные статусы', 'error');
          }
        }
        return x;
      });
    }

    function formAnswer(msg) {
      if (msg.success === 1) {
        axios.post(state.actionStatus, {
          elID: temp.vals,
          status: temp.columnid,
        })
          .then(() => {
          });
        state.els[temp.ind].status = temp.columnid;

        if (msg.newDate !== undefined) {
          state.els[temp.ind].delivery_date = msg.newDate;
          state.els[temp.ind].color = msg.color;
        }
      }
      if (state.mode === 'synh') {
        state.showContextForm = false;
        state.answerForm = '';
      } else {
        state.answerForm = msg.answer;
      }
    }

    function closeModal() {
      state.showContextForm = false;
      state.answerForm = '';
    }

    function answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e5d2d2';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.border = '1px solid #b32222';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    }

    return {
      ...toRefs(state),
      fetchBoard,
      onDragStart,
      onDrop,
      formAnswer,
      closeModal,
      answer,
    };
  },
  data() {
    return {
      showLeftScroll: false,
      showRightScroll: true,
      timer: '',
      packTitle: '',
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    left() {
      const content = document.querySelector('.board');
      this.showRightScroll = true;

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        content.scrollLeft -= 4;
        if (content.scrollLeft <= 0) {
          this.showLeftScroll = false;
          clearInterval(this.timer);
        }
      }, 20);
    },
    right() {
      const content = document.querySelector('.board');
      this.showLeftScroll = true;

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        content.scrollLeft += 4;
        if (content.scrollLeft === content.scrollWidth - content.clientWidth) {
          this.showRightScroll = false;
          clearInterval(this.timer);
        }
      }, 20);
    },
    leave() {
      clearInterval(this.timer);
    },
    showProds(el) {
      this.mode = 'stat';
      this.showContextForm = true;

      this.pack = el.els;
      this.packTitle = `${el.suname} ⇒ ${el.stname}`;
    },
  },
};
</script>

<style scoped>
  .link-prod{
    text-align: center;
    border-top: 1px solid #8f6726;
    border-bottom: 1px solid #8f6726;
    margin-top: 12px;
    margin-right: -4px;
    margin-left: -4px;
    cursor: pointer;
    transition: background 0.7s;
  }
  .link-prod:hover{
    opacity: 0.9;
    background: #fff;
  }
  .link-prod .fa-angle-up, .link-prod .fa-angle-down{
    display: block;
    line-height: 8px;
    transition: line-height 0.3s;
  }
  .link-prod:hover .fa-angle-up, .link-prod:hover .fa-angle-down{
    line-height: 10px;
  }
  .link-prod .fa-angle-left{
    float: left;
  }
  .link-prod .fa-angle-right{
    float: right;
  }
  .gran-left{
    z-index: 2;
    position: fixed;
    left: 0px;
    top: 50px;
    height: 100%;
    background: transparent;
    border: none;
    transition: background 0.7s, opacity 0.6s;
    cursor: pointer;
  }
  .gran-right {
    z-index:2;
    position: fixed;
    right: 0px;
    top: 50px;
    height: 100%;
    background: transparent;
    border: none;
    transition: background 0.7s, opacity 0.6s;
    cursor: pointer;
  }
  .gran-left span, .gran-right span{
    display: block;
    height: 40px;
    width: 40px;
    margin-top: -20px;
    font-size: 40px;
    line-height: 40px;
  }
  .gran-right:hover, .gran-left:hover {
    background: #555;
    opacity: 0.5
  }
  .title{font-weight: bold;}
  .pack-stock{
    position: relative;
    padding-top: 10px;
  }
  .stname{
    position: absolute;
    left: -4px;
    top: 6px;
    background: #8f6726;
    color: #fff;
    font-size: 12px;
    padding: 1px 6px;
  }
  .droppable {
    padding:15px;
    border-radius: 5px;
    background: #2c3e50;
    margin-bottom: 15px;
  }

  .droppable h4{
    color: white;
  }

  .draggable {
    background: white;
    padding:5px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .draggable h5 {
    margin: 0px;
  }

  .board{
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: hidden;
    position: relative;
    background: url('@/assets/fon.jpg') repeat 0 0;
  }
  .column{
    width: 280px;
    display: inline-block;
    vertical-align: top;
    border-left: 2px solid #5981ce;
  }
  .board .name{
    background: #5981ce;
    color: #fff;
    padding:4px;
  }
  .column .els{
    height: calc(100vh - 70px - 6px);
    overflow-y: auto;
    padding: 0px 4px;
  }
  .created, .sended{
    background: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 4px 2px;
  }
  .created{
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .sended{
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

 .task{
    white-space: normal;
    background: -webkit-gradient(linear,left top,right top,from(#f6efd2),to(#cead78));
    background: linear-gradient(90deg,#f6efd2,#cead78);
    border-radius: 4px;
    margin: 4px 0px;
    padding: 4px;
    position: relative;
    padding-bottom: 20px;

     webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .numb{
    background: #8f6726;
    color: #fff;
    display: inline-block;
    padding: 2px 6px 0px;
    position: absolute;
    left: 50%;
    bottom: 0px;
    margin-left: -36.6px
  }
  .type{
    float: right;
    background: #ffffff;
    padding: 2px 4px;
    margin-right: -4px;
    margin-top: -4px;
    color: #000;
    border-radius: 0 0 0 4px;
  }

  .task.green{
    background: -webkit-gradient(linear,left top,right top,from(#ebfff0),to(#52df6b));
    background: linear-gradient(90deg,#ebfff0,#52df6b)
  }
  .task.green .numb, .task.green .stname{
    background: #4d935a;
  }
  .task.green .link-prod{
    border-top: 1px solid #4d935a;
    border-bottom: 1px solid #4d935a;
  }

  .task.yellow{
    background: -webkit-gradient(linear,left top,right top,from(#fff9de),to(#e9bf49));
    background: linear-gradient(90deg,#fff9de,#e9bf49);
  }
  .task.yellow .numb, .task.yellow .stname{
    background: #9f8a4e;
  }
  .task.yellow .link-prod{
    border-top: 1px solid #9f8a4e;
    border-bottom: 1px solid #9f8a4e;
  }

  .task.red{
    background: -webkit-gradient(linear,left top,right top,from(#ffe5e5),to(#ff5f5f));
    background: linear-gradient(90deg,#ffe5e5,#ff5f5f);
  }
  .task.red .numb, .task.red .stname{
    background: #b31e1e;
  }
  .task.red .link-prod{
    border-top: 1px solid #b31e1e;
    border-bottom: 1px solid #b31e1e;
  }

  .task.black{
    background: -webkit-gradient(linear,left top,right top,from(#ebebeb),to(#a3a3a3));
    background: linear-gradient(90deg,#ebebeb,#a3a3a3);
  }
  .task.black .numb, .task.black .stname{
    background: #6e6e6e;
  }
  .task.black .link-prod{
    border-top: 1px solid #6e6e6e;
    border-bottom: 1px solid #6e6e6e;
  }
</style>
