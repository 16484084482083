<template>
  <div v-if="!loader" class="block" >
    <div class="link" v-for="(el, ind) in list" :key="ind"
      @click="run(ind)"
      @keydown.f7="run(ind)">
        <div style="float: right">
          <span class="wait" v-if="el.status == 0">Очередь</span>
          <span class="err" v-else-if="el.status == -1">Ошибка</span>
          <span class="ok" v-else>Успешно</span>
        </div>
        {{el.lbl}}
    </div>
  </div>
  <div v-else class="text-info-1">
    Выполнение скрипта может занять до 5 минут, не закрывайте страницу
    &nbsp;<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    &nbsp;<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    &nbsp;<i class="fa fa-cog fa-spin" aria-hidden="true"></i>
  </div>
  <pre class="truth-block">{{answer}}</pre>

  <div class="block-request">
    POST
    <div class="fields">
      <input v-model="urlrequest" />
    </div>
    <input type="button" @click="sendCat" value="Отправить" />
    <div class="body-request">
      <label for="txtdd">
        <textarea id="txtdd" v-model="bodyjson"></textarea>
      </label>
    </div>
  </div>
  <pre>{{urlresponse}}</pre>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TestList',
  data() {
    return {
      loader: false,
      errors: [],
      list: [],
      answer: '',

      bodyjson: '',
      urlrequest: '',
      urlresponse: '',
    };
  },
  mounted() {
    this.init();
  },
  computed() {

  },
  methods: {
    init() {
      axios.get('/scm/test')
        .then((res) => {
          this.list = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    run(ind) {
      if (!this.loader) {
        this.loader = true;
        axios.post(this.list[ind].action)
          .then((res) => {
            this.loader = false;
            this.answer = res.data;
            if (res.data.success) {
              this.list[ind].status = 1;
            } else {
              this.list[ind].status = -1;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    sendCat() {
      console.log(this.urlrequest);
      let data = '';
      if (this.bodyjson !== '') {
        data = JSON.parse(this.bodyjson);
      }

      axios.post(this.urlrequest, data)
        .then((res) => {
          this.urlresponse = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
  .block{
    padding: 10px 0px;
    background: #ddd;
  }
  .link{
    cursor: pointer;
    color: #333;
    text-decoration: underline;
    padding: 5px 10px;
  }
  .link:hover{
    text-decoration: none;
    background: #fff;
  }
  .wait{color: black}
  .err{color: red}
  .ok{color: green}
  .truth-block{
    display: inline-block;
    padding: 6px;
    background: #ddd;
    border: 1px solid #aaa;
    float: right;
  }
</style>
