<template>
  <label :for="id">
     <div class="inner-label">
      <i class="fa fa-cloud-upload" aria-hidden="true"></i>
      <div style="margin-top:20px;">Выбрать файл с новыми ЦУ</div>
    </div>
    <input :id="id" type="file" ref="file"
      :accept="accept"
      style="display:none"
      @change="handleFileUpload()"/>
  </label>
</template>
<script>
export default {
  name: 'InputFile',
  props: {
    accept: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this.$.uid;
  },
  methods: {
    handleFileUpload() {
      this.$emit('update:modelValue', this.$refs.file.files[0]);
    },
  },
};
</script>
<style scoped>
i{
  font-size: 80px;
  color: #6dc2ed;
}
.inner-label{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
