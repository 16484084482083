<template>
  <canvas @mouseup="placeCoordinates" :id="name" height='320' width='320'></canvas>
</template>

<script>
export default {
  name: 'ChotoMetr',
  props: {
    name: String,
    info: Array,
    lbl: String,
    unitdopinfo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      canvas: null,
      xMouse: 0,
      yMouse: 0,
      wCanvas: 0,
      hCanvas: 0,
      rs: [],
      labels: [],

      radius: 150,
      radiusInner: 70,
      cx: 160,
      cy: 160,

      tempAxel: 0,
      sectors: [],

      errors: [],
    };
  },
  mounted() {
    const c = document.getElementById(this.name);
    this.canvas = c.getContext('2d');

    this.init();
    /*
    c.width = window.innerWidth - 12 * 2;

    window.addEventListener('resize', () => {
      c.width = window.innerWidth - 12 * 2;
      this.wCanvas = c.width;

      this.drawStuff();
    }, false);

    this.wCanvas = c.width;
    this.hCanvas = c.height;
    */
  },
  methods: {
    init() {
      const ctx = this.canvas;
      this.rs = [];

      let startangle = 0;
      let finishangle = 0;
      let color = '';
      let percent = 0;
      const roundL = 2 * Math.PI;

      let len = this.info.length;
      if (len) {
        let cntProd = 0;
        let cntWeight = 0;
        while (len) {
          len -= 1;

          const angleVal = (roundL * this.info[len].percent) / 100;

          finishangle += angleVal;
          color = this.info[len].color;
          percent = Math.round(this.info[len].percent);

          const tempObj = this.info[len];
          tempObj.startangle = startangle;
          tempObj.finishangle = finishangle;
          this.sectors.push(tempObj);

          cntProd += this.info[len].cnt;
          cntWeight += this.info[len].weight;
          if (percent > 0) {
            this.sector(this.cx, this.cy, this.radius, startangle, finishangle, color, percent);
          }
          startangle = finishangle;
        }
        this.sectors.reverse();

        let lenLabels = this.labels.length;
        while (lenLabels) {
          lenLabels -= 1;

          const elem = this.labels[lenLabels];
          ctx.fillStyle = elem.fillStyle;
          ctx.font = elem.font;
          ctx.textBaseline = elem.textBaseline;
          ctx.textAlign = elem.textAlign;
          ctx.fillText(elem.text, elem.coordX, elem.coordY);
        }

        this.sector(this.cx, this.cy, this.radiusInner, 0, roundL, '#fff');

        ctx.fillStyle = '#000';
        ctx.font = 'italic 40px Arial';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(cntProd, this.cx, this.cy);

        if (this.unitdopinfo !== '') {
          ctx.font = 'italic 20px Arial';
          ctx.textBaseline = 'middle';
          ctx.textAlign = 'center';
          ctx.fillText(`${cntWeight.toFixed(3)} ${this.unitdopinfo}`, this.cx, this.cy + 30);
        }
        /*
        ctx.font = 'bold 30px sans-serif';
        ctx.strokeText("Stroke text", 20, 100);
        */
      } else {
        this.sector(this.cx, this.cy, this.radius, 0, roundL, '#aaa');
        this.sector(this.cx, this.cy, this.radiusInner, 0, roundL, '#fff');

        ctx.fillStyle = '#000';
        ctx.font = 'italic 40px Arial';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(0, this.cx, this.cy);

        ctx.font = 'italic 20px Arial';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText('пусто', this.cx, this.cy + 30);
      }
    },
    sector(cx, cy, r, startangle, finishangle, color, percent) {
      const ctx = this.canvas;

      ctx.beginPath();
      ctx.lineTo(cx, cy);
      ctx.arc(cx, cy, r, startangle, finishangle, false);
      ctx.fillStyle = color;
      ctx.fill();

      const needAxel = Math.round((percent * 360) / 100);
      const subAxel = needAxel / 2;

      this.tempAxel += needAxel;

      const rad = (Math.PI / 180) * (this.tempAxel - subAxel);

      let offsetX;
      let offsetY;
      if (percent <= 5) {
        offsetX = (7 / 8) * r * Math.cos(rad);
        offsetY = (7 / 8) * r * Math.sin(rad);
      } else {
        offsetX = (3 / 4) * r * Math.cos(rad);
        offsetY = (3 / 4) * r * Math.sin(rad);
      }

      let x = cx + offsetX;
      let y = cy + offsetY;

      x += 0;
      y += 0;

      let fontSize = 'italic 24px Arial';
      if (percent <= 5) {
        fontSize = 'italic 18px Arial';
      }

      this.labels.push({
        fillStyle: '#fff',
        font: fontSize,
        textBaseline: 'middle',
        textAlign: 'center',
        text: `${percent}%`,
        coordX: x,
        coordY: y,
        rad,
      });
      /*
      ctx.fillStyle = '#fff';
      ctx.font = 'italic 24px Arial';
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillText(`${percent}%`, x, y);
      */
    },
    placeCoordinates(e) {
      const ctx = this.canvas;

      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, this.wCanvas, this.hCanvas);

      this.xMouse = e.offsetX;
      this.yMouse = e.offsetY;

      const xLength = this.xMouse - this.cx;
      const yLength = this.yMouse - this.cy;
      const leq = (xLength * xLength + yLength * yLength);

      let lenSec = this.sectors.length;
      while (lenSec) {
        lenSec -= 1;

        ctx.beginPath();
        ctx.lineTo(this.cx, this.cy);
        ctx.arc(
          this.cx,
          this.cy,
          this.radius,
          this.sectors[lenSec].startangle,
          this.sectors[lenSec].finishangle,
          false,
        );
        ctx.closePath();
        if (ctx.isPointInPath(e.offsetX, e.offsetY)) {
          if (leq > (this.radiusInner * this.radiusInner)) {
            this.$emit('click-sector', {
              lbl: this.lbl,
              name: this.name,
              sector: this.sectors[lenSec],
            });
            break;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.sum-rub {
  text-align: right;
  background: #82b5ef;
  display: inline-block;
  padding: 2px 12px;
  color: rgb(255, 255, 255);
  font-size: 23px;
  float: right;
  line-height:30px;
}
</style>
