<template>
  <div v-if="items">
    <div class="title">{{title}}</div>
    <div class="stock" v-for="(stock, ind) in items" :key="ind">
      <div class="name">{{stock.name}}</div>
      <div v-for="(pack, ind2) in stock.pack" :key="ind2" class="line">
        <div>
          <div class="btn-collapse"
            @click="showCollapse(ind, ind2)"
            @keydown.f7="showCollapse(ind, ind2)">{{tabs[ind2]}} ({{pack.cnt}})
            <i v-if="pack.show" class="fa fa-caret-up" aria-hidden="true"></i>
            <i v-else class="fa fa-caret-down" aria-hidden="true"></i>
          </div>
          <div v-if="pack.show" class="content-collapse">
            <table>
              <tr>
                <th v-for="(el,ind5) in heads[ind2]" :key="ind5">{{el}}</th>
              </tr>
              <tr v-for="(el, ind4) in pack.list" :key="ind4">
                <td>{{el.name}}</td>
                <td>{{el.cnt_all}}</td>
                <td>{{el.cnt_nouse}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-warn-1">
    Необходимо создать хотя бы одно правило
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CheckRule',
  props: ['action'],
  data() {
    return {
      items: [],
      tabs: [],
      heads: [],
      title: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get(this.action)
        .then((res) => {
          if (res.data.success === 1) {
            this.title = res.data.title;
            this.tabs = res.data.list.tabs;
            this.heads = res.data.list.heads;
            this.items = res.data.list.items;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showCollapse(ind, ind2) {
      if (this.items[ind].pack[ind2].show) {
        this.items[ind].pack[ind2].show = false;
      } else {
        this.items[ind].pack[ind2].show = true;
      }
    },
    closeCollapse() {
      console.log('dffdf');
      let len = this.items.length;
      while (len) {
        len -= 1;
        let len2 = this.items[len].pack.length;
        while (len2) {
          len2 -= 1;
          this.items[len].pack[len2].show = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.title{font-weight: bold;}
.stock{
  border:1px solid #cbd3d5;
  padding:8px;
  margin-top:6px;
  margin-bottom:6px;
  background: #e6ebec;
}
.name{font-weight: bold;
  font-size:20px;
  margin-bottom: 6px;
}
.line{
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.btn-collapse {
  padding: 6px 10px;
  background: #fff;
  margin-right:5px;
  cursor: pointer;
}
.btn-collapse:hover{
  background: #eee;
}

.content-collapse{
  position: absolute;
  left: 0px;
  top: 30px;
  background: #fff;
  border: 1px solid #c5c5c5;
  box-shadow: 0px 1px 3px #787878;
  min-width: 490px;
  z-index: 1;
}

.line th{text-align: left;}
.line th, .line td{padding:8px;}
.fa{margin-left: 4px;}
</style>
