<template>
  <h1 class="mb0">
    <span>Положение дел</span>
  </h1>

  <div class="scm">
    <div v-if="globalLoader" class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div class="wrp-table" v-else-if="managers.length">
      <table>
        <tr class="tr-head">
          <th v-for="(task, ind) in heads" :key="ind">
            <div style="head-vertical">{{task}}</div>
          </th>
        </tr>
        <tr v-for="(manager, ind) in managers" :key="ind">
          <td class="head">{{manager.name}}</td>
          <td v-if="manager.tasks.length == 1" class="cell danger"
            :colspan="heads.length">{{manager.tasks[0].text}}</td>
          <td v-else v-for="(task, ind2 ) in manager.tasks" :key="ind2"
            class="cell"
            :class="{ready: task.status == 0, danger: task.status == 1}">
            <i v-if="task.status == 0" class="fa fa-check" aria-hidden="true"></i>
            <span v-else-if="task.cnt">{{task.cnt}}</span>
            <i v-else class="fa fa-times" aria-hidden="true"></i>
          </td>
        </tr>
      </table>
    </div>
    <div v-else class="text-warn-1">
      Необходимо добавить менеджеров и привязать к ним склад
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ManagerControll',
  data() {
    return {
      managers: [],
      heads: [],
      globalLoader: true,

      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const url = '/scm/task/manager-controll/';
      axios.get(url)
        .then((res) => {
          if (res.data.success === 1) {
            this.managers = res.data.list;
            this.heads = res.data.heads;
          }
          this.globalLoader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.globalLoader = false;
        });
    },
  },
};
</script>

<style scoped>
.mb0{
  margin-bottom: 0px;
}
.scm{
  /* background: url("/img/tresh.jpg") repeat 0px 0px; */
  height: calc(100vh - 62px);
  /* box-shadow: 0px 3px 3px #242424 inset; */
  padding-top: 12px;
  /*
  width: 100%;
  text-align: center;
  line-height: calc(100vh - 50px);
  */
}
.cell{
  text-align:center; background: #ddd
}
.cell.ready{
  background: #d1e5d6;
  border: 1px solid #b7cfbd;
  border-top: 0px;
  border-bottom: 0px;
}
.cell.danger{
  background: #e5cdcd;
  border: 1px solid #c3afaf;
  border-top: 0px;
  border-bottom: 0px;
}
.wrp-table{
  margin-right: 10px;
  margin-left: 10px;
}
table{
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  background: #fff;
}
.head{
  /* background: rgb(94 139 229); */
  /* color: #fff; */
  background: #e6e6e6;
  border: 1px solid #ccc;
  font-weight: normal;
  font-size: 14px;
  padding: 6px 10px;
}
.tr-head th{
  /* background: rgb(94 139 229); */
  /* color: #fff; */
  background: #e6e6e6;
  border: 1px solid #ccc;
  font-weight: normal;
  font-size: 14px;
  padding: 6px 10px;
}

.tr-head th:first-child{
  background: #d1d1d1;
}

.fa-check{color: #009f29;}
.fa-times{color: #9f0000}

.head-vertical{
  writing-mode: tb; height: 140px; min-width: 60px;
}
.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
