<template>
  <div class="wb-select">
    <div class="label" v-if="lbl">{{lbl}} ({{allChecked}} / {{all}})</div>
    <div v-if="elements.length" class="block2">
      <div v-for="(el, ind) in elements" :key="ind">
        <div class="item" :class="{active: el.chk}" @click="sendChk([
          ind
        ])" @keydown.f2="sendChk([0])">
          <div v-if="typeof el.mode !== 'undefined'" class="mode-btn" @click.stop="chgMode([
            ind
          ])" @keydown.f3="sendChk([0])">
            <i v-if="el.mode == 1" class="fa fa-eye" aria-hidden="true"
              title="Только наблюдать"></i>
            <i v-if="el.mode == 0" class="fa fa-gamepad" aria-hidden="true"
              title="Управлять складом"></i>
          </div>
          <div class="squad">
            <span v-if="el.chk">&#10004;</span>
          </div>
          <span>{{el.lbl}}</span>
        </div>
        <div class="lvl2" v-for="(el2, ind2) in el.els" :key="ind2">
          <div class="item" :class="{active: el2.chk}"  @click="sendChk([
            ind,
            ind2
          ])" @keydown.f4="sendChk([0])">
            <div v-if="typeof el2.mode !== 'undefined'" class="mode-btn" @click.stop="chgMode([
              ind,
              ind2
            ])" @keydown.f5="sendChk([0])">
              <i v-if="el2.mode == 1" class="fa fa-eye" aria-hidden="true"
                title="Только наблюдать"></i>
              <i v-else class="fa fa-gamepad" aria-hidden="true"
                title="Управлять складом"></i>
            </div>
            <div class="squad">
              <span v-if="el2.chk">&#10004;</span>
            </div>
            <span>{{el2.lbl}}</span>
          </div>
          <div class="lvl3" v-for="(el3, ind3) in el2.els" :key="ind3">
            <div class="item" :class="{active: el3.chk}"  @click="sendChk([
              ind,
              ind2,
              ind3
            ])" @keydown.f6="sendChk([0])">
              <div v-if="typeof el3.mode !== 'undefined'" class="mode-btn" @click.stop="chgMode([
                ind,
                ind2,
                ind3
              ])" @keydown.f6="sendChk([0])">
                <i v-if="el3.mode == 1" class="fa fa-eye" aria-hidden="true"
                  title="Только наблюдать"></i>
                <i v-else class="fa fa-gamepad" aria-hidden="true"
                  title="Управлять складом"></i>
              </div>
              <div class="squad">
                <span v-if="el3.chk">&#10004;</span>
              </div>
              <span>{{el3.lbl}}</span>
            </div>
            <div class="lvl4" v-for="(el4, ind4) in el3.els" :key="ind4">
              <div class="item" :class="{active: el4.chk}" @click="sendChk([
                ind,
                ind2,
                ind3,
                ind4
              ])" @keydown.f7="sendChk([0])">
                <div v-if="typeof el4.mode !== 'undefined'" class="mode-btn" @click.stop="chgMode([
                  ind,
                  ind2,
                  ind3,
                  ind4
                ])" @keydown.f7="sendChk([0])">
                  <i v-if="el4.mode == 1" class="fa fa-eye" aria-hidden="true"
                    title="Только наблюдать"></i>
                  <i v-else class="fa fa-gamepad" aria-hidden="true"
                    title="Управлять складом"></i>
                </div>
                <div class="squad">
                  <span v-if="el4.chk">&#10004;</span>
                </div>
                <span>{{el4.lbl}}</span>
              </div>
              <div class="lvl5" v-for="(el5, ind5) in el4.els" :key="ind5">
                <div class="item" :class="{active: el5.chk}" @click="sendChk([
                  ind,
                  ind2,
                  ind3,
                  ind4,
                  ind5
                ])" @keydown.f8="sendChk([0])">
                  <div v-if="typeof el5.mode !== 'undefined'"
                    class="mode-btn" @click.stop="chgMode([
                    ind,
                    ind2,
                    ind3,
                    ind4,
                    ind5
                  ])" @keydown.f8="sendChk([0])">
                    <i v-if="el5.mode == 1" class="fa fa-eye" aria-hidden="true"
                      title="Только наблюдать"></i>
                    <i v-else class="fa fa-gamepad" aria-hidden="true"
                      title="Управлять складом"></i>
                  </div>
                  <div class="squad">
                    <span v-if="el5.chk">&#10004;</span>
                  </div>
                  <span>{{el5.lbl}}</span>
                </div>
                <div class="lvl6" v-for="(el6, ind6) in el5.els" :key="ind6">
                  <div class="item" :class="{active: el6.chk}" @click="sendChk([
                    ind,
                    ind2,
                    ind3,
                    ind4,
                    ind5,
                    ind6,
                  ])" @keydown.f9="sendChk([0])">
                    <div v-if="typeof el6.mode !== 'undefined'"
                    class="mode-btn" @click.stop="chgMode([
                      ind,
                      ind2,
                      ind3,
                      ind4,
                      ind5,
                      ind6
                    ])" @keydown.f9="sendChk([0])">
                      <i v-if="el6.mode == 1" class="fa fa-eye" aria-hidden="true"
                        title="Только наблюдать"></i>
                      <i v-else class="fa fa-gamepad" aria-hidden="true"
                        title="Управлять складом"></i>
                    </div>
                    <div class="squad">
                      <span v-if="el6.chk">&#10004;</span>
                    </div>
                    <span>{{el6.lbl}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelectIerarch',
  props: ['lbl', 'options', 'def', 'modelValue', 'err'],
  data() {
    return {
      showList: false,
    };
  },
  computed: {
    elements() {
      return this.options;
    },
    allChecked() {
      let i = this.options.length;
      let cnt = 0;
      while (i) {
        i -= 1;
        if (this.options[i].els) {
          let j = this.options[i].els.length;
          while (j) {
            j -= 1;
            if (this.options[i].els[j].els) {
              let k = this.options[i].els[j].els.length;
              while (k) {
                k -= 1;
                if (this.options[i].els[j].els[k].els) {
                  let m = this.options[i].els[j].els[k].els.length;
                  while (m) {
                    m -= 1;
                    if (this.options[i].els[j].els[k].els[m].els) {
                      let l = this.options[i].els[j].els[k].els[m].els.length;
                      while (l) {
                        l -= 1;
                        if (this.options[i].els[j].els[k].els[m].els[l].chk) {
                          cnt += 1;
                        }
                      }
                    }
                    if (this.options[i].els[j].els[k].els[m].chk) {
                      cnt += 1;
                    }
                  }
                }
                if (this.options[i].els[j].els[k].chk) {
                  cnt += 1;
                }
              }
            }
            if (this.options[i].els[j].chk) {
              cnt += 1;
            }
          }
        }
        if (this.options[i].chk) {
          cnt += 1;
        }
      }

      return cnt;
    },
    all() {
      let i = this.options.length;
      let cnt = 0;
      while (i) {
        i -= 1;
        if (this.options[i].els) {
          let j = this.options[i].els.length;
          while (j) {
            j -= 1;
            if (this.options[i].els[j].els) {
              let k = this.options[i].els[j].els.length;
              while (k) {
                k -= 1;
                if (this.options[i].els[j].els[k].els) {
                  let m = this.options[i].els[j].els[k].els.length;
                  while (m) {
                    m -= 1;
                    if (this.options[i].els[j].els[k].els[m].els) {
                      let l = this.options[i].els[j].els[k].els[m].els.length;
                      while (l) {
                        l -= 1;
                        cnt += 1;
                      }
                    }
                    cnt += 1;
                  }
                }
                cnt += 1;
              }
            }
            cnt += 1;
          }
        }
        cnt += 1;
      }

      return cnt;
    },
  },
  methods: {
    clean(ind) {
      if (this.elements[ind].chk) {
        this.elements[ind].chk = false;
      }
      this.$emit('update:modelValue', this.elements);
      this.$emit('change');
    },
    sendChk(lvl) {
      this.spiner = true;

      const len = lvl.length;
      if (len === 1) {
        if (this.elements[lvl[0]].chk) {
          this.elements[lvl[0]].chk = false;
        } else {
          this.elements[lvl[0]].chk = true;
        }
      } else if (len === 2) {
        if (this.elements[lvl[0]].els[lvl[1]].chk) {
          this.elements[lvl[0]].els[lvl[1]].chk = false;
        } else {
          this.elements[lvl[0]].els[lvl[1]].chk = true;
        }
      } else if (len === 3) {
        if (this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].chk) {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].chk = false;
        } else {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].chk = true;
        }
      } else if (len === 4) {
        if (this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].chk) {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].chk = false;
        } else {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].chk = true;
        }
      } else if (len === 5) {
        if (this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].chk) {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].chk = false;
        } else {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].chk = true;
        }
      }
    },
    chgMode(lvl) {
      const len = lvl.length;
      if (len === 1) {
        // console.log(this.elements[lvl[0]].mode);
        if (this.elements[lvl[0]].mode === 1) {
          this.elements[lvl[0]].mode = 0;
        } else {
          this.elements[lvl[0]].mode = 1;
        }
        // console.log(this.elements[lvl[0]].mode);
      } else if (len === 2) {
        if (this.elements[lvl[0]].els[lvl[1]].mode) {
          this.elements[lvl[0]].els[lvl[1]].mode = 0;
        } else {
          this.elements[lvl[0]].els[lvl[1]].mode = 1;
        }
      } else if (len === 3) {
        if (this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].mode) {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].mode = 0;
        } else {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].mode = 1;
        }
      } else if (len === 4) {
        if (this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].mode) {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].mode = 0;
        } else {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].mode = 1;
        }
      } else if (len === 5) {
        if (this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].mode) {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].mode = 0;
        } else {
          this.elements[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].mode = 1;
        }
      }
    },
  },
};
</script>

<style scoped>
  .mode-btn{
    float: right;
  }
  .block2{
    min-height: 295px;
    max-height: 295px;
    overflow-y: auto;
  }

  .wb-select{
    margin-top: 9px;
  }
  .wb-btn-pick {
    padding: 2px 6px;
    background: #5685cb;
    margin: 2px 2px;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    transition: background 0.5s;
  }
  .wb-btn-pick:hover {
    background: #000;
    color: #ddd;
  }
  .wb-btn-pick .squad{
    background: #fff;
    width: 12px;
    height: 12px;
    color: #5685cb;
    line-height: 12px;
  }
  .squad{
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #999;
    width: 16px;
    height: 16px;
    line-height: 15px;
    text-align: center;
    margin-right: 4px;
  }
  .block2{
    background: #f1f1f1;
    color: #333;
    border: 1px solid #b9b3b3;
    padding-top: 10px;
  }
  .block2 .item{
    padding: 4px 10px;
    cursor: pointer;
  }
  .block2 .item:hover{
    background: #ddd;
  }
  .block2 .item.active{
    color: #187a24;
  }
  .block2 .item span{
    vertical-align: middle;
    display: inline-block;
  }
  .current{
    display: inline-block;
    background: #b9ebb9;
    padding: 0px 4px;
    margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid #a9d7a9;
  }
  .current .squad {
    float: left;
    margin-right: 2px;
  }
  .current .fa-times{
    color: #e74949;
    margin-left:3px;
    transition: color 0.5s;
  }
  .current .fa-times:hover{
    color: #b249e7;
  }
  .wrp-current{
    display:inline-block;
  }
  .list--item{
    padding:8px 12px
  }
  .list--item:hover{
    background: #;
  }

  .wb-select{
    position:relative;
    border-radius: 0 4px 4px 0;
    border: 1px solid #aaa;
    cursor: pointer;
    background: #fff;
    border-left: 4px solid #aaa;
    min-width: 200px;
  }
  .wb-select-label{
    padding: 10px 12px;
    white-space: nowrap;
  }
  .val-text{
    max-width: 200px;
    overflow: hidden;
  }
  .wb-select-label:hover{background:#efefef;}
  .wb-select-list{
    position: absolute;
    left: -4px;
    top: 26px;
    margin-top: 10px;
    width: 100%;
    max-height: 250px;
    background: #fff;
    border: 1px solid #aaa;
    border-radius: 0px 0px 4px 4px;
    overflow-y: auto;
    border-left: 4px solid #aaa;
    z-index:2
  }

  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 12px;
    line-height: 16px;
    padding: 0px 8px;
    border-radius: 0px;
    color: #fff;
  }

  /* W3C standard сейчас только для Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
  }
  /* для Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d7d7d7;
    width:3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #848484;
  /*  border-radius: 5px; */
    border: 2px solid #d7d7d7;
  }
  .icon-right{
    float: right;
    margin-left: 5px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .block2 .item .squad{float: left;}
  .block2 .item .squad + span{margin-left: 22px; display: block;}

  .lvl2{margin-left: 10px;}
  .lvl3{margin-left: 10px;}
  .lvl4{margin-left: 10px;}
  .lvl5{margin-left: 10px;}
</style>
