<template>
  <div class="wrp">
    <div v-if="loader" class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-else>
      <div v-if="items.length" class="block">
        <div v-for="(el, ind) in items" :key="ind"
          class="block--item"
          :class="{'active': el.chk}"
          @click="selStock(ind)"
          @keydown.f7="selStock(ind)"
        >
          <i class="fa fa-link link-cat" aria-hidden="true"></i>
          {{el.lbl}}
        </div>
      </div>
      <div v-else class="text-warn-1">
        У вас нет складов. Если вы управляете автономно,
        то можно создать <router-link to="/scm/setting/stock">тут</router-link>.
      </div>
      <div v-if="items2.length" class="block2">
        <div class="panel">
          ({{allChecked}} / {{all}})
          <a v-if="false" class="wb-btn-pick"
            @click="linkAll" @keydown.enter="linkAll">Привязать все</a>
          <a v-if="false" class="wb-btn-pick"
            @click="unlinkAll" @keydown.enter="unlinkAll">Отвязать все</a>
          <a class="wb-btn-pick" @click="settParamStock" @keydown.enter="settParamStock">
            <div class="squad" >
              <span v-if="select.flag == 1">&#10004;</span>
            </div>Все новые категории привязывать к складу
          </a>
        </div>
        <div style="position: relative; padding-left: 8px;">
          <div v-if="loader" class="spinner">
            <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          </div>
          <div v-else-if="items2.length == 1 && !items2[0].lbl">
            <a href="/scm/setting/stock-link">Привяжите категории к выбранному складу >>></a>
          </div>
          <div v-else class="ierarch">
            <div :class="`level-${el.level}`" v-for="(el, ind) in items2" :key="ind">
              <div class="item" v-if="el.show"
                @click="toggleChild(ind)"
                @keydown.f9="toggleChild(0)"
                :class="{'ierarh-border': el.level > 0}">
                <div v-if="el.level > 0" class="squad-level"></div>
                <span class="el">
                  <span style="float:left; display:block;">
                    <template v-if="el.child || dynamic && el.cnt != 0">
                      <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
                      <i v-else class="fa fa-folder" aria-hidden="true"></i>
                    </template>
                    <span v-else class="empty-squad">
                      <i class="fa fa-folder " aria-hidden="true"></i>
                    </span>
                  </span>
                  <div class="squad"
                    @click.stop="check(ind, $event)"
                    @keydown.f8="check(0, $event)">
                    <span v-if="el.chk">&#10004;</span>
                  </div>
                  <span style="white-space:nowrap;"
                    :title="'('+el.cnt+') '+el.lbl">{{el.lbl}} ({{el.cnt_chk}}/{{el.cnt}}) </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="slide-form">
      <ModalRight v-show="flagModalRight"
        @close="flagModalRight = false;"
        :title="modalTitle">
        <ConfirmCrossDef v-if="flagModalRight"
          :inputtxt="confirm.txt"
          :btns="confirm.btns"
          closesec="10000"
          @close="flagModalRight = false;"
          @ok="confirmOk"
          />
      </ModalRight>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ConfirmCrossDef from '@/components/v2/cross/confirm/Def.vue';

export default {
  name: 'WBLinkSett',
  components: {
    ModalRight,
    ConfirmCrossDef,
  },
  props: ['action', 'action2'],
  data() {
    return {
      actChg: '',
      flagModalRight: false,
      confirm: {},
      checkedIndex: -1,

      select: {},
      selectIndex: -1,

      items: [],
      items2: [],
      spiner: false,
      loader: true,
      errors: [],

      level: 0,
      flag: 0,
      open: false,

      chk: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    elements() {
      return this.items2;
    },
    allChecked() {
      let i = this.items2.length;
      let cnt = 0;
      while (i) {
        i -= 1;
        if (this.items2[i].els) {
          let j = this.items2[i].els.length;
          while (j) {
            j -= 1;
            if (this.items2[i].els[j].els) {
              let k = this.items2[i].els[j].els.length;
              while (k) {
                k -= 1;
                if (this.items2[i].els[j].els[k].els) {
                  let m = this.items2[i].els[j].els[k].els.length;
                  while (m) {
                    m -= 1;
                    if (this.items2[i].els[j].els[k].els[m].els) {
                      let l = this.items2[i].els[j].els[k].els[m].els.length;
                      while (l) {
                        l -= 1;
                        if (this.items2[i].els[j].els[k].els[m].els[l].els) {
                          let n = this.items2[i].els[j].els[k].els[m].els[l].els.length;
                          while (n) {
                            n -= 1;
                            if (this.items2[i].els[j].els[k].els[m].els[l].els[n].chk) {
                              cnt += 1;
                            }
                          }
                        }
                        if (this.items2[i].els[j].els[k].els[m].els[l].chk) {
                          cnt += 1;
                        }
                      }
                    }
                    if (this.items2[i].els[j].els[k].els[m].chk) {
                      cnt += 1;
                    }
                  }
                }
                if (this.items2[i].els[j].els[k].chk) {
                  cnt += 1;
                }
              }
            }
            if (this.items2[i].els[j].chk) {
              cnt += 1;
            }
          }
        }
        if (this.items2[i].chk) {
          cnt += 1;
        }
      }

      return cnt;
    },
    all() {
      let i = this.items2.length;
      let cnt = 0;
      while (i) {
        i -= 1;
        if (this.items2[i].els) {
          let j = this.items2[i].els.length;
          while (j) {
            j -= 1;
            if (this.items2[i].els[j].els) {
              let k = this.items2[i].els[j].els.length;
              while (k) {
                k -= 1;
                if (this.items2[i].els[j].els[k].els) {
                  let m = this.items2[i].els[j].els[k].els.length;
                  while (m) {
                    m -= 1;
                    if (this.items2[i].els[j].els[k].els[m].els) {
                      let l = this.items2[i].els[j].els[k].els[m].els.length;
                      while (l) {
                        l -= 1;
                        if (this.items2[i].els[j].els[k].els[m].els[l].els) {
                          let n = this.items2[i].els[j].els[k].els[m].els[l].els.length;
                          while (n) {
                            n -= 1;
                            cnt += 1;
                          }
                        }
                        cnt += 1;
                      }
                    }
                    cnt += 1;
                  }
                }
                cnt += 1;
              }
            }
            cnt += 1;
          }
        }
        cnt += 1;
      }

      return cnt;
    },
  },
  methods: {
    init() {
      axios.get(this.action)
        .then((res) => {
          if (res.data.success === 1) {
            this.items = res.data.list;

            if (res.data.list.length) {
              this.selStock(0);
            }

            this.actChg = res.data.chg;
          }
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    confirmOk() {
      if (this.checkedIndex < 0) return;

      this.level = this.items2[this.checkedIndex].level;
      this.chk = this.items2[this.checkedIndex].chk;

      const linkCheck = [];
      let oldLevel = 1;
      this.flag = 0;
      let BreakException;
      try {
        this.items2.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            linkCheck.forEach((item2, j) => {
              if (item.level <= oldLevel) {
                if (item.level <= linkCheck[j].level) {
                  linkCheck[j].flag = 0;
                }
              }
              if (linkCheck[j].flag === 1) {
                linkCheck[j].offset += 1;
              }
            });
            linkCheck.push({
              ind: i,
              offset: 0,
              level: this.items2[i].level,
              flag: 1,
            });
            if (this.chk) {
              this.items2[i].chk = false;
            } else {
              this.items2[i].chk = true;
            }
          }

          if (this.checkedIndex === i) {
            linkCheck.push({
              ind: i,
              offset: 0,
              level: this.items2[i].level,
              flag: 1,
            });
            this.flag = 1;
            if (this.chk) {
              this.items2[i].chk = false;
            } else {
              this.items2[i].chk = true;
            }
          }
          oldLevel = item.level;
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      if (!this.chk) { // добавить
        linkCheck.forEach((item) => {
          this.items2[item.ind].cnt_chk += item.offset;
        });
      } else { // убавить
        linkCheck.forEach((item) => {
          this.items2[item.ind].cnt_chk -= item.offset;
        });
      }
    },
    selStock(ind) {
      this.spiner = true;

      axios.get(`${this.action2}?val=${this.items[ind].val}`)
        .then((res) => {
          if (res.data.success === 1) {
            this.items2 = res.data.list;
            this.select = this.items[ind];
            this.selectIndex = ind;

            let i = this.items.length;
            while (i) {
              i -= 1;
              if (i === ind) {
                this.items[i].chk = true;
              } else {
                this.items[i].chk = false;
              }
            }
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    /*
    sendChk(lvl, event) {
      this.spiner = true;

      const len = lvl.length;
      let flagChk = 0;
      let catID = 0;
      if (len === 1) {
        catID = this.items2[lvl[0]].val;
        if (this.items2[lvl[0]].chk) {
          this.items2[lvl[0]].chk = false;
          flagChk = 0;
        } else {
          this.items2[lvl[0]].chk = true;
          flagChk = 1;
        }
      } else if (len === 2) {
        catID = this.items2[lvl[0]].els[lvl[1]].val;
        if (this.items2[lvl[0]].els[lvl[1]].chk) {
          this.items2[lvl[0]].els[lvl[1]].chk = false;
          flagChk = 0;
        } else {
          this.items2[lvl[0]].els[lvl[1]].chk = true;
          flagChk = 1;
        }
      } else if (len === 3) {
        catID = this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].val;
        if (this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].chk) {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].chk = false;
          flagChk = 0;
        } else {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].chk = true;
          flagChk = 1;
        }
      } else if (len === 4) {
        catID = this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].val;
        if (this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].chk) {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].chk = false;
          flagChk = 0;
        } else {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].chk = true;
          flagChk = 1;
        }
      } else if (len === 5) {
        catID = this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].val;
        if (this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].chk) {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].chk = false;
          flagChk = 0;
        } else {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].chk = true;
          flagChk = 1;
        }
      } else if (len === 6) {
        catID = this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].els[lvl[5]].val;
        if (this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]].els[lvl[5]].chk) {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]]
            .els[lvl[5]].chk = false;
          flagChk = 0;
        } else {
          this.items2[lvl[0]].els[lvl[1]].els[lvl[2]].els[lvl[3]].els[lvl[4]]
            .els[lvl[5]].chk = true;
          flagChk = 1;
        }
      }

      axios.post(this.actChg, {
        st: this.select.val,
        cat: catID,
        val: flagChk,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
          } else {
            this.answer(event.pageX, event.pageY, res.data.answer, 'error');
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    */
    linkAll(event) {
      axios.post(this.actChg, {
        st: this.select.val,
        cat: 0,
        val: 1,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.pageX, event.pageY, res.data.answer, 'ok');

            let i = this.items2.length;
            while (i) {
              i -= 1;
              if (this.items2[i].els) {
                let j = this.items2[i].els.length;
                while (j) {
                  j -= 1;
                  if (this.items2[i].els[j].els) {
                    let k = this.items2[i].els[j].els.length;
                    while (k) {
                      k -= 1;
                      if (this.items2[i].els[j].els[k].els) {
                        let m = this.items2[i].els[j].els[k].els.length;
                        while (m) {
                          m -= 1;
                          if (this.items2[i].els[j].els[k].els[m].els) {
                            let l = this.items2[i].els[j].els[k].els[m].els.length;
                            while (l) {
                              l -= 1;
                              if (this.items2[i].els[j].els[k].els[m].els[l].els) {
                                let n = this.items2[i].els[j].els[k].els[m].els[l].els.length;
                                while (n) {
                                  n -= 1;
                                  this.items2[i].els[j].els[k].els[m].els[l].els[n].chk = true;
                                }
                              }
                              this.items2[i].els[j].els[k].els[m].els[l].chk = true;
                            }
                          }
                          this.items2[i].els[j].els[k].els[m].chk = true;
                        }
                      }
                      this.items2[i].els[j].els[k].chk = true;
                    }
                  }
                  this.items2[i].els[j].chk = true;
                }
              }
              this.items2[i].chk = true;
            }
          } else {
            this.answer(event.pageX, event.pageY, res.data.answer, 'error');
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    toggleChild(ind) {
      if (this.items2[ind].child === false) {
        return;
      }
      this.level = this.items2[ind].level;
      this.flag = 0;
      this.open = this.items2[ind].open;
      let BreakException;
      try {
        this.items2.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level === item.level) {
              throw BreakException;
            }

            // изменить show
            if (this.open === true) {
              this.items2[i].show = false;
              this.items2[i].open = false;
            } else if ((this.level + 1) === item.level) {
              this.items2[i].show = true;
            }
          }

          if (ind === i) {
            this.flag = 1;
            // изменить open
            this.items2[i].open = !this.items2[i].open;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    check(ind, event) {
      if (ind < 0) return;
      this.checkedIndex = ind;

      this.level = this.items2[ind].level;
      this.chk = this.items2[ind].chk;

      const ids = [];
      this.flag = 0;
      let BreakException;
      try {
        this.items2.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            ids.push(this.items2[i].val);
          }

          if (ind === i) {
            ids.push(this.items2[i].val);
            this.flag = 1;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      if (this.chk === true) {
        this.flagModalRight = true;
        this.confirm = {
          txt: 'У товаров, отключаемой категории (в том числе всех вложенных в неё категорий) для настраиваемого склада, параметр "Тип контроля" перейдёт в значение "Не контролируемые". При повторной привязки категории все её товары перейдут в значение "Не настроенные", их необходимо будет настроить через "Массовый интерфейс настройки товаров"',
          btns: [
            {
              tp: 'action',
              action: this.actChg,
              param: {
                st: this.select.val,
                cat: ids,
                val: this.chk,
              },
              txt: 'Подтвердить',
            }, {
              tp: 'close',
              txt: 'Отменить',
            },
          ],
        };
        console.log(event);
      } else {
        this.flagModalRight = true;
        this.confirm = {
          txt: 'У товаров, подключаемой категории (в том числе всех вложенных в неё категорий) для настраиваемого склада, параметр "Тип контроля" перейдёт в значение "Не настроенный"',
          btns: [
            {
              tp: 'action',
              action: this.actChg,
              param: {
                st: this.select.val,
                cat: ids,
                val: this.chk,
              },
              txt: 'Подтвердить',
            }, {
              tp: 'close',
              txt: 'Отменить',
            },
          ],
        };
        console.log(event);
      }
      /*
      axios.post(this.actChg, {
        st: this.select.val,
        cat: ids,
        val: this.chk,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
            if (!this.chk) { // добавить
              linkCheck.forEach((item) => {
                this.items2[item.ind].cnt_chk += item.offset;
              });
            } else { // убавить
              linkCheck.forEach((item) => {
                this.items2[item.ind].cnt_chk -= item.offset;
              });
            }
          } else {
            this.answer(event.pageX, event.pageY, res.data.answer, 'error');
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      */
    },
    unlinkAll(event) {
      axios.post(this.actChg, {
        st: this.select.val,
        cat: 0,
        val: 0,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.pageX, event.pageY, res.data.answer, 'ok');

            let i = this.items2.length;
            while (i) {
              i -= 1;
              if (this.items2[i].els) {
                let j = this.items2[i].els.length;
                while (j) {
                  j -= 1;
                  if (this.items2[i].els[j].els) {
                    let k = this.items2[i].els[j].els.length;
                    while (k) {
                      k -= 1;
                      if (this.items2[i].els[j].els[k].els) {
                        let m = this.items2[i].els[j].els[k].els.length;
                        while (m) {
                          m -= 1;
                          if (this.items2[i].els[j].els[k].els[m].els) {
                            let l = this.items2[i].els[j].els[k].els[m].els.length;
                            while (l) {
                              l -= 1;
                              if (this.items2[i].els[j].els[k].els[m].els[l].els) {
                                let n = this.items2[i].els[j].els[k].els[m].els[l].els.length;
                                while (n) {
                                  n -= 1;
                                  this.items2[i].els[j].els[k].els[m].els[l].els[n].chk = false;
                                }
                              }
                              this.items2[i].els[j].els[k].els[m].els[l].chk = false;
                            }
                          }
                          this.items2[i].els[j].els[k].els[m].chk = false;
                        }
                      }
                      this.items2[i].els[j].els[k].chk = false;
                    }
                  }
                  this.items2[i].els[j].chk = false;
                }
              }
              this.items2[i].chk = false;
            }
          } else {
            this.answer(event.pageX, event.pageY, res.data.answer, 'error');
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    settParamStock(event) {
      console.log(this.select.flag);
      axios.post('/scm/stock/link-chg-param', {
        st: this.select.val,
        param: 'flag',
        val: this.select.flag,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
            if (this.select.flag === '0' || this.select.flag === 0) {
              this.select.flag = 1;
            } else {
              this.select.flag = 0;
            }
          } else {
            this.answer(event.pageX, event.pageY, res.data.answer, 'error');
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    answer(x, y, text, type) {
      const offsetX = x + 20;
      const offsetY = y - 20;
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${offsetY}px`;
      el.style.left = `${offsetX}px`;
      // el.position.top = `${offsetX} px`;
      // el.position.left = `${offsetY} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${offsetY - 50}px`;
        el.style.left = `${offsetX}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
  },
};
</script>

<style scoped >
.wb-btn-pick {
  padding: 2px 6px;
  background: #5685cb;
  margin: 2px 2px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  transition: background 0.5s;
}
.wb-btn-pick:hover {
  background: #000;
  color: #ddd;
}
.wb-btn-pick .squad{
  background: #fff;
  width: 12px;
  height: 12px;
  color: #5685cb;
  line-height: 12px;
}
.wrp.tab {
  height: calc(100vh - 150px);
}
.block{
  width: 30%;
  max-height: calc(100vh - 128px);
  overflow-x: hidden;
  float:left;
  z-index: 1;
  padding-top: 28px;
}
.block--item{
  padding:4px 10px;
  cursor: pointer;
  background: #ddd;
  margin-bottom:2px;
}
.block--item:hover{
  background: #b9c0cf;
}
.block--item.active{
  /* background: #f1f1f1; */
  /* color: #333; */
  color: #fff;
  border-right: none;
  margin-right:-1px;
  background: #5981ce;
  position: relative;
}

.fa-link.link-cat{
  display: none;
}
.block--item.active .fa-link.link-cat{
  display: block;
  position: absolute;
  right: 6px;
  top: 5px;
}

.block2{
  margin-left: 30%;
  background: #f1f1f1;
  color: #333;
  border: 1px solid #b9b3b3;
  height: calc(100vh - 100px);
  overflow-y: auto;
}
.block2 .item{
  padding: 4px 0px;
  cursor: pointer;
  display: flex;
}
.block2 .item:hover{
  background: #ddd;
}
.block2 .item.active{
  color: #187a24;
}
.squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
}
.block2 .panel {
  padding: 1px 8px 2px;
  background: #b3dcff;
  position: sticky;
  top: 0px;
}
.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #cfcfcf; padding: 3px}

.zhir{font-weight: bold; margin-right: 5px;}
.item{
  display: inline-block;
  cursor: pointer;
}
.item .fa-folder, .item .fa-folder-open{
  margin-right: 3px;
  float:left;
}
.item .fa-folder{ color: green; }
.item .fa-folder-open{ color: #b91717; }
.item .empty-squad .fa-folder{ color: gray; }

.empty-squad{width: 12.5px; height:16px; display: inline-block; margin-right: 5px;}
.squad-level{
  width: 10px;
  height: 20px;
  border-left: 1px solid #000;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  position:relative;
  float:left;
  left:-1px;
}
.squad-level:before{
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #000;
  position: absolute;
  top: 36%;
  left: 0px;
}
.ierarh-border{
  border-left: 1px solid #000;
}
.fa-cube{margin-right: 4px;}
.r-cnt{position: absolute; right: 0px; top: -40px;}

.btns{float: right}
.btns a{margin-left: 10px;}
.col{display: inline-block;}
.prods{margin-left: 16px; margin-top:2px; margin-bottom: 2px;}

.prod-el{padding: 3px 0px 4px;}
.prod-el:hover{background: #dbdbdb;}

.btn-select{display: inline-block;}
.btn-select i.fa{color: #5981ce;}
.btn-select:hover i.fa{color: #3569cf}

.squad{
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #999;
  width: 16px;
  height: 16px;
  line-height: 15px;
  text-align: center;
  margin-right: 4px;
  float: left;
}
.squad + span {
  display: block;
  margin-left: 24px;
  line-height: 22px;
}

.level-1{margin-left: 5px}
.level-2{margin-left: 25px}
.level-3{margin-left: 45px}
.level-4{margin-left: 65px}
.level-5{margin-left: 85px}
.level-6{margin-left: 105px}
.level-7{margin-left: 125px}
.level-8{margin-left: 145px}
.level-9{margin-left: 165px}
.level-10{margin-left: 185px}

.level-1 .squad + span,
.level-2 .squad + span,
.level-3 .squad + span,
.level-4 .squad + span,
.level-5 .squad + span,
.level-6 .squad + span,
.level-7 .squad + span,
.level-8 .squad + span,
.level-9 .squad + span,
.level-10 .squad + span{margin-left: 39px;}
</style>
