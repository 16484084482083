<template>
  <div>
    <WBMTable :items="items" @act-table="actTable" />
  </div>

  <div v-if="showContextForm" class="context-menu modal-form"
    @click="showContextForm = false"
    @keydown.f7="showContextForm = false">
    <div class="context-menu--body"  @click.stop >
      <div v-if="answerForm.length" class="form-answer">{{answerForm}}</div>
      <Form v-else :title="titleForm"
        :btn="btnForm"
        class="center-center"
        :fields="dataForm"
        :action="actionForm"
        method="post"
        @answerForm="formAnswer" />
    </div>
  </div>
  <!--
  <div v-if="showContextForm" class="context-menu modal-form" @click="closeModal()">
    <div class="context-menu--body"  @click.stop >
      <div v-if="answerForm.length" class="form-answer">{{answerForm}}</div>
      <Form v-else :title="form.title"
        :btn="form.btn"
        class="center-center big"
        :fields="form.fields"
        :action="form.act"
        :method="form.method"
        @answerForm="formAnswer" />
    </div>
  </div>
  -->
  <div v-if="instrumental.length" class="instrumental">
    <a v-for="(instrument, ind) in instrumental" :key="ind"
      class="btn btn-add"
      @click="act(instrument.act)"
      @keydown.f7="act(instrument.act)">
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import Form from '@/components/atoms/Form.vue';
import WBMTable from '@/components/molecules/WBMTable.vue';

export default {
  name: 'MCompany',
  components: {
    WBMTable, Form,
  },
  data() {
    return {
      items: {},

      showContextForm: false,
      answerForm: '',
      titleForm: '',
      btnForm: '',
      dataForm: [],
      actionForm: '',

      instrumental: [],
      errors: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      axios.get('/god/company/lst')
        .then((res) => {
          this.items = res.data.pack;
          this.instrumental = res.data.instrumental;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    act(action) {
      this.actionType = 'add';
      axios.get(action)
        .then((res) => {
          if (res.data.success === 1) {
            this.showContextForm = true;
            this.titleForm = res.data.form_info.name;
            this.btnForm = res.data.form_info.btn;
            this.dataForm = res.data.pack;
            this.actionForm = res.data.form_info.url;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    actTable(data) {
      if (data.t === 'chk') {
        if (this.items.items[data.i].act[data.i4].val === 0) {
          this.items.items[data.i].act[data.i4].val = 1;
        } else {
          this.items.items[data.i].act[data.i4].val = 0;
        }

        axios.post(data.act, { val: this.items.items[data.i].fields[0].val })
          .then((res) => {
            console.log(res.data.msg);
          })
          .catch(() => {
            this.$router.push('/404');
          });
      } else if (data.t === 'url') {
        axios.get(`${data.act}?val=${this.items.items[data.i].fields[0].val}`)
          .then((res) => {
            if (res.data.success === 1) {
              this.showContextForm = true;

              this.titleForm = res.data.form_info.name;
              this.btnForm = res.data.form_info.btn;
              this.dataForm = res.data.pack;
              this.actionForm = res.data.form_info.url;
            }
          })
          .catch(() => {
            this.$router.push('/404');
          });
      }
    },
    formAnswer(data) {
      console.log(data);
      this.answerForm = data.answer;
      if (this.actionType === 'add') {
        this.items.items.push(data.elem);
      } else if (this.actionType === 'chg') {
        if (data.elem.fields) {
          this.items.items.splice(this.actionElem, 1, data.elem);
        }
      }
    },
  },
};
</script>

<style scoped>
.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{background:#d2d2d2; width:auto; display:inline-block;
text-align:left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
}
.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}
</style>
