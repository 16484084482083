<template>
  <div class="scm">
    <h1>Настройка автозаказов</h1>
  </div>
</template>

<script>
export default {
  name: 'ScmSettingAutoorder',
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>
