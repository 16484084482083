<template>
  <div class="wrp">
    <div class="wrp-add">
      <div class="field">
        <input-text v-model="form.val"
          :def="form.def"
          :lbl="form.lbl" />
      </div>
      <div class="wb-btn wb-btn-success" @click="sendAdd" @keydown.enter="sendAdd">Добавить</div>
    </div>
    <div class="r">
      <div v-for="(el, ind) in items" :key="ind" class="field line">
        <i class="fa fa-times" @click.stop="sendDel(ind, $event)"
          @keydown.enter="sendDel(ind, $event)"></i>
        <input-text v-model="el[1].value"
          :def="el.placeholder"
          :lbl="el.lbl"
          :err="el.error"
          :ind="index"
          @change="sendChg(ind, el[0].value, $event)" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import InputText from '@/components/atoms/InputText.vue';

export default {
  name: 'WBList',
  components: {
    InputText,
  },
  props: ['action', 'add', 'chg', 'del'],
  data() {
    return {
      form: {
        def: '',
        lbl: '',
        val: '',
      },

      items: [],
      spiner: false,
      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get(this.action)
        .then((res) => {
          if (res.data.success === 1) {
            this.items = res.data.list;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    sendAdd(event) {
      if (this.add) {
        this.spiner = true;
        axios.post(this.add, { name: this.form.val })
          .then((res) => {
            if (res.data.success === 1) {
              this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
              this.items.unshift(res.data.elem);
              this.form.val = '';
            } else {
              this.answer(event.pageX, event.pageY, res.data.answer, 'error');
            }
            this.spiner = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    sendChg(ind, val, event) {
      if (this.chg) {
        this.spiner = true;
        axios.post(this.chg, {
          name: this.items[ind][1].value,
          val,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
            } else {
              this.answer(event.pageX, event.pageY, res.data.answer, 'error');
            }
            this.spiner = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    sendDel(ind, event) {
      if (this.del) {
        this.spiner = true;
        axios.post(this.del, {
          val: this.items[ind][0].value,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
              this.items.splice(ind, 1);
            } else {
              this.answer(event.pageX, event.pageY, res.data.answer, 'error');
            }
            this.spiner = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
  },
};
</script>

<style scoped >
.wrp-add{
  margin-bottom:20px;
}
.field{
  width: 80%;
  float: left;
}
.field.line{
  float: none;
  margin-bottom:2px;
  position: relative;
}
.field.line .fa.fa-times{
  position: absolute;
  top: 7px;
  right: -35px;
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #d93535;
  cursor: pointer;
}

.wb-btn{
  width: 132px;
  box-sizing: border-box;
  height: 40px;
  margin-left:12px;
}
</style>
