<template>
  <h1><span>Отчет по артикулу</span></h1>
  <div id="areacontent" class='area-content interface-report'>
    <Filter
      :options = "filter"
      stick = ""
      @change-field="showGraph" />

    <div v-if="info3.length > 0" class="right-top-icons">
      <div v-for="(el, ind) in info3" :key="ind"
        :title="el.title"
        class="flag-line"
        :class="el.color">
        <div class="icon-head">
          <i v-if="el.type == 0" class="fa" :class="el.icon" aria-hidden="true"></i>
          <span v-else>{{el.icon}}</span>
        </div>
        <div class="icon-body">{{el.val}}</div>
      </div>
    </div>
    <div class="text-warn-1 mar15" v-if="errorText != ''">
      {{errorText}}
    </div>
    <WBChart
      v-show="graphData.length"
      :dataGraph = "graphData"
      :options = "graphOptions"
      @chg-options = "toggleGraph"/>

    <div class="wrp-table" v-if="info.title">
      <div class="title">{{info.title}}</div>
      <table>
        <tr v-for="(elem, ind) in info.list" :key="ind">
          <td v-for="(ele, ind2) in elem" :key="ind2">{{ele}}</td>
        </tr>
      </table>
    </div>
    <div class="wrp-table" v-if="info4.list && info4.list.length">
      <div class="title" v-if="info4.title">{{info4.title}}</div>
      <table>
        <tr v-for="(el, ind) in info4.list" :key="ind">
          <td>[{{el.articul}}] {{el.name}}</td>
        </tr>
      </table>
    </div>
    <div class="wrp-table" v-if="info2.head">
      <div class="title" v-if="info2.title">{{info2.title}}</div>
      <table>
        <tr class="table-title">
          <th v-for="(elem, ind) in info2.head" :key="ind">{{elem}}</th>
        </tr>
        <tr v-for="(elem, ind) in info2.list" :key="ind">
          <td v-for="(ele, ind2) in elem" :key="ind2">{{ele}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import WBChart from '@/components/parts/WBChart.vue';

export default {
  name: 'RubDS',
  components: {
    WBChart, Filter,
  },
  data() {
    return {
      urlAction: '/scm/api/report/articul',

      filter: {},
      graphData: [],
      graphOptions: {},

      stock: {
        value: 0,
        options: [],
        def: '',
      },

      info: {},
      info2: {},
      info3: [],
      info4: {},

      errors: [],
      errorText: '',
    };
  },
  created() {
    if (this.$route.query.stock && this.$route.query.prod) {
      this.filter = {
        left: [
          {
            value: {
              val: this.$route.query.stock,
            },
          },
          {
            value: this.$route.query.prod,
          },
        ],
      };
    } else if (localStorage.getItem('stock')) {
      this.urlAction += `?stockid=${localStorage.getItem('stock')}`;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.post(this.urlAction, { filter: this.filter })
        .then((res) => {
          this.filter = res.data.filter;
          this.graphData = res.data.graphData;
          this.graphOptions = res.data.graphOptions;

          this.info = res.data.info;
          this.info2 = res.data.info2;
          this.info3 = res.data.info3;
          this.info4 = res.data.info4;
          this.errorText = res.data.answer;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    showGraph() {
      setTimeout(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (this.filter.left[0].value.val) {
          localStorage.setItem('stock', this.filter.left[0].value.val);
          urlParams.set('stock', this.filter.left[0].value.val);
        } else {
          localStorage.setItem('stock', this.filter.left[0].value);
          urlParams.set('stock', this.filter.left[0].value);
        }

        if (this.filter.left[1].value) {
          urlParams.set('prod', this.filter.left[1].value);
          window.history.pushState({}, '', `?${urlParams.toString()}`);
        }

        axios.post(this.urlAction, { filter: this.filter })
          .then((res) => {
            this.graphData = res.data.graphData;
            this.graphOptions = res.data.graphOptions;

            this.info = res.data.info;
            this.info2 = res.data.info2;
            this.info3 = res.data.info3;
            this.info4 = res.data.info4;
            this.errorText = res.data.answer;
          })
          .catch(() => {
            this.$router.push('/404');
          });
      }, 50);
    },
    toggleGraph(data) {
      this.graphOptions.legend[data.ind].show = data.val;
    },
  },
};
</script>

<style scoped>
.mar15{
  margin-bottom: 15px;
}
.area-content {
  padding-left: 12px;
  padding-right: 12px;
}
.text-warn-1 {
  background: #efcccc;
  color: #852121;
  padding: 12px;
  /* border: 1px solid #e79c9c; */
}

.wrp-table {
  text-align: left;
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  background: #ebf6ff;
}
.wrp-table .title {
  font-weight: bold;
  font-size: 18px;
  padding-left: 8px;
  padding-top: 8px;
  margin-bottom: 8px;
}

.wrp-table table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table table td, .wrp-table table th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.wrp-table .table-title th {border-top: 0px;}
.wrp-table table tr:hover td {
  background: #eee;
}

.right-top-icons {
  margin-top: -15px;
  z-index: 0;
  position: relative;
  background: #bbb;
  float: right;
}
.flag-line {
    display: inline-block;
    vertical-align: top;
}
.right-top-icons .flag-line {
  margin: 0px 0px 0px 2px;
  background: #efefef;
  padding: 4px 8px 2px;
  text-align: center;
  min-width: 30px;
  cursor: default;
}
.right-top-icons .flag-line > div {
  display: inline-block;
}
.icon-head{margin-right: 12px;}

.flag-line.blue,
.flag-line.green,
.flag-line.yellow,
.flag-line.red,
.flag-line.black,
.flag-line.gray{
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,
                    transparent 0,
                    transparent 50%,
                    hsla(0,0%,100%,.15) 0,
                    hsla(0,0%,100%,.15) 75%,
                    transparent 0,
                    transparent);
  color: #fff;
}
.flag-line.blue{ background-color: #5baade;}
.flag-line.green { background-color: #5cb85c;}
.flag-line.yellow { background-color: #f0ad4e;}
.flag-line.red { background-color: #d9534f;}
.flag-line.black { background-color: #757575;}
</style>
