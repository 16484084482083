<template>
  <div class="title" v-if="title.length">
    {{title}}
  </div>
  <table class="table-info">
    <tbody>
      <tr v-for="(elem, ind) in els" :key="ind" :class="{'odd': ind % 2 == 0}">
        <td v-for="(elem2, ind2) in elem" :key="ind2" :class="{'th': ind2 == 0}" >{{elem2}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'TableInfo',
  props: ['title', 'els'],
  data() {
    return {
      list: [],

      errors: [],
      spiner: false,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.title{
  font-weight: bold;
  padding: 10px;
  min-width: 320px;
  color: #fff;
  background: #6b7bb9;
}
table{
  border-spacing: 0px;
  border-collapse: collapse;
}
.table-info{
  width: 100%;
}
.table-info td{
  text-align: left;
  border-top: 1px solid #ddd;
  line-height: 37px;
  padding: 0px 10px;
}
.table-info .odd{
  background:#eee;
}
.th{font-weight: bold}
.table-info tr:hover td{
  background: #dfdfdf;
}
</style>
