<template>
  <div class="" style="max-height:400px; overflow-y:auto; margin-bottom: 10px;">
    <table class="log-synh" style="width: 100%;">
      <tr class="log-synh-el" v-for="(el, ind) in synh_logs"
        :key="ind" :class="{error: el.t == 'e'}">
        <td>{{el.created_at}}</td>
        <td>{{el.cname}}</td>
        <td>{{el.sname}}</td>
        <td>{{el.ename}}</td>
      </tr>
    </table>
  </div>
  <div>
    <table v-if="dirs.length" border="1" style="margin-bottom: 20px;">
      <tr><th>Не выгруженные файлы</th></tr>
      <tr v-for="(el, ind3) in dirs" :key="ind3"><td>{{el}}</td></tr>
    </table>
    <div v-else class="alert-info">XML очередь файлов пуста</div>

    <table border="1" style="margin-bottom: 20px;">
    <tr v-for="(el, ind) in stat_req" :key="ind">
      <td><strong>{{el.name}}</strong></td><td>{{el.cnt}}</td>
    </tr>
    </table>
  </div>
  <div class="log-items" style="max-height: 400px; overflow-y:auto;">
    <div class="item cr-row"  v-for="(el, ind) in logs" :key="ind">
      <div class="cr-col col-1">{{el.val}}</div>
      <div class="cr-col col-2">{{el.cname}}</div>
      <div class="cr-col col-3">{{el.uname}}</div>
      <transition name="fade">
      <div class="cr-col col-4 info-pack" v-if="el.info.length"
        @click="openInfo(ind)"
        @keydown.f7="openInfo(ind)"
         :class="{open: el.show}">
        <div class="info-el" v-for="(el2, ind2) in el.info" :key="ind2" >
          <strong>{{el2.lbl}}:</strong> {{el2.val}}
        </div>
      </div>
      </transition>
    </div>
  </div>
  <pre v-if="errors.length">{{errors}}</pre>
</template>

<script>
import axios from 'axios';

export default {
  name: 'GodReq',
  data() {
    return {
      logs: [],
      stat_req: [],
      dirs: [],
      synh_logs: [],

      flagOpen: false,

      info: [],
      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.post('/god/company/get-logs')
        .then((res) => {
          this.logs = res.data.list;
          this.stat_req = res.data.stat_req;
          this.dirs = res.data.dirs;
          this.synh_logs = res.data.synh_logs;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    openInfo(ind) {
      if (this.logs[ind].show) {
        this.logs[ind].show = false;
      } else {
        this.logs[ind].show = true;
      }
    },
  },
};
</script>

<style scoped>
.log-synh-el td{
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  padding:6px;
}
.log-synh-el.error td{
  background-color: #f8d7da;
  color: #842029;
  border-color: #f5c2c7;
}

.cr-row{
  display: flex;
  margin-bottom: 2px;
}
.cr-col{
  padding: 10px;
  box-sizing: border-box;
  background: #d9e8f1;
}
.cr-col.col-1{
  /* width: 10%; */
  flex:1;
}
.cr-col.col-2{
  /* width: 20%; */
  flex:2;
}
.cr-col.col-3{
  /* dth: 15%; */
  flex:1;
}
.cr-col.col-4{
  /* width: 49%; */
  flex:5;
}
.info-pack{
  height: 56px;
  overflow: hidden;
  cursor: pointer;
  transition: height 0.5s;
}
.info-pack.open{
  height: 150px;
}

.offset-r{
  margin-right: 6px;
}
tr.first{cursor: pointer;}
tr.first:hover td{opacity: 0.9}
.instrumental{position: fixed;
  left:0px;
  bottom:0px;
  background: #aaa;
  width: 100%;
}

.btn.btn-add{
  background: #5981ce;
  color: #fff;
  padding: 6px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.btn.btn-add:hover{
  background: #3b70d7;
  padding: 8px;
}
.btn.btn-add span, .btn.btn-add i{
  vertical-align: middle;
}

.list-table td.cell-act{
  padding: 0px;
}
.acts__item{
  padding:0px
}
.acts__item a{
  font-size: 25px;
}
.list-table{
  width:100%;
  text-align: left;
}

.list-table tr.odd{
  background: #e5f0ff;
}

.list-table th{
  background: #5981ce /* #5390ef */;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  line-height: 18px;
}
.list-table td{
  vertical-align:top;
  padding: 8px;
}
.list-list-item{
  padding: 4px;
  background: #ccc;
  margin-bottom: 1px;
  border-radius:2px;
  font-size:12px;
}
.list-list-item .unlink{float:right; padding-left:4px;}
.stick-right{
  float: right;
  height: 34px;
  line-height: 34px;
  width: 34px;
  text-align: center;
  background: #f9d0d0;
  border: 1px solid #a17979;
  margin-top: 1px;
  color: #700e0e;
}
.alert-info{
  background-color: #dbf8e1;
  display: inline-block;
  padding: 8px;
  margin-top: 2px;
  margin-bottom: 12px;
  color: #1a502a;
}
</style>
