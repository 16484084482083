<template>
  <div class="wb-autocomplette" v-click-outside="closeSelect">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <div
      @mouseenter="entermouse"
      @mouseleave="leavemouse"
      @focusin="entermouse"
      @focusout="leavemouse"
      >
      <input :id="id" class="text" @input="sendDataList" v-model="search" :placeholder="def"/>
      <div v-if="showClear" class="btn-clear" @click.stop="newinput" @keydown.tab.stop="newinput">
        <i class="fa fa-times-circle" aria-hidden="true"></i>
      </div>
    </div>
    <transition name="fade">
      <div class="wb-autocomplette-list" v-if="showList">
        <template v-if="options.length">
          <div class="list--item" v-for="(option, index) in options"
            :key="index"
            @click="selectProduct(option[0], option[1])"
            @keydown.enter="selectProduct(option[0], option[1])">{{option[1]}}</div>
        </template>
        <template v-else>
          <div class="list--item">Не найдено</div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AutoComplette',
  props: ['lbl', 'text', 'def', 'modelValue', 'url'],
  data() {
    return {
      showList: false,
      search: this.text,
      loader: false,
      modeInput: false,
      showClear: false,
      tempName: '',

      id: null,
      options: [],
    };
  },
  mounted() {
    this.id = `auto${this.lbl.length}`;
  },
  methods: {
    sendDataList() {
      if (this.search.length > 2) {
        this.showList = true;
        axios.get(this.url, {
          params: {
            search: this.search,
            limit: 50,
          },
        })
          .then((res) => {
            this.options = res.data.list;
            this.showList = true;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.options = [];
        this.showList = false;
      }
      if (this.search === '') {
        this.showClear = false;
      } else {
        this.showClear = true;
      }
    },
    selectProduct(val, lbl) {
      this.showList = false;
      this.search = lbl;
      this.$emit('change');
      this.$emit('update:modelValue', val);
      this.modeInput = false;
    },
    closeSelect() {
      this.showList = false;

      if (this.modeInput) {
        this.modeInput = false;
        this.search = this.tempName;
      }
    },
    entermouse() {
      if (this.search !== '') {
        this.showClear = true;
      }
    },
    leavemouse() {
      this.showClear = false;
    },
    newinput() {
      const tempName2 = this.search;
      this.tempName = tempName2;
      this.search = '';
      this.showClear = false;
      this.modeInput = true;

      document.getElementById(this.id).focus();
    },
  },
};
</script>

<style scoped>
  .wb-autocomplette{
    position: relative;
  }
  .wb-autocomplette input.text{
    line-height: 18px;
    border: 1px solid #aaa;
    padding: 10px 12px;
    border-radius: 0px 4px 4px 0px;
    border-left-width: 4px;
    outline:none;
    box-sizing: border-box;
    width: 250px;
    font-size: 16px;
  }
  .wb-autocomplette input.text:hover{
    background: #efefef;
  }
  .wb-autocomplette-list{
    position: absolute;
    left: 0px;
    top: 37px;
    border:1px solid #aaa;
    border-left-width: 4px;
    min-width: 245px;
    max-width: 320px;
    max-height: 340px;
    overflow-y: auto;
    background: #fff;
    z-index: 12;
  }

  /* wb100 */
  .wb-autocomplette.wb100 input.text{
    width: 100%;
  }
  .wb100 .wb-autocomplette-list{
    max-width: 100%;
  }

  .wb-autocomplette-list .list--item {
    padding:8px 12px;
    cursor: pointer;
  }
  .list--item:hover {
    background: #ddd
  }
  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }
  .btn-clear{
    position: absolute;
    top: 50%;
    margin-top: -12px;
    right: 6px;
    cursor: pointer;
    font-size: 24px;
    z-index: 15;
    background: #fff;
    border-radius: 24px;
    line-height: 23px;
    color: #777;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
