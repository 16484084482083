<template>
  <div>
    <Filter
      :options = "filter"
      stick = ""
      @change-field="showGraph" />
    <div  class="wrp-middle">
      <WBChart
        v-show="graphData.length"
        :dataGraph = "graphData"
        :options = "graphOptions"
        @chg-options = "toggleGraph"/>
      <table v-if="graphData.length" class="table">
        <thead>
          <tr>
            <th v-for="(el, ind) in heads" :key="ind">{{el}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(el, ind) in info" :key="ind">
            <th>{{el.title}}</th>
            <td>{{el.you}}</td>
            <td>{{el.tos}}</td>
            <td :class="el.class">{{el.diff}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import WBChart from '@/components/parts/WBChart.vue';
import Filter from '@/components/parts/Filter.vue';

export default {
  name: 'WBGraphParamFIlter',
  components: {
    WBChart, Filter,
  },
  emits: ['closeOuterModal'],
  props: {
    action: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      graphData: [],
      graphOptions: [],

      heads: [],
      info: [],

      linkUrl: '',
      linkParam: '',
      filter: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
  },
  methods: {
    init() {
      axios.post(this.action)
        .then((res) => {
          this.filter = res.data.filter;
          this.graphData = res.data.graphData;
          this.graphOptions = res.data.graphOptions;

          this.info = res.data.info;
          this.info2 = res.data.info2;
          this.errorText = res.data.answer;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    showGraph() {
      setTimeout(() => {
        axios.post(this.action, { filter: this.filter })
          .then((res) => {
            this.graphData = res.data.graphData;
            this.graphOptions = res.data.graphOptions;

            this.info = res.data.info;
            this.heads = res.data.heads;
          })
          .catch(() => {
            this.$router.push('/404');
          });
      }, 50);
    },
    toggleGraph(data) {
      this.graphOptions.legend[data.ind].show = data.val;
    },
  },
};
</script>

<style scoped>
h1{margin-right: 0px;}
.wrp-graph{
  padding-left: 10px; padding-right: 10px;
  background: white;
}
.head-graph{
  padding-bottom: 20px;
  background: white;
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}
.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 28px;
  background: white;
  z-index: 10;
  transition: width 1s;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.btn-active{
  color: #5981ce;
}
.tr-head span{
  margin-right: 15px;
}
.right{
  float: right;
}
.modal-form{
  text-align: center;
}
.wrp-table{
  margin-bottom: 40px;
}
.short-input{
  width:100px;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.icon-last-status{
  font-size: 22px;
  font-weight: bold;
  padding-right: 6px;
}

.btn-close{
  float: right;
  font-size: 20px;
  color: #780c0c;
  cursor: pointer;
}
.btn-close:hover {
  color: #bf1111;
}

.btn-close-big{
  float: right;
  font-size: 28px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
  padding-left: 3px;
  padding-right: 3px;
}
.btn-close-big:hover {
  color: #bf1111;
}

.err-text{
  margin-top: -18px;
  margin-bottom: 10px;
  color: #b34040;
  background: #ffdfdf;
  padding: 4px;
}

.block .column{
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  /*
  margin-right: 12px;
  margin-left: 12px;
  */
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: bottom;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.btn-a{color: #556b7d}
.btn-a:hover {color: #2f4a60; text-decoration: underline;}

.btn-cart{
  display: block;
  width: 100%;
  background: #44b752;
  border: 1px solid #2c9539;

  color:#fff;
  text-align: center
}
.btn-cart:hover{background: #2c9539}
.btn-cart.disabled{
  background: #a4e5ac;
  border-color: #ddd;
  position: relative;
}

.btn-cart.disabled:after{
  content: '';
  position: absolute;
  top: 5px;
  left: 40%;
  margin-left: -15px;
  width: 60px;
  height: 30px;
  border-top: 2px solid red;
  transform: rotate(-40deg);
}

tr.disabled {
  opacity: 0.5;
}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.table{width: 100%; margin-top: 20px;
  border-collapse: collapse;
}
.table > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}
.table > thead > tr > th, .table > tbody > tr > th,
.table > tfoot > tr > th, .table > thead > tr > td,
.table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th{
  background: #ebebeb;
}
.table tr > th{
  text-align: left;
}
.wrp-middle{
  margin-left: 10px;
  margin-right: 10px;
}

.table > tbody > tr:hover td, .table > tbody > tr:hover th{
  background: #eee;
}

td.red{
  background: #ffd2d2;
  border-bottom: 1px solid white;
}
td.green{
  background: #bbf1be;
  border-bottom: 1px solid white;
}

.table > tbody > tr:hover td.red{
  background: #fec4c4;
}
.table > tbody > tr:hover td.green{
  background: #aaefae;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
