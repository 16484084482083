<template>
  <div class="wb-input-calendar" v-click-outside="closeSpoiler">
    <div v-if="lbl" class="label">{{lbl}}</div>
    <div @click="showCalendar" @keydown.tab="showCalendar" class="input">{{textData}}</div>
    <transition name="fade">
      <div v-if="flagShowCalendar" class="wrp-calendar">
        <div class="calendar--head">
          <span @click="nextMonth" @keydown.right="nextMonth" class="arr right">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </span>
          <span @click="prevMonth" @keydown.left="prevMonth" class="arr left">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
          </span>
          <div class="year">{{monthText}} {{year}}</div>
        </div>
        <div class="calendar--body">
          <div class="weeks" v-if="weekDays">
            <div class="day" v-for="(day, ind) in weekDays" :key="ind">
              {{day}}
            </div>
          </div>
          <div class="days" v-for="(week, ind) in monthDays" :key="ind" >
            <div @click="pickDay(day)"
              @keydown.enter="pickDay(day)" class="day" v-for="(day, ind2) in week" :key="ind2"
              :class="{'active': currDay === day && month === currMonth && year === currYear}">
              <span v-if="day > 0" >{{day}}</span>
              <span v-else></span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputCalendar',
  props: ['range', 'lbl', 'type', 'weekDays', 'months', 'format', 'modelValue'],
  data() {
    return {
      flagShowCalendar: false,
      year: 0,
      month: 0,
      currDay: 0,
      currMonth: 0,
      currYear: 0,
      monthText: '',
      monthDays: [],
      weekDay: 0,

      monthDaysCnt: [
        31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
      ],
    };
  },
  computed: {
    textData() {
      const date = new Date(this.modelValue * 1000);
      const y = date.getFullYear();
      const m = this.months[date.getMonth()];
      const d = date.getDate();

      return `${d} ${m} ${y}`;
    },
  },
  methods: {
    nextMonth() {
      this.month += 1;
      if (this.month > 11) {
        this.month = 0;
        this.year += 1;
      }
      this.monthText = this.months[this.month];
      this.renderMonthDays();
    },
    prevMonth() {
      this.month -= 1;
      if (this.month < 0) {
        this.month = 11;
        this.year -= 1;
      }
      this.monthText = this.months[this.month];
      this.renderMonthDays();
    },
    pickDay(newDay) {
      const newTime = new Date(this.year, this.month, newDay, 0, 0, 0).getTime() / 1000;
      this.$emit('update:modelValue', newTime);
      this.flagShowCalendar = false;
    },
    showCalendar() {
      if (this.flagShowCalendar) {
        this.flagShowCalendar = false;
      } else {
        const date = new Date(this.modelValue * 1000);
        this.year = date.getFullYear();
        this.monthText = this.months[date.getMonth()];
        this.month = date.getMonth();
        this.currDay = date.getDate();

        this.currYear = date.getFullYear();
        this.currMonth = date.getMonth();

        this.renderMonthDays();

        this.flagShowCalendar = true;
      }
    },
    renderMonthDays() {
      this.monthDays = [];
      this.weekDay = new Date(this.year, this.month, 1).getDay() - 1;
      if (this.weekDay === -1) {
        this.weekDay = 6;
      }

      const weekDays = 7;
      let weekDaysCnt = 0;
      let tempWeek = [];
      for (let i = 1; i <= this.monthDaysCnt[this.month] + this.weekDay; i += 1) {
        tempWeek.push(i - this.weekDay);

        weekDaysCnt += 1;
        if (weekDaysCnt === weekDays) {
          weekDaysCnt = 0;
          this.monthDays.push(tempWeek);
          tempWeek = [];
        }
      }
      if (tempWeek.length) {
        this.monthDays.push(tempWeek);
      }
    },
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    closeSpoiler() {
      this.flagShowCalendar = false;
    },
  },
};
</script>

<style scoped>
  .wb-input-calendar {
    position: relative;
    display: inline-block;
  }
  .wb-input-calendar .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }
  .wrp-calendar {
    position: absolute;
    top: 38px;
    right: 0px;
    width:211px;
    background: #fff;
    padding: 6px;
    box-shadow: 0px 1px 2px #999;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #aaa;
    border-top: 1px solid #d7d7d7;
    z-index: 12;
    text-align:center;
  }

  .calendar--head{height: 25px;}
  .calendar--body{text-align: left;}
  .calendar--body .day{
    width: 28px;
    display: inline-block;
    padding: 4px 5px;
    text-align: right;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .calendar--body .weeks .day{font-weight: bold}
  .calendar--body .days .day.active, .calendar--body .days .day:hover{
    color: #fff;
    background: #60c46b;
    cursor: pointer;
  }

  .req{border-left: 4px solid #2567f3;}
  .error{
    float: right;
    position: absolute;
    display: block;
    top: 36px;
    left: 0px;
    width: 91.5%;
    z-index: 1;
    background: #f3c9c9;
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
    padding: 3px 11px;
    color: #b33d3d;
  }
  .error-input{
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
  .error .close{
    float: right;
    cursor: pointer;
    font-style: normal;
    margin-right: -11px;
    margin-top: -3px;
    display: block;
    width: 22px;
    height: 22px;
    text-align: center;
  }

  div.input{
    background: #fff;
    display: inline-block;
    padding: 10px 12px;
    border-radius: 0 4px 4px 0px;
    border: 1px solid #aaa;
    border-left-color: rgb(170, 170, 170);
    border-left-style: solid;
    border-left-width: 1px;
    border-left: 4px solid #aaa;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    width: 214px;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  div.input:hover{
    background: #eee;
  }
  .arr{
    width: 20px;
    height: 20px;
    background: #5981ce;
    color: #fff;
  }
  .arr.right{
    float: right;
  }
  .arr.left{
    float: left;
  }
</style>
