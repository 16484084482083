<template>
  <table v-if="items" class="list-table">
    <tr>
      <th v-for="(lbl, ind) in items.heads" :key="ind">
        {{lbl}}
      </th>
    </tr>
    <tr :class="{odd: ind % 2 === 0}" v-for="(user, ind) in items.items" :key="ind">
      <td v-for="(field, ind2) in user.fields" :key="ind2">
        <template v-if="field.type === 'S'">
          <span class="type-s">{{field.val}} <span v-if="field.lbl">({{field.lbl}})</span></span>
        </template>
        <template v-else-if="field.type === 'L'">
          <div class="list-list-item" v-for="(elem, ind3) in field.val" :key="ind3">
            <div class="unlink" title="Отвязать роль"
              @click="linkDel(user.fields[0].val, elem.val, ind, ind2, ind3)"
              @keydown.f9="linkDel(user.fields[0].val, elem.val, ind, ind2, ind3)">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            {{elem.lbl}}
          </div>
          <a href="javascript:void(0)" class="add-elem"
            @click="showList(field.addsel.type, user.fields[0].val, ind, ind2)">
            <i class="fa fa-plus" aria-hidden="true"></i>
            <span v-if="field.addsel" style="padding-left:4px;">{{field.addsel.title}}</span>
            <span v-else style="padding-left:4px;">Добавить</span>
          </a>
        </template>
      </td>
      <td v-if="user.act"  class="cell-act">
        <div class="acts__item" v-for="(action, ind4) in user.act" :key="ind4">
            <template v-if="action.type == 'url'">
              <div :title="action.title"
                @click.prevent.stop="actTable(action.act, ind, ind4, action.type)"
                @keydown.f10="actTable(action.act, ind, ind4, action.type)">
                <i class="fa" :class="action.icon" aria-hidden="true"></i>
              </div>
            </template>
            <template v-if="action.type == 'chk'">
              <div :title="action.items[action.val].title"
                @click.prevent.stop="actTable(action.items[action.val].act, ind, ind4, action.type)"
                @keydown.f8.stop="actTable(action.items[action.val].act, ind, ind4, action.type)" >
                <i class="fa" :class="action.items[action.val].icon" aria-hidden="true"></i>
              </div>
            </template>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'AC',
  props: ['items'],
  data() {
    return {
    };
  },
  methods: {
    actTable(action, ind, ind4, type) {
      this.$emit('act-table', {
        act: action, i: ind, i4: ind4, t: type,
      });
    },
    linkDel(val, role, ind, ind2, ind3) {
      this.$emit('link-del', {
        val,
        role,
        ind,
        ind2,
        ind3,
      });
    },
    showList(type, val, ind, ind2) {
      this.$emit('show-list', {
        type,
        val,
        ind,
        ind2,
      });
    },
  },
};
</script>

<style scoped >
.list-table td.cell-act{
  padding: 0px;
}
.acts__item{
  padding:0px
}
.acts__item a{
  font-size: 25px;
}
.list-table{
  width:100%;
  margin-top:12px;
  text-align: left;
}

.list-table tr.odd{
  background: #e5f0ff;
}

.list-table th{
  background: #5981ce /* #5390ef */;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  line-height: 18px;
}
.list-table td{
  vertical-align:top;
  padding: 8px;
}
.list-list-item{
  padding: 4px;
  background: #ccc;
  margin-bottom: 1px;
  border-radius:2px;
  font-size:12px;
}
.list-list-item .unlink{float:right; padding-left:4px;}

.add-elem{text-align:center; width:100%; display:block;
  background: #bbb;
  font-size:12px;
  line-height:14px;
  padding:4px 0;
}

.type-s{display:block; padding:2px 4px; font-size:16px;}
.acts__item{display:inline-block; padding:4px;}

.fa.green{color:#3fbd3a}
.fa.red{color:#f06565;}
</style>
