<template>
  <div class="wb-select"
  :class="{'error-input': err, 'req': req}">
    <div class="label" v-if="lbl">{{lbl}} ({{allChecked}} / {{elements.length}})</div>
    <div class="wb-select-label"
      @click.stop="showList = !showList"
      @keydown.f7.stop="showList = !showList">
      <div class="icon-right">
        <template v-if="showList">
          <i class="fa fa-chevron-up"></i>
        </template>
        <template v-else >
          <i class="fa fa-chevron-down"></i>
        </template>
      </div>
      <div class="val-text">{{def}}</div>
    </div>
    <transition name="fade">
      <div class="wb-select-list" v-if="showList">
        <div v-for="(el, ind) in options" :key="ind"
          @click="selectOption(ind)"
          @keydown.f8="selectOption(0)">
          <div class="list--item" v-if="!el.chk">{{el.lbl}}</div>
        </div>
      </div>
    </transition>
    <div class="list">
      <div class="wrp-current" v-for="(el, ind) in elements"
        :key="ind">
        <div class="current" v-if="el.chk">
          <div class="squad">
            <span v-if="el.chk">&#10004;</span>
          </div>
          <span>{{el.lbl}}</span>
          <i class="fa fa-times"
            @click="clean(ind)"
            @keydown.f9="clean(0)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect2',
  props: ['lbl', 'options', 'def', 'modelValue', 'err', 'req'],
  data() {
    return {
      showList: false,
    };
  },
  computed: {
    elements() {
      return this.options;
    },
    allChecked() {
      let len = this.elements.length;
      let cnt = 0;
      while (len > 0) {
        len -= 1;
        if (this.elements[len].chk) {
          cnt += 1;
        }
      }

      return cnt;
    },
  },
  methods: {
    clean(ind) {
      if (this.elements[ind].chk) {
        this.elements[ind].chk = false;
      }
      this.$emit('update:modelValue', this.elements);
      this.$emit('change');
    },
    selectOption(ind) {
      this.showList = false;

      if (this.elements[ind].chk) {
        this.elements[ind].chk = false;
      } else {
        this.elements[ind].chk = true;
      }

      this.$emit('update:modelValue', this.elements);
      this.$emit('change');
    },
    closeSelect() {
      this.showList = false;
    },
  },
};
</script>

<style scoped>
  .current{
    display: inline-block;
    background: #b9ebb9;
    padding: 0px 4px;
    margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid #a9d7a9;
  }
  .current .squad {
    float: left;
    margin-right: 2px;
  }
  .current .fa-times{
    color: #e74949;
    margin-left:3px;
    transition: color 0.5s;
  }
  .current .fa-times:hover{
    color: #b249e7;
  }
  .wrp-current{
    display:inline-block;
  }
  .list--item{
    padding:8px 12px
  }
  .list--item:hover{
    background: #;
  }

  .wb-select{
    position:relative;
    border-radius: 0 4px 4px 0;
    border: 1px solid #aaa;
    cursor: pointer;
    background: #fff;
    border-left: 4px solid #aaa;
    min-width: 200px;
  }
  .wb-select-label{
    padding: 10px 12px;
    white-space: nowrap;
  }
  .req{border-left: 4px solid #2567f3;}
  .val-text{
    max-width: 200px;
    overflow: hidden;
  }
  .wb-select-label:hover{background:#efefef;}
  .wb-select-list{
    position: absolute;
    left: -4px;
    top: 26px;
    margin-top: 10px;
    width: 100%;
    max-height: 250px;
    background: #fff;
    border: 1px solid #aaa;
    border-radius: 0px 0px 4px 4px;
    overflow-y: auto;
    border-left: 4px solid #aaa;
    z-index:2
  }

  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }

  /* W3C standard сейчас только для Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
  }
  /* для Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d7d7d7;
    width:3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #848484;
  /*  border-radius: 5px; */
    border: 2px solid #d7d7d7;
  }
  .icon-right{
    float: right;
    margin-left: 5px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
