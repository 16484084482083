<template>
  <h1>
    <span>Моделирование</span>
  </h1>

  Тут будет Второй шаг заполнения формы
</template>

<script>
export default {
  name: 'ScmAddStepTwo',
  components: {
  },
  data() {
    return {

    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log('инициализация');
    },
  },
};
</script>
<style scoped>

</style>
