<template>
  <div class="scm">
    <h1><span>Отчет об изменении ЦУ</span></h1>

    <div v-if="!loaderFilter">
      <Filter
        class="wb-filter"
        :options = "filter"
        stick = ""
        @change-field="chgFilter" />
    </div>

    <WBManage v-if="action"
      :action="action"
      :filter="filter"
      :key="key"
      :modepost="1"
    />
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import WBManage from '@/components/molecules/WBManage.vue';

export default {
  name: 'ChangeCu',
  components: {
    WBManage, Filter,
  },
  data() {
    return {
      urlAction: '/scm/api/report/change-cu',

      filter: {},
      action: '',
      key: 0,

      loaderFilter: true,
    };
  },
  mounted() {
    if (localStorage.getItem('stock')) {
      this.urlAction += `?stockid=${localStorage.getItem('stock')}`;
    }
    this.init();
  },
  methods: {
    init() {
      axios.get(this.urlAction)
        .then((res) => {
          this.filter = res.data.filter;
          this.action = res.data.action;

          this.loaderFilter = false;
        })
        .catch(() => {
          this.loaderFilter = false;
          this.$router.push('/404');
        });
    },
    chgFilter(options) {
      this.filter = options;
      this.key += 1;

      setTimeout(() => {
        if (this.filter.left[0].value.val) {
          localStorage.setItem('stock', this.filter.left[0].value.val);
        } else {
          localStorage.setItem('stock', this.filter.left[0].value);
        }

        this.loaderFilter = true;
        const data = { filter: this.filter };
        axios.post('/scm/api/report/change-cu', data)
          .then((res) => {
            this.filter = res.data.filter;

            this.loaderFilter = false;
          })
          .catch(() => {
            this.loaderFilter = false;
            this.$router.push('/404');
          });
      }, 50);
    },
    /*
    toggleGraph(data) {
      this.graphOptions.legend[data.ind].show = data.val;
    },
    */
  },
};
</script>

<style scoped>

</style>
