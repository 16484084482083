<template>
  <table class="table-info">
    <tbody>
      <tr v-for="(elem, ind) in els" :key="ind" :class="{'odd': ind % 2 == 0}">
        <td v-for="(elem2, ind2) in elem" :key="ind2" :class="{'th': ind2 == 0}" >{{elem2}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TableInfo',
  props: ['action', 'list'],
  data() {
    return {
      els: [],

      errors: [],
      spiner: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.spiner = true;
      if (this.action !== undefined) {
        axios.get(this.action, { form: this.curr_fields })
          .then((res) => {
            if (res.data.success === 1) {
              this.els = res.data.list;
            }
            this.spiner = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else {
        this.els = this.list;
      }
    },
    outError(ind) {
      this.curr_fields[ind].error = '';
    },
  },
};
</script>

<style scoped>
table{
  border-spacing: 0px;
  border-collapse: collapse;
}
.table-info{
  width: 100%;
}
.table-info td{
  text-align: left;
  border-top: 1px solid #ddd;
  line-height: 37px;
  padding: 0px 10px;
}
.table-info .odd{
  background:#eee;
}
.th{font-weight: bold}
.table-info tr:hover td{
  background: #dfdfdf;
}
</style>
