<template>
  <div class="wrp-board">
    <Board :action="action" />
  </div>
</template>

<script>
// import axios from 'axios';
import Board from '@/components/parts/WBBoard.vue';

export default {
  name: 'ScmLogist',
  components: {
    Board,
  },
  data() {
    return {
      filter: {},
      loader: false,

      action: '/scm/logist/board',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      /*
      axios.get('/scm/panel/set')
        .then((res) => {
          if (res.data.success) {
            this.filter = res.data.filter;
            this.diagramms = res.data.diagramms;
            this.diagramms2 = res.data.diagramms2;
          } else {
            alert(res.data.answer);
          }
        })
        .catch(() => {
          this.$router.push('/404');
        });
      */
    },
  },
};
</script>

<style scoped>
  .wrp-board{
    width: 100%;
    overflow-x: auto;
  }
</style>
