import axios from 'axios';

const state = () => ({
  authData: {
    token: localStorage.getItem('user'),
    menu: JSON.parse(localStorage.getItem('menu')),
    qmenu: JSON.parse(localStorage.getItem('qmenu')),
    start: localStorage.getItem('start'),
  },
  loginStatus: '',
  showMissing: 0,
  errors: [],
});

const getters = {
  getLoginStatus(state) {
    return state.loginStatus;
  },
  getAuthData(state) {
    return state.authData;
  },
  getErrors(state) {
    return state.errors;
  },
  getShowMissing(state) {
    return state.showMissing;
  },
};

const actions = {
  async login({ commit }, payload) {
    // console.log(payload);
    axios.post('/auth/login', { form: payload })
      .then((res) => {
        if (res.data.success === 1) {
          commit('saveTokenData', res.data);
          commit('setLoginStatus', 'success');
        } else {
          commit('setErrors', res.data.pack);
          commit('setLoginStatus', 'failed');
          // this.curr_fields = res.data.pack;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
  async logout({ commit }) {
    commit('delTokenData');
  },
  chgMissing({ commit }, val) {
    commit('setShowMissing', val);
  },
};

const mutations = {
  saveTokenData(state, data) {
    localStorage.setItem('user', data.token);
    localStorage.setItem('menu', JSON.stringify(data.nav));
    localStorage.setItem('qmenu', JSON.stringify(data.qmenu));
    localStorage.setItem('start', data.start);
    const newTokenData = {
      token: data.token,
      menu: data.nav,
      qmenu: data.qmenu,
      start: data.start,
    };
    state.authData = newTokenData;
  },
  delTokenData(state) {
    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    localStorage.removeItem('qmenu');
    localStorage.removeItem('start');
    const newTokenData = {
      token: '',
      menu: '',
      qmenu: '',
      start: '',
    };
    state.authData = newTokenData;
  },
  setLoginStatus(state, value) {
    state.loginStatus = value;
  },
  setErrors(state, value) {
    state.errors = value;
  },
  setShowMissing(state, value) {
    state.showMissing = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
