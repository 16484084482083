<template>
  <div class="not-found">
    <h1>404 страница</h1>
    <h2>Страница не найдена</h2>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
  .not-found{
    padding: 50px;
    background: #ccc;
    position: fixed; top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
</style>
