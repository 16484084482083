<template>
  <h1>
    <span>Первый шаг создания модели</span>
  </h1>

  <transition name="fade">
    <div v-if="formText">
      {{formText}}
    </div>
    <Form v-else-if="form.act" :title="form.title"
      :btn="form.btn"
      class="center-center"
      :fields="form.fields"
      :action="form.act"
      :method="form.method"
      @answerForm="formAnswer" />
  </transition>
</template>

<script>
import axios from 'axios';
import Form from '@/components/atoms/Form.vue';

export default {
  name: 'ScmAddStepOne',
  components: {
    Form,
  },
  data() {
    return {
      action: '/scm/modeling/add-step-one',
      form: {
        title: '',
        btn: '',
        fields: [],
        act: '',
        method: 'post',
      },
      formText: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get(this.action)
        .then((res) => {
          if (res.data.success === 1) {
            this.form = res.data.form;
          } else {
            this.formText = res.data.answer;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    formAnswer(res) {
      this.$router.push('/scm/modeling');
      console.log(res);
    },
  },
};
</script>
<style scoped>

</style>
