<template>
  <table v-if="items.items.length" class="list-table">
    <tr>
      <th v-for="(lbl, ind) in items.heads" :key="ind">
        {{lbl}}
      </th>
    </tr>
    <template v-for="(user, ind) in items.items" :key="ind">
      <tr class="first" :class="{odd: ind % 2 === 0}" @click="collapse(ind)" >
        <td v-for="(field, ind2) in user.fields" :key="ind2">
          <template v-if="field.type === 'H' && user.collapse">
            <i v-if="user.show" class="fa fa-chevron-down" aria-hidden="true"></i>
            <i v-else class="fa fa-chevron-right" aria-hidden="true"></i>
          </template>
          <template v-if="field.type === 'S'">
            <span class="type-s">{{field.val}}
              <span v-if="field.lbl">({{field.lbl}})</span>
            </span>
          </template>
          <template v-else-if="field.type === 'L'">
            <div class="list-list-item" v-for="(elem, ind3) in field.val" :key="ind3">
              <div class="unlink" title="Отвязать роль"
                @click="linkDel(user.fields[0].val, elem.val, ind, ind2, ind3)"
                @keydown.f7="linkDel(user.fields[0].val, elem.val, ind, ind2, ind3)">
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
              {{elem.lbl}}
            </div>
            <a href="javascript:void(0)" class="add-elem"
              @click="showList(field.addsel.type, user.fields[0].val, ind, ind2)">
              <i class="fa fa-plus" aria-hidden="true"></i>
              <span v-if="field.addsel" style="padding-left:4px;">{{field.addsel.title}}</span>
              <span v-else style="padding-left:4px;">Добавить</span>
            </a>
          </template>
        </td>
        <td v-if="user.act"  class="cell-act">
          <div class="acts__item" v-for="(action, ind4) in user.act" :key="ind4">
              <template v-if="action.type == 'url' || action.type == 'url2'">
                <div :title="action.title"
                  @click.prevent.stop="actTable(action.act, ind, ind4, action.type)"
                  @keydown.f7.stop="actTable(action.act, ind, ind4, action.type)" >
                  <i class="fa" :class="action.icon" aria-hidden="true"></i>
                </div>
              </template>
              <template v-if="action.type == 'chk'">
                <div :title="action.items[action.val].title"
                  @click.prevent.stop="actTable(action.items[action.val].act,
                    ind, ind4, action.type)"
                  @keydown.f7.stop="actTable(action.items[action.val].act,
                    ind, ind4, action.type)" >
                  <i class="fa" :class="action.items[action.val].icon" aria-hidden="true"></i>
                </div>
              </template>
          </div>
        </td>
      </tr>
      <tr class="second" :class="{odd: ind % 2 === 0}" v-if="user.collapse && user.show">
        <td colspan="4">
          <div v-for="(prop, indp) in user.collapse" :key="indp" class="row-field">
            <div class="f1">{{prop.name}}</div>
            <div class="f2">
              <InputText v-if="prop.type == 0"
                v-model="prop.val"
                def=""
                req="1"
                @update:modelValue="changeField(ind, prop.pval, prop.val)"
                />
              <Checkbox v-if="prop.type == 1"
                v-model="prop.val"
                def=""
                @update:modelValue="changeField(ind, prop.pval, prop.val)"
                />
            </div>
          </div>
        </td>
      </tr>
    </template>
  </table>
  <div v-else class="text-warn-1">
    Список пуст
  </div>

  <div v-if="showContextForm" class="context-menu modal-form"
    @click="closeModal()"
    @keydown.f7="closeModal()">
    <div class="context-menu--body"  @click.stop >
      <div v-if="answerForm.length" class="form-answer">{{answerForm}}</div>
      <Form v-else :title="form.title"
        :btn="form.btn"
        class="center-center big"
        :fields="form.fields"
        :action="form.act"
        :method="form.method"
        @answerForm="formAnswer" />
    </div>
  </div>

  <div v-if="instrumental.length" class="instrumental">
    <a v-for="(instrument, ind) in instrumental"
      :key="ind" class="btn btn-add"
      @click="act(instrument.act)"
      @keydown.f7="act(instrument.act)">
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import Form from '@/components/atoms/Form.vue';
import InputText from '@/components/atoms/InputText.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';

export default {
  name: 'ListManage',
  components: {
    Form, InputText, Checkbox,
  },
  props: ['action'],
  data() {
    return {
      showContextForm: false,
      answerForm: '',
      form: {
        title: '',
        btn: '',
        fields: [],
        action: '',
        method: 'post',
      },
      actionType: '',
      actionElem: 0,

      items: {
        items: [],
      },
      test: [],
      instrumental: [],
      errors: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios.get(this.action)
        .then((res) => {
          if (res.data.success === 1) {
            this.items = res.data.list;
            this.instrumental = res.data.instrumental;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    act(action) {
      this.actionType = 'add';
      axios.get(action)
        .then((res) => {
          if (res.data.success === 1) {
            this.showContextForm = true;
            this.form = res.data.form;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    actTable(action, ind, ind2, type) {
      if (type === 'chk') {
        const status = this.items.items[ind].act[ind2].val;
        if (status === 1) {
          this.items.items[ind].act[ind2].val = 0;
        } else {
          this.items.items[ind].act[ind2].val = 1;
        }
        axios.post(action, { val: this.items.items[ind].fields[0].val })
          .then((res) => {
            if (res.data.success === 1) {
              console.log(res.data.answer);
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else if (type === 'url') {
        this.actionType = 'chg';
        this.actionElem = ind;
        axios.get(action, {
          params: {
            val: this.items.items[ind].fields[0].val,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.showContextForm = true;
              this.form = res.data.form;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else if (type === 'url2') {
        axios.post(action, { val: this.items.items[ind].fields[0].val })
          .then((res) => {
            if (res.data.success === 1) {
              this.items.items.splice(ind, 1);
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    formAnswer(data) {
      this.answerForm = data.answer;
      if (this.actionType === 'add') {
        this.items.items.push(data.elem);
      } else if (this.actionType === 'chg') {
        if (data.elem.fields) {
          this.items.items.splice(this.actionElem, 1, data.elem);
        }
      }
    },
    closeModal() {
      this.showContextForm = false;
      this.answerForm = '';
    },
    collapse(ind) {
      if (this.items.items[ind].show) {
        this.items.items[ind].show = false;
      } else {
        this.items.items[ind].show = true;
      }
    },
    changeField(ind, pval, val) {
      console.log(val);
      axios.post('/god/politika/change-prop', {
        p: this.items.items[ind].fields[0].val,
        p2: pval,
        val: val ? 1 : 0,
      })
        .then((res) => {
          if (res.data.success === 1) {
            console.log(`${res.data.answer}`);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.row-field{margin-bottom: 12px;}
.row-field .f1, .row-field .f2{display:inline-block}
.row-field .f1{width:69%; }
.row-field .f2{width:29%; }
.row-field:hover{background:#ddd}
.second td{
  border: 1px solid #ddd;
  border-top: none;
}

.fa-times{color: #ce5959}
.fa-times:hover{color: #ef3737}

tr.first{cursor: pointer;}
tr.first:hover td{opacity: 0.9}
.instrumental{position: fixed;
  left:0px;
  bottom:0px;
  background: #aaa;
  width: 100%;
}

.btn.btn-add{
  background: #5981ce;
  color: #fff;
  padding: 6px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.btn.btn-add:hover{
  background: #3b70d7;
  padding: 8px;
}
.btn.btn-add span, .btn.btn-add i{
  vertical-align: middle;
}

.list-table td.cell-act{
  padding: 0px;
}
.acts__item{
  padding:0px
}
.acts__item > div{
  font-size: 22px;
}
.list-table{
  width:100%;
  text-align: left;
}

.list-table tr.odd{
  background: #e5f0ff;
}

.list-table th{
  background: #5981ce /* #5390ef */;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  line-height: 18px;
}
.list-table td{
  vertical-align:top;
  padding: 8px;
}
.list-list-item{
  padding: 4px;
  background: #ccc;
  margin-bottom: 1px;
  border-radius:2px;
  font-size:12px;
}
.list-list-item .unlink{float:right; padding-left:4px;}

.add-elem{text-align:center; width:100%; display:block;
  background: #bbb;
  font-size:12px;
  line-height:14px;
  padding:4px 0;
}

.type-s{display:block; padding:2px 4px; font-size:16px;}
.acts__item{display:inline-block; padding:4px;}

.fa.green{color:#3fbd3a}
.fa.red{color:#f06565;}

.modal-form{
  text-align: center;
}
</style>
