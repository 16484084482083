<template>
  <div>
    <h1><span>Настройки</span></h1>
    <div class="list">
      <div class="list--elem" v-for="(el, ind) in list" :key="ind">
        <router-link :to="el.href"
          :title="el.title"
          class="list--link" >
          <i class="fa" :class="el.icon" aria-hidden="true"></i>
          {{el.lbl}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScmSettings',
  data() {
    return {
      list: [
        {
          icon: 'fa-list-alt',
          lbl: 'Склады',
          title: 'Склады',
          href: '/scm/setting/stock',
        }, {
          icon: 'fa-list-alt',
          lbl: 'Поставщики',
          title: 'Поставщики',
          href: '/scm/setting/supp',
        }, {
          icon: 'fa-list-alt',
          lbl: 'Списки',
          title: 'Списки',
          href: '/scm/setting/list',
        }, {
          icon: 'fa-list-alt',
          lbl: 'Товары и категории',
          title: 'Товары и категории',
          href: '/scm/setting/prod',
        }, {
          icon: 'fa-list-alt',
          lbl: 'Привязка категорий к складам',
          title: 'Привязка категорий к складам',
          href: '/scm/setting/stock-link',
        }, {
          icon: 'fa-list-alt',
          lbl: 'Автозаказы',
          title: 'Автозаказы',
          href: '/scm/order/autoorder-iface',
        },
      ],
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.list{}
.list--elem{
  display: inline-block;
  width: 50%;
  padding: 0 6px;
  text-align:center;
  margin-bottom: 12px;
  box-sizing: border-box;
}
.list--link{
  background: #ddd;
  color: #333;
  display: block;
  padding: 12px;
  transition: background .5s, color .5s;
}
.list--link:hover{
  background: #5981ce;
  color: #fff;
}
</style>
