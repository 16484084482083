<template>
  <div class="confirm-middle">
    <div class="confirm-info-text">{{txt}}</div>
    <div v-if="btns && showBtns" class="confirm-info-btns">
      <div v-for="(el, ind) in btns" :key="ind" class="wrp-confirm-btn">
        <template v-if="el.tp == 'action'">
          <div v-if="run == false"
            @click="action(el.action, el.param)"
            @keydown.f7="action(el.action, el.param)"
            class="confirm-ok">{{el.txt}}</div>
          <LoaderTreeSpin v-else-if="run == true" class="small" />
        </template>
        <template v-else-if="el.tp == 'close'">
          <div class="confirm-cancel"
            @click="closeModal"
            @keydown.f7="closeModal">{{el.txt}}</div>
        </template>
        <template v-else></template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'ConfirmCrossDef',
  props: ['inputtxt', 'btns', 'closesec'],
  components: {
    LoaderTreeSpin,
  },
  data() {
    return {
      run: false,
      txt: '',
      showBtns: true,
    };
  },
  mounted() {
    this.txt = this.inputtxt;
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    action(url, param) {
      this.run = true;

      axios.post(url, param)
        .then((res) => {
          if (res.data.success === 1) {
            this.txt = res.data.answer;

            this.$emit('ok', res.data);
            setTimeout(() => this.$emit('close'), this.closesec);
          } else if (res.data.success === 0) {
            this.txt = res.data.answer;
          } else {
            this.txt = 'Ошибка отправки запроса';
          }
          this.run = false;
          this.showBtns = false;
        });
    },
  },
};
</script>

<style scoped>
.confirm-middle{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px;
}
.confirm-info-text{
  font-size: 20px;
  line-height: 24px;
  border: 1px solid #dfc4c4;
  padding: 20px;
  margin-bottom: 15px;
  background-color: #fcdfdf;
  color: #292525;
}
.wrp-confirm-btn{
  display: inline-block;
  vertical-align:top;
  margin-right: 12px;
}
.confirm-ok{
  cursor: pointer;
  background-color: #cff7db;
  border: 1px solid #b1d4bb;
  padding: 10px 20px;
  transition: background-color 0.7s;
}
.confirm-ok:hover{
  background-color: #b5e6c3
}
.confirm-cancel{
  cursor: pointer;
  background: #dedede;
  border: 1px solid #b7b7b7;
  padding: 10px 20px;
  transition: background-color 0.7s;
}
.confirm-cancel:hover{
  background-color: #c5c3c3;
}
</style>
