<template>
  <div class="title" v-if="title">
    {{title}}
  </div>
  <table class="table">
    <thead>
      <tr>
        <th v-for="(el, ind) in heads" :key="ind">{{el}}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, ind) in els" :key="ind">
        <td v-for="(el, ind2) in row" :key="ind2">{{el}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'TableInfoHead',
  props: ['title', 'heads', 'els'],
  data() {
    return {
      list: [],

      errors: [],
      spiner: false,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.title{
  font-weight: bold;
  padding: 10px;
  min-width: 320px;
  color: #fff;
  background: #6b7bb9;
}

.table{width: 100%;
  border-collapse: collapse;
}
.table > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}
.table > thead > tr > th, .table > tbody > tr > th,
.table > tfoot > tr > th, .table > thead > tr > td,
.table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table > thead > tr > th{
  background: #ebebeb;
}
.table tr > th{
  text-align: left;
  font-size: 12px;
  line-height: 14px;
}
.wrp-middle{
  margin-left: 10px;
  margin-right: 10px;
}

.table > tbody > tr:hover td, .table > tbody > tr:hover th{
  background: #eee;
}

td.red{
  background: #ffd2d2;
  border-bottom: 1px solid white;
}
td.green{
  background: #bbf1be;
  border-bottom: 1px solid white;
}

.table > tbody > tr:hover td.red{
  background: #fec4c4;
}
.table > tbody > tr:hover td.green{
  background: #aaefae;
}
</style>
