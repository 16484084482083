<template>
  <div class="wrp-checkmate" :class="{ready: loader == false}">
    <div v-if="loader" class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-else>
      <div class="above-filter">
        <div class="two-word">
          <div class="bg-word">#{{info.number}}</div>
        </div>
        <div class="two-word">
          <div class="bg-word">Склад:</div>
          <div class="sm-word">{{info.stock_name}}</div>
        </div>
        <div class="two-word">
          <div class="bg-word">Поставщик:</div>
          <div class="sm-word">{{info.supplier_name}}</div>
        </div>
        <div class="two-word">
          <div class="bg-word">{{info.date}}</div>
        </div>
      </div>
      <div class="common-info">
        SKU: {{info.sku_all}}<br />
        Штук: {{info.cnt_all}}<br />
      </div>
      <Filter v-if="filter"
        :options = "filter"
        stick = ""
        @change-field="showGraph" />
      <div class="filter-info">
        <div class="two-word">
          <div class="bg-word">SKU:</div>
          <div class="sm-word">{{info.sku_cat}}</div>
        </div>
        <div class="two-word">
          <div class="bg-word">Штук:</div>
          <div class="sm-word">{{info.cnt_cat}}</div>
        </div>
      </div>
      <table class="setka-sizes">
        <thead>
          <tr>
            <th class="block-head">Наименование</th>
            <th v-for="(el, ind) in headSetkaProducts" :key="ind"
              class="block-head">{{el}}</th>
            <th class="block-head" style="background-color: #cecece">∑</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(el, ind) in listSetkaProducts" :key="ind">
            <td class="block-head-line"
              :class="{new: el.new = 1}">
              <i class="name-stick" v-if="el.new == 1">НОВЫЙ</i>
              {{el.name}}
            </td>
            <td v-for="(head, ind2) in headSetkaProducts" :key="ind2"
              :class="{'block-empty': !el.sizes[head]}">
              <template v-if="el.sizes[head] && el.sizes[head].buff">
                <div
                  class="block-cell no-empty"
                  :class="el.sizes[head].buff.opt.color"
                  @click="showArticleBtn(el.sizes[head])"
                  @keydown.f7="setkaDetail(el.sizes[head].id)"
                  >
                  {{el.sizes[head].buff.value}}%
                </div>
              </template>
              <template v-if="el.sizes[head] && typeof el.sizes[head].cnt !== 'undefined'">
                <div style="min-height: 42px;">
                  <InputTextShort
                    v-model="el.sizes[head].cnt"
                    @change="chgOrderEl(el.sizes[head], ind, head)"
                    class="short-input-filter"
                    :class="{'inp-blue': el.sizes[head].cnt == el.sizes[head].orig,
                      'inp-green': el.sizes[head].cnt !== el.sizes[head].orig
                    }"/>
                </div>
              </template>
            </td>
            <td :class="{'cell-blue': el.summ == el.summ_orig,
                    'cell-green': el.summ !== el.summ_orig
                  }">
              <template v-if="typeof el.summ !== 'undefined'">
                <div class="block-head-summ">
                  {{el.summ}}
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import InputTextShort from '@/components/atoms/InputTextShort.vue';

export default {
  name: 'WBCheckMate',
  components: {
    Filter, InputTextShort,
  },
  props: ['action', 'actchange'],
  emits: ['loadcheck'],
  data() {
    return {
      headSetkaProducts: [],
      listSetkaProducts: [],
      filter: [],
      info: {},
      chgproduct: this.actchange,

      loader: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loader = true;
      axios.post(this.action, { filter: this.filter })
        .then((res) => {
          if (res.data.success === 1) {
            this.filter = res.data.filter;
            this.headSetkaProducts = res.data.list.head;
            this.listSetkaProducts = res.data.list.list;
            this.info = res.data.info;
            this.chgproduct = res.data.actchange;
          }
          this.loader = false;
          this.$emit('loadcheck');
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showGraph() {
      setTimeout(() => {
        this.init();
      }, 50);
    },
    chgOrderEl(el, ind, head) {
      axios.post(this.chgproduct, el)
        .then((res) => {
          if (res.data.success === 1) {
            const range = parseInt(el.cnt, 10) - parseInt(el.cnt_old, 10);
            this.listSetkaProducts[ind].summ += range;

            this.info.cnt_all = parseInt(this.info.cnt_all, 10) + range;
            this.info.cnt_cat += range;

            this.listSetkaProducts[ind].sizes[head].cnt_old = el.cnt;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped >
.block-head-summ{
  padding-left: 4px;
}
.cell-blue{
  background-color: #e6ecf7;
}
.cell-green{
  background-color: #c9edd5;
}
.common-info{
  float: right;
  position: relative;
  z-index: 1;

  background: #5981ce;
  color: #fff;
  padding: 0px 7px 4px;
}
.filter-info{
  display: inline-block;
  background: #efefef;
  position: relative;
  top: -15px;
  padding-left: 10px;
  box-shadow: -1px -1px 1px inset #e9e9e9;
}
.above-filter{
  background: #5981ce;
  color: #fff;
  padding: 6px 8px;
}
.two-word{
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}
.bg-word{
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
}
.sm-word{
  display: inline-block;
  vertical-align: top;
}

table.setka-sizes{
  border-spacing: 0px;
  border-collapse: collapse;
  margin-bottom: 40px;
  transition: 1s;
  padding: 0px 12px;
}
table.setka-sizes tbody td{
  min-width: 50px;
  border-bottom: 1px solid #ccc;
}

.flag-line.new{ background: #e7e992; color: #136e23; }

.block-head{
  background: #e6e6e6;
  padding: 6px 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}
.block-head-line{
  font-size: 14px;
  font-weight: bold;
  padding-left: 6px;
  padding-right: 6px;
  padding-top:4px;
  line-height: 18px;
  position: relative;
  z-index: 1;
}
.block-head-line.new{
  background-color: #e7e992;
}
.name-stick{
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 8px;
  line-height: 10px;
  font-weight: bold;
  color: #136e23;
}

.block-head-line{
  position: sticky;
  left: 0;
  background-color: #ffffff;
}

.block-cell{
  padding: 8px 8px;
  text-align: center;
  font-size: 14px;
}
.block-cell.no-empty{
  cursor: pointer;
  transition: 0.5s opacity;
}
.block-cell.no-empty:hover{
  opacity: 0.8;
}
table.setka-sizes{
  border-spacing: 0px;
  border-collapse: collapse;
  margin-bottom: 40px;
}
table.setka-sizes tbody td{
  min-width: 45px;
  border-bottom: 1px solid #ccc;
}

.setka-sizes .progress-bar-success{ background: #a8e0a8;}
.setka-sizes .progress-bar-danger{ background: #fbb4b2;}
.setka-sizes .progress-bar-info{ background: #9cd8ff; }
.setka-sizes .progress-bar-warning { background: #ffdeb0;}
.setka-sizes .progress-bar-black { background: #b0b0b0; }

.block-empty{
  /*
  border: 22px solid #e4e4e4;
  border-bottom: 12px solid #dadada;
  border-left: 43px solid #dadada;
  width: 0px;
  height: 0px;
  padding: 0px;
  */
  background: url("~@/assets/triangle_empty.jpg") no-repeat 0 0;
  background-size: 100% 100%;
}

.short-input{
  width: 50px;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #cfcfcf; padding: 3px}
</style>
