<template>
  <div>
    <canvas @mousemove="placeCoordinates" id="canvas" height='500' width='1000'
      @click="interceptorClick"
      @keydown.f7="interceptorClick"></canvas>
    <div v-if="0" class="panel-bottom">
      Точек на графике:  {{cntPoints}}
      <div class="block-info">Позиция: {{xMouse}}, {{yMouse}}</div>
      <div class="block-info">Товаров: {{cntProducts}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WBChart',
  props: {
    dataGraph: {
      type: Array,
    },
    options: {
      type: Object,
    },
    unit: {
      type: String,
      default: '',
    },
  },
  emits: ['chg-options'],
  data() {
    return {
      canvas: null,
      xMouse: 0,
      yMouse: 0,
      wCanvas: 0,
      hCanvas: 0,
      legendAreas: [],

      paddingTop: 65,
      paddingBottom: 100,
      paddingLeft: 70,
      paddingRight: 50,
      horizontalLine: 5,

      maxVal: 1,
      maxValRound: 1,
      cntGraph: 0,

      cntPoints: 0,
      legend: {},

      errors: [],
    };
  },
  computed: {
    cntProducts() {
      return this.dataGraph.length;
    },
    summRub() {
      return this.options.sumAll;
    },
    heightGraph() {
      return this.hCanvas - this.paddingTop - this.paddingBottom;
    },
    widthGraph() {
      return this.wCanvas - this.paddingLeft - this.paddingRight;
    },
    lenLegendAreas() {
      return this.legendAreas.length;
    },
  },
  mounted() {
    const c = document.getElementById('canvas');
    this.canvas = c.getContext('2d');

    c.width = window.innerWidth - 12 * 2;

    window.addEventListener('resize', () => {
      c.width = window.innerWidth - 12 * 2;
      this.wCanvas = c.width;

      this.drawStuff();
    }, false);

    this.wCanvas = c.width;
    this.hCanvas = c.height;
  },
  watch: {
    dataGraph() {
      if (this.dataGraph[0]) {
        this.cntPoints = this.dataGraph[0].length;
        this.legend = this.options.legend;
        this.cntGraph = this.legend.length;
        this.drawStuff();
      }
    },
  },
  methods: {
    drawStuff() {
      const ctx = this.canvas;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, this.wCanvas, this.hCanvas);

      let len2 = this.cntGraph;
      let max = 1;
      while (len2 > 0) {
        len2 -= 1;
        if (max < this.legend[len2].max
          && this.legend[len2].show) {
          max = this.legend[len2].max;
        }
      }

      this.maxValRound = this.roundRazrad(max);

      this.drawSetka();

      let len = this.legend.length;

      while (len > 0) {
        len -= 1;
        if (this.legend[len].show) {
          this.drawLine(len);
        }
      }

      this.drawX();
      this.drawLegend();
    },
    drawLegend() {
      const ctx = this.canvas;
      let len = this.cntGraph;
      let xPos = this.paddingLeft;
      let yPos = 0;
      const wColor = 30;
      const hColor = 10;
      ctx.textAlign = 'left';
      ctx.font = 'normal normal 14px Arial';

      const maxWidth = this.wCanvas + this.paddingLeft;

      this.legendAreas = [];

      while (len > 0) {
        len -= 1;

        if (xPos > maxWidth) {
          xPos = this.paddingLeft;
          yPos += wColor;
        }

        const startPos = xPos;
        if (this.legend[len].show) {
          ctx.fillStyle = this.legend[len].color;
        } else {
          ctx.fillStyle = '#ccc';
        }
        ctx.fillRect(xPos, yPos, wColor, hColor);
        xPos += wColor + 5;

        ctx.fillStyle = '#000';
        ctx.fillText(this.legend[len].name, xPos, yPos + hColor);
        xPos += this.legend[len].name.length * 9;

        this.legendAreas.push([
          startPos,
          yPos,
          xPos - startPos,
          hColor,
          len,
        ]);
      }

      ctx.fillStyle = '#fff';
    },
    drawX() {
      const ctx = this.canvas;
      const startH = this.heightGraph + this.paddingTop + 20;

      const cnt = this.cntPoints;
      const offset = this.widthGraph / (cnt - 1);
      let iter = 1;
      if (this.widthGraph / cnt < 18) {
        iter = 2;
      }
      if (this.widthGraph / cnt < 10) {
        iter = 3;
      }
      if (this.widthGraph / cnt < 5) {
        iter = 4;
      }
      for (let i = 0; i < cnt; i += iter) {
        const x = i * offset + this.paddingLeft;

        ctx.save();
        ctx.translate(x, startH);
        ctx.rotate(this.inRad(-34));
        ctx.fillText(this.dataGraph[0][i][0], 0, 0);
        ctx.restore();
      }
    },
    drawLine(len) {
      const ctx = this.canvas;
      ctx.lineWidth = 2;

      const graph = this.dataGraph[len];
      const options = this.legend[len];

      const cnt = graph.length;
      const offset = this.widthGraph / (cnt - 1);
      const offsetTop = this.heightGraph + this.paddingTop;

      const koef = this.heightGraph / this.maxValRound;

      ctx.strokeStyle = options.color;
      ctx.beginPath();
      ctx.moveTo(this.paddingLeft, offsetTop - graph[0][1] * koef);
      const arrDrawHints = [];
      for (let i = 1; i < cnt; i += 1) {
        const x = i * offset + this.paddingLeft;
        const y = offsetTop - graph[i][1] * koef;
        ctx.lineTo(x, y);

        if (graph[i].length === 5 || graph[i].length === 6) {
          if (graph[i][3] === 1) {
            arrDrawHints.push({ human: 1, x, y });
            // ctx.fillText('Ч', x + 5, y - 5);
          } else {
            arrDrawHints.push({ human: 0, x, y });
            // ctx.fillText('А', x + 5, y - 5);
          }
        }
      }

      ctx.stroke();
      if (options.type === 'area') {
        const rightGran = this.widthGraph + this.paddingLeft;
        const botGran = this.heightGraph + this.paddingTop;
        const leftGran = this.paddingLeft;

        ctx.lineTo(rightGran, botGran);
        ctx.lineTo(leftGran, botGran);
        ctx.lineTo(leftGran, offsetTop - graph[0][1] * koef);
        ctx.fillStyle = `${options.color}cc`;
        ctx.fill();
      }
      ctx.closePath();

      ctx.lineWidth = 0.5;
      for (let i = 0; i < arrDrawHints.length; i += 1) {
        ctx.clearRect(arrDrawHints[i].x - 8, arrDrawHints[i].y - 20, 15, 18);
        ctx.strokeRect(arrDrawHints[i].x - 8, arrDrawHints[i].y - 20, 15, 18);
        if (arrDrawHints[i].human === 1) {
          ctx.fillText('Ч', arrDrawHints[i].x + 5, arrDrawHints[i].y - 5);
        } else {
          ctx.fillText('А', arrDrawHints[i].x + 5, arrDrawHints[i].y - 5);
        }
      }
      ctx.lineWidth = 2;

      ctx.fillStyle = '#000';
      /*
      for (let i = 1; i < cnt; i += 1) {
        const x = i * offset + this.paddingLeft;
        const y = offsetTop - graph[i][1] * koef;
        this.drawRound(x, y, options.color);
      }
      */
    },
    inRad(num) {
      return (num * Math.PI) / 180;
    },
    drawRound(x, y, color) {
      const ctx = this.canvas;
      ctx.beginPath();
      ctx.arc(x, y, 4, 0, 2 * Math.PI, false);
      ctx.fillStyle = color;
      ctx.fill();
      ctx.lineWidth = 1;
      ctx.strokeStyle = color;
      ctx.stroke();
    },

    drawSetka() {
      const ctx = this.canvas;

      const vInterval = this.heightGraph / this.horizontalLine;
      const vIntervalReal = this.maxValRound / this.horizontalLine;

      ctx.font = 'normal normal 16px Arial';
      ctx.textAlign = 'right';
      ctx.fillStyle = '#000';

      for (let i = this.horizontalLine; i >= 0; i -= 1) {
        const xPos = vInterval * i + this.paddingTop;

        if (this.horizontalLine === i) {
          this.drawSegment(
            this.paddingLeft,
            xPos,
            this.wCanvas - this.paddingRight,
            xPos,
            '#333',
          );
        } else {
          this.drawSegment(
            this.paddingLeft,
            xPos,
            this.wCanvas - this.paddingRight,
            xPos,
            '#ccc',
          );

          this.drawSegment(
            this.paddingLeft,
            xPos + vInterval / 2,
            this.wCanvas - this.paddingRight,
            xPos + vInterval / 2,
            '#ebebeb',
          );
        }

        let txt = this.maxValRound - vIntervalReal * i;
        if (String(txt).length >= 6) {
          txt = Math.round(txt * 10) / 10;
        }
        if (String(txt).length > 7) {
          txt = this.formatExp(txt);
        }
        if (String(txt).length >= 6) {
          ctx.font = 'normal normal 12px Arial';
        }

        ctx.fillText(txt, 50, vInterval * i + 6 + this.paddingTop);
      }
    },
    formatExp(txt) {
      let p = 10;
      let cntRazrad = 19;
      for (let i = 1; i < 19; i += 1) {
        if (txt < p) {
          cntRazrad = i;
          break;
        }
        p *= 10;
      }

      return `${txt / (p / 10)}e${cntRazrad - 1}`;
    },
    drawSegment(x1, y1, x2, y2, color) {
      const ctx = this.canvas;

      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.closePath();
      ctx.stroke();
    },
    placeCoordinates(e) {
      this.xMouse = e.offsetX;
      this.yMouse = e.offsetY;

      const ctx = this.canvas;

      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, this.wCanvas, this.hCanvas);

      this.drawStuff();

      const len = this.dataGraph.length;
      let flagExit = 0;
      for (let i = 0; i < len; i += 1) {
        if (this.legend[i] && this.legend[i].show) {
          let len2 = this.dataGraph[i].length;
          const hOffset = this.widthGraph / (len2 - 1);

          while (len2 > 0) {
            len2 -= 1;
            const offsetTop = this.heightGraph + this.paddingTop;
            const koef = this.heightGraph / this.maxValRound;

            const x = len2 * hOffset + this.paddingLeft;
            const y = offsetTop - this.dataGraph[i][len2][1] * koef;

            const xx = x - this.xMouse;
            const yy = y - this.yMouse;

            if (Math.sqrt(xx * xx + yy * yy) < 8) {
              this.drawRound(x, y, '#000');

              this.drawInfo(x, y, this.dataGraph[i][len2], this.legend[i].name);

              flagExit = 1;
              break;
            }
          }
          if (flagExit) {
            break;
          }
        }
      }
    },
    drawInfo(x, y, data, lbl) {
      const ctx = this.canvas;
      let cntLine = 2;

      if (lbl.length > 15) {
        cntLine = 3;
      }

      ctx.fillStyle = '#fff';
      ctx.strokeStyle = '#aaa';
      ctx.beginPath();

      if (data.length === 6) {
        let offsetX = x - 60;
        if (this.wCanvas - x > 100) {
          ctx.moveTo(x + 4, y - 3);
          ctx.lineTo(x + 15, y - 20);
          ctx.lineTo(x - 70, y - 20);
          ctx.lineTo(x - 70, y - 130);
          ctx.lineTo(x + 150, y - 130);
          ctx.lineTo(x + 150, y - 20);
          ctx.lineTo(x + 30, y - 20);
          ctx.lineTo(x + 4, y - 3);
        } else {
          ctx.moveTo(x, y - 5);
          ctx.lineTo(x - 15, y - 20);
          ctx.lineTo(x + 50, y - 20);
          ctx.lineTo(x + 50, y - 130);
          ctx.lineTo(x - 120, y - 130);
          ctx.lineTo(x - 120, y - 20);
          ctx.lineTo(x - 30, y - 20);
          ctx.lineTo(x, y - 5);
          offsetX = x - 110;
        }

        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = '#333';
        ctx.textAlign = 'left';
        ctx.font = 'bold normal 16px Arial';
        ctx.fillText(data[0], offsetX, y - 110);
        ctx.font = 'normal normal 16px Arial';
        ctx.fillText(`${lbl}:`, offsetX, y - 90);

        let textVal = data[2] ? data[2] : data[1];
        textVal = textVal.toString();

        const arrText = textVal.split('.');
        if (arrText.length === 2) {
          const firstText = arrText[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
          textVal = `${firstText}.${arrText[1]}`;
        } else {
          textVal = textVal.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
        }

        ctx.fillText(`${textVal} ${this.unit.toLowerCase()}`, offsetX, y - 70);

        ctx.fillText(`${data[4]}`, offsetX, y - 50);
        ctx.fillText(`${data[5]}`, offsetX, y - 30);
      } else if (data.length === 5) {
        let offsetX = x - 60;
        if (this.wCanvas - x > 100) {
          ctx.moveTo(x + 4, y - 3);
          ctx.lineTo(x + 15, y - 20);
          ctx.lineTo(x - 70, y - 20);
          ctx.lineTo(x - 70, y - 110);
          ctx.lineTo(x + 150, y - 110);
          ctx.lineTo(x + 150, y - 20);
          ctx.lineTo(x + 30, y - 20);
          ctx.lineTo(x + 4, y - 3);
        } else {
          ctx.moveTo(x, y - 5);
          ctx.lineTo(x - 15, y - 20);
          ctx.lineTo(x + 50, y - 20);
          ctx.lineTo(x + 50, y - 110);
          ctx.lineTo(x - 120, y - 110);
          ctx.lineTo(x - 120, y - 20);
          ctx.lineTo(x - 30, y - 20);
          ctx.lineTo(x, y - 5);
          offsetX = x - 110;
        }

        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = '#333';
        ctx.textAlign = 'left';
        ctx.font = 'bold normal 16px Arial';
        ctx.fillText(data[0], offsetX, y - 90);
        ctx.font = 'normal normal 16px Arial';
        ctx.fillText(`${lbl}:`, offsetX, y - 70);

        let textVal = data[2] ? data[2] : data[1];
        textVal = textVal.toString();

        const arrText = textVal.split('.');
        if (arrText.length === 2) {
          const firstText = arrText[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
          textVal = `${firstText}.${arrText[1]}`;
        } else {
          textVal = textVal.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
        }

        ctx.fillText(`${textVal} ${this.unit.toLowerCase()}`, offsetX, y - 50);

        ctx.fillText(`${data[4]}`, offsetX, y - 30);
      } else if (cntLine === 3) {
        let offsetX = x - 60;
        if (this.wCanvas - x > 100) {
          ctx.moveTo(x + 4, y - 3);
          ctx.lineTo(x + 15, y - 20);
          ctx.lineTo(x - 70, y - 20);
          ctx.lineTo(x - 70, y - 90);
          ctx.lineTo(x + 150, y - 90);
          ctx.lineTo(x + 150, y - 20);
          ctx.lineTo(x + 30, y - 20);
          ctx.lineTo(x + 4, y - 3);
        } else {
          ctx.moveTo(x, y - 5);
          ctx.lineTo(x - 15, y - 20);
          ctx.lineTo(x + 50, y - 20);
          ctx.lineTo(x + 50, y - 90);
          ctx.lineTo(x - 120, y - 90);
          ctx.lineTo(x - 120, y - 20);
          ctx.lineTo(x - 30, y - 20);
          ctx.lineTo(x, y - 5);
          offsetX = x - 110;
        }

        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = '#333';
        ctx.textAlign = 'left';
        ctx.font = 'bold normal 16px Arial';
        ctx.fillText(data[0], offsetX, y - 70);
        ctx.font = 'normal normal 16px Arial';
        ctx.fillText(`${lbl}:`, offsetX, y - 50);

        let textVal = data[2] ? data[2] : data[1];
        textVal = textVal.toString();

        const arrText = textVal.split('.');
        if (arrText.length === 2) {
          const firstText = arrText[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
          textVal = `${firstText}.${arrText[1]}`;
        } else {
          textVal = textVal.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
        }

        ctx.fillText(`${textVal} ${this.unit.toLowerCase()}`, offsetX, y - 30);
      } else {
        let offsetX = x - 60;
        if (this.wCanvas - x > 100) {
          ctx.moveTo(x + 4, y - 3);
          ctx.lineTo(x + 15, y - 20);
          ctx.lineTo(x - 70, y - 20);
          ctx.lineTo(x - 70, y - 70);
          ctx.lineTo(x + 150, y - 70);
          ctx.lineTo(x + 150, y - 20);
          ctx.lineTo(x + 30, y - 20);
          ctx.lineTo(x + 4, y - 3);
        } else {
          ctx.moveTo(x, y - 5);
          ctx.lineTo(x - 15, y - 20);
          ctx.lineTo(x + 40, y - 20);
          ctx.lineTo(x + 40, y - 70);
          ctx.lineTo(x - 120, y - 70);
          ctx.lineTo(x - 120, y - 20);
          ctx.lineTo(x - 30, y - 20);
          ctx.lineTo(x, y - 5);
          offsetX = x - 110;
        }

        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = '#333';
        ctx.textAlign = 'left';
        ctx.font = 'bold normal 16px Arial';
        ctx.fillText(data[0], offsetX, y - 50);
        ctx.font = 'normal normal 16px Arial';
        ctx.fillText(`${lbl}: ${data[2] ? data[2] : data[1]} ${this.unit.toLowerCase()}`, offsetX, y - 30);
      }
    },
    interceptorClick(e) {
      this.xMouse = e.offsetX;
      this.yMouse = e.offsetY;

      let len = this.lenLegendAreas;

      while (len > 0) {
        len -= 1;

        if (this.rectPointCollision([e.offsetX, e.offsetY], this.legendAreas[len])) {
          if (this.legend[this.legendAreas[len][4]].show) {
            this.$emit('chg-options', { ind: this.legendAreas[len][4], val: false });
          } else {
            this.$emit('chg-options', { ind: this.legendAreas[len][4], val: true });
          }
          this.drawStuff();
        }
      }
    },
    drawRect(r, color) {
      const ctx = this.canvas;

      ctx.fillStyle = color;
      ctx.fillRect(...r.slice(0, 4));
    },
    rectPointCollision(point, rect) {
      return (
        point[0] > rect[0]
        && point[0] < rect[0] + rect[2]
        && point[1] > rect[1]
        && point[1] < rect[1] + rect[3]
      );
    },
    drawText(r) {
      const ctx = this.canvas;

      // const w = ctx.measureText(r[4]).width;
      ctx.fillStyle = '#fff';
      const wInfo = 200;
      const hInfo = 150;
      ctx.fillRect(this.wCanvas - wInfo, 0, wInfo, hInfo);
      ctx.fillStyle = '#333';
      ctx.textAlign = 'left';
      ctx.font = 'bold normal 16px Arial';
      ctx.fillText(r[4], this.wCanvas - wInfo + 10, 35);
      ctx.font = 'normal normal 16px Arial';
      ctx.fillText(`${r[6]}: ${r[5]}`, this.wCanvas - wInfo + 10, 15);
    },
    roundRazrad(x) {
      let p = 10;
      let cntRazrad = 19;
      for (let i = 1; i < 19; i += 1) {
        if (x <= p) {
          cntRazrad = i;
          break;
        }
        p *= 10;
      }

      let decimalRange = 1;
      for (let i = 0; i < cntRazrad - 1; i += 1) {
        decimalRange *= 10;
      }
      return (parseInt(x / decimalRange, 10) + 1) * decimalRange;
    },
  },
};
</script>

<style scoped>
.panel-bottom {
  display:flex;
  position: fixed;
  bottom:0px;
  left:0px;
  width: 100%;
  background: #cacaca;
  padding-right:12px;
  text-align: right
}
.panel-bottom .block-info {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 5px;
}
.sum-rub {
  text-align: right;
  background: #82b5ef;
  display: inline-block;
  padding: 2px 12px;
  color: rgb(255, 255, 255);
  font-size: 23px;
  float: right;
  line-height:30px;
}
</style>
