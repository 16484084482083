<template>
  <div>
    <WBChart
      v-show="graphData.length"
      :dataGraph = "graphData"
      :options = "graphOptions" />
  </div>
</template>

<script>
import axios from 'axios';
import WBChart from '@/components/parts/WBChart.vue';

export default {
  name: 'WBGraphParam',
  components: {
    WBChart,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      graphData: [],
      graphOptions: [],
      info: {
        title: '',
      },
      info2: {
        title: '',
      },
      linkUrl: '',
      linkParam: '',
    };
  },
  mounted() {
    this.init();
  },
  computed: {
  },
  methods: {
    init() {
      axios.post(this.action)
        .then((res) => {
          this.graphData = res.data.graphData;
          this.graphOptions = res.data.graphOptions;

          this.info = res.data.info;
          this.info2 = res.data.info2;
          this.errorText = res.data.answer;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
  },
};
</script>

<style scoped>
h1{margin-right: 0px;}
.wrp-graph{
  padding-left: 10px; padding-right: 10px;
  background: white;
}
.head-graph{
  padding-bottom: 20px;
  background: white;
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}
.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 28px;
  background: white;
  z-index: 10;
  transition: width 1s;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.btn-active{
  color: #5981ce;
}
.tr-head span{
  margin-right: 15px;
}
.right{
  float: right;
}
.modal-form{
  text-align: center;
}
.wrp-table{
  margin-bottom: 40px;
}
.short-input{
  width:100px;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.icon-last-status{
  font-size: 22px;
  font-weight: bold;
  padding-right: 6px;
}

.btn-close{
  float: right;
  font-size: 20px;
  color: #780c0c;
  cursor: pointer;
}
.btn-close:hover {
  color: #bf1111;
}

.btn-close-big{
  float: right;
  font-size: 28px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
  padding-left: 3px;
  padding-right: 3px;
}
.btn-close-big:hover {
  color: #bf1111;
}

.err-text{
  margin-top: -18px;
  margin-bottom: 10px;
  color: #b34040;
  background: #ffdfdf;
  padding: 4px;
}

.block .column{
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  /*
  margin-right: 12px;
  margin-left: 12px;
  */
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: bottom;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.btn-a{color: #556b7d}
.btn-a:hover {color: #2f4a60; text-decoration: underline;}

.btn-cart{
  display: block;
  width: 100%;
  background: #44b752;
  border: 1px solid #2c9539;

  color:#fff;
  text-align: center
}
.btn-cart:hover{background: #2c9539}
.btn-cart.disabled{
  background: #a4e5ac;
  border-color: #ddd;
  position: relative;
}

.btn-cart.disabled:after{
  content: '';
  position: absolute;
  top: 5px;
  left: 40%;
  margin-left: -15px;
  width: 60px;
  height: 30px;
  border-top: 2px solid red;
  transform: rotate(-40deg);
}

tr.disabled {
  opacity: 0.5;
}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
    background-color: #5baade;
}

.progress-bar-black {
    background-color: #757575;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-bar-danger {
    background-color: #d9534f;
}

.progress-bar-success {
    background-color: #5cb85c;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}

.buffer-lbl{
  position: absolute;
  width: 100%;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}

.buffer-lbl{
  position: absolute;
  width: 100%;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
