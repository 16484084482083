<template>
  <div>
    <SuperSelect v-if="field.value"
      :field="field"
      @show-tasks="showStock"/>
  </div>

  <transition name="fade">
    <div v-if="!globalLoader" class="scm">
      <div class="left-column">
        <div class="list-page" v-if="!leftLoader">
          <div v-for="(el, ind) in tasks" :key="ind" class="list-elem"
            :class="{notdeal: el.status == 0, ready: el.status == 2, active: tabActive == ind}"
            >
            <a v-if="el.status == 1"
              class="btn-tab"
              @click="selectTab(ind)"
              href="javascript:void(0)" >
              {{el.name}}
            </a>
            <span v-else>
              {{el.name}}
            </span>
          </div>
        </div>
        <LoaderTreeSpin v-else
          fon="off" />
      </div>
      <div class="work-area" :class="{active: tabActive != -1}" >
        <template v-if="type == 'html'">
          <div class="content-html" v-html="content"></div>
        </template>
        <template v-if="type == 'list'">
          <table>
          <tr v-for="(el, ind) in content" :key="ind" :class="{odd: ind % 2 != 0}">
            <td class="td-txt"><a :href="el.url">{{el.name}}</a></td>
            <td :class="el.color"><div class="squad-color"></div>{{el.roi}} %</td>
            <td><div class="wb-btn-sh wb-btn-success wb-btn-sm"
                  @click="passTestTime(ind, $event)"
                  @keydown.f7="passTestTime(ind, $event)">Оставить</div></td>
            <td><div class="wb-btn-sh wb-btn-success wb-btn-sm"
                  @click="markedForOutput(ind, $event)"
                  @keydown.f7="markedForOutput(ind, $event)">Вывести</div></td>
          </tr>
          </table>
        </template>
        <template v-if="type == 'list2'">
          <table style="margin-top: 4px">
            <tr v-if="heads.length">
              <th style="text-align:left" v-for="(el, ind) in heads" :key="ind">{{el}}</th>
            </tr>
            <tr v-for="(el, ind) in content" :key="ind" :class="{odd: ind % 2 != 0}">
              <td v-for="(td, ind2) in el" :key="ind2" class="td-txt">
                <template v-if="td.action">
                  <div class="wb-btn-sh wb-btn-success wb-btn-sm"
                    @click="act(td.active)"
                    @keydown.f7="act(td.active)">{{td.title}}</div>
                </template>
                <template v-else>{{td}}</template>
              </td>
            </tr>
          </table>
        </template>
      </div>
    </div>
    <LoaderTreeSpin v-else />
  </transition>
</template>

<script>
import axios from 'axios';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';
import SuperSelect from '@/components/atoms/select/SuperSelect.vue';

export default {
  name: 'ManagerTask',
  components: {
    LoaderTreeSpin,
    SuperSelect,
  },
  data() {
    return {
      globalLoader: true,
      leftLoader: true,

      type: 'html',
      heads: [],
      content: '<div> << Задачи на сегодня, слева в списке.</div>',
      tabActive: -1,

      stocksTasks: [],
      tasks: [],

      field: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get('/scm/task/sett-iface-two/')
        .then((res) => {
          if (res.data.success === 1) {
            this.field = res.data.field;
            this.$nextTick(() => this.getStockTask(res.data.field.value.val));
          }
          this.globalLoader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.globalLoader = false;
        });
    },
    showStock(el) {
      if (this.stocksTasks[el.val]) {
        this.tasks = this.stocksTasks[el.val];
      } else {
        this.getStockTask(el.val);
      }
    },
    getStockTask(val) {
      this.leftLoader = true;
      axios.get('/scm/task/stock-tasks/', { params: { val } })
        .then((res) => {
          if (res.data.success === 1) {
            this.tasks = res.data.list;
            this.stocksTasks[val] = res.data.list;

            let i = this.field.options.length;
            while (i) {
              i -= 1;
              if (this.field.options[i].val === val) {
                this.field.options[i].cnt = res.data.cnt;
              }
            }
            this.field.value.cnt = res.data.cnt;

            this.type = 'html';
            this.content = '<div> << Задачи на сегодня, слева в списке.</div>';
            this.tabActive = -1;
          }
          this.leftLoader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.leftLoader = false;
        });
    },
    passTestTime(ind, event) {
      const data = {
        stock_id: this.content[ind].stock_id,
        prod_id: this.content[ind].prod_id,
      };

      axios.post('/scm/task/pass-test-time', data)
        .then((res) => {
          if (res.data.success === 1) {
            this.showAnswer(event, res.data.answer, 'ok');
            this.content.splice(ind, 1);
          } else {
            this.showAnswer(event, res.data.answer, 'error');
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    markedForOutput(ind, event) {
      const data = {
        prod_id: this.content[ind].prod_id,
        stock_id: this.content[ind].stock_id,
      };
      axios.post('/scm/task/marked-for-output', data)
        .then((res) => {
          if (res.data.success === 1) {
            this.showAnswer(event, res.data.answer, 'ok');
            this.content.splice(ind, 1);
          } else {
            this.showAnswer(event, res.data.answer, 'error');
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    selectTab(ind) {
      this.content = this.tasks[ind].content;
      if (this.tasks[ind].heads) {
        this.heads = this.tasks[ind].heads;
      }
      this.type = this.tasks[ind].type;
      this.tabActive = ind;
    },
    showAnswer(event, msg, type) {
      let x = event.pageX;
      const merge = window.screen.width - x;
      if (merge < 320) {
        x -= 320 - merge;
      }

      this.answer(x, event.pageY, msg, type);
    },
    act(active) {
      console.log(active);
      alert('Заглушка, до актуального времени пополнения');
    },
    answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
  },
};
</script>

<style scoped>
th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  padding: 6px 4px;
}
td{
  padding-left: 4px;
  padding-right: 4px;
}
.panel-stocks{
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  /* хром, сафари */
  .element::-webkit-scrollbar { width: 0; }
  /* ie 10+ */
  .element { -ms-overflow-style: none; }
  /* фф (свойство больше не работает, других способов тоже нет)*/
  .element { overflow: -moz-scrollbars-none; }
}
.stock-el{
  background: #fff;
  padding: 10px 18px;
  margin-top: 9px;
  margin-right: 9px;
  white-space: nowrap;
}
table{width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}
.td-txt{
  padding: 2px 4px;
}
table a{
  color: #556b7d;
}
table a:hover{
  text-decoration: underline;
}
.wb-btn-sm{
  padding: 6px 10px;
  width: 100%;
  box-sizing: border-box;
}
.odd{background: #ddd;}
.scm{
  /* background: url("@/assets/tresh.jpg") repeat 0px 0px; */
  background-color: #759ebd;
  height: calc(100vh - 90px);
  box-shadow: 0px 3px 3px #242424 inset;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*
  width: 100%;
  text-align: center;
  line-height: calc(100vh - 50px);
  */
}
.left-column{
  background: transparent;
  flex: 1;
  margin-top: 8px;
}
.list-page{
  padding-right: 8px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.list-elem{
  background: #f7dede;
  margin-bottom: 8px;
  box-shadow: 0px 0px 4px #000;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, margin-right 0.3s;
}

.btn-tab{
  transition: color 0.3s;
  display: block;
  color: #000;
}

.list-elem.active .btn-tab{
  color: #000;
}

.list-elem:hover{
  background-color: #5981ce;
}
.list-elem:hover .btn-tab{
  color: #fff;
}
.list-elem.active{
  margin-right: -12px;
  background-color: #f0f0f0;
}
.list-elem.active:hover .btn-tab{
  color: #000;
}

.work-area{
  margin-top: 8px;
  flex: 4;
  background: #fff;
  transition: background-color 0.3s;
  padding-left: 10px;
  padding-right: 10px;

  overflow-y: auto;
  height: 98%;
}
.work-area.active{
  background: #f0f0f0;
}
.content-html{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.list-elem.warning{
  background-color: #fff7c1;
}

.list-elem.notdeal{
  position: relative;
  cursor: default;
  background-color: #9ef5a5;
  color: #000;
}
.list-elem.notdeal:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0px;
  left: 0px;
  background: #ffffff8c;
}

.list-elem.notdeal:hover{
  background-color: #9ef5a5;
  color: #000;
}

td.green{
  background: #cae7c6;
  text-align: center;
}
td.yellow{
  background: #e5dfa0;
  text-align: center;
}
td.red{
  background: #ebcece;
  text-align: center;
}

.stock-el{background: #d3fad6; border-top: 2px solid #fff; cursor: pointer;}
.stock-el.current{border-top: 2px solid #73be79;}
.stock-el.danger{background: #f7dede;}
.stock-el.danger.current{background: #f6b8b8; border-top: 2px solid #bc2121; cursor:default;}

</style>
