<template>
  <h1><span>Наименование рассматриваемой модели</span></h1>
  <div class="wrp-tabs">
    <div
      v-for="(tab, ind) in tabs" :key="ind"
      :class="['btn-tab', { active: tab.show }]"
      @click="selTab(ind)"
      @keydown.f7="selTab(ind)"
    >
      {{ tab.lbl }}
    </div>
    <div class="tab-content">
      <template v-for="(tab, ind) in tabs" :key="ind">
        <component v-if="tab.show" :is="tab.name" v-bind="tab.options" class="tab"></component>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import WBGraphParam from '@/components/cross/WBGraphParam.vue';
import WBGraphParamFilter from '@/components/cross/WBGraphParamFilter.vue';
import TableInfoHead from '@/components/static/TableInfoHead.vue';
import Form from '@/components/atoms/Form.vue';

export default {
  name: 'ScmModelingDetail',
  components: {
    WBGraphParam, WBGraphParamFilter, TableInfoHead, Form,
  },
  data() {
    return {
      tabs: [],
      name: '',

      loader: false,
      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get('/scm/modeling/detail', {
        params: {
          elem: this.$route.params.id,
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.name = res.data.name;
            this.tabs = res.data.tabs;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    selTab(ind) {
      this.loader = true;
      let len = this.tabs.length;
      while (len) {
        len -= 1;
        if (len === ind) {
          this.tabs[len].show = true;
        } else {
          this.tabs[len].show = false;
        }
      }
      this.loader = false;
    },
  },
};
</script>
<style scoped>
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 15px 0px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
  background: #e7e7e7;
  transition: background-color 0.3s;
}
.btn-tab:hover{background-color:#d0d0d0;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
</style>
