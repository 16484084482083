<template>
  <div class="title not_print" v-if="modalTitle">{{modalTitle}}</div>
  <div class="anons not_print" v-if="modalDopText">{{modalDopText}}</div>
  <div class="before" v-if="before">
    <div class="wrp-btn not_print" v-if="before.btns">
      <div v-for="(item, ind) in before.btns" :key="ind" class="btn-action"
        @click="dopAct(item)"
        @keydown.enter="dopAct(item)">
        <i class="fa" :class="item.icon" aria-hidden="true"></i>
        {{item.title}}
      </div>
    </div>
    <div class="wrp-list">
      <div v-for="(item, ind) in before.list" :key="ind">
        <strong>{{item.lbl}}:</strong>  {{item.value}}
      </div>
    </div>
  </div>
  <div class="wrp-table">
    <div class="r-cnt" v-if="cntAll > 0">{{cntAll}}</div>
    <div class="r-pagination">
      <div v-for="(el, ind) in pagination" :key="ind"
        :class="{active: this.currPagination == ind}"
        @click="pag(ind)"
        @keydown.enter="pag(ind)">{{el}}</div>
    </div>

    <transition name="fade">
      <LoaderTreeSpin v-if="loader" />
      <table v-else-if="items.items && items.items.length" class="list-table">
        <tr>
          <th v-for="(lbl, ind) in items.heads" :key="ind">
            {{lbl}}
          </th>
        </tr>
        <tr v-for="(item, ind) in items.items" :key="ind" :class="{odd: ind % 2 === 0}" >
          <td v-for="(field, ind2) in item.fields" :key="ind2">
            <div v-if="field.type == 'I'" class="form-field">
              <input-text v-model="field.value"
                :def="field.placeholder"
                :err="field.error"
                :req="field.required"
                @change="fieldChg(ind, ind2)" />
            </div>
            <div v-if="field.type == 'L'" class="form-field">
              <SelectCustom v-model="field.value"
                :def="field.placeholder"
                :options="field.options"
                :err="field.error"
                @change="fieldChg(ind, ind2)" />
            </div>
            <div v-if="field.type == 'C'" class="form-field">
              <Checkbox v-model="field.value"
                class="big"
                :err="field.error"
                @change="fieldChg2(ind, ind2, $event)" />
            </div>
            <div v-if="field.type == 'S'" class="form-field">
              {{field.value}}
            </div>
            <div v-if="field.type == 'MS'" class="form-field">
              <div v-for="(els, ind) in field.value" :key="ind" class="pack-block">
                <div v-for="(el, ind2) in els" :key="ind2" class="pack-block-el"
                  :class="{warning: Array.isArray(el) && el[1] == 0}">
                  {{ Array.isArray(el) ? el[0] : el }}
                </div>
              </div>
            </div>
          </td>
          <td v-if="item.btns"  class="cell-act">
            <LoaderTreeSpin v-if="item.loader" />
            <template v-else>
              <div class="acts__item" v-for="(btn, ind4) in item.btns" :key="ind4">
                <div :title="btn.title"
                  @click.prevent.stop="actTable(btn, ind, $event)"
                  @keydown.enter.stop="actTable(btn, ind, $event)" >
                  <i class="fa" :class="btn.icon" aria-hidden="true"></i>
                </div>
              </div>
            </template>
          </td>
        </tr>
      </table>
      <div class="text-info-1" v-else>
        {{empty}}
      </div>
    </transition>
  </div>

  <div v-if="showContextForm" class="context-menu modal-form"
    @click="closeModal"
    @keydown.f7="closeModal">
    <div class="context-menu--body" :class="{ready: loadModal}" @click.stop >
      <div class="btn-close" @click.stop="closeModal" @keydown.f7="closeModal">
        <i data-v-8828ee9a="" class="fa fa-times" aria-hidden="true"></i>
      </div>
      <template v-if="modeModal === 0">
        <div v-if="answerForm" class="form-answer">{{answerForm}}</div>
        <Form v-else :title="form.title"
          :btn="form.btn"
          class="center-center"
          :fields="form.fields"
          :action="form.act"
          :method="form.method"
          @answerForm="formAnswer" />
      </template>
      <template v-if="modeModal === 1">
        <div v-if="modalDopInfo">{{modalDopInfo}}</div>
        <WBManage @del-parent="orderDel"
          :action="actionModal"
          :actchange="actchangeModal"
          @loadcheck="loadChildren"
        />
      </template>
      <template v-if="modeModal === 2">
        <div v-if="modalDopInfo">{{modalDopInfo}}</div>
        <WBManage @del-parent="orderDel"
          :action="actionModal"
          :actchange="actchangeModal"
          @loadcheck="loadChildren"/>
      </template>
      <template v-if="modeModal === 3">
        <WBCheckMate :action="actionModal"
          :actchange="actchangeModal"
          @loadcheck="loadChildren" />
      </template>
    </div>
  </div>

  <div class="area-autocomplette" v-if="showAreaAutocomplette" v-click-outside="closeArea">
    <AutocompletteAdd
      :action="areaAutocompletteAction"
      :url="areaAutocompletteUrl"
      def="поиск по наименованию товара (мин. 3 символа)"
      @checkelautocomplette="linkListEl" />
  </div>

  <div v-if="instrumental.length" class="instrumental">
    <div v-for="(el, ind) in instrumental"
      :key="ind"
      class="btn btn-add">
      <a v-if="el.type && el.type == 'ACM'"
        @click="actWind(el)"
        @keydown.enter="actWind(el)">
        <i :class="el.icon" aria-hidden="true"></i> <span>{{el.lbl}}</span>
      </a>
      <a v-else @click="act(el.act)"
        @keydown.enter="act(el.act)">
        <i :class="el.icon" aria-hidden="true"></i> <span>{{el.lbl}}</span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Form from '@/components/atoms/Form.vue';
import InputText from '@/components/atoms/InputText.vue';
import SelectCustom from '@/components/atoms/Select.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import AutocompletteAdd from '@/components/atoms/AutocompletteAdd.vue';
import WBCheckMate from '@/components/molecules/WBCheckMate.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'WBManage',
  components: {
    Form,
    InputText,
    SelectCustom,
    Checkbox,
    AutocompletteAdd,
    WBCheckMate,
    LoaderTreeSpin,
  },
  props: {
    action: String,
    filter: Object,
    actchange: String,
    title: String,
    doptext: String,
    modepost: {
      type: Number,
      default: 0,
    },
  },
  emits: ['loadcheck'],
  data() {
    return {
      showContextForm: false,
      answerForm: '',
      form: {
        title: '',
        btn: '',
        fields: [],
        action: '',
        method: 'post',
      },
      actionType: '',
      actionElem: 0,

      modeModal: 0,
      modalTitle: '',
      actionModal: '',
      actchangeModal: '',
      modalDopText: '',
      before: {},

      empty: 'Просматриваемый список пуст',

      items: {
        heads: [],
        items: [],
      },
      test: [],
      instrumental: [],
      errors: [],

      pagination: [],
      currPagination: 0,
      cntAll: 0,

      loader: true,
      loadModal: false,

      showAreaAutocomplette: false,
      areaAutocompletteUrl: '',
      areaAutocompletteAction: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loader = true;
      let param = {};
      if (this.filter) {
        param = { params: { filter: this.filter } };
      }

      if (this.modepost === 1) {
        axios.post(this.action, {
          filter: this.filter,
        })
          .then((res) => {
            if (res.data.success === 1) {
              if (res.data.ierarch) {
                this.items = res.data.ierarch;
              } else {
                this.items = res.data.list;
              }

              if (res.data.instrumental) {
                this.instrumental = res.data.instrumental;
              }
              if (res.data.actchange) {
                this.actchangeModal = res.data.actchange;
              }
              if (res.data.doptext) {
                this.modalDopText = res.data.doptext;
              }
              if (res.data.empty) {
                this.empty = res.data.empty;
              }
              if (res.data.pagination) {
                this.pagination = res.data.pagination;
              }
              if (res.data.cnt) {
                this.cntAll = res.data.cnt;
              }
              this.modalTitle = res.data.title;
              this.before = res.data.before;
            }
            this.$emit('loadcheck');
            this.loader = false;
          })
          .catch((e) => {
            this.loader = false;
            this.errors.push(e);
          });
      } else {
        axios.get(this.action, param)
          .then((res) => {
            if (res.data.success === 1) {
              if (res.data.ierarch) {
                this.items = res.data.ierarch;
              } else {
                this.items = res.data.list;
              }

              if (res.data.instrumental) {
                this.instrumental = res.data.instrumental;
              }
              if (res.data.actchange) {
                this.actchangeModal = res.data.actchange;
              }
              if (res.data.doptext) {
                this.modalDopText = res.data.doptext;
              }
              if (res.data.empty) {
                this.empty = res.data.empty;
              }
              if (res.data.pagination) {
                this.pagination = res.data.pagination;
              }
              if (res.data.cnt) {
                this.cntAll = res.data.cnt;
              }
              this.modalTitle = res.data.title;
              this.before = res.data.before;
            }
            this.$emit('loadcheck');
            this.loader = false;
          })
          .catch((e) => {
            this.loader = false;
            this.errors.push(e);
          });
      }
    },
    act(action) {
      this.modeModal = 0;
      this.actionType = 'add';
      axios.get(action)
        .then((res) => {
          if (res.data.success === 1) {
            this.showContextForm = true;
            this.form = res.data.form;
          } else {
            this.showContextForm = true;
            this.answerForm = res.data.answer;
          }
          this.loadChildren();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    actWind(el) {
      this.areaAutocompletteUrl = el.act;
      this.areaAutocompletteAction = el.listurl;

      setTimeout(() => {
        if (this.showAreaAutocomplette) {
          this.showAreaAutocomplette = false;
        } else {
          this.showAreaAutocomplette = true;
        }
      }, 50);
    },
    actTable(btn, ind, event) {
      if (btn.type === 'modalform') {
        this.actionType = 'chg';
        this.actionElem = ind;
        this.modeModal = 0;

        axios.get(btn.action, {
          params: {
            elem: this.items.items[ind].fields[0].value,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.showContextForm = true;
              this.form = res.data.form;
            }
            this.loadChildren();
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else if (btn.type === 'modal') {
        this.modeModal = 1;
        if (btn.mode_modal) {
          this.modeModal = btn.mode_modal;

          if (btn.mode_modal === 3) {
            this.loadModal = false;
          }
        }
        this.showContextForm = true;
        this.modalDopInfo = btn.info;
        this.actionModal = `${btn.action}?elem=${this.items.items[ind].fields[0].value}`;
      } else if (btn.type === 'link') {
        this.$router.push(`${btn.action}?elem=${this.items.items[ind].fields[0].value}`);
      } else if (btn.type === 'download') {
        this.items.items[ind].loader = true;

        axios.post(btn.action, {}, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          responseType: 'blob',
        }).then((response) => {
          const currentDate = new Date();
          const zipName = `Архив распределенных по поставщикам заказов ${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}.zip`;
          const blob = new Blob([response.data], { type: 'application/zip' });

          const elink = document.createElement('a');
          elink.style.display = 'none';
          elink.href = window.URL.createObjectURL(blob);
          elink.download = zipName;
          elink.click();
          URL.revokeObjectURL(elink.href);

          this.items.items[ind].loader = false;
        }).catch((error) => {
          console.log('download error {batch}');
          console.log(error);
        });
      } else if (btn.type === 'download-xlsx') {
        this.items.items[ind].loader = true;

        axios.post(btn.action, {}, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then((response) => {
          if (response.data.success === 1) {
            const elink = document.createElement('a');
            elink.style.display = 'none';
            elink.href = response.data.link;
            elink.download = response.data.name;
            elink.click();
            URL.revokeObjectURL(elink.href);
          }

          this.items.items[ind].loader = false;
        }).catch((error) => {
          console.log('download error {batch}');
          console.log(error);
        });
      } else {
        this.items.items[ind].loader = true;

        axios.post(btn.action, {
          elem: this.items.items[ind].fields[0].value,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.showAnswer(event, res.data.answer, 'ok');

              if (btn.items === 'add') {
                this.items.items.push(res.data.el);
              } else if (btn.items === 'del') {
                console.log('del');
                this.items.items.splice(ind, 1);
              } else if (btn.items === 'no') {
                console.log('no');
              } else if (!btn.nodel) {
                this.items.items.splice(ind, 1);
              }
              if (res.data.parent_del) {
                this.$emit('del-parent');
              }
              if (res.data.reload) {
                console.log('reload');
                this.init();
              }
            } else {
              this.showAnswer(event, res.data.answer, 'error');
            }
            this.items.items[ind].loader = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    showAnswer(event, msg, type) {
      let x = event.pageX;
      const merge = window.screen.width - x;
      if (merge < 320) {
        x -= 320 - merge;
      }

      this.answer(x, event.pageY, msg, type);
    },
    answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
    formAnswer(data) {
      this.answerForm = data.answer;
      if (this.actionType === 'add') {
        this.items.items.push(data.elem);
      } else if (this.actionType === 'chg') {
        this.items.items.splice(this.actionElem, 1, data.elem);
      }
    },
    closeModal() {
      this.showContextForm = false;
      this.answerForm = '';
    },
    collapse(ind) {
      if (this.items.items[ind].show) {
        this.items.items[ind].show = false;
      } else {
        this.items.items[ind].show = true;
      }
    },
    fieldChg(ind, ind2) {
      axios.post(this.actchange, {
        elem: this.items.items[ind].fields[0].value,
        val: this.items.items[ind].fields[ind2],
      })
        .then((res) => {
          if (res.data.success === 1) {
            console.log(`${res.data.answer}`);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    fieldChg2(ind, ind2) {
      axios.post(this.actchange, {
        elem: this.items.items[ind].fields[0].value,
        val: this.items.items[ind].fields[ind2],
      })
        .then((res) => {
          if (res.data.success === 1) {
            // this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
            if (res.data.reload) {
              this.init();
            }
          } else {
            console.log(this.items.items[ind].fields[ind2].value);
            if (res.data.backCheck) {
              if (this.items.items[ind].fields[ind2].value) {
                this.items.items[ind].fields[ind2].value = 0;
              } else {
                this.items.items[ind].fields[ind2].value = 1;
              }
            }
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    dopAct(item) {
      if (item.type === 'file') {
        axios({
          url: item.action,
          method: 'GET',
          responseType: 'blob',
        }).then((res) => {
          const href = URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'order.csv');
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          /*
          if (res.data.success === 1) {
            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(res.data.file)}`;
            anchor.target = '_blank';
            anchor.download = item.name_file;
            anchor.click();
          }
          */
        }).catch((e) => {
          this.errors.push(e);
        });
      } else if (item.type === 'print') {
        window.print();
      }
    },
    pag(ind) {
      this.loader = true;
      this.currPagination = ind;

      const param = { params: {} };
      param.params.page = ind;
      if (this.filter) {
        param.params.filter = this.filter;
      }
      axios.get(this.action, param)
        .then((res) => {
          if (res.data.success === 1) {
            this.items = res.data.list;
          }
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loader = false;
        });
    },
    linkListEl(el) {
      this.items.items.push(el);
    },
    closeArea() {
      if (this.showAreaAutocomplette) {
        this.showAreaAutocomplette = false;
      }
    },
    loadChildren() {
      this.loadModal = true;
    },
  },
};
</script>

<style scoped>
.pack-block {
  display: block;
  margin-bottom: 6px;
}
.pack-block-el{
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
}
.pack-block-el:nth-child(1){
  display: block;
  margin-bottom: 2px;
  background: #dedede;
  color: #0f0f0f;
  padding: 2px 6px;
  border-radius: 2px;
  border-top: 1px solid #c2c2c2;
}
.pack-block-el:nth-child(2){
  background: #dbc9ff;
  padding: 1px 8px;
  margin-right: 6px;
  color: #000;
  border-radius: 2px;
  margin-bottom: 2px;
  border-top: 1px solid #bf9fff;
}
.pack-block-el:nth-child(3){
  background: #a3e6b2;
  padding: 1px 8px;
  margin-right: 6px;
  color: #1f1f1f;
  border-radius: 2px;
  border-top: 1px solid #6cb67c;
}
.pack-block-el.warning:nth-child(3){
  background: #f09090;
  border-top: 1px solid #9d5757;
}
.r-cnt{position: absolute; right: 0px; top: -40px;
    display: inline-block;
    background: #5981ce;
    padding: 2px 30px;
    color: #fff;
}
.r-pagination{position: fixed; right: 0px; top: 107px; height: calc(100vh - 150px); display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  overflow-y: auto;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.r-pagination::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.r-pagination div{
  width: 30px;
  min-height: 30px;
  line-height: 30px;
  background: #ccc;
  border: 1px solid #c7cbcf;
  border-right: none;
  cursor: pointer;
  background: linear-gradient(to top, #fdfdfd, #f0f1f0);
  text-align: center;
  border-radius: 4px 0px 0px 4px;
  margin-bottom: 2px;
}
.r-pagination div:hover{
background: linear-gradient(to top, #5981ce, #5981ce);
color: #fff;
}
.r-pagination div.active{
background: linear-gradient(to top, #536169, #494f56);
color: #fff;
}
.wrp-table{position: relative;}

a{cursor: pointer;}
.row-field{margin-bottom: 12px;}
.row-field .f1, .row-field .f2{display:inline-block}
.row-field .f1{width:69%; }
.row-field .f2{width:29%; }
.row-field:hover{background:#ddd}
.second td{
  border: 1px solid #ddd;
  border-top: none;
}

table{
  border-spacing: 0px;
  border-collapse: collapse;
}

.wrp-btn{
  text-align: right;
}
.btn-action{
  background: #5cb85c;
  display: inline-block;
  padding: 3px 8px;
  color: #fff;
  clear: both;
  float: right;
  margin-bottom: 6px;
  border-radius: 3px 0px 0px 3px;
  transition: all 0.2s;
}
.btn-action:hover{
  padding-right:16px;
  cursor:pointer;
  background: #439943;
}
.btn-action i{color: #fff;}
.before{
  margin-top: 6px;
  margin-bottom: 6px;
}

.wrp-list{
  padding-left:12px;
  margin-right: 170px;
}

.context-menu{
  text-align: center;
}

.context-menu--body{background:#fff;
  overflow: hidden;
  width: 10%;
  height: 10%;
  transition: width 0.7s, height 0.7s;
}
.context-menu--body.ready{
  width: 96%;
  height: 96%;
  overflow: auto;
}
.context-menu--body .title{
  padding: 6px 12px 6px;
  background: #d6f5db;
  font-size: 22px;
  line-height: 24px;
}
.context-menu--body .anons{
  padding: 6px 12px 6px;
}

.fa-times, .fa-chain-broken{color: #ce5959}
.fa-times:hover, .fa-chain-broken:hover{color: #ef3737}

.fa-paper-plane,
.fa-file-excel-o,
.fa-repeat{color: #277824}
.fa-paper-plane:hover,
.fa-file-excel-o:hover,
.fa-repeat:hover{color: #3fbd3a}

.fa-pencil, .fa-eye,
.fa-download,
.fa-upload{color: #2056bf}
.fa-pencil:hover,
.fa-eye:hover,
.fa-download:hover,
.fa-upload:hover{color: #5981ce}

tr.first{cursor: pointer;}
tr.first:hover td{opacity: 0.9}
.instrumental{position: fixed;
  left:0px;
  bottom:0px;
  background: #aaa;
  width: 100%;
}

.btn.btn-add{
  background: #5981ce;
  color: #fff;
  padding: 0px;
  display: inline-block;
  cursor: pointer;
}
.btn.btn-add:hover{
  background: #3b70d7;
  padding: 0px;
}
.btn.btn-add > a{
  display: inline-block;
  padding: 6px;
  transition: all 0.3s;
}
.btn.btn-add:hover > a{
  padding: 8px;
}

.btn.btn-add span, .btn.btn-add i{
  vertical-align: middle;
}

.list-table{
  margin-bottom: 40px;
}
.list-table td.cell-act{
  padding: 0px;
  vertical-align: middle;
}
.acts__item{
  padding:0px
}
.acts__item > div{
  font-size: 22px;
  cursor: pointer;
}
.list-table{
  width:100%;
  text-align: left;
}

.list-table tr.odd{
  background: #e5f0ff;
}

.list-table th{
  background: #5981ce /* #5390ef */;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  line-height: 18px;
}
.list-table td{
  vertical-align:top;
  padding: 8px;
}
.list-list-item{
  padding: 4px;
  background: #ccc;
  margin-bottom: 1px;
  border-radius:2px;
  font-size:12px;
}
.list-list-item .unlink{float:right; padding-left:4px;}

.add-elem{text-align:center; width:100%; display:block;
  background: #bbb;
  font-size:12px;
  line-height:14px;
  padding:4px 0;
}

.type-s{display:block; padding:2px 4px; font-size:16px;}
.acts__item{display:inline-block; padding:4px; margin-right: 8px;}

.fa.green{color:#3fbd3a}
.fa.red{color:#f06565;}
.fa.blue{color: #5981ce}

.list-table tr td:last-child{
  white-space: nowrap;
}

.btn-close{
  position: absolute;
  top: 0px;
  right: 0px;
  color: #FFF;
  cursor: pointer;
  font-size: 30px;
  line-height: 32px;
}
.btn-close .fa-times{
  color: #fff;
}
.btn-close .fa-times:hover{
  color: #eec7c7;
}

.area-autocomplette{
  position:fixed;
  bottom: 30px;
  left: 0px;
  width: 50%;
  background: #ddd;
  padding: 5px;
  padding-bottom: 10px;

  height: 35%;
}

</style>
