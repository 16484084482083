<template>
  <div class="wrp-block">
    <div class="block-api">
      <div class="field">
        <div style="margin-bottom:5px">
          <div for="id1c">
            <strong>Уникальные ID клиента для синхронизации через АПИ</strong>
          </div>
          <span class="send" id="send"></span>
        </div>
        <InputMulti @update:modelValue="changeCompanyProp('id1c', block1.keys)"
          v-model="block1.keys" />
      </div>
      <div>
        <div style="display:inline-block; vertical-align:middle;">
          <Checkbox @change="checkboxChange('filter_base')"
            v-model="block1.flagBase" />
        </div>
        <strong>Пропускать только BASE запросы</strong>
      </div>
      , если Base конкретный
      <div style="width:50%; display:inline-block;">
        <InputText @change="changeCompanyProp('base_key', block1.nameBase)"
          v-model="block1.nameBase" />
      </div>
    </div>
  </div>
  <!--
  <div class="wrp-block">
    <div class="title">
      Загрузить данные вручную
    </div>
    <FormSchema v-bind="formOptions" />
  </div>
  <div class="wrp-block">
    <div class="title">Статистика запросов АПИ</div>
    <div class="body">
      <TableInfo v-if="block3.url" :action="block3.url" />
    </div>
    <div class="bottom" v-show="0">
      <BtnDownload
        lbl="Скачать CSV с запросами за вчера и сегодня"
        action="/"
        type=""
        :link=true
        />
      <BtnDownload
        lbl="Скачать CSV с запросами за последний месяц"
        action="/"
        type=""
        />
    </div>
  </div>-->
</template>

<script>
import axios from 'axios';

// import FormSchema from '@/components/molecules/FormSchema.vue';

import InputText from '@/components/atoms/InputText.vue';
import InputMulti from '@/components/atoms/InputMulti.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';

// import TableInfo from '@/components/experiment/TableInfo.vue';
// import BtnDownload from '@/components/atoms/BtnDownload.vue';

export default {
  name: 'FormSynch',
  components: {
    InputText, Checkbox, InputMulti, // FormSchema, TableInfo, BtnDownload,
  },
  data() {
    return {
      block1: {
        flagBase: 0,
        nameBase: '',
        keys: [{
          value: '',
        }],
        action: '',
      },

      block3: {},

      formOptions: {},

      form: {
        handeler: 0,
        stock: 0,
        file: '',
      },
      handlerErr: '',
      handlerOptions: [],
      fileSample: {
        url: '',
        lbl: '',
        icon: '',
      },
      stockErr: '',
      stockOptions: [],

      errors: [],
      spiner: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.spiner = true;
      axios.get('/scm/settings/sett-synch')
        .then((res) => {
          if (res.data.success === 1) {
            this.block1.keys = res.data.block1.id1c;
            this.block1.flagBase = res.data.block1.filter_base;
            this.block1.nameBase = res.data.block1.base_key;
            this.block1.action = res.data.block1.action;

            this.formOptions = res.data.block2;

            this.block3.url = res.data.block3.action;
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    submitForm() {
      if (this.validateReq()) {
        if (this.action) {
          this.spiner = true;
          if (this.method === 'post') {
            axios.post(this.action, { form: this.curr_fields })
              .then((res) => {
                if (res.data.success === 1) {
                  this.$emit('answerForm', res.data);
                } else {
                  this.curr_fields = res.data.pack;
                }
                this.spiner = false;
              })
              .catch((e) => {
                this.errors.push(e);
              });
          } else {
            // GET-запрос тут
            this.spiner = false;
          }
        } else {
          this.$emit('answerForm', this.curr_fields);
        }
      } else {
        // не пройжена валидация
      }
    },
    validateReq() {
      this.flag_no_err = true;
      this.curr_fields.forEach((p, ind) => {
        if (p.required && p.value === '') {
          this.curr_fields[ind].error = 'Поле не должно быть пустым';
          this.flag_no_err = false;
        }
        if (p.value !== '') {
          this.curr_fields[ind].error = '';
        }
      });
      return this.flag_no_err;
    },
    outError(ind) {
      this.curr_fields[ind].error = '';
    },
    downloadHandData() {
      const formData = new FormData();
      formData.append('file', this.form.file);
      axios.post('/scm/settings/import-data-prod', { files: formData }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        console.log(res.data);
      }).catch((e) => {
        this.errors.push(e);
      });
    },
    changeCompanyProp(prop, val) {
      axios.post(this.block1.action, { prop, val }).then((res) => {
        console.log(res.data);
      }).catch((e) => {
        this.errors.push(e);
      });
    },
    checkboxChange(prop) {
      axios.post(this.block1.action, { prop, val: this.block1.flagBase }).then((res) => {
        console.log(res.data);
      }).catch((e) => {
        this.errors.push(e);
      });
    },
  },
};
</script>

<style scoped>
  .wrp-block .body{
    margin-bottom: 24px;
  }

  .wrp-block{
    text-align: left;
    margin-bottom: 20px;
    background: #f1f1f1;
    padding: 10px;
    border: 1px solid #ddd;
    border-bottom: 1px solid #adadad;
  }
  .wrp-block .title{
    font-weight: bold;
    margin-bottom: 12px;
  }

  .block-api .field{
    margin-bottom:10px;
  }
  .form-controll.dop{width:40%; display:inline-block;}

  .field-exporterdata-filedata{
    width:120px;
    float:left;
    margin-right:12px;
  }
  .f1{
    background:#f1f1f1;
    width:232px;
  }
  .link-gray{
    color: #b7b7b7;
  }
</style>
