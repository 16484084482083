<template>
  <div v-if="loader" class="spinner">
    <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
  </div>
  <div v-else>
    <div v-if="list.length > 0" class="services">
      <div style="flex: 1">
        <div class="list-vertical">
          <div v-for="(el, ind) in list" :key="ind"
            class="list-vertical-el"
            :class="{active: currIndex == ind}"
            @click="selIndex(ind)"
            @keydown.enter="selIndex(ind)">
            <i v-if="el.active" class="fa fa-check"
              style="float: right"
              aria-hidden="true"></i>
            {{el.label}}
          </div>
        </div>
      </div>
      <div style="flex: 6; padding-left: 12px;">
        <div  class="list-tabs">
          <div v-for="(el, ind) in list[currIndex].list" :key="ind"
            class="list-tabs-el"
            :class="{active: currIndex2 == ind}"
            @click="selIndex2(ind)"
            @keydown.enter="selIndex2(ind)">
            {{el.label}}
          </div>
        </div>
        <div class="" style="padding-top:12px;">
          <transition name="fade">
            <component :is="activeComponent" v-bind="activeOptions"
              :key="currIndex2"
              @answer="componentAnswer"
              @answerForm="componentAnswer"
              @componentUpdate="componentUpdate"
              style="display:inline-block" ></component>
          </transition>
        </div>
      </div>
    </div>
    <div v-else class="info-mess" >
      Сервисы синхронизации не подключены.
      Для подключения необходимо добавить сервис-источник
      данных, кнопка добавления внизу экрана.
    </div>
  </div>

  <div v-if="showContextForm" class="context-menu modal-form"
    @click="closeModal"
    @keydown.f7="closeModal">
    <div class="context-menu--body" :class="{ready: loadModal}" @click.stop >
      <div class="btn-close" @click.stop="closeModal" @keydown.f7="closeModal">
        <i data-v-8828ee9a="" class="fa fa-times" aria-hidden="true"></i>
      </div>
      <template v-if="modeModal === 0">
        <div v-if="answerForm" class="form-answer">{{answerForm}}</div>
        <Form v-else :title="form.title"
          :btn="form.btn"
          class="center-center"
          :fields="form.fields"
          :action="form.act"
          :method="form.method"
          @answerForm="formAnswer" />
      </template>
    </div>
  </div>

  <div v-if="instrumental.length" class="instrumental">
    <a v-for="(instrument, ind) in instrumental" :key="ind"
      class="btn btn-add instrumental-item"
      @click.prevent="act(instrument)"
      @keydown.f7.prevent="act(instrument)">
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';

import Form from '@/components/atoms/Form.vue';
import TableInfo from '@/components/experiment/TableInfo.vue';
import InfoForm from '@/components/static/InfoForm.vue';

export default {
  name: 'ServicesCustom',
  components: {
    Form, TableInfo, InfoForm,
  },
  props: ['options', 'stick'],
  data() {
    return {
      loader: 1,
      currIndex: 0,
      currIndex2: 0,

      activeComponent: '',
      activeOptions: {},

      list: [],
      form: [],

      instrumental: [],
      showContextForm: false,
      answerForm: '',
      actionType: 'add',
      actionElem: 0,

      loadModal: 0,

      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get('/scm/synch/sett-iface')
        .then((res) => {
          this.list = res.data.tabs;
          this.instrumental = res.data.instrumental;

          if (res.data.activePage.length) {
            axios.get(res.data.activePage)
              .then((res2) => {
                this.activeComponent = res2.data.component.name;
                this.activeOptions = res2.data.component.options;
              });
          }

          this.loader = 0;
        });
    },
    selIndex(ind) {
      this.currIndex = ind;
      this.currIndex2 = 0;
      axios.get(this.list[this.currIndex].list[this.currIndex2].action)
        .then((res) => {
          this.activeComponent = res.data.component.name;
          this.activeOptions = res.data.component.options;
          this.currIndex2 = 50;
        });
    },
    selIndex2(ind) {
      axios.get(this.list[this.currIndex].list[ind].action)
        .then((res) => {
          this.activeComponent = res.data.component.name;
          this.activeOptions = res.data.component.options;
          this.currIndex2 = ind;
        });
    },
    componentAnswer(data) {
      if (data.success === 1) {
        if (data.action === 'del') {
          console.log(this.list);
          this.list.splice(this.currIndex, 1);
        } else {
          axios.get(data.action)
            .then((res) => {
              this.activeComponent = res.data.component.name;
              this.activeOptions = res.data.component.options;
            });
        }
      }
    },
    componentUpdate(el, ind) {
      this.activeOptions.list[ind] = el;
    },
    act(el) {
      this.modeModal = 0;
      this.actionType = 'add';
      axios.get(el.act)
        .then((res) => {
          if (res.data.success === 1) {
            this.showContextForm = true;
            this.form = res.data.form;
          } else {
            this.showContextForm = true;
            this.answerForm = res.data.answer;
          }
          this.loadChildren();
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    closeModal() {
      this.showContextForm = false;
      this.answerForm = '';
    },
    formAnswer(data) {
      this.answerForm = data.answer;
      if (this.actionType === 'add') {
        this.list.push(data.elem);
      } else if (this.actionType === 'chg') {
        // this.items.items.splice(this.actionElem, 1, data.elem);
        console.log('Изменение заблокировано');
      }
    },
  },
};
</script>

<style scoped>
.info-mess{
  background: #f1d6d6;
  padding: 12px;
  color: #3c0c0c;
}
.services{display: flex}

.list-vertical-el{
  padding: 8px 14px;
  background: #e7e7e7;
  margin-bottom: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.list-vertical-el:hover{
  background-color:#d7d7d7;
}
.list-vertical-el.active{
  background-color: #5981ce;
  color: #fff;
  cursor: default;
}

.list-tabs{
  border-bottom: 1px solid #dddd;
}
.list-tabs-el{
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  padding: 3px 8px;
  background: #e7e7e7;
  margin-right: 4px;
  font-size: 14px;
  text-transform: uppercase;
  transition: background-color 0.3s;
}
.list-tabs-el.active{
  background-color: #5981ce;
  color: #fff;
  cursor: default;
}

.btn-close{
  position: absolute;
  top: 0px;
  right: 0px;
  color: #FFF;
  cursor: pointer;
  font-size: 30px;
  line-height: 32px;
}
.btn-close .fa-times{
  color: #fff;
}
.btn-close .fa-times:hover{
  color: #eec7c7;
}

.spinner {
  float: left;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.spinner i{background: #cfcfcf; padding: 3px}
</style>
