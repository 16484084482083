<template>
  <div class="filter">
    <div v-if="stick" class="filter-stick">{{stick}}</div>
    <div class="filter-field-left" v-for="(field, ind) in options.left" :key="ind">
      <template v-if="field.type == 'L'">
        <SelectCustom v-model="field.value"
          :lbl="field.lbl"
          :def="field.placeholder"
          :options="field.options"
          @change="chgElem(field.name)" />
      </template>
      <template v-if="field.type == 'A'">
        <Autocomplette @update:modelValue="chgElem(field.name)" v-model="field.value"
          :lbl="field.lbl"
          :text="field.text"
          :url="field.url"
          :def="field.placeholder"
          :options="field.options" />
      </template>
      <template v-if="field.type == 'LF'">
        <SelectIerarch v-model="field.value"
          :lbl="field.lbl"
          :def="field.placeholder"
          :action="field.action"
          @change="chgElem(field.name)" />
      </template>
      <template v-if="field.type == 'LFM'">
        <SelectIerarchMulty v-model="field.value"
          :lbl="field.lbl"
          :def="field.placeholder"
          :action="field.action"
          @change="chgElem(field.name)" />
      </template>
      <template v-if="field.type == 'LR'">
        <Radio v-model="field.value"
          class="filter-radio"
          :lbl="field.lbl"
          :def="field.placeholder"
          :options="field.options"
          @change="chgElem(field.name)" />
      </template>
      <template v-if="field.type == 'C'">
        <Checkbox v-model="field.value"
          :text="field.lbl"
          :err="field.error"
          @change="chgElem(field.name)" />
      </template>
      <template v-if="field.type == 'S'">
        <div class="field-label">{{field.lbl}}</div>
      </template>
      <template v-if="field.type == 'D'">
        <InputCalendar
          :lbl="field.placeholder"
          :type="field.align"
          :months="field.months"
          :week-days="field.weekDays"
          v-model="field.value"
          @update:modelValue="chgElem" />
      </template>
    </div>

    <div style="float:right">
      <div class="field" v-for="(field, ind) in options.right" :key="ind">
        <template v-if="field.type == 'D'">
          <InputCalendar
            :lbl="field.placeholder"
            :type="field.align"
            :months="field.months"
            :week-days="field.weekDays"
            v-model="field.value"
            @update:modelValue="chgElem" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCustom from '@/components/atoms/Select.vue';
import Autocomplette from '@/components/atoms/Autocomplette.vue';
import InputCalendar from '@/components/atoms/InputCalendar.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import SelectIerarch from '@/components/atoms/SelectIerarch.vue';
import Radio from '@/components/atoms/Radio.vue';
import SelectIerarchMulty from '@/components/atoms/SelectIerarchMulty.vue';

export default {
  name: 'FilterCustom',
  components: {
    SelectCustom,
    InputCalendar,
    Autocomplette,
    Checkbox,
    SelectIerarch,
    Radio,
    SelectIerarchMulty,
  },
  props: ['options', 'stick'],
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    chgElem() {
      this.$emit('change-field', this.options);
    },
  },
};
</script>

<style scoped>
.filter {
  position: relative;
  margin-bottom: 15px;
  background: #efefef;
  padding: 12px 12px 12px 12px;
  box-shadow: 0px 0px 1px inset #999;
  min-height: 66px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.filter .filter-stick {
  position: relative;
  top: 0px;
  left: 0px;
  width: 64px;
  height: 40px;
  line-height: 40px;
  font-size: 25px;
  text-align: left;
  float: left;
}
.filter-field-left {
  margin-right: 20px;
  float:left;
  text-align: left;
}

.field {
  float: right;
}
.field-label{
  line-height: 40px;
}
</style>
