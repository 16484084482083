<template>
  <div>
    <h1><span>Пользовательские склады-поставщики</span></h1>
    <WBManage
      action="/scm/stock-custom/manage"
      actChange=""
    />
  </div>
</template>

<script>
import axios from 'axios';
import WBManage from '@/components/molecules/WBManage.vue';

export default {
  name: 'ScmSettingStockCustom',
  components: {
    WBManage,
  },
  data() {
    return {
      tabs: [],

      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get('/scm/settings-g/set-tabs-stock')
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 4px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-tab:hover{background:#eee;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
</style>
