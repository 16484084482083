<template>
  <div>
    <WBLogin />
  </div>
</template>

<script>
import WBLogin from '@/components/WBLogin.vue';

export default {
  name: 'LoginPage',
  components: {
    WBLogin,
  },
  data() {
    return {
    };
  },
};
</script>

<style scoped>
</style>
