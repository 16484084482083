<template>
  <div class="file-wrapper" :class="{lh: !lbl}">
    <div v-if="lbl" class="head">
      <div style="display:inline-block">{{lbl}}</div>
      <a v-if="sample.lbl != ''" class="load-template" download :href="sample.url">
        <i :class="sample.icon" aria-hidden="true"></i> {{sample.lbl}}
      </a>
    </div>
    <label for="fff">
      <input id="fff" type="file" ref="file" @change="handleFileUpload()"/>
    </label>
    <transition>
      <span class="error" v-if="err">
        <i class="close" @click="onClose" @keydown.x="onClose">&#10006;</i>
        {{err}}
      </span>
    </transition>
  </div>
</template>
<script>
// ['def', 'lbl', 'sample', 'err', 'ind', 'modelValue'],
export default {
  name: 'SingleFile',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    ind: {
      type: Number,
      default: 0,
    },
    err: {
      type: String,
      default: '',
    },
    sample: {
      type: Object,
      default() {
        return {
          lbl: '',
          url: '',
          icon: '',
        };
      },
    },
    def: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      file: '',
    };
  },
  methods: {
    handleFileUpload() {
      this.$emit('update:modelValue', this.$refs.file.files[0]);
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>
<style scoped>
  .lh{
    line-height: 40px;
    border: 1px solid #aaa;
    border-left: 4px solid #aaa;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
    padding-left: 4px;
  }
  .file-wrapper{
    position: relative;
  }
  .load-template{
    display: inline-block;
    margin-left:12px;
    font-size: 14px;
    line-height: 16px;
    color: #6171e8;
    cursor: pointer;
  }
  .load-template:hover{
    color: #263de7;
  }
  .head{
    margin-bottom: 6px;
  }

  .error{
    float: right;
    position: absolute;
    display: block;
    top: 36px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    background: #f3c9c9;
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
    padding: 3px 11px 2px;
    color: #b33d3d;
  }
  .error-input{
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
  .error .close{
    float: right;
    cursor: pointer;
    font-style: normal;
    margin-right: -11px;
    margin-top: 0px;
    display: block;
    width: 18px;
    height: 18px;
    text-align: center;
  }
</style>
