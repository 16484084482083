<template>
  <div>
    <div v-if="summRub" class="sum-rub">∑ {{summRub}}</div>
    <canvas @mousemove="placeCoordinates"
      id="canvas"
      height='320'
      width='1000'
      @click="linkProd"
      @keydown.enter="linkProd"></canvas>
    <div class="panel-bottom">
      <div class="block-info">Позиция: {{xMouse}}, {{yMouse}}</div>
      <div class="block-info">Товаров: {{cntProducts}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiaGramma',
  props: {
    dataGraph: Array,
    options: Object,
    mode: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      canvas: null,
      xMouse: 0,
      yMouse: 0,
      wCanvas: 0,
      hCanvas: 0,
      rs: [],

      paddingTop: 10,
      paddingBottom: 30,
      paddingLeft: 70,
      paddingRight: 0,
      horizontalLine: 5,

      maxVal: 0,
      maxValRound: 0,
      limitRoiCoord: {
        xLeftTop: 0,
        yLeftTop: 0,
        xRightBot: 0,
        yRightBot: 0,
      },

      errors: [],
    };
  },
  computed: {
    cntProducts() {
      return this.dataGraph.length;
    },
    summRub() {
      return this.options.sumAll;
    },
  },
  mounted() {
    const c = document.getElementById('canvas');
    this.canvas = c.getContext('2d');

    c.width = window.innerWidth - 12 * 2;

    window.addEventListener('resize', () => {
      c.width = window.innerWidth - 12 * 2;
      this.wCanvas = c.width;

      this.drawStuff();
    }, false);

    this.wCanvas = c.width;
    this.hCanvas = c.height;
  },
  watch: {
    dataGraph() {
      this.limitRoiCoord = {
        xLeftTop: 0,
        yLeftTop: 0,
        xRightBot: 0,
        yRightBot: 0,
      };
      this.drawStuff();
    },
  },
  methods: {
    drawStuff() {
      this.rs = [];
      const ctx = this.canvas;

      let len = this.dataGraph.length;

      const heightGraph = this.hCanvas - this.paddingTop - this.paddingBottom;
      const widthGraph = this.wCanvas - this.paddingLeft - this.paddingRight;

      // const widthColumn = 30;
      ctx.lineWidth = 0.5;
      ctx.fillStyle = '#fff';

      ctx.fillRect(0, 0, this.wCanvas, this.hCanvas);

      this.maxValRound = this.roundRazrad(this.options.maxVal);

      this.drawSetka();

      const vHorizontal = widthGraph / len;
      const offsetRight = (vHorizontal / 100) * 10;

      ctx.fillStyle = '#82b5ef';
      let x;
      let yRect;
      let xRect;
      while (len > 0) {
        len -= 1;

        x = (heightGraph * this.dataGraph[len].val) / this.maxValRound;
        yRect = heightGraph - x + this.paddingTop;

        xRect = vHorizontal * len + this.paddingLeft;
        ctx.fillRect(xRect, yRect, vHorizontal - offsetRight, x);

        if (this.mode === 1) {
          const pointOsY = ((vHorizontal - offsetRight) / 2) + xRect;
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${this.dataGraph[len].val}`, pointOsY, yRect - 10);
          ctx.fillText(`${this.dataGraph[len].name}`, pointOsY, (yRect + x) + 15);
        }

        ctx.fillStyle = '#82b5ef';

        this.rs.push([
          xRect,
          yRect,
          vHorizontal - offsetRight,
          x,
          this.dataGraph[len].name,
          this.dataGraph[len].val,
          this.options.nameVal,
          this.dataGraph[len].url,
        ]);

        if (this.options.limit > this.dataGraph[len].val) {
          this.limitRoiCoord = {
            xLeftTop: xRect - (offsetRight / 2),
            yLeftTop: 0,
            xRightBot: xRect - (offsetRight / 2),
            yRightBot: this.hCanvas,
          };
        }
      }

      if (this.options.lblY) {
        ctx.fillStyle = '#000';
        ctx.textAlign = 'right';
        ctx.fillText(`${this.options.lblY}`, this.wCanvas, this.hCanvas - 4);
      }

      if (this.limitRoiCoord.xLeftTop !== 0) {
        this.drawRoiLimit();
      }
    },
    drawRoiLimit() {
      const ctx = this.canvas;
      ctx.strokeStyle = '#e45c57';
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(this.limitRoiCoord.xLeftTop, this.limitRoiCoord.yLeftTop);
      ctx.lineTo(this.limitRoiCoord.xRightBot, this.limitRoiCoord.yRightBot);
      ctx.closePath();
      ctx.stroke();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#ccc';
    },
    drawSetka() {
      const ctx = this.canvas;
      ctx.strokeStyle = '#ccc';
      ctx.font = 'normal normal 16px Arial';
      const heightGraph = this.hCanvas - this.paddingTop - this.paddingBottom;
      const vInterval = heightGraph / this.horizontalLine;
      const vIntervalReal = this.maxValRound / this.horizontalLine;

      for (let i = this.horizontalLine; i >= 0; i -= 1) {
        const xPos = vInterval * i + this.paddingTop;

        ctx.beginPath();
        ctx.moveTo(this.paddingLeft, xPos);
        ctx.lineTo(this.wCanvas - this.paddingRight, xPos);
        ctx.closePath();
        ctx.stroke();

        ctx.textAlign = 'right';
        ctx.fillStyle = '#000';

        let txt = this.maxValRound - vIntervalReal * i;
        if (String(txt).length >= 6) {
          txt = Math.round(txt * 10) / 10;
        }
        if (String(txt).length >= 6) {
          ctx.font = 'normal normal 12px Arial';
        }

        ctx.fillText(txt, 50, vInterval * i + 6 + this.paddingTop);
        ctx.fillStyle = '#fff';
      }
    },
    placeCoordinates(e) {
      const ctx = this.canvas;
      // this.canvas.clearRect(0, 0, this.width, this.height);
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, this.wCanvas, this.hCanvas);

      this.xMouse = e.offsetX;
      this.yMouse = e.offsetY;

      this.drawSetka();

      this.drawRoiLimit();

      let len = this.rs.length;
      while (len > 0) {
        len -= 1;

        if (this.mode === 1) {
          const pointOsY = ((this.rs[len][2]) / 2) + this.rs[len][0];
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.fillText(`${this.rs[len][5]}`, pointOsY, this.rs[len][1] - 10);
          ctx.fillText(`${this.rs[len][4]}`, pointOsY, (this.rs[len][1] + this.rs[len][3]) + 15);
        }

        if (this.rectPointCollision([e.offsetX, e.offsetY], this.rs[len])) {
          this.drawRect(this.rs[len], '#e45c57');
          if (this.mode === 0) {
            this.drawText(this.rs[len]);
          }
        } else {
          this.drawRect(this.rs[len], '#82b5ef');
        }
      }

      if (this.options.lblY) {
        ctx.fillStyle = '#000';
        ctx.textAlign = 'right';
        ctx.fillText(`${this.options.lblY}`, this.wCanvas, this.hCanvas - 4);
      }
    },
    drawRect(r, color) {
      const ctx = this.canvas;

      ctx.fillStyle = color;
      ctx.fillRect(...r.slice(0, 4));
    },
    rectPointCollision(point, rect) {
      return (
        point[0] > rect[0]
        && point[0] < rect[0] + rect[2]
        && point[1] > rect[1]
        && point[1] < rect[1] + rect[3]
      );
    },
    drawText(r) {
      const ctx = this.canvas;

      // const w = ctx.measureText(r[4]).width;

      ctx.fillStyle = '#fff';
      const wInfo = 320;
      const hInfo = 100;
      ctx.fillRect(this.wCanvas - wInfo, 0, wInfo, hInfo);
      ctx.strokeStyle = '#000000';
      ctx.strokeRect(this.wCanvas - wInfo, 0, wInfo, hInfo);
      ctx.fillStyle = '#333';
      ctx.textAlign = 'left';
      ctx.font = 'normal normal 16px Arial';

      const str1 = r[6].length;
      // r[5] = r[5].toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
      const str2 = str1 + String(r[5]).length;
      let posY = 25;
      if (str2 >= 32) {
        ctx.fillText(`${r[6]}:`, this.wCanvas - wInfo + 10, posY);
        posY += 20;
        ctx.fillText(`${r[5]}`, this.wCanvas - wInfo + 10, posY);
        posY += 20;
      } else {
        ctx.fillText(`${r[6]}: ${r[5]}`, this.wCanvas - wInfo + 10, 25);
        posY += 20;
      }

      ctx.font = 'bold normal 16px Arial';
      const str3 = String(r[4]).length;
      if (str3 >= 32) {
        const arrSplitStr = r[4].split(' ');

        let strTemp = '';
        while (arrSplitStr.length) {
          const lenTemp = strTemp.length + arrSplitStr[0].length;

          if (lenTemp >= 32) {
            ctx.fillText(strTemp, this.wCanvas - wInfo + 10, posY);
            posY += 20;
            strTemp = '';
          }

          strTemp = `${strTemp}${arrSplitStr[0]} `;
          arrSplitStr.shift();
        }

        ctx.fillText(strTemp, this.wCanvas - wInfo + 10, posY);
        posY += 20;
      } else {
        ctx.fillText(`${r[4]}`, this.wCanvas - wInfo + 10, posY);
        posY += 20;
      }
    },
    roundRazrad(x) {
      let p = 10;
      let cntRazrad = 19;
      for (let i = 1; i < 19; i += 1) {
        if (x < p) {
          cntRazrad = i;
          break;
        }
        p *= 10;
      }

      let decimalRange = 1;
      for (let i = 0; i < cntRazrad - 1; i += 1) {
        decimalRange *= 10;
      }
      return (parseInt(x / decimalRange, 10) + 1) * decimalRange;
    },
    linkProd(e) {
      let len = this.rs.length;

      while (len > 0) {
        len -= 1;

        if (this.rectPointCollision([e.offsetX, e.offsetY], this.rs[len])) {
          if (this.rs[len][7] !== undefined) {
            this.$router.push(this.rs[len][7]);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.panel-bottom {
  display:flex;
  position: fixed;
  bottom:0px;
  left:0px;
  width: 100%;
  background: #cacaca;
  padding-right:12px;
  text-align: right
}
.panel-bottom .block-info {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 5px;
}
.sum-rub {
  text-align: right;
  background: #82b5ef;
  display: inline-block;
  padding: 2px 12px;
  color: rgb(255, 255, 255);
  font-size: 23px;
  float: right;
  line-height:30px;
}
</style>
