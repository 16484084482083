<template>
  <div class="wrp-table">
    <div v-for="(task, ind) in list_task" :key="ind"
      class="tr" :class="{ready: task.ended != ''}">
      <div class="td">{{list_task.length - ind}}</div>
      <div class="td">{{task.name}}</div>
      <div class="td">{{task.created}}</div>
      <div class="td">{{task.ended}}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MTask',
  data() {
    return {
      list_task: {},

      errors: [],
    };
  },
  created() {
    this.getTasks();
  },
  methods: {
    getTasks() {
      axios.get('/god/task/lst')
        .then((res) => {
          this.list_task = res.data.list;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
  },
};
</script>

<style scoped>
  .wrp-table{padding:10px 5%}
  .tr{display:flex; padding: 6px 4px; padding: 6px 4px; border: 1px solid #999;
    border-radius: 3px; margin-bottom:2px;
    cursor:default;
  }
  .tr:hover{background:#eee}
  .ready.tr:hover{background: #b9eab9;}
  .td{padding:2px 6px}
  .ready{background: #b9eab9; border: 1px solid #708c70}
</style>
