<template>
  <div class="block">
    <pre>{{list}}</pre>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AllPage',
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      axios.get('/god/all/orders-demo')
        .then((res) => {
          this.list = res.data.list;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
  .block{text-align:left; padding:12px; border: 1px solid #acbeca; display:inline-block;
    background: #d3e6f2;
    border: 1px solid #acbeca;
  }
  .block-title{font-size:18px; line-height:22px;}
  .block-list{padding:10px 0px 0px;}
  .block-elem{padding-bottom:4px;}

  .line-stick{display:inline-block; vertical-align:top; padding:0px 3px;}
</style>
