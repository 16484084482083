<template>
  <div class="form">
    <div class="form-header">
      <div class="form-title" v-if="title">{{title}}</div>
      <slot name="header"></slot>
    </div>
    <div class="form-body">
      <div v-for="(field,index) in curr_fields" :key="index">
        <div v-if="field.type == 'I'" class="form-field">
          <input-text v-model="field.value"
            :def="field.placeholder"
            :lbl="field.lbl"
            :err="field.error"
            :req="field.required"
            :ind="index"
            @outError="outError" />
        </div>
        <div v-if="field.type == 'P'" class="form-field">
          <input-pass v-model="field.value"
            :def="field.placeholder"
            :lbl="field.lbl"
            :err="field.error"
            :req="field.required"
            :ind="index"
            @outError="outError" />
        </div>
        <div v-if="field.type == 'C'" class="form-field">
          <Checkbox v-model="field.value" :text="field.placeholder" :err="field.error" />
        </div>
        <div v-if="field.type == 'L'" class="form-field">
          <SelectCustom v-model="field.value"
            :def="field.placeholder"
            :lbl="field.lbl"
            :options="field.options"
            :err="field.error" />
        </div>
        <div v-if="field.type == 'T'" class="form-field">
          <TextareaCustom v-model="field.value" :def="field.placeholder" :err="field.error" />
        </div>
      </div>
      <div class="wrp-spiners" style="background:#ddd" v-if="spiner">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <span class="spiner--text">Отправка...</span>
      </div>
      <template v-else>
        <a v-if="btn" @click="submitForm"
          @keydown.enter="submitForm"
          class="wb-btn wb-btn-success wb-btn-sh"
          style="width: auto">{{btn}}</a>
      </template>
    </div>
    <div class="form-footer">
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import SelectCustom from '@/components/atoms/Select.vue';
import InputText from '@/components/atoms/InputText.vue';
import InputPass from '@/components/atoms/InputPass.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextareaCustom from '@/components/atoms/Textarea.vue';

export default {
  name: 'FormCustom',
  props: ['title', 'btn', 'fields', 'action', 'method'],
  components: {
    SelectCustom, InputText, InputPass, Checkbox, TextareaCustom,
  },
  data() {
    return {
      curr_fields: this.fields,
      flag_no_err: true,
      errors: [],
      spiner: false,
    };
  },
  /*
  computed: {
    curr_fields() {
      return this.fields;
    },
  },
  */
  methods: {
    submitForm() {
      if (this.validateReq()) {
        if (this.action) {
          this.spiner = true;
          if (this.method === 'post') {
            axios.post(this.action, { form: this.curr_fields })
              .then((res) => {
                if (res.data.success === 1) {
                  this.$emit('answerForm', res.data);
                } else {
                  this.curr_fields = res.data.pack;
                }
                this.spiner = false;
              })
              .catch((e) => {
                this.errors.push(e);
              });
          } else {
            // GET-запрос тут
            this.spiner = false;
          }
        } else {
          this.$emit('answerForm', this.curr_fields);
        }
      } else {
        // не пройжена валидация
      }
    },
    validateReq() {
      this.flag_no_err = true;
      this.curr_fields.forEach((p, ind) => {
        if (p.required && p.value === '') {
          this.curr_fields[ind].error = 'Поле не должно быть пустым';
          this.flag_no_err = false;
        }
        if (p.value !== '') {
          this.curr_fields[ind].error = '';
        }
      });
      console.log(this.flag_no_err);
      return this.flag_no_err;
    },
    outError(ind) {
      this.curr_fields[ind].error = '';
    },
  },
};
</script>

<style scoped>
.form{
  text-align: left;
  padding-rigth: 0px;
  padding-left: 10px;
}
.form-field{
  margin-top: 12px;
  margin-bottom: 24px;
  max-width: 500px;
  min-width: 300px;
}
.wb-btn{width: 120px; margin-top: 0px}
</style>
