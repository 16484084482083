<template>
  <div class="home" style="text-align:left">
    404 страница
    <!--
    <div class="form-field">
      <SelectCustom v-model="form[0].value"
        :def="form[0].placeholder"
        :options="form[0].options1" />
    </div>
    <div class="form-field">
      <input-text v-model="form[1].value" :def="form[1].placeholder" />
    </div>
    <div class="form-field">
      <Checkbox v-model="form[2].value" :text="form[2].placeholder" />
    </div>
    <div class="form-field" style="width:320px;">
      <TextareaCustom v-model="form[3].value" :def="form[3].placeholder" />
    </div>
    <div class="form-field">
      <a class="wb-btn wb-btn-success">Отправить</a>
      <a class="wb-btn wb-btn-info">Отправить</a>
    </div>
    -->
  </div>
</template>

<script>
// @ is an alias to /src
// import SelectCustom from '@/components/atoms/Select.vue';
// import InputText from '@/components/atoms/InputText.vue';
// import Checkbox from '@/components/atoms/Checkbox.vue';
// import TextareaCustom from '@/components/atoms/Textarea.vue';

export default {
  name: 'HomePage',
  components: {
    // SelectCustom, InputText, Checkbox, TextareaCustom,
  },
  data() {
    return {
      form: [
        {
          type: 'S',
          value: 0,
          placeholder: 'Выберите что-то',
          options1: [
            {
              val: 0,
              lbl: 'ewerewr',
            },
            {
              val: 1,
              lbl: 'dfsdf',
            },
            {
              val: 2,
              lbl: 'dfsdf',
            },
            {
              val: 3,
              lbl: 'dfsdf',
            },
            {
              val: 4,
              lbl: 'dfsdf',
            },
            {
              val: 5,
              lbl: 'dfsdf',
            },
            {
              val: 6,
              lbl: 'dfsdf',
            },
            {
              val: 7,
              lbl: 'dfsdf',
            },
          ],
        }, {
          type: 'I',
          value: '',
          placeholder: 'Ваш телефон',
        }, {
          type: 'C',
          value: false,
          placeholder: 'Подтверждаю <a href="/">Можно перейти по этой ссылке</a>',
        }, {
          type: 'T',
          value: '',
          placeholder: 'Что нибудь впишите в эту область',
        },
      ],
    };
  },
};
</script>

<style scoped>
.form-field{margin-bottom:20px; }
.wb-btn{
  border-radius:4px; padding:12px 30px;
  color: #fff;
  cursor:pointer;
  display:inline-block;
}

.wb-btn-success{
  background: #098e04; /* Для старых браузеров - без градиента */
  background: -moz-linear-gradient(top,  #8bc465 0%, #098e04 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top,
  left bottom, color-stop(0%,#8bc465), color-stop(100%,#098e04));
  background: -webkit-linear-gradient(top,  #8bc465a 0%,#098e04 100%);
  background: -o-linear-gradient(top,  #8bc465 0%,#098e04 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #8bc465 0%,#098e04 100%); /* IE10+ */
  background: linear-gradient(to bottom, #8bc465 0%,#098e04 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc465',
  endColorstr='#098e04',GradientType=0 );
}
.wb-btn-success:hover{
  background: #8bc465; /* Для старых браузеров - без градиента */
  background: -moz-linear-gradient(top,  #098e04 0%, #8bc465 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top,
  left bottom, color-stop(0%,#098e04), color-stop(100%,#8bc465));
  background: -webkit-linear-gradient(top, #098e04 0%,#8bc465a 100%);
  background: -o-linear-gradient(top,  #098e04 0%,#8bc465 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #098e04 0%,#8bc465 100%); /* IE10+ */
  background: linear-gradient(to bottom, #098e04 0%,#8bc465 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#098e04',
  endColorstr='#8bc465',GradientType=0 );
}
.wb-btn-info{
  background: linear-gradient(to bottom, #65b7c4 0%,#045a8e 100%);
}
.wb-btn-info:hover{
  background: linear-gradient(to bottom, #045a8e 0%,#65b7c4 100%);
}
</style>
