<template>
  <div>
    <h1><span>Панель</span></h1>
  </div>
</template>

<script>

export default {
  name: 'DB',
};
</script>

<style scoped>
</style>
