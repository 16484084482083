<template>
  <div class="wb-input-text-short">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <label :for="id">
    <input
      :id="id"
      :class="{'error-input': err, 'req': req}"
      type="text"
      :placeholder="def"
      :value="modelValue"
      @input="onInput"
      @focus="onFocus" />
    </label>
    <transition>
      <span class="error" v-if="err">
        <i class="close" @click="onClose" @keydown.f7="onClose">&#10006;</i>
        {{err}}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: ['lbl', 'def', 'err', 'req', 'ind', 'modelValue'],
  data() {
    return {
      show_ext_lbl: false,
      id: null,
    };
  },
  mounted() {
    this.id = this.$.uid;
  },
  methods: {
    onInput(event) {
      if (event.target.value.length === 0) {
        // this.show_ext_lbl = false;
      } else {
        // this.show_ext_lbl = true;
        this.$emit('outError', this.ind);
      }
      this.$emit('update:modelValue', event.target.value);
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
  .wb-input-text-short{
    position:relative;
  }
  input {
    display:inline-block;
    width:100%;
/*    padding: 4px 6px;*/
    /* border-radius: 0 4px 4px 0px; */
    /* border: 1px solid #aaa; */
    /* border-left: 4px solid #aaa; */
    font-size: 12px;
    line-height: 18px;
    outline:none;
    box-sizing: border-box;
    border: 1px solid #dadee0;
    transition: all 0.3s;
/*    border: 6px solid #e6e6e6;*/
/*    border-top: 3px solid #e6e6e6;*/
/*    border-bottom: 3px solid #e6e6e6;*/
  }
  input:hover{
/*    background: #f6f4f4;*/
    border: 1px solid #6dc2ed;
  }
  input:focus{
    background: #f6f4f4;
    border: 1px solid #6dc2ed;
  }
  .ext-label{
    position: absolute;
    top: -10px;
    left: 8px;
    display: block;
    border: 1px solid #aaa;
    padding: 0px 6px;
    font-size: 12px;
    background: #efefef;
  }
  .inp-blue input{
    background-color: #e6ecf7;
    border: none;
    min-height: 42px;
  }
  .inp-green input{
    background-color: #c9edd5;
    border: none;
    min-height: 42px;
  }
  .label{
    position: absolute;
    background: #82b5ef;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }
  .req{border-left: 4px solid #2567f3;}
  .error{
    float: right;
    position: absolute;
    display: block;
    top: 36px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    background: #f3c9c9;
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
    padding: 3px 11px;
    color: #b33d3d;
  }
  .error-input{
    border: 1px solid #e57272;
    border-left: 4px solid #e57272;
  }
  .error-input::-webkit-input-placeholder {color: #e57272;}
  .error-input:-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input::-moz-placeholder {color: #e57272; opacity: 1;}
  .error-input:-ms-input-placeholder {color: #e57272;}
  .error-input::-ms-input-placeholder {color: #e57272;}
  .error-input::placeholder {color: #e57272;}
  .error .close{
    float: right;
    cursor: pointer;
    font-style: normal;
    margin-right: -11px;
    margin-top: -3px;
    display: block;
    width: 22px;
    height: 22px;
    text-align: center;
  }
</style>
