<template>
  <div class="scm">
    <h1><span>Отчет по надежности поставщиков</span></h1>
    <Filter v-if="success"
      :options = "filter"
      stick = "N"
      @change-field="showGraph"/>

    <div v-if="loader" class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
    <div v-show="!loader">
      <Diagramma
        v-show="dataGraph.length"
        :dataGraph = "dataGraph"
        :options = "diagramma"
        :mode = "mode" />

      <div v-show="dataGraph.length" style="padding: 0px 0px 0px 40px; overflow: hidden;">
        <table v-if="listRefillTimes.length" style="float: left; margin-right: 20px;">
          <tr>
            <th>Время пополнения</th>
            <th>Количество товаров</th>
          </tr>
          <tr v-for="(el, ind) in listRefillTimes" :key="ind">
            <td>{{el.rt}}</td>
            <td>{{el.cnt}}</td>
          </tr>
        </table>
        <div class="block-warning">
          <strong>Предлагаемое время пополнения:</strong> <div class="new-rt">{{newRt}}</div>
        </div>
      </div>

      <div v-show="dataGraph.length" v-if="debug" class="info-table">
        <br />
        <div class="btn-100"
          @click="showInfoClick"
          @keydown.f7="showInfoClick" >
          <i v-if="showInfo === false" style="float:right"
            class="fa fa-angle-double-down" aria-hidden="true"></i>
          <i v-if="showInfo === false" style="float:left"
            class="fa fa-angle-double-down" aria-hidden="true"></i>
          <i v-if="showInfo === true" style="float:right"
            class="fa fa-angle-double-up" aria-hidden="true"></i>
          <i v-if="showInfo === true" style="float:left"
            class="fa fa-angle-double-up" aria-hidden="true"></i>
          <div class="cent" style="text-align:center">Показать информацию по заказам</div>
        </div>
        <template v-if="showInfo">
          <div class="info-panel">
            <div class="info-element" v-for="(el, ind) in info" :key="ind">
              {{el.lbl}}: <strong>{{el.val}}</strong>
            </div>
          </div>
          <div class="info-table-tr" v-for="(tr, ind) in table" :key="ind">
            №{{tr.id}}
            <div class="info-table-td" v-for="(td, ind2) in tr.els" :key="ind2">
              <div class="line"
                :class="{done: td.cnt == td.cnt_ready}">
                  {{td.cnt_ready}} / {{td.cnt}}</div>
              <div class="line">{{td.name}}</div>
              <div class="line">
                <div class="date-cre">Создан: {{td.created_at}}</div>
                <div class="date-fin" v-if="td.finish != 0">Завершен: {{td.finish}}</div>
                <div class="days" v-if="td.days != 0">{{td.days}} дн.</div>
              </div>
            </div>
          </div>
        </template>
        <br />
        <br />
      </div>
      <div v-if="!dataGraph.length" class="text-warn-1" v-html="answer"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import Diagramma from '@/components/parts/Diagramma.vue';

export default {
  name: 'SupplierReliability',
  components: {
    Diagramma, Filter,
  },
  data() {
    return {
      debug: true,
      mode: 1,
      showInfo: false,
      table: [],
      info: [],
      newRt: 0,
      listRefillTimes: [],

      filter: {},
      dataGraph: [],
      diagramma: {},

      success: 0,
      answer: '',
      loader: true,
      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get('/scm/api/report/supplier-reliability')
        .then((res) => {
          this.filter = res.data.filter;
          this.dataGraph = res.data.diagramma.dataGraph;
          this.diagramma = res.data.diagramma;
          this.newRt = res.data.newRt;
          this.listRefillTimes = res.data.listRefillTimes;
          this.info = res.data.info;
          this.success = res.data.success;
          this.answer = res.data.answer;

          if (this.dataGraph.length > 80) {
            this.mode = 0;
          } else { this.mode = 1; }

          this.table = res.data.table;

          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          this.$router.push('/404');
        });
    },
    showGraph() {
      this.loader = true;
      setTimeout(() => {
        axios.post('/scm/api/report/supplier-reliability', {
          filter: this.filter,
        })
          .then((res) => {
            this.filter = res.data.filter;
            this.dataGraph = res.data.diagramma.dataGraph;
            this.diagramma = res.data.diagramma;
            this.newRt = res.data.newRt;
            this.listRefillTimes = res.data.listRefillTimes;
            this.info = res.data.info;
            this.success = res.data.success;
            this.sanswer = res.data.answer;

            if (this.dataGraph.length > 80) {
              this.mode = 0;
            } else { this.mode = 1; }

            this.table = res.data.table;

            this.loader = false;
          })
          .catch(() => {
            this.loader = false;
            this.$router.push('/404');
          });
      }, 50);
    },
    showInfoClick() {
      this.showInfo = !this.showInfo;
    },
    confirm() {
      alert('Заглушка для подтверждения операции');
    },
  },
};
</script>

<style scoped>
.block-warning{
  overflow: hidden;
  background: #efefef;
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 0px 10px;
  margin-top:2px;
}
td, th{
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.th{font-weight: bold;}

.btn-100{
  background: #547cb8;
  line-height: 32px;
  color: #fff;
  padding: 0px 15px;
  cursor: pointer;
}
.btn-100 i{
  line-height: 32px;
}
.btn-100:hover{
  background: #25508f;
}

.info-table-tr{
  margin-bottom: 2px;
  background: #ddd;
  padding: 4px;
}
.info-table-td{
  margin-left: 5px;
}
.info-table-td .line{
  display: inline-block;
  font-size: 14px;
  margin-right: 4px;
}
.info-table-td .line:first-child{
  background: #e6adad;
  padding: 0px 5px;
}
.info-table-td .line.done:first-child{
  background: #279a2e;
  color: #fff;
}
.date-cre, .date-fin, .line .days{
  display: inline-block;
}
.date-cre{
  background: #d1bcd0;
  padding: 0px 4px;
}
.date-fin{
  background: #9fc9a2;
  padding: 0px 4px;
}
.line .days{
  background: #adc9ff;
  padding: 0px 4px;
}
.new-rt{
  display: inline-block;
  vertical-align: top;
  font-size: 32px;
  margin-right: 12px;
  background: #ffb9b9;
  padding: 0px 12px;
}

.info-panel{
  padding: 10px;
  background: #bdd0ff;
}
.info-element{
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
