<template>
  <div class="tabs">
    <div class="tabs--btn">
      <a :class="{'active': curr_tab == 1}"
        href="javascript:void(0)"
        @click="curr_block(1)">Пользователи</a>
      <a :class="{'active': curr_tab == 2}"
        href="javascript:void(0)"
        @click="curr_block(2)">Роли</a>
      <a :class="{'active': curr_tab == 3}"
        href="javascript:void(0)"
        @click="curr_block(3)">Разрешения</a>
    </div>
    <div class="tabs--blocks">
      <transition-group name="list" tag="div">
        <div class="list-item" v-if="curr_tab == 1">
          <WBTable @act-table="actTable" @show-list="showList"
            @link-del="linkDel" :items="list_users" />
        </div>
        <div class="list-item" v-if="curr_tab == 2">
          <WBTable @act-table="actTable" @show-list="showList"
            @link-del="linkDel" :items="list_roles" />
        </div>
        <div class="list-item" v-if="curr_tab == 3">
          <WBTable @act-table="actTable" @show-list="showList" :items="list_permission" />
        </div>
      </transition-group>
    </div>
  </div>

  <div class="context-menu" v-if="listElement.show"
    @click="closeListElement"
    @keydown.f7="closeListElement">
    <div class="context-menu--body" @click.stop>
      <a v-for="(item, index) in listElement.items" :key="index"
        class="context-menu--item"
        @click.prevent="goActList(item.fields[0].lbl)"
        @keydown.f7.prevent="goActList(item.fields[0].lbl)">
        {{item.fields[0].val}}
      </a>
    </div>
  </div>

  <!-- временно, переделать в компонент и сделать обзий вывод ошибок -->
  <div class="context-menu" v-if="message.msg"
    @click="message.msg = ''"
    @keydown.f7="message.msg = ''">
    <div class="context-menu--body" @click.stop>
      <span class="res-message" :class="message.type">{{message.msg}}</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import WBTable from '@/components/atoms/WBTable.vue';

export default {
  name: 'AC',
  components: {
    WBTable,
  },
  data() {
    return {
      errors: [],
      curr_tab: 1,
      list_users: {},
      list_roles: {},
      list_permission: {},

      listElement: {
        items: [],
        val: '',
        act: '',
        show: false,
        index: 0,
        index2: 0,
      },

      message: {
        msg: '',
        type: '',
      },
    };
  },
  created() {
    this.getUsers();
    this.getRoles();
    this.getPerm();
  },
  methods: {
    curr_block(numb) {
      if (this.curr_tab !== numb) {
        this.curr_tab = numb;
      }
    },
    getUsers() {
      axios.get('/auth/user-lst')
        .then((res) => {
          this.list_users = res.data.pack;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getRoles() {
      axios.get('/auth/role-lst')
        .then((res) => {
          this.list_roles = res.data.pack;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    getPerm() {
      axios.get('/auth/perm-lst')
        .then((res) => {
          this.list_permission = res.data.pack;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    actTable(data) {
      if (data.t === 'chk') {
        if (this.list_users.items[data.i].act[data.i4].val === 0) {
          this.list_users.items[data.i].act[data.i4].val = 1;
        } else {
          this.list_users.items[data.i].act[data.i4].val = 0;
        }

        axios.post(data.act, { val: this.list_users.items[data.i].fields[0].val })
          .then((res) => {
            console.log(res.data.msg);
          })
          .catch(() => {
            this.$router.push('/404');
          });
      }

      console.log(data.act);
    },
    showList(data) {
      if (data.type === 'role') {
        this.listElement = {
          items: this.list_roles.items,
          val: data.val,
          act: '/auth/user-link-role',
          index: data.ind,
          index2: data.ind2,
        };
      } else if (data.type === 'perm') {
        this.listElement = {
          items: this.list_permission.items,
          val: data.val,
          act: '/auth/role-link-perm',
          index: data.ind,
          index2: data.ind2,
        };
      }
      this.listElement.show = true;
    },
    linkDel(data) {
      console.log(data);
      this.list_users.items[data.ind].fields[data.ind2].val.splice(data.ind3, 1);

      axios.post('/auth/user-unlink-role', { form: { val: data.role, id: data.val } })
        .then((res) => {
          if (res.data.success === 1) {
            this.message.type = 's';
          } else {
            this.message.type = 'e';
          }
          this.message.msg = res.data.msg;
          this.listElement.show = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      // this.list_roles.items[data.ind].fields[data.ind2].val.splice(data.ind3, 1);
    },
    // вынести в отдельный компонент
    /*
    showList(data) {
      this.listElement = data;
      this.listElement.show = true;
    },
    */
    goActList(val) {
      axios.post(this.listElement.act, { form: { val, id: this.listElement.val } })
        .then((res) => {
          if (res.data.success === 1) {
            // добавить к списку роль автоматом
            this.list_users.items[this.listElement.index]
              .fields[this.listElement.index2].val.push(res.data.elem);
            this.message.type = 's';
          } else {
            this.message.type = 'e';
          }
          this.message.msg = res.data.msg;
          this.listElement.show = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    closeListElement() {
      this.listElement.show = false;
    },
    // ******************************* //
  },
};
</script>

<style scoped>
.tabs{text-align:left;}
.tabs--btn{background: #eee;}
.tabs--btn a{
  display:inline-block;
  text-decoration:none;
  padding:12px;
  background:#ddd;
  border-top: 4px solid #999;
  color: #111;
  margin-right:2px;
  transition: border,background 1s;
}
.tabs--btn a:hover{background:#fff}
.tabs--btn a.active{background:#fff; border-color: #5390ef; font-weight:bold}

.tabs--blocks{position:relative}
.list-item {
  position: absolute;
  top:0px; left:0px;
  width:100%;
}
.list-enter-active, .list-leave-active {
  transition: all 0.3s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
