<template>
  <div class="scm">
    <h1>
      <span>Управление архивом продаж</span>
      <span style="float:right">{{cntAll}}</span>
    </h1>
    <transition name="fade">
      <div v-if="loaderGlobal" class="spinner">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
      <div v-else>
        <Filter v-if="filter.type"
          :options = "filter"
          @change-field="showList" />

        <div v-if="!loader" class="r">
          <table class="table-iface">
            <thead>
              <tr class="tr-head">
                <th v-for="(el, ind) in head" :key="ind"
                :title="el.lbltitle"
                :class="{hover: el.sort.show, active: el.sort.active > 0,
                  'btn-filter': ind === 0, 'btn-active': showFilter && ind === 0}"
                @click="sort(ind)">
                  <div v-if="ind == numberFieldCalc && infoData.length"
                    class="stick-head-dop-info">{{summWeight}} кг.</div>
                  <i v-if="el.sort.active == 1"
                    class="fa right"
                    :class="{
                      'fa-sort-amount-asc': el.sort.val == 0,
                      'fa-sort-amount-desc': el.sort.val == 1
                    }"
                    aria-hidden="true"></i>
                  <template v-if="ind === 0">
                  </template>
                  <template v-else>
                    <span v-if="el.type === 0">{{el.lbl}}</span>
                    <span v-if="el.type === 1" v-html="el.lbl" class="th-icon"
                      :style="{'color': el.color}" ></span>
                  </template>
                </th>
              </tr>
            </thead>
            <transition name="fade">
              <tbody v-if="!loaderBody && list.length">
                <tr v-for="(tr, ind) in list" :key="ind">
                  <td v-for="(el, ind2) in tr.fields" :key="ind2">
                    <template v-if="el.type === 'S'">
                      <router-link v-if="el.link" class="btn-field"
                        :to="el.link.url" :title="el.link.title" >
                        <i class="fa" :class="el.link.icon" aria-hidden="true"></i>
                      </router-link>
                      <span>{{el.value}}</span>
                    </template>
                    <template v-if="el.type === 'L'">
                      <router-link v-if="el.link" class="btn-field"
                        :to="el.link.url" :title="el.link.title" >
                        <i class="fa" :class="el.link.icon" aria-hidden="true"></i>
                      </router-link>
                      <router-link v-if="el.link" class="btn-a"
                        :to="{path: el.url, query: el.param}" :title="el.title" >
                        <span>{{el.value}}</span>
                      </router-link>
                    </template>
                    <div v-if="el.type === 'G'" style="position:relative">
                      <input-text v-model="el.value"
                        :def="el.placeholder"
                        class="l-margin"
                        @update:modelValue="toggleInstrumental" />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="text-warn-1">
                <tr><td colspan="11">Список пуст</td></tr>
              </tbody>
            </transition>
          </table>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="showForm" class="context-menu modal-form"
    @click="closeModal"
    @keydown.f7="closeModal">
    <div class="context-menu--body"  @click.stop >
      <div class="" style="width:600px;">
        <div v-if="answerText" class="answer-text">
          {{answerText}}
        </div>
        <template v-else>
          <div class="form-head">
            <div class="form-title">{{form.title}}</div>
          </div>
          <div v-if="errText" class="err-text">{{errText}}</div>
          <div class="form-body">
            На <strong>{{filter.left[0].value.lbl}}</strong>
            за <strong>{{dateCurr}}</strong><br />
            у <strong>{{chgArch.length}}</strong> SKU
          </div>
          <a class="wb-btn-sh wb-btn-info" style="margin-right:15px;"
            @click.prevent="chgArchSale"
            @keydown.f7.prevent="chgArchSale">Изменить данные</a>
          <a class="wb-btn-sh wb-btn-def"
            @click.prevent="closeModal"
            @keydown.f7.prevent="closeModal" >Отменить</a>
        </template>
      </div>
    </div>
  </div>

  <div class="instrumental" :class="{show: instrumental.length}">
    <a v-for="(instrument, ind) in instrumental" :key="ind"
      class="btn btn-add instrumental-item" style="float: right"
      @click.prevent="showForm = true"
      @keydown.f7.prevent="showForm = true">
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import InputText from '@/components/atoms/InputText.vue';

export default {
  name: 'ArchiveSale',
  components: {
    Filter, InputText,
  },
  data() {
    return {
      filter: {},
      list: [],
      loader: false,
      loaderGlobal: true,
      loaderBody: false,
      instrumental: [],

      cntAll: 0,
      showForm: false,
      chgArch: [],

      yesBtnChange: false,

      form: {
        title: 'Изменение архивных данных',
      },

      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateCurr() {
      const date = new Date(this.filter.right[0].value * 1000);
      const y = date.getFullYear();
      const m = this.filter.right[0].months[date.getMonth()];
      const d = date.getDate();

      return `${d} ${m} ${y}`;
    },
  },
  methods: {
    init() {
      this.loaderGlobal = true;
      axios.get('/scm/archive/sale')
        .then((res) => {
          if (res.data.success) {
            this.filter = res.data.filter;
            this.head = res.data.list.heads;

            res.data.list.items.forEach((item) => {
              this.list.push(item);
            });

            this.cntAll = res.data.cnt;
            this.instrumental = res.data.instrumental;
          }

          this.loaderGlobal = false;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    showList() {
      this.chgArch = [];
      this.yesBtnChange = false;
      this.loader = true;
      this.loaderFilter = true;

      setTimeout(() => {
        this.loadList(0);
      }, 50);
    },
    loadList(mode) {
      axios.post('/scm/archive/sale', {
        filter: this.filter,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.filter = res.data.filter;
            this.list = res.data.list.items;
            if (mode === 0) {
              this.head = res.data.list.heads;
            }
            this.cntAll = res.data.cnt;
            this.instrumental = res.data.instrumental;
          }
          this.loader = false;
          this.loaderBody = false;
        })
        .catch((e) => {
          this.loader = false;
          this.errors.push(e);
        });
    },
    toggleInstrumental() {
      let flagHide = true;
      let i = this.list.length;

      this.chgArch = [];
      while (i) {
        i -= 1;
        if (this.list[i].fields) {
          let j = this.list[i].fields.length;
          while (j) {
            j -= 1;
            if (this.list[i].fields[j].type === 'G') {
              if (this.list[i].fields[j].value !== '') {
                this.chgArch.push({
                  id: this.list[i].fields[0].value,
                  val: this.list[i].fields[j].value,
                  oldval: this.list[i].fields[2].value,
                });
                flagHide = false;
                // break;
              }
            }
          }
        }
      }

      if (!this.yesBtnChange) {
        if (this.chgArch.length > 0) {
          this.addBtn();
        }
      }

      if (flagHide) {
        this.instrumental = [];
        this.yesBtnChange = false;
      }
    },
    closeModal() {
      this.showForm = false;
      this.answerText = '';
    },
    addBtn() {
      const btn = {
        type: 1,
        icon: 'fa-sliders',
        lbl: 'Изменить назначенные продажи',
      };
      this.instrumental.push(btn);
      this.yesBtnChange = true;
    },
    chgArchSale() {
      axios.post('/scm/archive/sale-chg', this.chgArch)
        .then((res) => {
          if (res.data.success === 1) {
            this.answerText = res.data.answer;
            this.showList(this.filter);
            this.chgArch = [];
            this.instrumental = [];
            this.yesBtnChange = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
table{width: 100%;}

.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}
table th {
  position: sticky;
  top: 50px;
  background: white;
  z-index: 10;
  transition: width 1s;
  /* height: 40px; */
  white-space: nowrap;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 40px;
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: bottom;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.form-title{
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
}

.form-body{
  margin-bottom: 20px;
}

.form-field{
  margin-bottom: 20px;
}

.instrumental{
  height: 0px;
  /* overflow: hidden; */
  transition: all 0.5s;
}
.instrumental.show{
  height: 31px;
}
.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.filter{
  z-index: 11;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
