<template>
  <div class="wrp">
    <div class="r">
      <div v-for="(field, ind) in fields" :key="ind" :class="type">
        <div v-if="field.type == 'I'" class="form-field">
          <input-text v-model="field.value"
            :def="field.placeholder"
            :lbl="field.lbl"
            :err="field.error"
            @change="sendChg(ind, $event)" />
        </div>
        <div v-if="field.type == 'P'" class="form-field">
          <input-pass v-model="field.value"
            :def="field.placeholder"
            :lbl="field.lbl"
            :err="field.error"
            @change="sendChg(ind, $event)" />
        </div>
        <div v-if="field.type == 'C'" class="form-field">
          <Checkbox v-model="field.value"
            :text="field.placeholder"
            :lbl="field.lbl"
            @change="sendChg(ind, $event)" />
        </div>
        <div v-if="field.type == 'L'" class="form-field">
          <SelectCustom v-model="field.value"
            :def="field.placeholder"
            :lbl="field.lbl"
            :options="field.options"
            :err="field.error"
            @change="sendChg(ind, $event)" />
        </div>
        <div v-if="field.type == 'T'" class="form-field">
          <TextareaCustom v-model="field.value"
            :def="field.placeholder"
            :lbl="field.lbl"
            :err="field.error"
            @change="sendChg(ind, $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SelectCustom from '@/components/atoms/Select.vue';
import InputText from '@/components/atoms/InputText.vue';
import InputPass from '@/components/atoms/InputPass.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import TextareaCustom from '@/components/atoms/Textarea.vue';

export default {
  name: 'WBFormSync',
  components: {
    SelectCustom, InputText, InputPass, Checkbox, TextareaCustom,
  },
  props: ['id', 'type', 'action', 'items'],
  data() {
    return {
      spiner: false,
      errors: [],
    };
  },
  computed: {
    fields() {
      return this.items;
    },
  },
  methods: {
    sendChg(ind, event) {
      let data = {};
      if (this.id) {
        data = this.items[ind];
        data.id = this.id;
      }

      axios.post(this.action, data)
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(event.pageX, event.pageY, res.data.answer, 'ok');
          } else {
            this.answer(event.pageX, event.pageY, res.data.answer, 'error');
          }
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
  },
};
</script>

<style scoped >
.wrp{
  text-align:left;
  margin-top:20px;
}
.c-2{
  width: calc(100% / 2);
}
.r{margin-left: -10px; margin-right:-10px;}
.c-3{
  width: calc(100% / 3);
  display: inline-block;
  padding: 0px 10px;
  box-sizing: border-box;
  margin-bottom: 20px;

  position: relative;
  z-index: 2;
}
.c-3:hover{
  z-index: 3
}

.c-1{
  width: 100%;
  display: inline-block;
  padding: 0px 10px;
  box-sizing: border-box;
  margin-bottom: 20px;

  position: relative;
  z-index: 2;
}
.c-1:hover{
  z-index: 3
}
</style>
