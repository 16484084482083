<template>
  <div class="scm">
    <h1><span>Отчеты</span></h1>
    <div class="list" style="padding-top:12px;">
      <div class="list--elem" v-for="(el, ind) in list" :key="ind">
        <router-link :to="el.href"
          :title="el.title"
          class="list--link" >
          <i class="fa" :class="el.icon" aria-hidden="true"></i>
          {{el.lbl}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

/*
fa-area-chart
fa-bar-chart
fa-line-chart
*/
export default {
  name: 'ReportPage',
  data() {
    return {
      list: [
        {
          icon: 'fa-line-chart',
          lbl: 'По артикулу',
          title: 'Отчеты по артикулу',
          href: '/scm/report/articul',
        }, {
          icon: 'fa-area-chart',
          lbl: 'Инвестиции в товар',
          title: 'Инвестиции в товар',
          href: '/scm/report/invest-in-goods',
        }, {
          icon: 'fa-area-chart',
          lbl: 'По статусу буферов',
          title: 'Отчет по статусу запасов',
          href: '/scm/report/status-buffer',
        }, {
          icon: 'fa-area-chart',
          lbl: 'По дефицитам',
          title: 'Отчет по дефицитам',
          href: '/scm/report/deficit',
        }, {
          icon: 'fa-list-alt',
          lbl: 'Об изменении ЦУ',
          title: 'Отчет об изменении ЦУ',
          href: '/scm/report/change-cu',
        }, {
          icon: 'fa-line-chart',
          lbl: 'Потребление',
          title: 'Отчет об изменении ЦУ',
          href: '/scm/report/surpluses',
        }, {
          icon: 'fa-bar-chart',
          lbl: 'По ROI',
          title: 'Отчет по ROI',
          href: '/scm/report/roi',
        }, {
          icon: 'fa-line-chart',
          lbl: 'Потерянные рубле-дни прохода',
          title: 'Отчет потерянные рубле-дни прохода',
          href: '/scm/report/rub-day-pass',
        }, {
          icon: 'fa-line-chart',
          lbl: 'Рубле-дни запаса',
          title: 'Отчет рубле-дни запаса',
          href: '/scm/report/rub-day-store',
        }, {
          icon: 'fa-list-alt',
          lbl: 'Выведенные из ассортимента',
          title: 'Отчет о выводимых из ассортимента товарах',
          href: '/scm/report/output',
        }, {
          icon: 'fa-bar-chart',
          lbl: 'Надежность поставщиков',
          title: 'Отчет по надежности поставщиков',
          href: '/scm/report/supplier-reliability',
        }, {
          icon: 'fa-bar-chart',
          lbl: 'НЗП в производстве',
          title: 'Отчет НЗП в производстве',
          href: '/scm/report/in-way-now',
        }, {
          icon: 'fa-bar-chart',
          lbl: 'ЗП каждый день',
          title: 'Отчет ЗП каждый день',
          href: '/scm/report/in-way-every-day',
        }, {
          icon: 'fa-list',
          lbl: 'По обмену данными',
          title: 'Отчет по обмену данными',
          href: '/scm/report/logs-data-exchange',
        }, {
          icon: 'fa-list-alt',
          lbl: 'По остаткам',
          title: 'Отчет по остаткам',
          href: '/scm/report/show-cnt',
        },
      ],
    };
  },
  mounted() {
    this.setFilter();
  },
  methods: {
    setFilter() {
      /*
      axios.get('/scm/panel/graph-stocks')
        .then((res) => {
          if (res.data.success === 1) {
            this.filterSet = res.data.pack;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      */
    },
  },
};
</script>

<style scoped>
.list{}
.list--elem{
  display: inline-block;
  width: 50%;
  padding: 0 6px;
  text-align:center;
  margin-bottom: 12px;
  box-sizing: border-box;
}
.list--link{
  background: #ddd;
  color: #333;
  display: block;
  padding: 12px;
  transition: background .5s, color .5s;
}
.list--link:hover{
  background: #5981ce;
  color: #fff;
}
</style>
