<template>
  <div class="context-modal"
    @click="closeModal"
    @keydown.f7="closeModal">
    <div class="btn-close" @click.stop="closeModal" @keydown.f7="closeModal">
      <i data-v-8828ee9a="" class="fa fa-times" aria-hidden="true"></i>
    </div>
    <div class="context-modal--body right" :class="{ready: loadModal}" @click.stop >
      <template v-if="viewGroup.fields">
        <div class="view">
          <div class="view--title">Группа товаров</div>
          <div v-if="viewGroup.btns"  class="view--acts">
            <LoaderTreeSpin v-if="viewGroup.loader" />
            <template v-else>
              <div class="acts__item" v-for="(btn, ind4) in viewGroup.btns" :key="ind4">
                <div :title="btn.title"
                  @click.prevent.stop="actTable(btn, ind, $event)"
                  @keydown.enter.stop="actTable(btn, ind, $event)" >
                  <i class="fa" :class="btn.icon" aria-hidden="true"></i>
                </div>
              </div>
            </template>
          </div>
          <div class="view--main">
            <div class="view--main--title">Основной (заказываемый) товар: </div>
            <div class="view--main--el">
              <span>[{{viewGroup.fields[1].value}}] </span>
              {{viewGroup.fields[2].value}}
            </div>
          </div>
          <div class="view--els">
            <div class="view--els--title">Аналоги:</div>
            <div v-for="(el, ind) in viewGroup.childs" :key="ind"
              class="view--els--el">
              <span>[{{el.articul}}] </span>
              {{el.name}}
            </div>
          </div>
          <br />
          <div v-if="viewGroup.extinfo" class="ext-info">
            <div class="ext-info--title view--els--title">Инфо по складам</div>
            <table class="table-ext-info">
              <tr>
                <th>Склад</th>
                <th>Остаток</th>
                <th>В пути</th>
                <th>ЦУ</th>
                <th>буфер</th>
              </tr>
              <tr v-for="(el, ind) in viewGroup.extinfo" :key="ind">
                <td v-for="(el2, ind2) in el" :key="ind2"
                :class="{odd: (ind % 2) == 0, 'td-color': el2.type == 'SE'}">
                  <template v-if="el2.type == 'S'">
                    {{el2.val}}
                  </template>
                  <div v-if="el2.type == 'SE'" class="inner-color" :class="el2.color">
                    {{el2.val}}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';

export default {
  name: 'ModalGroup',
  props: ['viewGroup', 'loadModal'],
  components: {
    LoaderTreeSpin,
  },
  data() {
    return {
      show_ext_lbl: false,
      id: null,
    };
  },
  mounted() {
    // this.id = this.$.uid;
  },
  methods: {
    closeModal() {
      console.log('Закрыть окно');
      this.$emit('close-modal-group');
    },
  },
};
</script>

<style scoped>
.context-modal {
    position: fixed;
    background: #1e1e1ecf;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: inline-block;
    vertical-align: middle;
    /* line-height: 100vh; */
}

.context-modal{
  text-align: center;
}

.context-modal--body{background:#fff;
  overflow: hidden;
  width: 10%;
  height: 10%;
  transition: width 0.7s, height 0.7s;
  position: relative;
}

.context-modal--body {
    background: #fff;
    width: auto;
    display: inline-block;
    text-align: left;
    line-height: 20px;
    max-height: 100%;
    display: inline-block;
    vertical-align: middle;
    content: '';
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
}

/*
.context-modal--body.ready{
  width: 96%;
  height: 96%;
  overflow: auto;
}
*/
.context-modal--body .title{
  padding: 6px 12px 6px;
  background: #d6f5db;
  font-size: 22px;
  line-height: 24px;
}
.context-modal--body .anons{
  padding: 6px 12px 6px;
}

.fa-times, .fa-trash, .fa-chain-broken{color: #ce5959}
.fa-times:hover, .fa-trash:hover, .fa-chain-broken:hover{color: #ef3737}

.fa-paper-plane,
.fa-file-excel-o,
.fa-repeat{color: #277824}
.fa-paper-plane:hover,
.fa-file-excel-o:hover,
.fa-repeat:hover{color: #3fbd3a}

.fa-pencil, .fa-eye,
.fa-download,
.fa-upload{color: #2056bf}
.fa-pencil:hover,
.fa-eye:hover,
.fa-download:hover,
.fa-upload:hover{color: #5981ce}

.context-modal{
  text-align: right;
}

.context-modal--body.right{
  width: 0%;
  height: 100%;
  overflow: auto;
  padding: 10px;
  transition: width 0.5s;
}
.context-modal--body.ready.right{
  width: 50%;
  height: 100%;
}
.context-modal--body.ready.right .btn-close{
  position: absolute;
  top: 0px;
  right: auto;
  left: -26px;
}
.form--title{
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 10px;
}
.form--hint{
  background-color: #ddd;
}
.form--field{
  margin-bottom: 20px;
}
.form--field .form-field{
  margin-bottom: 20px;
}

.form--btn{
  display: inline-block;
  padding: 9px 12px;
  background-color: #60af80;
  color: #fff;
  transition: 0.8s;
}
.form--btn:hover{
  background-color: #3d895b;
}

.view{position: relative;}
.view--title{
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}
.view--acts{
  display: inline-block;
  vertical-align: top;
}
.view--els--el{
  background: #ddd;
  padding: 6px 5px;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 4px;
}
.view--els--title{
  font-weight: bold;
}
.view--main{
  margin-bottom: 10px;
}
.view--main--title{
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}
.view--main--el{
  font-size: 24px;
  line-height: 28px;
  background-color: #a6dbba;
  padding: 2px 4px 4px;
}

.table-ext-info {
  width: 100%;
  margin-bottom: 20px;
}
.table-ext-info th, .table-ext-info td{
  padding: 6px;
}

td.td-color{padding: 0px;}
.inner-color{padding: 6px;
background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,
                  transparent 0,
                  transparent 50%,
                  hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,
                  transparent 0,transparent);
}
.td-black{background-color: #757575; color: #fff;}
.td-red{background-color: #d9534f;}
.td-yellow{background-color: #f0ad4e;}
.td-green{background-color: #5cb85c;}
.td-blue{background-color: #5baade; color: #fff;}

.btn-close {
    position: absolute;
    top: 0px;
    right: 52%;
    color: #FFF;
    cursor: pointer;
    font-size: 30px;
    line-height: 32px;
}
</style>
