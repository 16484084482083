<template>
  <div class="scm">
    <h1>
      <span>Дашборд. Распределение запасов по магазинам</span>
      <span style="float:right">{{sortedList.length}}</span>
    </h1>
    <transition name="fade">
      <div v-if="loaderGlobal" class="spinner">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
      <div v-else>
        <table class="table-iface">
          <thead>
            <tr class="tr-head">
              <th v-for="(el, ind) in head" :key="ind"
              :title="el.lbltitle"
              :class="{hover: el.sort.show, active: el.sort.active > 0,
                'btn-filter': ind === 0, 'btn-active': showFilter && ind === 0}"
              @click="sort(ind)">
                <i v-if="el.sort.active == 1"
                  class="fa"
                  :class="{
                    'fa-sort-amount-asc': el.sort.val == 0,
                    'fa-sort-amount-desc': el.sort.val == 1
                  }"
                  aria-hidden="true"></i>
                <span v-if="el.type === 0">{{el.lbl}}</span>
                <span v-if="el.type === 1" v-html="el.lbl" class="th-icon"
                  :style="{'color': el.color}" ></span>
              </th>
            </tr>
            <tr class="table-filter" v-if="extfilter.length"
            :class="{'filter-hide': !showFilter, 'filter-show': showFilter}">
              <td v-for="(el, ind) in extfilter" :key="ind"
              style="padding: 0px;">
                <div v-if="ind === 0" class="wrp-icon-filter">
                  <i class="fa fa-filter big-icon" aria-hidden="true"></i>
                </div>
                <InputTextShort v-if="el.type == 'I' || el.type == 'S'"
                  v-model="el.value"
                  @change="chgExtFilter"
                  class="short-input-filter" />
              </td>
            </tr>
          </thead>
          <tbody v-if="sortedList.length">
            <tr v-for="(fields, ind) in sortedList" :key="ind">
              <td v-for="(el, ind2) in fields" :key="ind2"
                :class="{btnActive: el.active}"
                :style="{background: el.color}"
                @click="showItems(el, fields[0].value)">{{el.value}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="showProducts" class="context-menu modal-form"
        @click="closeModalProducts"
        @keydown.f7="closeModalProducts">
        <div class="context-menu--body" style="padding:0px !important;"  @click.stop >
          <!--
          <a class="btn-close-big" @click.prevent="closeModalProducts">
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
          -->
          <listFilterSort
            :action="products.action"
            :params="products.params"
            @closeOuterModal="closeModalProducts"
          ></listFilterSort>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import listFilterSort from '@/components/cross/listFilterSort.vue';
import InputTextShort from '@/components/atoms/InputTextShort.vue';

export default {
  name: 'PanelShop',
  components: {
    listFilterSort, InputTextShort,
  },
  data() {
    return {
      loader: false,
      loaderGlobal: true,
      list: [],
      head: [],
      sortIndex: 0,
      extfilter: [],
      filteredList: [],
      showFilter: true,

      showProducts: false,
      products: {},
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    sortedList() {
      const ind = this.sortIndex;

      if (this.head[ind]) {
        if (this.head[ind].sort.val) {
          if (this.head[ind].sort.typesort === 'N') {
            return this.filteredList.slice().sort((a, b) => (
              a[ind].sortval < b[ind].sortval ? 1 : -1
            ));
          }
          return this.filteredList.slice().sort((a, b) => (
            a[ind].value < b[ind].value ? 1 : -1
          ));
        }

        if (this.head[ind].sort.typesort === 'N') {
          return this.filteredList.slice().sort((a, b) => (
            a[ind].sortval > b[ind].sortval ? 1 : -1
          ));
        }
        return this.filteredList.slice().sort((a, b) => (
          a[ind].value > b[ind].value ? 1 : -1
        ));
      }
      return this.filteredList;
    },
  },
  methods: {
    init() {
      this.loaderGlobal = true;
      axios.get('/scm/panel/shop')
        .then((res) => {
          if (res.data.success) {
            this.list = res.data.list;
            this.head = res.data.head;
            this.filteredList = JSON.parse(JSON.stringify(res.data.list));
            this.sortIndex = 7;

            this.loaderGlobal = false;
          } else {
            this.loaderGlobal = false;
          }
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    toggleGraph(data) {
      this.graphOptions.legend[data.ind].show = data.val;
    },
    showItems(el, stockName) {
      if (!el.active) return;
      document.body.style.overflow = 'hidden';
      this.showProducts = true;
      el.stockName = stockName;

      this.products = {
        action: '/scm/panel/shop-products',
        params: el,
      };
    },
    closeModalProducts() {
      document.body.style.overflow = 'visible';
      this.showProducts = false;
    },
    sort(ind) {
      this.sortIndex = ind;
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 50px;
  background: white;
  z-index: 10;
  transition: width 1s;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  margin-right: 12px;
  margin-left: 12px;
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: botton;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.btnActive{
  cursor: pointer;
  transition: background-color 0.5s;
}
.btnActive:hover{
  background-color: #ddd !important;
}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #fff;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.btn-close-big{
  position: absolute;
  right: 20px;
  top: 4px;
  font-size: 30px;
  line-height: 30px;
  /* color: #780c0c; */
  color: #fff;
  cursor: pointer;
  transition: color 0.7s;
}
.btn-close-big:hover {
  color: #bf1111;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
