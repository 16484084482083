<template>
  <div class="wb-select" :class="{'error-input': err, 'req': req}">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <div class="wb-select-label"
      @click.stop="show=true"
      @keydown.f8.stop="show=true">
      <div class="icon-right">
        <i class="fa fa-arrows-alt" aria-hidden="true"></i>
      </div>
      <div class="val-text">{{modelValue.length ? `${modelValue.length} категории` : def}}</div>
    </div>
    <div class="select-collapse" :class="{active: show}"
      @click="show = false"
      @keydown.f9="show = false">
      <div class="inner-collapse" @click.stop="">
        <div v-if="def" class="caption">{{def}}</div>
        <ListIerarchDef
          :action="action"
          mode="selectmulty"
          :selectel="modelValue"
          @sel="selectOption"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListIerarchDef from '@/components/atoms/ListIerarchDef.vue';

export default {
  name: 'SelectIerarchMultyDef',
  components: {
    ListIerarchDef,
  },
  props: ['lbl', 'action', 'def', 'modelValue', 'err', 'req'],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    selectOption(el) {
      this.show = false;
      this.$emit('update:modelValue', el);
      this.$emit('change');
    },
    closeCollapse() {
      this.show = false;
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
.wb-select{
  position:relative;
  border-radius: 0 4px 4px 0;
  border: 1px solid #aaa;
  cursor: pointer;
  background: #fff;
  border-left: 4px solid #aaa;
  min-width: 200px;
}
.wb-select:hover{background:#ccc}
.wb-select-label{
  padding: 10px 12px;
  white-space: nowrap;
}
.wb-select-label i.fa{
  margin-top: 1px;
}
.wb-select-label:hover{
  padding-right: 10px;
}
.wb-select-label:hover i.fa{
  font-size: 18px;
}
.val-text{
  max-width: 200px;
  overflow: hidden;
}
.wb-select.w100 .val-text{max-width: 100%;}
.wb-select-label:hover{background:#efefef;}
.wb-select-list{
  position: absolute;
  left: -4px;
  top: 26px;
  margin-top: 10px;
  width: 100%;
  max-height: 250px;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0px 0px 4px 4px;
  overflow-y: auto;
  border-left: 4px solid #aaa;
  z-index: 3
}
.list--item{padding:8px 12px }
.list--item:hover{background:#ddd}

.label{
  position: absolute;
  background: #5981ce;
  left: 8px;
  top: -10px;
  font-size: 14px;
  line-height: 16px;
  padding: 1px 12px;
  border-radius: 0px;
  color: #fff;
}

/* W3C standard сейчас только для Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
}
/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #d7d7d7;
  width:3px;
}
*::-webkit-scrollbar-thumb {
  background-color: #848484;
/*  border-radius: 5px; */
  border: 2px solid #d7d7d7;
}
.icon-right{
  float: right;
  margin-left: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.req{border-left: 4px solid #2567f3;}
.error{
  float: right;
  position: absolute;
  display: block;
  top: 36px;
  left: -4px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  background: #f3c9c9;
  border: 1px solid #e57272;
  border-left: 4px solid #e57272;
  padding: 3px 11px;
  color: #b33d3d;
}
.error-input{
  border: 1px solid #e57272;
  border-left: 4px solid #e57272;
}
.error-input::-webkit-input-placeholder {color: #e57272;}
.error-input:-moz-placeholder {color: #e57272; opacity: 1;}
.error-input::-moz-placeholder {color: #e57272; opacity: 1;}
.error-input:-ms-input-placeholder {color: #e57272;}
.error-input::-ms-input-placeholder {color: #e57272;}
.error-input::placeholder {color: #e57272;}
.error .close{
  float: right;
  cursor: pointer;
  font-style: normal;
  margin-right: -11px;
  margin-top: -3px;
  display: block;
  width: 22px;
  height: 22px;
  text-align: center;
}

.select-collapse{
  position: fixed;
  height: 100%;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 20;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: row;
  /* margin-top: 10px; */
  background: #565656b8;
  display: none;
  cursor: pointer;
}
.select-collapse.active{
  display: flex;
}
.inner-collapse{
  min-width: 320px;
  max-height: 100%;
  background: #f5f5f5;
  padding: 15px 20px;
  overflow-y: auto;
  cursor: default;
  box-shadow: 0px 0px 8px #303030;
  position: relative;
}
.caption{
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}
</style>
