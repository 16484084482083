<template>
  <div class="info-sub-line">{{cnt}} / {{cnt2}}</div>
  <div style="position: relative;">
    <transition name="fade">
      <div v-if="loader" class="spinner">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
      <div v-else class="ierarch">
        <div :class="`level-${el.level}`" v-for="(el, ind) in items" :key="ind">
          <div class="item" v-if="el.show"
            @click="toggleChild(ind)"
            @keydown.right="toggleChild(0)">
            <div v-if="el.level > 0" class="squad-level"></div>
            <template v-if="el.child || dynamic && el.cnt != 0">
              <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
              <i v-else class="fa fa-folder" aria-hidden="true"></i>
            </template>
            <div v-else class="empty-squad"><i class="fa fa-folder" aria-hidden="true"></i></div>
            <span class="">{{el.lbl}} ({{el.cnt}})</span>
            <span class="lamp-green" :class="{active: el.gactive}"
              @click.stop="togglegroup(el, ind)"
              @keydown.j="togglegroup(0, 0)">группа 1</span>
            <!--{{el}}-->
            <div @click.stop="" class="prods" v-if="el.prods && el.prods.length && el.open">
              <div class="prod-el" v-for="(el2, ind2) in el.prods" :key="ind2">
                <i class="fa fa-cube" aria-hidden="true"></i>
                <div class="btns" v-if="el2.btns.length">
                  <div v-for="(btn, ind4) in el2.btns" :key="ind4"
                    @click.prevent.stop="actTable(btn, ind, ind2, $event)"
                    @keydown.f9="actTable(0, 0, 0, $event)"
                    :title="btn.title">
                      <i class="fa" :class="btn.icon" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col" v-for="(el3, ind3) in el2.fields" :key="ind3">
                  <template v-if="el3.type == 'S'">
                    <template v-if="ind3 == 1">[</template>
                    <span :class="{zhir: ind3 == 1}">{{el3.value}}</span>
                    <template v-if="ind3 == 1">]</template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-if="showContextForm" class="context-menu modal-form"
    @click="closeModal()"
    @keydown.ctrl="closeModal()">
    <div class="context-menu--body"  @click.stop >
      <template v-if="modeModal === 0">
        <div v-if="answerForm" class="form-answer">{{answerForm}}</div>
        <Form v-else :title="form.title"
          :btn="form.btn"
          class="center-center"
          :fields="form.fields"
          :action="form.act"
          :method="form.method"
          @answerForm="formAnswer" />
      </template>
      <template v-if="modeModal === 1">
        <div v-if="modalDopInfo">{{modalDopInfo}}</div>
        <WBManage @del-parent="orderDel"
          :action="actionModal"
          :actchange="actchangeModal"
        />
      </template>
      <template v-if="modeModal === 2">
        <div v-if="modalDopInfo">{{modalDopInfo}}</div>
        <WBManage @del-parent="orderDel" :action="actionModal" :actchange="actchangeModal" />
      </template>
    </div>
  </div>

  <div v-if="instrumental.length" class="instrumental">
    <a v-for="(instrument, ind) in instrumental"
      :key="ind" class="btn btn-add"
      @click="act(instrument.act)"
      @keydown.f8="act(0)">
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';
import Form from '@/components/atoms/Form.vue';

export default {
  name: 'ListIerarch',
  components: {
    Form,
  },
  props: ['action', 'dynamic', 'action2', 'actioncustom'],
  data() {
    return {
      items: [],

      level: 0,
      flag: 0,
      open: false,

      cnt: 0,
      cnt2: 0,
      showContextForm: false,
      answerForm: '',
      form: {
        title: '',
        btn: '',
        fields: [],
        action: '',
        method: 'post',
      },

      instrumental: [],
      errors: [],
      loader: true,
    };
  },
  computed: {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loader = true;
      axios.get(this.action)
        .then((res) => {
          if (res.data.success === 1) {
            this.items = res.data.list;
            if (res.data.cnt) {
              this.cnt = res.data.cnt;
            }
            if (res.data.cnt2) {
              this.cnt2 = res.data.cnt2;
            }
            if (res.data.instrumental) {
              this.instrumental = res.data.instrumental;
            }
          }
          this.loader = false;
        })
        .catch((e) => {
          this.loader = false;
          this.errors.push(e);
        });
    },
    toggleChild(ind) {
      if (this.dynamic) {
        if (this.items[ind].prods === undefined) {
          axios.get(this.action2, { params: { categoryID: this.items[ind].val } })
            .then((res) => {
              if (res.data.success === 1) {
                this.items[ind].prods = res.data.list;
              }
            })
            .catch((e) => {
              this.errors.push(e);
            });
        }
      }

      if (this.items[ind].child === false && this.dynamic === false) {
        return;
      }
      this.level = this.items[ind].level;
      this.flag = 0;
      this.open = this.items[ind].open;
      let BreakException;
      try {
        this.items.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              throw BreakException;
            }

            // изменить show
            if (this.open === true) {
              this.items[i].show = false;
              this.items[i].open = false;
            } else if ((this.level + 1) === item.level) {
              this.items[i].show = true;
            }
          }

          if (ind === i) {
            this.flag = 1;
            // изменить open
            this.items[i].open = !this.items[i].open;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    actTable(btn, ind, ind2, event) {
      if (btn.type === 'modalform') {
        this.actionType = 'chg';
        this.actionElem = ind;
        this.modeModal = 0;

        axios.get(btn.action, {
          params: {
            elem: this.items[ind].prods[ind2].fields[0].value,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.showContextForm = true;
              this.form = res.data.form;
            }
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else if (btn.type === 'modal') {
        this.modeModal = 1;
        this.showContextForm = true;
        this.modalDopInfo = btn.info;
        this.actionModal = `${btn.action}?elem=${this.items[ind].prods[ind2].fields[0].value}`;
      } else if (btn.type === 'link') {
        this.$router.push(`${btn.action}?elem=${this.items[ind].prods[ind2].fields[0].value}`);
      } else {
        this.items[ind].prods[ind2].loader = true;

        axios.post(btn.action, {
          elem: this.items[ind].prods[ind2].fields[0].value,
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.showAnswer(event, res.data.answer, 'ok');

              if (btn.items === 'add') {
                this.items.items.push(res.data.el);
              } else if (btn.items === 'del') {
                this.items.items.splice(ind, 1);
              } else if (btn.items === 'no') {
                console.log('no');
              } else if (!btn.nodel) {
                this.items.items.splice(ind, 1);
              }
              if (res.data.parent_del) {
                this.$emit('del-parent');
              }
              if (res.data.reload) {
                console.log('reload');
                this.init();
              }
            } else {
              this.showAnswer(event, res.data.answer, 'error');
            }
            console.log('откл');
            this.items.items[ind].loader = false;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    act(action) {
      this.modeModal = 0;
      this.actionType = 'add';
      axios.get(action)
        .then((res) => {
          if (res.data.success === 1) {
            this.showContextForm = true;
            this.form = res.data.form;
          } else {
            this.showContextForm = true;
            this.answerForm = res.data.answer;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    closeModal() {
      this.showContextForm = false;
      this.answerForm = '';
    },
    togglegroup(el, ind) {
      if (el.gactive) {
        this.items[ind].gactive = false;
      } else {
        this.items[ind].gactive = true;
      }

      if (this.actioncustom) {
        axios.post(this.actioncustom, {
          val: el.val,
        })
          .then((res) => {
            console.log(res.data);
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
  },
};
</script>

<style scoped>
.level-1{margin-left: 5px}
.level-2{margin-left: 25px}
.level-3{margin-left: 45px}
.level-4{margin-left: 65px}
.level-5{margin-left: 85px}
.level-6{margin-left: 105px}
.level-7{margin-left: 125px}
.level-8{margin-left: 145px}
.level-9{margin-left: 165px}
.level-10{margin-left: 185px}

.zhir{font-weight: bold; margin-right: 5px;}
.item{
  display: inline-block;
  cursor: pointer;
}
.item .fa-folder, .item .fa-folder-open{margin-right: 5px;}
.item .fa-folder{ color: green; }
.item .fa-folder-open{ color: #b91717; }
.item .empty-squad .fa-folder{ color: gray; }

.empty-squad{width: 12.5px; height:16px; display: inline-block; margin-right: 5px;}
.squad-level{
  width: 10px;
  height: 20px;
  border-left: 1px solid #000;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  position:relative;
}
.squad-level:before{
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #000;
  position: absolute;
  top: 36%;
  left: 0px;
}
.fa-cube{margin-right: 4px; color: #0065ff}
.r-cnt{position: absolute; right: 0px; top: -40px;}

.btns{float: right}
.btns a{margin-left: 10px;}
.col{display: inline-block;}
.prods{margin-left: 16px; margin-top:2px; margin-bottom: 2px;}

.prod-el{padding: 3px 0px 4px;}
.prod-el:hover{background: #dbdbdb;}

.lamp-green{
  font-size: 12px;
  margin-left: 3px;
  line-height: 25px;
  vertical-align: middle;
  color: #aaa;
}
.lamp-green:hover{
  color: #888;
}
.lamp-green.active{
  color: #088304;
  font-weight:bold;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #cfcfcf; padding: 3px}

.info-sub-line{
  float: right;
  margin-top: -42px;
  background: #5981ce;
  color: #fff;
  padding: 2px 10px;
}
</style>
