<template>
  <div class="wb-radio">
    <div v-if="lbl" class="label">{{lbl}}</div>
    <div v-for="(el,ind) in elements" :key="ind" class="one"
      @click="check(ind)"
      @keydown.f9="check(0)">
      <div class="squad">
        <div v-if="el.chk">&#10004;</div>
      </div>
      <span class="">{{el.lbl}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioCustom',
  props: [
    'lbl',
    'options',
    'modelValue',
  ],
  emits: ['update:modelValue'],
  data() {
    return {
    };
  },
  computed: {
    elements() {
      return this.options;
    },
  },
  mounted() {
    this.elements = this.options;
  },
  methods: {
    check(ind) {
      let len = this.elements.length;
      while (len > 0) {
        len -= 1;
        if (len === ind) {
          this.elements[len].chk = true;
        } else {
          this.elements[len].chk = false;
        }
      }

      this.$emit('update:modelValue', this.elements[ind]);
      this.$emit('change');
    },
  },
};
</script>

<style scoped>
  .wb-radio{
    border: 1px solid #aaa;
    border-left-width: 4px;
    padding: 18px 12px 6px;
    position: relative;
    max-width: 550px;
    min-width: 320px;
  }
  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 12px;
    line-height: 16px;
    padding: 0px 8px;
    border-radius: 0px;
    color: #fff;
  }
  .one{
    overflow: hidden;
    margin-bottom:6px;
  }

  .squad{
    width:18px;
    height:18px;
    border:1px solid #aaa;
    display: inline-block;
    cursor:pointer;
    text-align:center;
    line-height:19px;
    border-radius:2px;
    background: #fff;
    vertical-align: middle;
    margin-right: 4px;
  }
  .squad:hover{background:#efefef}
  .chk-lbl{margin-left:26px; line-height:21px;}

  .wb-radio.filter-radio{padding: 9px 12px 0px;}
  .filter-radio{min-width: auto;}
  .filter-radio .one{
    display:inline-block;
    margin-right: 9px;
    margin-bottom: 5px;
  }
  .filter-radio .one span{
    vertical-align: middle;
  }
</style>
