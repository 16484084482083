<template>
  <h1><span>Рубле-дни запаса</span></h1>

  <div v-if="!loaderFilter">
    <Filter
      :options = "filter"
      stick = "РУБ"
      @change-field="showGraph" />
  </div>

  <div v-if="loader" class="spinner">
    <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
  </div>
  <transition name="fade" >
    <div v-show="!loader">
      <WBChart
        v-show="graphData.length"
        :dataGraph = "graphData"
        :options = "graphOptions"
        @chg-options="toggleGraph"/>
      <div v-if="!graphData.length" class="text-warn-1">
        Нет данных по выбранным параметрам фильтра
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import WBChart from '@/components/parts/WBChart.vue';

export default {
  name: 'RubDS',
  components: {
    WBChart, Filter,
  },
  data() {
    return {
      urlAction: '/scm/api/report/rub-day-store-set',

      filter: {},
      graphData: [],
      graphOptions: {},

      loaderFilter: true,
      loader: true,
      errors: [],
    };
  },
  mounted() {
    if (localStorage.getItem('stock')) {
      this.urlAction += `?stockid=${localStorage.getItem('stock')}`;
    }
    this.init();
  },
  methods: {
    init() {
      axios.get(this.urlAction)
        .then((res) => {
          this.filter = res.data.filter;
          this.graphData = res.data.graphData;
          this.graphOptions = res.data.graphOptions;

          this.loaderFilter = false;
          this.loader = false;
        })
        .catch(() => {
          this.loaderFilter = false;
          this.loader = false;
          this.$router.push('/404');
        });
    },
    showGraph() {
      this.loader = false;
      this.loaderFilter = true;
      setTimeout(() => {
        if (this.filter.left[0].value.val) {
          localStorage.setItem('stock', this.filter.left[0].value.val);
        } else {
          localStorage.setItem('stock', this.filter.left[0].value);
        }

        axios.post('/scm/api/report/rub-day-store-set', {
          filter: this.filter,
        })
          .then((res) => {
            this.graphData = res.data.graphData;
            this.graphOptions = res.data.graphOptions;
            this.filter = res.data.filter;

            this.loaderFilter = false;
            this.loader = false;
          })
          .catch(() => {
            this.loaderFilter = false;
            this.loader = false;
            this.$router.push('/404');
          });
      }, 50);
    },
    toggleGraph(data) {
      this.graphOptions.legend[data.ind].show = data.val;
    },
  },
};
</script>

<style scoped>
.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
