<template>
  <div>
    <div
      v-for="(tab, ind) in tabs"
      :key="ind"
      :class="['btn-tab', { active: tab.show }]"
      @click="selTab(ind)"
      @keydown.f7="selTab(ind)"
    >
      {{ tab.lbl }}
    </div>
    <div class="tab-content">
      <template v-for="(tab, ind) in tabs" :key="ind">
        <component v-if="tab.show" :is="tab.name" v-bind="tab.options" class="tab"></component>
      </template>
    </div>

    <div v-if="instrumental.length" class="instrumental">
      <a v-if="0" style="float: right" class="btn btn-add instrumental-item">Скачать CSV</a>
      <a v-for="(instrument, ind) in instrumental" :key="ind"
        class="btn btn-add instrumental-item"
        @click.prevent="act(instrument)"
        @keydown.f7.prevent="act(instrument)">
        <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import WBManage from '@/components/molecules/WBManage.vue';
import WBFormSync from '@/components/molecules/WBFormSync.vue';
import CheckRule from '@/components/special/CheckRule.vue';

export default {
  name: 'ScmAutoorder',
  components: {
    WBManage, WBFormSync, CheckRule,
  },
  data() {
    return {
      tabs: [],
      instrumental: [],

      errors: [],
    };
  },
  mounted() {
    this.settIface();
  },
  methods: {
    settIface() {
      axios.get('/scm/order/auto-iface')
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
            this.instrumental = res.data.instrumental;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    selTab(ind) {
      this.loader = true;
      let len = this.tabs.length;
      while (len) {
        len -= 1;
        if (len === ind) {
          this.tabs[len].show = true;
        } else {
          this.tabs[len].show = false;
        }
      }
      this.loader = false;
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
    },
  },
};
</script>

<style scoped>
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 4px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-tab:hover{background:#eee;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
</style>
