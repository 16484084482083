<template>
  <div v-if="modeView == 0 && pagination.cnt && pagination.els.length > 1" class="pagination">
    <div v-for="(el, ind) in pagination.els" :key="ind"
      class="pag-el" :class="{active: pagination.page == el.lbl}"
      @click="pagLoad(el)"
      @keydown.f7="pagLoad(el)">{{el.lbl}}</div>
  </div>

  <transition name="fade">
    <LoaderTreeSpin v-if="loader" />
    <div v-else-if="modeView == 0">
      <div class="wrp-table">
        <table class="table-iface">
          <thead>
            <tr class="tr-head">
              <th v-for="(el, ind) in list.heads" :key="ind"
              :title="el.lbltitle"
              :class="{hover: el.sort.show, active: el.sort.active > 0}"
              @click="sort(ind)">
                <i v-if="el.sort.active == 1"
                  class="fa"
                  :class="{
                    'fa-sort-amount-asc': el.sort.val == 0,
                    'fa-sort-amount-desc': el.sort.val == 1
                  }"
                  aria-hidden="true"></i>
                <span>{{el.lbl}}</span>
              </th>
            </tr>
            <tr class="table-filter"
              :class="{'filter-hide': !showFilter, 'filter-show': showFilter}">
              <td :title="el.title" v-for="(el, ind) in tablefilter" :key="ind"
              style="padding: 0px;">
                <div v-if="ind === 0" class="wrp-icon-filter">
                  <i class="fa fa-filter big-icon" aria-hidden="true"></i>
                </div>
                <InputTextShort v-if="el.type == 'I'"
                  v-model="el.value"
                  @change="chgSubFilter"
                  class="short-input-filter" />
              </td>
            </tr>
          </thead>
          <transition name="fade">
            <tbody v-if="loaderBody" class="bg-loader">
              <tr><td colspan="12">
                <LoaderTreeSpin />
              </td></tr>
            </tbody>
            <tbody v-else-if="list.length">
              <tr v-for="(tr, ind) in list" :key="ind" :class="{disabled: tr.disabled == 1}">
                <td v-for="(elem, ind2) in tr.fields" :key="ind2">
                  <template v-if="elem.type === 'H'">
                    <button v-if="elem.btn && elem.btn.icon" class="btn-group"
                      @click="showGroup(ind, elem.value)">
                      <i class="fa" :class="elem.btn.icon" aria-hidden="true"></i>
                    </button>
                  </template>
                  <template v-if="elem.type === 'S'">
                    <router-link v-if="elem.link" class="btn-field"
                      :to="elem.link.url" :title="elem.link.title" >
                      <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                    </router-link>
                    <i v-if="elem.icon && parseInt(elem.value) > 0" class="icon-last-status fa"
                      :class="elem.icon.fa"
                      :title="elem.icon.title" ></i>
                    <span>{{elem.value}}</span>
                  </template>
                  <template v-if="elem.type === 'L'">
                    <router-link v-if="elem.link" class="btn-field"
                      :to="elem.link.url" :title="elem.link.title" >
                      <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                    </router-link>
                    <a href="javascript:void(0)" @click.prevent="showArticleBtn(elem)"
                      class="btn-a">{{elem.value}}</a>
                  </template>
                  <template v-if="elem.type === 'B'">
                    <div class='progress progress-striped'>
                      <div class='progress-bar' :class="elem.opt.color"
                        role='progressbar' :aria-valuenow='elem.lbl'
                        aria-valuemin='0' aria-valuemax='100'
                        :style='{width: elem.opt.w_block}'>
                        <span class='sr-only'>{{elem.lbl}}</span>
                      </div>
                      <div v-if="elem.opt.color_text"
                        class="buffer-lbl"
                        :class="elem.opt.color_text" >{{elem.value}} %</div>
                    </div>
                  </template>
                  <template v-if="elem.type === 'M'">
                    <div v-if="elem.link.disabled == 0" :title="elem.title"
                      class="btn-cart"
                      @click="showModal(elem.link.param, ind)"
                      @keydown.f7="showModal(elem.link.param, ind)">
                      <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                    </div>
                    <span class="btn-cart disabled" v-else>
                      <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                    </span>
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else class="text-warn-1">
              <tr><td colspan="12">Список пуст</td></tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>
    <div v-else>
      <table class="setka-sizes">
      <thead>
        <th class="block-head">Наименование</th>
        <th v-for="(el, ind) in headSetkaProducts" :key="ind"
          class="block-head">{{el}}</th>
      </thead>
      <tbody>
        <tr v-for="(el, ind) in listSetkaProducts" :key="ind">
          <td class="block-head-line"
            :class="{new: el.new = 1}">
            <i class="name-stick" v-if="el.new == 1">НОВЫЙ</i>
            {{el.name}}
          </td>
          <td v-for="(head, ind2) in headSetkaProducts" :key="ind2">
            <div v-if="el.sizes[head]"
              class="block-cell no-empty"
              :class="el.sizes[head].buff.opt.color"
              @click="showArticleBtn(el.sizes[head])"
              @keydown.f7="setkaDetail(el.sizes[head].id)"
              >
              {{el.sizes[head].buff.value}}%
            </div>
            <div v-else class="block-cell block-empty"></div>
          </td>
        </tr>
      </tbody>
      </table>
    </div>
  </transition>

  <div v-if="showFormOrder" class="context-menu modal-form"
    @click="closeModal"
    @keydown.f7="closeModal">
    <div class="context-menu--body"  @click.stop >

    </div>
  </div>

  <div v-if="instrumental.length" class="instrumental">
    <a v-for="(instrument, ind) in instrumental" :key="ind"
      class="btn btn-add instrumental-item"
      @click.prevent="act(instrument)"
      @keydown.f7.prevent="act(instrument)">
      <i class="{{instrument.icon}}" aria-hidden="true"></i> <span>{{instrument.lbl}}</span>
    </a>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ListManage',
  props: {
    action: {
      type: String,
      default: '',
    },
    params: {
      type: Array,
      default: () => [],
    },
  },
  components: {
  },
  data() {
    return {
      // l_curr_stock: localStorage.getItem('stock'),

      list: {
        heads: [],
        items: [],
        pagination: [],
      },
      instrumental: [],

      showFormOrder: false,
      modalProducts: [],
      modalGenProduct: {},
      modalBuffer: {},
      modalStock: '',

      showFilter: false,

      currParam: {},
      currProduct: {},
      currIndex: [],

      pagination: {
        cnt: 0,
        els: [],
        page: 1,
      },

      modeView: 0,
      headSetkaProducts: [],
      listSetkaProducts: [],
      tabsView: [],

      answerText: '',
      errText: '',
      errors: [],

      loader: true,
      loaderFilter: true,
      loaderBody: false,

      cntAll: 0,

      showArticle: false,

      viewGroup: {},
      loadModal: false,
      showModalGroup: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
  },
  methods: {
    init() {
      this.loader = true;

      axios.get(this.action, { params: this.params })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list;

            this.instrumental = res.data.instrumental;
          }
          this.loader = false;
          this.loaderFilter = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    pagLoad(el) {
      if (this.pagination.page === el.lbl) { return; }

      this.pagUrlActive = el.val;

      this.loaderBody = true;

      setTimeout(() => {
        this.loadList(1);
      }, 300);
    },
    showList() {
      this.loader = true;
      this.loaderFilter = true;
      this.pagUrlActive = '/scm/product/sett-iface';
      setTimeout(() => {
        this.loadList(0);
      }, 300);
    },
    showModal(param, ind) {
      this.currIndex = ind;
      this.currParam = param;
      axios.get('/scm/product/form-order', {
        params: param,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.modalStock = res.data.stock;
            this.currProduct = res.data.genProduct;
            this.modalProducts = res.data.products;
            this.modalBuffer = res.data.buffer;
            this.modalGenProduct = res.data.genProduct;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });

      this.showFormOrder = true;
    },
    closeModal() {
      this.showFormOrder = false;
      this.answerText = '';
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
    },
    loadList(mode) {
      if (this.filter.left[0].value.val) {
        localStorage.setItem('stock', this.filter.left[0].value.val);
      } else {
        localStorage.setItem('stock', this.filter.left[0].value);
      }

      axios.post(this.pagUrlActive, {
        filter: this.filter,
        dopfilter: { arr: this.tablefilter },
        sort: { arr: this.head },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.filter = res.data.filter;
            this.list = res.data.list.items;
            if (mode === 0) {
              this.tablefilter = res.data.list.filter;
              this.head = res.data.list.heads;
            }

            this.headSetkaProducts = res.data.headSetkaProducts;
            this.listSetkaProducts = res.data.listSetkaProducts;

            this.pagination = res.data.pagination;
          }
          this.loader = false;
          if (mode !== 1) {
            this.loaderFilter = false;
          }
          this.loaderBody = false;
        })
        .catch((e) => {
          this.loader = false;
          if (mode !== 1) {
            this.loaderFilter = false;
          }
          if (mode !== 2) {
            this.loaderBody = false;
          }
          this.errors.push(e);
        });
    },
    chgSubFilter() {
      this.loaderBody = true;
      this.pagUrlActive = '/scm/product/sett-iface';
      this.loadList(2);
    },
    sort(ind) {
      if (!this.head[ind].sort.show) {
        if (ind === 0) {
          this.showFilter = !this.showFilter;
        }
        return;
      }
      if (this.head[ind].sort.show) {
        this.loaderBody = true;
      }
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
        this.pagUrlActive = '/scm/product/sett-iface';
        this.loadList(1);
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
        this.pagUrlActive = '/scm/product/sett-iface';
        this.loadList(1);
      }
    },
    showArticleBtn(el) {
      this.showArticle = true;
      document.body.style.overflowY = 'hidden';

      this.article.linkUrl = el.url;
      this.article.linkParam = el.param;

      const filterArticul = {
        left: [
          {
            value: {
              val: el.param.stock,
            },
          },
          {
            value: el.param.prod,
          },
        ],
      };

      axios.post('/scm/api/report/articul', { filter: filterArticul })
        .then((res) => {
          if (res.data.success === 0) {
            this.$router.push('/login');
          } else {
            this.article.graphData = res.data.graphData;
            this.article.graphOptions = res.data.graphOptions;
            this.article.answer = res.data.answer;

            this.article.info = res.data.info;
            this.article.info2 = res.data.info2;
            this.article.info3 = res.data.info3;
            this.article.info4 = res.data.info4;

            this.article.configLink = res.data.configLink;
          }
        })
        .catch(() => {
        });
    },
    toggleGraph(data) {
      this.article.graphOptions.legend[data.ind].show = data.val;
    },
    closeModalArticle() {
      document.body.style.overflow = 'visible';
      this.showArticle = false;
    },
  },
};
</script>

<style scoped>
.wrp-info-line {
  text-align: left;
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  background: #ebf6ff;
}
.wrp-info-line .title {
  font-weight: bold;
  font-size: 18px;
  padding-left: 8px;
  padding-top: 8px;
  margin-bottom: 8px;
}

.wrp-info-line table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-info-line table td, .wrp-info-line table th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.wrp-info-line table th{
  background: transparent;
}

.wrp-info-line .table-title th {border-top: 0px;}
.wrp-info-line table tr:hover td {
  background: #eee;
}

.wrp-text-warn-1{
  padding-top: 34px;
  background: #fff;
  padding-bottom: 56px;
}

.right-top-icons{
  margin-top: -20px;
  z-index: 1;
  position: relative;
  background: #bbb;
  float: right;
}
.flag-line{
  display: inline-block;
  vertical-align: top;
}
.right-top-icons .flag-line{
  margin: 0px 0px 0px 2px;
  background: #efefef;
  padding: 4px 8px 2px;
  text-align: center;
  min-width: 30px;
  cursor: default;
}
.right-top-icons .flag-line > div {
  display: inline-block;
}
.icon-head{margin-right: 12px;}

.flag-line.blue,
.flag-line.green,
.flag-line.yellow,
.flag-line.red,
.flag-line.black,
.flag-line.gray{
  background-image: linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,
                    transparent 0,
                    transparent 50%,
                    hsla(0,0%,100%,.15) 0,
                    hsla(0,0%,100%,.15) 75%,
                    transparent 0,
                    transparent);
  color: #fff;
}
.flag-line.blue{ background-color: #5baade;}
.flag-line.green { background-color: #5cb85c;}
.flag-line.yellow { background-color: #f0ad4e; color: #000;}
.flag-line.red { background-color: #d9534f;}
.flag-line.black { background-color: #757575;}

.flag-line.new{ background: #e7e992; color: #136e23; }

.block-head{
  background: #e6e6e6;
  padding: 6px 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}
.block-head-line{
  font-size: 14px;
  font-weight: bold;
  padding-left: 6px;
  padding-right: 6px;
  padding-top:4px;
  line-height: 18px;
  position: relative;
}
.block-head-line.new{
  background-color: #e7e992;
}
.name-stick{
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 8px;
  line-height: 10px;
  font-weight: bold;
  color: #136e23;
}

.block-head-line{
  position: sticky;
  left: 0;
  background-color: #ffffff;
}

.block-cell{
  padding: 8px 8px;
  text-align: center;
  font-size: 14px;
}
.block-cell.no-empty{
  cursor: pointer;
  transition: 0.5s opacity;
}
.block-cell.no-empty:hover{
  opacity: 0.8;
}
table.setka-sizes{
  border-spacing: 0px;
  border-collapse: collapse;
  margin-bottom: 40px;
}
table.setka-sizes tbody td{
  min-width: 65px;
  border-bottom: 1px solid #ccc;
}

.setka-sizes .progress-bar-success{ background: #a8e0a8;}
.setka-sizes .progress-bar-danger{ background: #fbb4b2;}
.setka-sizes .progress-bar-info{ background: #9cd8ff; }
.setka-sizes .progress-bar-warning { background: #ffdeb0;}
.setka-sizes .progress-bar-black { background: #b0b0b0; }

.block-empty{
  border: 22px solid #e4e4e4; /* $c1c1c1 */
  border-bottom: 12px solid #dadada;
  border-left: 43px solid #dadada;
  width: 0px;
  height: 0px;
  padding: 0px;
}

.btn-view{
  float: right;
  padding: 2px 10px;
  margin-right: 4px;
  line-height: 23px;
  background-color: #989898;
  cursor: pointer;
  transition: 0.5s background-color;
}
.btn-view.active{
  background: #1f912d;
}
.wrp-graph{
  padding-left: 10px; padding-right: 10px;
  background: white;
}
.head-graph{
  padding-bottom: 20px;
  background: white;
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}

.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 50px;
  background: white;
  z-index: 10;
  transition: width 1s;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.btn-active{
  color: #5981ce;
}
.tr-head span{
  margin-right: 15px;
}
.right{
  float: right;
}
.modal-form{
  text-align: center;
}
.wrp-table{
  margin-bottom: 40px;
}
.short-input{
  width:100px;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.icon-last-status{
  font-size: 22px;
  font-weight: bold;
  padding-right: 6px;
}

.btn-close{
  float: right;
  font-size: 20px;
  color: #780c0c;
  cursor: pointer;
}
.btn-close:hover {
  color: #bf1111;
}

.btn-close-big{
  position: absolute;
  right: 20px;
  top: 4px;
  font-size: 30px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
}
.btn-close-big:hover {
  color: #bf1111;
}

.err-text{
  margin-top: -18px;
  margin-bottom: 10px;
  color: #b34040;
  background: #ffdfdf;
  padding: 4px;
}

.block .column{
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  margin-right: 12px;
  margin-left: 12px;
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: botton;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.btn-a{color: #556b7d}
.btn-a:hover {color: #2f4a60; text-decoration: underline;}

.btn-cart{
  display: block;
  width: 100%;
  background: #44b752;
  border: 1px solid #2c9539;

  cursor:pointer;
  color:#fff;
  text-align: center
}
.btn-cart:hover{background: #2c9539}
.btn-cart.disabled{
  background: #a4e5ac;
  border-color: #ddd;
  position: relative;
}

.btn-cart.disabled:after{
  content: '';
  position: absolute;
  top: 5px;
  left: 40%;
  margin-left: -15px;
  width: 60px;
  height: 30px;
  border-top: 2px solid red;
  transform: rotate(-40deg);
}

tr.disabled {
  opacity: 0.5;
}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
    background-color: #5baade;
}

.progress-bar-black {
    background-color: #757575;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-bar-danger {
    background-color: #d9534f;
}

.progress-bar-success {
    background-color: #5cb85c;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}

.buffer-lbl{
  position: absolute;
  width: 100%;
}

.btn-filter{
  text-align: center;
}
.btn-group{
  cursor: pointer;
  border: none;
  padding: 0px;
  font-size: 24px;
  color: #4a975b;
}
</style>
