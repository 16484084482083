<template>
  <div class="wb-select">
    <div class="label" v-if="lbl">{{lbl}} ({{allChecked}} / {{elements.length}})</div>
    <div class="list">
      <div class="list--item one" v-for="(el, ind) in elements" :key="ind"
        @click="check(ind)"
        @keydown.f9="check(0)">
        <div class="squad">
          <span v-if="el.chk">&#10004;</span>
        </div>
        <span>{{el.lbl}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: ['lbl', 'options', 'def', 'modelValue', 'err'],
  data() {
    return {
    };
  },
  computed: {
    elements() {
      return this.options;
    },
    allChecked() {
      let len = this.elements.length;
      let cnt = 0;
      while (len > 0) {
        len -= 1;
        if (this.elements[len].chk) {
          cnt += 1;
        }
      }

      return cnt;
    },
  },
  methods: {
    check(ind) {
      if (this.elements[ind].chk) {
        this.elements[ind].chk = false;
      } else {
        this.elements[ind].chk = true;
      }

      this.$emit('update:modelValue', this.elements[ind]);
      this.$emit('change');
    },
  },
};
</script>

<style scoped>
  .wb-select{
    position:relative;
    border-radius: 0 4px 4px 0;
    border: 1px solid #aaa;
    cursor: pointer;
    background: #fff;
    border-left: 4px solid #aaa;
    min-width: 200px;
    padding-top:20px;
    height: 92%;
  }
  .wb-select:hover{background:#efefef}
  .wb-select-label{
    padding: 10px 12px;
    white-space: nowrap;
  }
  .val-text{
    max-width: 200px;
    overflow: hidden;
  }
  .wb-select-label:hover{background:#efefef;}
  .list{height:100%; overflow-y:auto}
  .list--item{padding:4px 12px 2px;}
  .list--item:hover{background:#ddd}

  .label{
    position: absolute;
    background: #5981ce;
    left: 0px;
    top: 0px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }

  .one{
    overflow: hidden;
  }

  .squad{
    width:18px;
    height:18px;
    border:1px solid #aaa;
    display: inline-block;
    cursor:pointer;
    text-align:center;
    line-height: 19px;
    border-radius:2px;
    background: #fff;
    vertical-align: middle;
    margin-right: 4px;
    float: left;
  }
  .squad:hover{background:#efefef}

  .squad + span{
    display: block;
    margin-left: 26px;
    line-height: 22px;
  }

  /* W3C standard сейчас только для Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
  }
  /* для Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d7d7d7;
    width:3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #848484;
  /*  border-radius: 5px; */
    border: 2px solid #d7d7d7;
  }
  .icon-right{
    float: right;
    margin-left: 5px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
