<template>
  <div v-if="!loader" class="block" >
    <div class="link" v-for="(el, ind) in list" :key="ind"
      @click="run"
      @keydown.f7="run">{{el.name}}</div>
  </div>
  <div v-else class="text-info-1">
    Выполнение скрипта может занять до 5 минут, не закрывайте страницу
    &nbsp;<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    &nbsp;<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
    &nbsp;<i class="fa fa-cog fa-spin" aria-hidden="true"></i>
  </div>
  <div @click="testCreSubCompany"
    @keydown.f7="testCreSubCompany"
    class="run-btn">Тестовое создание зависимой компании</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CronList',
  data() {
    return {
      errors: [],
      loader: false,
      list: [
        {
          val: 1,
          name: 'Демо из списка таблицы базы',
          action: '',
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    run() {
      if (!this.loader) {
        this.loader = true;
        axios.get('/god/all/auto-demo-cre')
          .then((res) => {
            this.loader = false;
            this.list = res.data.list;
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },
    testCreSubCompany() {
      this.loader = true;
      axios.post('/god/all/test-sub-company-cre')
        .then((res) => {
          console.log(res.data);
          this.loader = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
  .block{
    padding: 12px;
    background: #ddd;
  }
  .link{
    cursor: pointer;
    color: #333;
    text-decoration: underline;
  }
  .link:hover{
    text-decoration: none;
  }
  .run-btn{
    margin-top: 30px;
    height: 24px;
    line-height: 24px;
    background-color: #ddd;
    padding: 4px 10px;
    cursor: pointer;
    display: inline-block;
  }
  .run-btn:hover {
    background-color: #aaa;
  }
</style>
