<template>
  <transition name="fade">
    <div v-if="!globalLoader" class="scm" v-html="context"></div>
    <div v-else class="spinner">
      <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserGuide',
  data() {
    return {
      globalLoader: true,

      context: '',
      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const url = '/scm/help/user-guide';
      axios.get(url)
        .then((res) => {
          if (res.data.success === 1) {
            this.context = res.data.html;
          }
          this.globalLoader = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.globalLoader = false;
        });
    },
  },
};
</script>

<style scoped>

.scm img{
  max-width: 100%;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
