import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
// import FontAwesomeIcon from './plugins/font-awesome';

// axios.defaults.baseURL = 'http://scm2.my/';
// axios.defaults.baseURL = 'https://scm2.wa-scm.ru/';
axios.defaults.baseURL = 'https://scm3.wa-scm.ru/';

const user = localStorage.getItem('user');
if (user) {
  axios.defaults.headers.common.Authorization = `Bearer ${user}`;
}

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      router.push('Login');
    } else if (error.response.data.message) {
      alert(error.response.data.message);
    }
  } else {
    console.log('Error', error.message);
  }
  throw error;
});

const clickOutside = {
  beforeMount: (el, binding) => {
    const theElement = el;
    theElement.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
  /*
  bind: (el, binding, vnode) => {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: (el) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
  */
};

createApp(App)
  .use(store)
  .use(router)
  .directive('click-outside', clickOutside)
  // .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
