<template>
  <div class="wb-select" v-click-outside="closeSelect">
    <div class="label" v-if="data.lbl">{{data.lbl}}</div>
    <div class="wb-select-label"
      @click.stop="showList = !showList"
      @keydown.enter.stop="showList = !showList">
      <div class="icon-right">
        <template v-if="showList">
          <i class="fa fa-chevron-up"></i>
        </template>
        <template v-else >
          <i class="fa fa-chevron-down"></i>
        </template>
      </div>
      <div class="val-text">{{modelValue ? modelValue.lbl : data.placeholder}}</div>
    </div>
    <transition name="fade">
      <div class="wb-select-list" v-if="showList">
        <div class="list--item" v-for="(option, index) in data.options" :key="index"
          @click="selectOption(option)"
          @keydown.f7="selectOption(option)">{{option.lbl}}</div>
      </div>
    </transition>
  </div>
  <template v-if="modelValue.val > 0">
    <div class="tab" v-if="modelValue.val === 1">
      <div v-for="(el, ind) in modelValue.options" :key="ind" class="el"
        :class="{'active': el.chk}"
        @click="check(ind)"
        @keydown.f7="check(ind)">
        <div class="squad">
          <span class="galka">&#10004;</span>
        </div>
        <span>{{el.lbl}}</span>
      </div>
    </div>
    <div class="tab" v-if="modelValue.val === 2">
      <div v-for="(el, ind) in modelValue.options" :key="ind" class="el"
        :class="{'active': el.chk}"
        @click="check(ind)"
        @keydown.f7="check(ind)">
        <span>{{el.lbl}}</span>
      </div>
    </div>
    <div class="tab" v-if="modelValue.val === 3 && currOption.options">
      <div class="ryad">
        <InputText v-model="currOption.options.interval"
          lbl="X дни" />
      </div>
      <div class="ryad">
        <InputCalendar
          :lbl="currOption.options.day_start.lbl"
          :months="currOption.options.day_start.months"
          :week-days="currOption.options.day_start.weekDays"
          v-model="currOption.options.day_start.value" />
      </div>
    </div>
    <div class="tab" v-if="modelValue.val === 4 && currOption.options">
      <div v-for="(month, ind1) in currOption.options.days" :key="ind1" class="four-month">
        <div class="four-title">{{month.lbl}}</div>
        <div v-for="(lbl ,ind2) in currOption.options.weekDays" :key="ind2" class="four-head">
          {{lbl}}
        </div>
        <div v-for="(ryad, ind2) in month.days" :key="ind2" >
          <div v-for="(day, ind3) in ryad" :key="ind3"
            class="four-day"
            :class="{active: day.chk}"
            @click="check2(ind1, ind2, ind3)"
            @keydown.f7="check2(ind1, ind2, ind3)">
            {{day.lbl}}
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import InputText from '@/components/atoms/InputText.vue';
import InputCalendar from '@/components/atoms/InputCalendar.vue';

export default {
  name: 'ModeTime',
  components: {
    InputText, InputCalendar,
  },
  props: ['data', 'modelValue'],
  data() {
    return {
      showList: false,
      currOption: {},
    };
  },
  mounted() {
    this.currOption = this.modelValue;
  },
  methods: {
    selectOption(option) {
      this.showList = false;
      this.currOption = option;
      this.$emit('update:modelValue', option);
      this.$emit('change');
    },
    closeSelect() {
      this.showList = false;
    },
    check(ind) {
      if (this.modelValue.val === 1) {
        const option = this.modelValue;

        if (option.options[ind].chk) {
          option.options[ind].chk = false;
        } else {
          option.options[ind].chk = true;
        }

        this.$emit('update:modelValue', option);
      }
      if (this.modelValue.val === 2) {
        const option = this.modelValue;

        if (option.options[ind].chk) {
          option.options[ind].chk = false;
        } else {
          option.options[ind].chk = true;
        }

        this.$emit('update:modelValue', option);
      }
    },
    check2(ind1, ind2, ind3) {
      if (this.currOption.options.days[ind1].days[ind2][ind3].chk) {
        this.currOption.options.days[ind1].days[ind2][ind3].chk = false;
      } else {
        this.currOption.options.days[ind1].days[ind2][ind3].chk = true;
      }
    },
  },
};
</script>

<style scoped>
  .four-month {
    display: inline-block;
    width: 260px;
    vertical-align: top;
    margin-bottom: 20px;
  }
  .four-title{
    font-weight: bold;
    padding:6px;
  }
  .four-title:first-letter{
    text-transform:uppercase;
  }
  .four-head {
    display: inline-block;
    width: 20px;
    padding: 6px;
    font-weight: bold;
  }
  .four-day {
    display: inline-block;
    width:20px;
    padding:6px;
    cursor: pointer;
  }
  .four-day:hover {
    background: #aaa;
  }
  .four-day.active {
    background: #63b15e;
    color: #fff;
  }

  .ryad{
    width: 250xp;
    display: inline-block;
    vertical-align: top;
    margin-right:6px;
  }
  .tab{
    padding:12px;
    border: 1px solid #aaa;
    border-top: none;
    border-left-width: 4px;
  }
  .tab .el{overflow: hidden;
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 6px;
    padding: 2px 6px 2px 6px;
  }
  .tab .el .galka{display:none; color: #000;}
  .tab .el:hover{
    cursor: pointer;
    background: #aaa;
  }
  .tab .el.active{
    background: #63b15e;
    color: #fff;
  }
  .tab .el.active .galka{display: inline;}
  .tab .el .squad{margin-right: 6px;}
  .tab .el span{line-height: 20px}
  .wb-select{
    position:relative;
    border-radius: 0 4px 4px 0;
    border: 1px solid #aaa;
    cursor: pointer;
    background: #fff;
    border-left: 4px solid #aaa;
    min-width: 200px;
  }
  .wb-select:hover{background:#ccc}
  .wb-select-label{
    padding: 10px 12px;
    white-space: nowrap;
  }
  .val-text{
    overflow: hidden;
  }
  .wb-select-label:hover{background:#efefef;}
  .wb-select-list{
    position: absolute;
    left: -4px;
    top: 26px;
    margin-top: 10px;
    width: 100%;
    max-height: 250px;
    background: #fff;
    border: 1px solid #aaa;
    border-radius: 0px 0px 4px 4px;
    overflow-y: auto;
    border-left: 4px solid #aaa;
    z-index:2
  }
  .list--item{padding:8px 12px }
  .list--item:hover{background:#ddd}

  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }

  /* W3C standard сейчас только для Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
  }
  /* для Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d7d7d7;
    width:3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #848484;
  /*  border-radius: 5px; */
    border: 2px solid #d7d7d7;
  }
  .icon-right{
    float: right;
    margin-left: 5px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .squad{
    width:18px;
    height:18px;
    border:1px solid #aaa;
    float:left;
    cursor:pointer;
    text-align:center;
    line-height:19px;
    border-radius:2px;
    background:#fff;
  }
  .squad:hover{background:#efefef}
</style>
