<template>
  <div class="wb-autocomplette" v-click-outside="closeSelect">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <div
      @mouseenter="entermouse"
      @mouseleave="leavemouse"
      @focusin="entermouse"
      @focusout="leavemouse"
      >
      <input :id="id"
        class="text"
        autocomplete="off"
        @input="searchEls" v-model="search" :placeholder="def"/>
      <div v-if="showClear" class="btn-clear" @click.stop="newinput" @keydown.tab.stop="newinput">
        <i class="fa fa-times-circle" aria-hidden="true"></i>
      </div>
    </div>
    <transition name="fade">
      <div class="wb-autocomplette-list" v-if="showList">
        <template v-if="options.length">
          <div v-for="(el, index) in options"
            :key="index"
            @click="selectProduct(el)"
            @keydown.enter="selectProduct(el)">
              <div v-if="el.show == 1">{{el.lbl}}
                <a v-if="el.group_type == 0" href="javascript:void(0)"
                @click="selectElement(index)"><i>Добавить</i></a>
                <span v-else>в группе</span>
              </div>
          </div>
        </template>
        <template v-else>
          <div class="list--item">Не найдено</div>
        </template>
      </div>
    </transition>
    <div class="area-selected">
      <template v-if="listSel.length">
        <div v-for="(el, ind) in listSel" :key="ind">
          {{el.lbl}} <a href="javascript:void(0)" @click="dropElement(ind)">Убрать</a>
        </div>
      </template>
      <div v-else class="text-area-empty">
        Список товаров в группе...
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AutoComplette',
  props: ['lbl', 'text', 'def', 'modelValue', 'action'],
  data() {
    return {
      showList: false,
      search: this.text,
      loader: false,
      modeInput: false,
      showClear: false,
      tempName: '',

      listSel: [],
      id: null,
      options: [],
    };
  },
  mounted() {
    this.id = `auto${this.lbl.length}`;
    this.init();
    this.listSel = this.modelValue;
  },
  methods: {
    init() {
      axios.get(this.action)
        .then((res) => {
          this.options = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectElement(ind) {
      this.listSel.push(this.options[ind]);

      this.options[ind].group_type = 1;
      this.showList = false;
    },
    dropElement(ind) {
      this.listSel[ind].group_type = 0;

      this.listSel.splice(ind, 1);
    },
    searchEls() {
      this.showList = true;
      let i = this.options.length;
      const s = this.search.toLowerCase();
      while (i) {
        i -= 1;
        const l = this.options[i].lbl.toLowerCase();

        if (~l.indexOf(s)) { // eslint-disable-line no-bitwise
          this.options[i].show = 1;
        } else {
          this.options[i].show = 0;
        }
      }
    },
    visibleAll() {
      let i = this.options.length;
      while (i) {
        i -= 1;
        this.options[i].show = 1;
      }
    },
    sendDataList() {
      if (this.search.length > 2) {
        this.showList = true;
        axios.get(this.url, {
          params: {
            search: this.search,
            limit: 50,
          },
        })
          .then((res) => {
            this.options = res.data.list;
            this.showList = true;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.options = [];
        this.showList = false;
      }
      if (this.search === '') {
        this.showClear = false;
      } else {
        this.showClear = true;
      }
    },
    selectProduct(val, lbl) {
      this.showList = false;
      this.search = lbl;
      // this.$emit('change');
      // this.$emit('update:modelValue', val);
      this.modeInput = false;
    },
    closeSelect() {
      console.log('sd2');
      this.showList = false;

      if (this.modeInput) {
        this.modeInput = false;
        this.search = this.tempName;
      }
    },
    entermouse() {
      if (this.search !== '') {
        this.showClear = true;
      }
    },
    leavemouse() {
      this.showClear = false;
    },
    newinput() {
      const tempName2 = this.search;
      this.tempName = tempName2;
      this.search = '';
      this.showClear = false;
      this.modeInput = true;

      document.getElementById(this.id).focus();
    },
  },
};
</script>

<style scoped>
  .wb-autocomplette{
    position: relative;
  }
  .wb-autocomplette input.text{
    line-height: 18px;
    border: 1px solid #aaa;
    padding: 10px 12px;
    border-radius: 0px 4px 4px 0px;
    border-left-width: 4px;
    outline:none;
    box-sizing: border-box;
    width: 250px;
    font-size: 16px;
  }
  .wb-autocomplette input.text:hover{
    background: #efefef;
  }
  .wb-autocomplette-list{
    position: absolute;
    left: 0px;
    top: 39px;
    border:1px solid #aaa;
    border-left-width: 4px;
    min-width: 245px;
    max-width: 320px;
    max-height: 340px;
    overflow-y: auto;
    background: #fff;
    z-index: 12;
  }

  /* wb100 */
  .wb-autocomplette.wb100 input.text{
    width: 100%;
  }
  .wb100 .wb-autocomplette-list{
    max-width: 100%;
  }

  .text-area-empty{
    color: #a7a7a7;
  }

  .wb-autocomplette-list .list--item {
    padding:8px 12px;
    cursor: pointer;
  }
  .list--item:hover {
    background: #ddd
  }
  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }
  .btn-clear{
    position: absolute;
    top: 50%;
    margin-top: -12px;
    right: 6px;
    cursor: pointer;
    font-size: 24px;
    z-index: 15;
    background: #fff;
    border-radius: 24px;
    line-height: 23px;
    color: #777;
  }

  .area-selected{
    box-sizing: border-box;
    width: 100%;
    min-height: 80px;
    margin-top: 4px;
    border: 1px solid #aaa;
    padding: 10px 12px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
