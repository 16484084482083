<template>
  <div class="stick-right">{{products.length}}</div>
  <div class="wb-btn-sh wb-btn-success offset-r"
    @click="synhOzon"
    @keydown.f7="synhOzon">Синхронизировать FBO товары с OZON</div>

  <div class="wb-btn-sh wb-btn-success offset-r"
    @click="synhOstatki"
    @keydown.f7="synhOstatki">Синхронизация остатков</div>

  <div class="wb-btn-sh wb-btn-success offset-r"
    @click="synhProdsData"
    @keydown.f7="synhProdsData">Синронизировать инфу по товарам</div>

  <div class="wb-btn-sh wb-btn-success offset-r"
    @click="synhPays"
    @keydown.f7="synhProdsData">Синхр. продажи(возвраты)</div>

  <div class="wb-btn-sh wb-btn-success offset-r"
    @click="postingFbo"
    @keydown.f7="postingFbo">Получить список отправлений</div>

  <pre>{{info}}</pre>

  <table class="list-table">
    <tr>
      <th>Наименование</th>
      <th>FBO Остаток</th>
      <th>FBO Зарезервировано</th>
      <th>FBS Остаток</th>
      <th>FBS Зарезервировано</th>
      <th>Продано FBO</th>
      <th>Возврат FBO</th>
      <th>Цена</th>
    </tr>
    <tr v-for="(el, ind) in products" :key="ind">
      <td v-for="(el2, ind2) in el" :key="ind2">{{el2}}</td>
    </tr>
  </table>

  <pre>{{errors}}</pre>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TestPage',
  data() {
    return {
      products: [],

      info: [],
      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  computed() {

  },
  methods: {
    init() {
      axios.get('/god/test/test')
        .then((res) => {
          this.products = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    synhOzon() {
      axios.get('/god/test/synh-fbo-prods')
        .then((res) => {
          this.info = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    synhOstatki() {
      axios.get('/god/test/synh-ostatki')
        .then((res) => {
          this.info = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    synhProdsData() {
      axios.get('/god/test/synh-prods-data')
        .then((res) => {
          this.info = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    synhPays() {
      axios.get('/god/test/synh-pays')
        .then((res) => {
          this.info = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    postingFbo() {
      axios.get('/god/test/posting-fbo')
        .then((res) => {
          this.info = res.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.offset-r{
  margin-right: 6px;
}
tr.first{cursor: pointer;}
tr.first:hover td{opacity: 0.9}
.instrumental{position: fixed;
  left:0px;
  bottom:0px;
  background: #aaa;
  width: 100%;
}

.btn.btn-add{
  background: #5981ce;
  color: #fff;
  padding: 6px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.btn.btn-add:hover{
  background: #3b70d7;
  padding: 8px;
}
.btn.btn-add span, .btn.btn-add i{
  vertical-align: middle;
}

.list-table td.cell-act{
  padding: 0px;
}
.acts__item{
  padding:0px
}
.acts__item a{
  font-size: 25px;
}
.list-table{
  width:100%;
  text-align: left;
}

.list-table tr.odd{
  background: #e5f0ff;
}

.list-table th{
  background: #5981ce /* #5390ef */;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  line-height: 18px;
}
.list-table td{
  vertical-align:top;
  padding: 8px;
}
.list-list-item{
  padding: 4px;
  background: #ccc;
  margin-bottom: 1px;
  border-radius:2px;
  font-size:12px;
}
.list-list-item .unlink{float:right; padding-left:4px;}
.stick-right{
  float: right;
  height: 34px;
  line-height: 34px;
  width: 34px;
  text-align: center;
  background: #f9d0d0;
  border: 1px solid #a17979;
  margin-top: 1px;
  color: #700e0e;
}
</style>
