<template>
  <div class="rp">
    <h1>Управление ПРОИЗВОДСТВОМ</h1>
    <div>

    {{filterSet}}

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RpPage',
  data() {
    return {
      filterSet: '',
    };
  },
  mounted() {
    this.setFilter();
  },
  methods: {
    setFilter() {
      axios.get('/rp/panel/graph-stocks')
        .then((res) => {
          if (res.data.success === 1) {
            this.filterSet = res.data.pack;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>

</style>
