<template>
  <h1>
    <span>Сезоны/акции</span>
  </h1>

  <div class="wrp-tabs">
    <div
      v-for="(tab, ind) in tabs"
      :key="ind"
      :class="['btn-tab', { active: currentTab === tab.name }]"
      @click="selTab(tab)"
      @keydown.f7="selTab(tab)"
    >
      {{ tab.lbl }}
    </div>
    <div class="tab-content">
      <component :is="currentTabComponent" v-bind="currentOptions"></component>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import List2 from '@/components/list/List2.vue';

export default {
  name: 'IfaceAkcii',
  components: {
    List2,
  },
  data() {
    return {
      filterSet: '',

      currentTab: '',
      currOptions: {},
      tabs: [],
    };
  },
  mounted() {
    this.setTabs();
  },
  computed: {
    currentTabComponent() {
      return `${this.currentTab}`;
    },
    currentOptions() {
      return this.currOptions;
    },
  },
  methods: {
    selTab(tab) {
      this.currentTab = tab.name;
      this.currOptions = tab.options;
    },
    setTabs() {
      axios.get('/scm/akcii/set-iface')
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
            this.currentTab = res.data.component.name;
            this.currOptions = res.data.component.options;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 15px 0px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
  background: #e7e7e7;
  transition: background-color 0.3s;
}
.btn-tab:hover{background-color:#d0d0d0;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
</style>
