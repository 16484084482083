<template>
  <div>
    <h1 style="position: sticky; top: 0px; left: 0px; height: 27px;
      overflow:hidden; background: #fff; z-index: 1;">
      <span>{{title}}</span>
      <div class="btn-close-big"
        @click.prevent="closeOuterModal"
        @keydown.f7="closeOuterModal">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
      <span :style="{'background-color': bgcolor, color: '#000'}"
        style="float:right">{{sortedList.length}}</span>
    </h1>
    <div v-if="pagination.cnt && pagination.els.length > 1" class="pagination">
      <div v-for="(el, ind) in pagination.els" :key="ind"
        class="pag-el"
        :class="{active: pagination.page == el.lbl}"
        @click="pagLoad(el)"
        @keydown.f7="pagLoad(el)">{{el.lbl}}</div>
    </div>
    <transition tag="div" style="position:relative; overflow:hiden">
      <div style="position:absolute;" v-if="loader" class="spinner">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
      <div style="position:absolute;" v-else>
        <div class="wrp-table">
          <table class="table-iface">
            <thead>
              <tr class="tr-head">
                <th v-for="(el, ind) in head" :key="ind"
                :title="el.lbltitle"
                :class="{hover: el.sort.show, active: el.sort.active > 0,
                  'btn-filter': ind === 0, 'btn-active': showFilter && ind === 0}"
                @click="sort(ind)">
                  <i v-if="el.sort.active == 1"
                    class="fa"
                    :class="{
                      'fa-sort-amount-asc': el.sort.val == 0,
                      'fa-sort-amount-desc': el.sort.val == 1
                    }"
                    aria-hidden="true"></i>
                  <template v-if="ind === 0">
                    <i title="Показать/скрыть фильтр" class="fa fa-filter"
                      aria-hidden="true"></i>
                  </template>
                  <template v-else>
                    <span v-if="el.type === 0">{{el.lbl}}</span>
                    <span v-if="el.type === 1" v-html="el.lbl" class="th-icon"
                      :style="{'color': el.color}" ></span>
                  </template>
                </th>
              </tr>
              <tr class="table-filter" v-if="extfilter.length"
              :class="{'filter-hide': !showFilter, 'filter-show': showFilter}">
                <td v-for="(el, ind) in extfilter" :key="ind"
                style="padding: 0px;">
                  <div v-if="ind === 0" class="wrp-icon-filter">
                    <i class="fa fa-filter big-icon" aria-hidden="true"></i>
                  </div>
                  <InputTextShort v-if="el.type == 'I' || el.type == 'S'"
                    v-model="el.value"
                    @change="chgExtFilter"
                    class="short-input-filter" />
                </td>
              </tr>
            </thead>
            <transition name="fade">
              <tbody v-if="loaderBody" class="bg-loader">
                <tr><td colspan="12">
                  <div class="spinner">
                    <i style="color:#fff"
                       class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                    <i style="color:#2567f3"
                       class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                    <i style="color:#f32525"
                       class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                  </div>
                </td></tr>
              </tbody>
              <transition-group v-else-if="sortedList.length" name="list" tag="tbody">
                <tr class="moving-item" v-for="(tr, ind) in sortedList"
                  :key="ind" :class="{disabled: tr.disabled == 1}">
                  <td v-for="(elem, ind2) in tr.fields" :key="ind2">
                    <template v-if="elem.type === 'S'">
                      <router-link v-if="elem.link" class="btn-field"
                        :to="elem.link.url" :title="elem.link.title" >
                        <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                      </router-link>
                      <i v-if="elem.icon && parseInt(elem.value) > 0" class="icon-last-status fa"
                        :class="elem.icon.fa"
                        :title="elem.icon.title" ></i>
                      <span>{{elem.value}}</span>
                    </template>
                    <template v-if="elem.type === 'L'">
                      <router-link v-if="elem.link" class="btn-field"
                        :to="elem.link.url" :title="elem.link.title" >
                        <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                      </router-link>
                      <a href="javascript:void(0)" @click.prevent="showArticleBtn(elem)"
                        class="btn-a">{{elem.value}}</a>
                    </template>
                    <template v-if="elem.type === 'B'">
                      <div class='progress progress-striped'>
                        <div class='progress-bar' :class="elem.opt.color"
                          role='progressbar' :aria-valuenow='elem.lbl'
                          aria-valuemin='0' aria-valuemax='100'
                          :style='{width: elem.opt.w_block}'>
                          <span class='sr-only'>{{elem.lbl}}</span>
                        </div>
                        <div v-if="elem.opt.color_text"
                          class="buffer-lbl"
                          :class="elem.opt.color_text" >{{elem.value}} %</div>
                      </div>
                    </template>
                    <template v-if="elem.type === 'M'">
                      <div v-if="elem.link.disabled == 0"
                        @click="showModal(elem.link.param, ind)"
                        @keydown.f7="showModal(elem.link.param, ind)"
                        class="btn-cart" :title="elem.title">
                        <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                      </div>
                      <span class="btn-cart disabled" v-else>
                        <i class="fa" :class="elem.link.icon" aria-hidden="true"></i>
                      </span>
                    </template>
                  </td>
                </tr>
              </transition-group>
              <tbody v-else class="text-warn-1">
                <tr><td colspan="12">Список пуст</td></tr>
              </tbody>
            </transition>
          </table>
        </div>
      </div>
    </transition>
  </div>

  <transition name="fade">
  <div v-if="showArticle" class="context-menu modal-form"
    @click="closeModalArticle"
    @keydown.f7="closeModalArticle">
    <div class="context-menu--body" style="padding:0px !important;"  @click.stop >
      <div class="head-graph" v-if="article.info.list">
        <div class="line-1">
          <strong class="stock-head">{{article.info.list[0][0]}}: </strong>
          <span class="stock-name">{{article.info.list[0][1]}}</span>&nbsp;
          <strong class="category-head">{{article.info.list[1][0]}}: </strong>
          <span class="category-name">{{article.info.list[1][1]}}</span>
        </div>
        <div class="btn-close-big" @click.prevent="closeModalArticle"
          @keydown.f7="closeModalArticle">
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        <div class="line-1">
          <span>[{{article.info.list[2][1]}}] </span>
          <span class="prod-name">{{article.info.list[3][1]}}</span>
          <router-link class="btn-a"
            style="padding-left: 20px; padding-left: 20px; font-size: 14px; color: #367ce0;"
            :to="{path: article.linkUrl, query: article.linkParam}"
            @click.stop="closeModalArticle"
            title="Перейти в отчет по товару" >
            <span>Перейти в отчет <i class="fa fa-external-link" aria-hidden="true"></i></span>
          </router-link>
        </div>
      </div>
      <div class="wrp-graph"
        v-show="article.graphData.length">
        <WBChart
          :dataGraph = "article.graphData"
          :options = "article.graphOptions"
          @chg-options = "toggleGraph"/>
      </div>

      <div class="inline-block" v-if="article.info.title">
        <div class="title">{{article.info.title}}</div>
        <table>
          <tr v-for="(elem, ind) in article.info.list" :key="ind">
            <template v-if="ind > 3">
              <td v-for="(el, ind2) in elem" :key="ind2">{{el}}</td>
            </template>
          </tr>
        </table>
      </div>
      <div class="inline-block" v-if="article.info2.title">
        <div class="title">{{article.info2.title}}</div>
        <table>
          <tr>
            <th v-for="(elem, ind) in article.info2.head" :key="ind">{{elem}}</th>
          </tr>
          <tr v-for="(elem, ind) in article.info2.list" :key="ind">
            <td v-for="(el, ind2) in elem" :key="ind2">{{el}}</td>
          </tr>
        </table>
      </div>

    </div>
  </div>
  </transition>
</template>

<script>
import axios from 'axios';
import WBChart from '@/components/parts/WBChart.vue';
import InputTextShort from '@/components/atoms/InputTextShort.vue';

export default {
  name: 'listFilterSort',
  components: {
    InputTextShort, WBChart,
  },
  emits: ['closeOuterModal'],
  props: {
    action: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urlAction: this.action,
      urlParams: this.params,
      bgcolor: '#d2d2d2',
      procent: 0,

      list: [],
      head: [],
      sortIndex: 0,
      extfilter: [],
      filteredList: [],
      showFilter: true,

      loader: true,
      loaderBody: false,

      title: '',

      filter: {},

      pagination: {
        cnt: 0,
        els: [],
        page: 1,
      },

      answerText: '',
      errText: '',
      errors: [],

      showArticle: false,
      article: {
        graphData: [],
        graphOptions: [],
        info: {
          title: '',
        },
        info2: {
          title: '',
        },
        linkUrl: '',
        linkParam: '',
      },
    };
  },
  mounted() {
    this.init();

    if (this.urlParams.stockName) {
      this.title = this.urlParams.stockName;
      if (this.urlParams.color) {
        this.title = `${this.title} [${this.urlParams.value} %]`;
      }
    }
    if (this.urlParams.color) {
      this.bgcolor = this.urlParams.color;
    }
  },
  computed: {
    sortedList() {
      const ind = this.sortIndex;

      if (this.head[ind]) {
        if (this.head[ind].sort.val) {
          if (this.head[ind].sort.typesort === 'N') {
            return this.filteredList.slice().sort((a, b) => (
              a.fields[ind].sortval < b.fields[ind].sortval ? 1 : -1
            ));
          }
          return this.filteredList.slice().sort((a, b) => (
            a.fields[ind].value < b.fields[ind].value ? 1 : -1
          ));
        }

        if (this.head[ind].sort.typesort === 'N') {
          return this.filteredList.slice().sort((a, b) => (
            a.fields[ind].sortval > b.fields[ind].sortval ? 1 : -1
          ));
        }
        return this.filteredList.slice().sort((a, b) => (
          a.fields[ind].value > b.fields[ind].value ? 1 : -1
        ));
      }
      return this.filteredList;
    },
  },
  methods: {
    init() {
      this.loadList(this.urlParams);
    },
    chgExtFilter() {
      // this.filteredList = JSON.parse(JSON.stringify(this.currentData));
      // this.loaderBody = true;
      const filter = [];
      for (let i = 0; i < this.extfilter.length; i += 1) {
        if (this.extfilter[i].value) {
          if (this.head[i].sort.typesort === 'N') {
            if (this.extfilter[i].value.indexOf('<=') === 0) {
              const newval = parseFloat(this.extfilter[i].value.replace('<=', ''));
              filter.push([i, newval, '<=']);
            } else if (this.extfilter[i].value.indexOf('>=') === 0) {
              const newval = parseFloat(this.extfilter[i].value.replace('>=', ''));
              filter.push([i, newval, '>=']);
            } else if (this.extfilter[i].value.indexOf('>') === 0) {
              const newval = parseFloat(this.extfilter[i].value.replace('>', ''));
              filter.push([i, newval, '>']);
            } else if (this.extfilter[i].value.indexOf('<') === 0) {
              const newval = parseFloat(this.extfilter[i].value.replace('<', ''));
              filter.push([i, newval, '<']);
            } else {
              filter.push([i, parseFloat(this.extfilter[i].value), '']);
            }
          } else {
            filter.push([i, this.extfilter[i].value]);
          }
        }
      }

      if (filter.length) {
        this.filteredList = this.list.filter((el) => {
          let flagRes = false;
          for (let i = 0; i < filter.length; i += 1) {
            if (this.head[filter[i][0]].sort.typesort === 'N') {
              if (filter[i][2] === '' && el.fields[filter[i][0]].sortval === filter[i][1]) {
                flagRes = true;
              } else if (filter[i][2] === '>' && el.fields[filter[i][0]].sortval > filter[i][1]) {
                flagRes = true;
              } else if (filter[i][2] === '<' && el.fields[filter[i][0]].sortval < filter[i][1]) {
                flagRes = true;
              } else if (filter[i][2] === '>=' && el.fields[filter[i][0]].sortval >= filter[i][1]) {
                flagRes = true;
              } else if (filter[i][2] === '<=' && el.fields[filter[i][0]].sortval <= filter[i][1]) {
                flagRes = true;
              }
            } else if (el.fields[filter[i][0]].value.toLowerCase()
              .indexOf(filter[i][1].toLowerCase()) !== -1) {
              flagRes = true;
              break;
            }
          }
          return flagRes;
        });
      } else {
        this.filteredList = JSON.parse(JSON.stringify(this.list));
      }
    },
    loadList(params) {
      axios.post(this.urlAction, params)
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.items;
            this.head = res.data.list.heads;
            this.extfilter = res.data.listFilter;
            this.filteredList = JSON.parse(JSON.stringify(res.data.list.items));

            this.pagination = res.data.pagination;
          }
          this.loader = false;
        })
        .catch((e) => {
          this.loader = false;
          this.errors.push(e);
        });
    },
    closeOuterModal() {
      this.$emit('closeOuterModal');
    },

    pagLoad(el) {
      if (this.pagination.page === el.lbl) { return; }

      this.pagUrlActive = el.val;

      this.loaderBody = true;

      setTimeout(() => {
        this.loadList(1);
      }, 300);
    },
    showList() {
      this.loader = true;
      this.pagUrlActive = '/scm/product/sett-iface';
      setTimeout(() => {
        this.loadList(0);
      }, 300);
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
    },

    sort(ind) {
      if (!this.head[ind].sort.show) {
        if (ind === 0) {
          this.showFilter = !this.showFilter;
        }
        return;
      }

      this.sortIndex = ind;
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
      }
    },

    showArticleBtn(el) {
      this.showArticle = true;
      document.body.style.overflowY = 'hidden';

      this.article.linkUrl = el.url;
      this.article.linkParam = el.param;

      const filterArticul = {
        left: [
          {
            value: {
              val: el.param.stock,
            },
          },
          {
            value: el.param.prod,
          },
        ],
      };

      axios.get('/scm/api/report/articul', { params: { filter: filterArticul } })
        .then((res) => {
          this.article.graphData = res.data.graphData;
          this.article.graphOptions = res.data.graphOptions;

          this.article.info = res.data.info;
          this.article.info2 = res.data.info2;
        })
        .catch(() => {
        });
    },
    toggleGraph(data) {
      this.article.graphOptions.legend[data.ind].show = data.val;
    },
    closeModalArticle() {
      document.body.style.overflow = 'visible';
      this.showArticle = false;
    },
  },
};
</script>

<style scoped>
h1{margin-right: 0px;}
.wrp-graph{
  padding-left: 10px; padding-right: 10px;
  background: white;
}
.head-graph{
  padding-bottom: 20px;
  background: white;
  position: relative;
}
.line-1{
  background: #d2d2d2;
  padding: 3px 10px;
}
.line-1:first-child{
  padding-top: 10px;
}
.line-1:last-child{
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 24px;
  color: #cb2222;
}
.inline-block{
  display: inline-block;
  vertical-align: top;
  padding: 10px 20px;
}
.inline-block .title{
  font-weight: bold;
  margin-bottom: 10px;
}
.inline-block table{
  border-collapse: collapse;
}
.inline-block table th{
  background: transparent;
  padding: 4px;
}
.inline-block tr td{
  padding: 4px;
  border-bottom: 1px solid #aaa;
}
.inline-block tr:empty{
  display: none;
}
.btn-m{
  font-size: 22px; line-height: 22px;
  display:inline-block;
  vertical-align: top;
  cursor: pointer;
  color: #556b7d;
}
.big-icon{
  line-height: 32px;
  font-size: 18px;
}
.wrp-icon-filter{
  width: 100%;
  text-align: center;
  background: #e6e6e6;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

table th {
  position: sticky;
  top: 28px;
  background: white;
  z-index: 10;
  transition: width 1s;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.btn-active{
  color: #5981ce;
}
.tr-head span{
  margin-right: 15px;
}
.right{
  float: right;
}
.modal-form{
  text-align: center;
}
.wrp-table{
  margin-bottom: 40px;
}
.short-input{
  width:100px;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.icon-last-status{
  font-size: 22px;
  font-weight: bold;
  padding-right: 6px;
}

.btn-close{
  float: right;
  font-size: 20px;
  color: #780c0c;
  cursor: pointer;
}
.btn-close:hover {
  color: #bf1111;
}

.btn-close-big{
  float: right;
  font-size: 28px;
  line-height: 30px;
  color: #780c0c;
  cursor: pointer;
  transition: color 0.7s;
  padding-left: 3px;
  padding-right: 3px;
}
.btn-close-big:hover {
  color: #bf1111;
}

.err-text{
  margin-top: -18px;
  margin-bottom: 10px;
  color: #b34040;
  background: #ffdfdf;
  padding: 4px;
}

.block .column{
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}
.wrp-table{
  /*
  margin-right: 12px;
  margin-left: 12px;
  */
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: bottom;
  cursor: default;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

.instrumental-item{margin: 0px 1px;}

.btn-field{color: #556b7d; font-size:22px; line-height:22px; margin-right:4px;}
.btn-field:hover{color: #2f4a60;}

.btn-a{color: #556b7d}
.btn-a:hover {color: #2f4a60; text-decoration: underline;}

.btn-cart{
  display: block;
  width: 100%;
  background: #44b752;
  border: 1px solid #2c9539;

  color:#fff;
  text-align: center
}
.btn-cart:hover{background: #2c9539}
.btn-cart.disabled{
  background: #a4e5ac;
  border-color: #ddd;
  position: relative;
}

.btn-cart.disabled:after{
  content: '';
  position: absolute;
  top: 5px;
  left: 40%;
  margin-left: -15px;
  width: 60px;
  height: 30px;
  border-top: 2px solid red;
  transform: rotate(-40deg);
}

tr.disabled {
  opacity: 0.5;
}

.context-menu{position: fixed; background: #1e1e1ecf;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display:inline-block;
  vertical-align: middle;
  line-height:100vh;
}
.context-menu--body{
  background: #d2d2d2;
  width:auto; display: inline-block;
  text-align: left;
  line-height: 20px;
  max-height: 100%;
  display: inline-block;
  vertical-align: middle;
  content: '';
  overflow:auto;
  scrollbar-width: thin;
  scrollbar-color: #848484 #d7d7d7;
  padding: 15px;
}
.context-menu--body .block{
  margin: 25px 0px;
}

.context-menu--item{display:block;
line-height: 20px;
cursor:pointer;
padding:12px 6px;
}
.context-menu--item:hover{
  color: #fff;
  background:#5981ce;
}
.form-answer{padding:20px;}

.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress.progress-striped{
  position:relative;
  margin-bottom:0px !important;
  text-align: center;
}
.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    -webkit-transition: width .6s ease;
    -o-transition: width .6s ease;
    transition: width .6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, .15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .15) 50%,
    rgba(255, 255, 255, .15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
    background-color: #5baade;
}

.progress-bar-black {
    background-color: #757575;
}
.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-bar-danger {
    background-color: #d9534f;
}

.progress-bar-success {
    background-color: #5cb85c;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}

.buffer-lbl{
  position: absolute;
  width: 100%;
}

.white-text {
    color: #fff;
}
.th-icon{font-size: 21px;}

.buffer-lbl{
  position: absolute;
  width: 100%;
}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}
</style>
