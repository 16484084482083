<template>
  <div class="wb-textarea">
    <div class="label" v-if="lbl">{{lbl}}</div>
    <label for="txtr">
      <textarea id="txtr" :placeholder="def" :value="modelValue" @input="onInput" ></textarea>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TextareaCustom',
  props: ['lbl', 'def', 'err', 'req', 'modelValue'],
  data() {
    return {
    };
  },
  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped>
  .wb-textarea{
    position: relative;
  }
  .label{
    position: absolute;
    background: #5981ce;
    left: 8px;
    top: -10px;
    font-size: 14px;
    line-height: 16px;
    padding: 1px 12px;
    border-radius: 0px;
    color: #fff;
  }
  textarea{
    padding: 10px 12px;
    width: 100%;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    border:1px solid #aaa;
    border-radius: 0 4px 4px 0;
    border-left-width: 4px;
    box-sizing: border-box;
  }
  textarea:hover{background: #efefef}
</style>
