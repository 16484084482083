<template>
  <div class="about">
    <h1><span>Управление политиками ЦУ 1</span></h1>
    <div id="dynamic-component-demo" class="demo">
      <div
        v-for="(tab, ind) in tabs"
        :key="ind"
        :class="['btn-tab', { active: tab.show}]"
        @click="selTab(ind)"
        @keydown.f7="selTab(ind)"
      >{{tab.lbl}}</div>
      <div class="tab-content">
        <div v-for="(tab, ind) in tabs" :key="ind" class="tab">
          <component v-if="tab.show"
            :is="tab.name"
            v-bind="tab.options"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import ListManage from '@/components/experiment/ListManage.vue';
import ListManageSelect from '@/components/experiment/ListManageSelect.vue';

export default {
  name: 'MPolitika',
  components: {
    ListManage, ListManageSelect,
  },
  data() {
    return {
      filterSet: '',

      tabs: [],
    };
  },
  mounted() {
    this.setTabs();
  },
  methods: {
    selTab(ind) {
      for (let i = 0; i < this.tabs.length; i += 1) {
        if (i === ind) {
          this.tabs[i].show = true;
        } else {
          this.tabs[i].show = false;
        }
      }
    },
    setTabs() {
      axios.get('/god/politika/set-tabs')
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 20px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-tab:hover{background:#eee;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
</style>
