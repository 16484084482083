<template>
  <div v-if="els" class="els">
    <div class="title">{{title}}</div>
    <div class="wrp-prods">
      <div v-for="(el, ind) in els" :key="ind" class="order-pack">
        <div class="number">№{{el.id}}</div>
        <div class="suname"><strong>поставщик: </strong>{{el.suname}}</div>
        <div class="stname"><strong>склад: </strong>{{el.stname}}</div>
        <div v-for="(el2, ind2) in el.els" :key="ind2" class="row">
          <div class="cnt">
            <InputTextShort
              v-model="el2.cnt"
              @change="chg(ind, ind2, $event)"/>
          </div>
          <div class="name">{{el2.name}}</div>
        </div>
      </div>
    </div>
    <div class="wb-btn wb-btn-success" @click="answerBtn"
      @keydown.f7="answerBtn">
      {{btn ? btn : 'Подтвердить'}}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import InputTextShort from '@/components/atoms/InputTextShort.vue';

export default {
  name: 'SynhModal',
  components: {
    InputTextShort,
  },
  props: ['fields', 'action', 'title', 'btn'],
  data() {
    return {
    };
  },
  computed: {
    els() {
      return this.fields;
    },
  },
  methods: {
    chg(ind, ind2, e) {
      axios.post(this.action, {
        id: this.els[ind].id,
        val: this.els[ind].els[ind2].val,
        cnt: this.els[ind].els[ind2].cnt,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer(e.pageX, e.pageY, res.data.answer, 'ok');
          } else {
            this.answer(e.pageX, e.pageY, res.data.answer, 'error');
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    answerBtn() {
      const msg = {
        success: 1,
        answer: 'Заказы подтверждены',
      };
      this.$emit('answerForm', msg);
    },
    answer(x, y, text, type) {
      const el = document.createElement('div');

      if (typeof el.textContent !== 'undefined') {
        el.textContent = text;
      } else {
        el.innerText = text;
      }

      if (type === 'ok') {
        el.style.background = '#acd5ab';
        el.style.color = '#10420f';
      } else if (type === 'error') {
        el.style.background = '#e9bebe';
        el.style.color = '#b32222';
      } else {
        el.style.background = '#fff';
        el.style.color = '#000';
      }

      el.innetHTML = text;
      el.className = 'msg';
      el.style.maxWidth = '300px';
      el.style.boxShadow = '0px 1px 2px #000';
      el.style.borderRadius = '4px';
      el.style.padding = '4px 10px';
      el.style.position = 'fixed';
      el.style.transition = 'all 4s';
      el.style.top = `${y}px`;
      el.style.left = `${x}px`;
      // el.position.top = `${x} px`;
      // el.position.left = `${y} px`;

      document.body.append(el);
      setTimeout(() => {
        el.style.top = `${y - 50}px`;
        el.style.left = `${x}px`;
      }, 50);

      setTimeout(() => {
        el.remove();
      }, 4000);
    },
  },
};
</script>

<style scoped>
.order-pack{
  margin-bottom:12px;
}
.number{
  display:inline-block;
  background: #ddd;
  padding: 1px 4px 0px;
  font-weight: bold;
}
.suname, .stname{
  background: #ddd;
  margin-left: -10px;
  margin-right: -10px;
  padding: 2px 10px;
}
.suname strong, .stname strong{
  font-size: 12px;
}
.row{
  overflow: hidden;
  background: #ddd;
  margin-left: -10px;
  margin-right: -10px;
  padding: 2px 10px;
}
.row:hover{
  background: #c5c5c5;
}

.row .cnt{float: right; width: 70px}
.row .name{line-height: 28px;}
.els{
  min-width: 320px;
  padding: 10px;
}
.title{
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}
.wrp-prods{
  margin-bottom: 20px;
}
</style>
