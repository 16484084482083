<template>
  <div class="wrp-board">
    ИНтерфейс добавления авто в парк.
  </div>
</template>

<script>

export default {
  name: 'ScmLogistPark',
  data() {
    return {
      filter: {},
      loader: false,

      action: '/scm/logist/board',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
};
</script>

<style scoped>
  .wrp-board{
    width: 100%;
    overflow-x: auto;
  }
</style>
