<template>
  <h1><span>Отчет по остаткам</span><span style="float:right">{{list.length}}</span></h1>

  <Filter
    :options = "filter"
    stick = ""
    @change-field="filterGet" />

  <table class="table-iface">
    <thead>
      <tr class="table-head">
        <th @click="toggleExtFilter" style="width: 10px">
          <i title="Показать/скрыть фильтр" class="fa fa-filter"
            aria-hidden="true"></i>
        </th>
        <th v-for="(el, ind) in head" :key="ind"
          :class="{hover: el.sort.show, active: el.sort.active > 0}"
          :style="{width: el.width}"
          @click="sort(ind)">
          <i v-if="el.sort.active == 1"
            class="fa"
            :class="{
              'fa-sort-amount-asc': el.sort.val == 0,
              'fa-sort-amount-desc': el.sort.val == 1
            }"
            aria-hidden="true"></i>
          <span>{{el.label}}</span>
        </th>
      </tr>
      <tr class="table-filter"
        :class="{'filter-hide': !showExtFilter, 'filter-show': showExtFilter}">
        <td style="padding: 0px">
          <div v-if="ind === 0" class="wrp-icon-filter">
            <i class="fa fa-filter big-icon" aria-hidden="true"></i>
          </div>
        </td>
        <td :title="el.title" v-for="(el, ind) in extFilter" :key="ind"
          style="padding: 0px;">
          <InputTextShort v-if="el.type == 'I' || el.type == 'S'"
            v-model="el.value"
            @change="filterGet"
            class="short-input-filter" />
        </td>
      </tr>
    </thead>
    <tbody v-if="list.length > 0">
      <tr v-for="(el, ind) in list" :key="ind">
        <td></td>
        <td>{{el.articul}}</td>
        <td>{{el.name}}</td>
        <td>{{el.cnt}}</td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr><td colspan="4">
        <div class="text-warn-1">
          Остатки отсутствуют
        </div>
      </td></tr>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';
import Filter from '@/components/parts/Filter.vue';
import InputTextShort from '@/components/atoms/InputTextShort.vue';

export default {
  name: 'ShowCnt',
  components: {
    Filter, InputTextShort,
  },
  data() {
    return {
      urlAction: '/scm/api/report/show-cnt',

      list: [],
      head: [],
      filter: [],
      showExtFilter: true,
      extFilter: [],

      loaderFilter: true,
      loader: true,
      errors: [],
    };
  },
  mounted() {
    if (localStorage.getItem('stock')) {
      this.urlAction += `?stockid=${localStorage.getItem('stock')}`;
    }
    this.init();
  },
  methods: {
    init() {
      axios.get(this.urlAction)
        .then((res) => {
          this.head = res.data.heads;
          this.list = res.data.list;
          this.filter = res.data.filter;
          this.extFilter = res.data.extFilter;
        })
        .catch(() => {
          this.loaderFilter = false;
          this.loader = false;
          this.$router.push('/404');
        });
    },
    filterGet() {
      axios.post(this.urlAction, {
        filter: this.filter,
        dopfilter: this.extFilter,
        sort: this.head,
      })
        .then((res) => {
          this.list = res.data.list;
          console.log(res.data);
        })
        .catch(() => {
          this.loaderFilter = false;
          this.loader = false;
          this.$router.push('/404');
        });
    },
    sort(ind) {
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
        this.filterGet();
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
        this.filterGet();
      }
    },
    toggleExtFilter() {
      this.showExtFilter = !this.showExtFilter;
    },
  },
};
</script>

<style scoped>
.table-head{
  cursor: pointer;
}
.report-area-btn{
  float: right;
  margin-right: 12px;
}
.btn-stick-def{
  background: #ddd;
  padding: 4px 10px;
  display: inline-block;
  color: #000;
}
.btn-stick-def.active{
  background: #5981ce;
  color: #fff;
}
.left-round{border-radius: 4px 0 0 4px;}
.right-round{border-radius: 0 4px 4px 0;}

.spinner {
  height: calc(100vh - 124px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.spinner i{background: #aaa; padding: 3px}

.title{
  text-align: center;
  /* background: #e0e6f7; */
  padding-top: 1px;
}
.title .center{
  padding: 4px 10px;
  display: inline-block;
  font-size: 22px;
  line-height: 24px;
  background-color: #7c9ede;
  border-radius: 4px;
  color: #fff;
}
.wrp-company{
  padding: 0px 10px;
}

.inline{
  display: inline-block;
  vertical-align: top;
}
.el2{
  margin-bottom: 12px;
}
.title2{
  font-size: 18px;
  line-height: 20px;
  padding: 6px 0px;
}
.one{
  margin-bottom: 3px;
}
.inline.first{
  background: #c3f0db;
  padding: 3px 5px;
  font-size: 16px;
  line-height: 20px;
}
.inline.second{
  line-height: 20px;
  padding: 3px 0px 3px 6px;
  cursor: default;
}
.inline.second:hover{
  background-color: #ddd;
}

.table-filter .short-input-filter{
  max-width: 100%
}
.table-iface .table-filter td{
  border: 1px solid #ccc;
  transition: width 1s;
}

.filter-hide td div{
    height: 0px;
    overflow: hidden;
    transition: height 0.5s;
}

table th {
  position: sticky;
  top: 50px;
  background: white;
  z-index: 10;
  transition: width 1s;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.btn-filter:hover{
  cursor: pointer;
}
.btn-active{
  color: #5981ce;
}

.table-iface{
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  border-collapse: collapse;
}

.table-iface th{
  background: #e6e6e6;
  border: 1px solid #ccc;
  vertical-align: botton;
  cursor: default;
  text-align:left;
}
.table-iface th, .table-iface td{
  line-height: 20px;
  padding: 8px;
  font-size: 14px;
}
.table-iface th.hover{
  cursor: pointer;
  white-space: nowrap;
}
.table-iface th.hover:hover{
  background: #dfdddd;
}
.table-iface th.active{
  background: #cbcaca;
}
.table-iface th.active:hover{
  background: #bfbbbb;
}

.table-iface td{
  border-bottom: 1px solid #ccc;
}

</style>
