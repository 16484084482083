<template>
  <div class="scm">
    <div class="demo">
      <div
        v-for="(tab, ind) in tabs" :key="ind"
        :class="['btn-tab', { active: tab.show }]"
        @click="selTab(ind)"
        @keydown.f7="selTab(ind)"
      >
        {{ tab.lbl }}
      </div>
      <div class="tab-content">
        <template v-for="(tab, ind) in tabs" :key="ind">
          <component v-if="tab.show" :is="tab.name" v-bind="tab.options" class="tab"></component>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import WBList from '@/components/molecules/WBList.vue';

export default {
  name: 'ScmSettingList',
  components: {
    WBList,
  },
  data() {
    return {
      tabs: [],

      errors: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      axios.get('/scm/settings-g/set-tabs-list')
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    selTab(ind) {
      this.loader = true;
      let len = this.tabs.length;
      while (len) {
        len -= 1;
        if (len === ind) {
          this.tabs[len].show = true;
        } else {
          this.tabs[len].show = false;
        }
      }
      this.loader = false;
    },
  },
};
</script>

<style scoped>
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 20px 10px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-tab:hover{background:#eee;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
</style>
